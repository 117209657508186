import React, { createContext, useState } from 'react';

export const ContextTripStepper = createContext();

const ProviderTripStepper = (props) => {
    const [ activeStep, setActiveStep ] = useState(0);

    return (
        <ContextTripStepper.Provider value={[ activeStep, setActiveStep ]}>
            {props.children}
        </ContextTripStepper.Provider>
    );
};

export default ProviderTripStepper;