import { useStaticQuery, graphql } from "gatsby";


const useVideaFinstat = () => {

  const data = useStaticQuery(graphql`
  query {
    videaFinstatFiles: allFile(
      filter: {
        extension: {regex: "/mp4/"}
        sourceInstanceName: { eq: "dirStatic" }
        relativeDirectory: { eq: "vba-finst" }
    }
      sort: { order: ASC, fields: name }
      ) {
        nodes {
          id
          base
          name
        }
      }
    }
  `);
  return data.videaFinstatFiles
}

export default useVideaFinstat;
