import React, { useEffect, useState, useContext, useRef, useCallback } from 'react'
import CirclesMotionListX4 from './CirclesMotionListX4';
import CirclesMotionItemX4 from './CirclesMotionItemX4';
import { getAngleOfPointOnCircle, getXYAtDistanceFromCenterRev, getAngleInRadsFromDeg, getXYAtDistanceFromCenter, normalizeValueIntoOtherScale } from '../../utils/circleUtils';
import { useAnimation } from 'framer-motion';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import { NotInViewContext } from './AnimateScrollIntoViewport';


const itemsSample = [
    { name: "Google", link: "https://www.google.com/", description: "There’s no doubt that Google is the most popular search engine, but this year it remains at the top spot as the most popular website on the internet. Over 40k searches are reported each second on Google — which translates to over 1.2 trillion searches per year! No matter what someone wants to know, Google is the go-to. Not only that, but Google also now serves as the hub for a plethora of other web services including Google Docs, Google Calendar, Google Drive and many more. source https://freshysites.com/" },
    { name: "YouTube", link: "https://www.youtube.com/", description: "This website jumped up the list two spots this year as it’s impressive stats continue to climb. Almost 5 billion videos are watched on YouTube every single day. YouTube gets over 30 million visitors per day. Every minute, over 300 minutes of video is uploaded to YouTube. We expect to see YouTube in the top three for a long time to come. source https://freshysites.com/" },
    { name: "Amazon", link: "https://www.amazon.com/", description: "Down one spot from last year, Amazon is still one of the top three most popular website on the internet and dominates the online shopping world. From Amazon.com, site visitors can purchase their favorite books, clothing, home goods, groceries and more! And, if they sign up for the Prime membership, those purchases can be delivered to their front door within just a day or two — for free! With such a wide variety of goods and such amazing service, it’s no wonder Amazon is one of the leaders again this year. source https://freshysites.com/" },
    { name: "Chase", link: "https://www.chase.com/", description: "One of the “Big Four” banks in the United States, Chase manages nearly $3 trillion in assets and runs over 5,000 branches across the country. According to the company, almost half of American households have a Chase banking account, and the majority of those customers make use of the company’s digital portal. Chase’s robust online banking service lets account holders handle a myriad of tasks from paying bills to depositing checks. Chase’s considerable daily online traffic has earned it the 20th spot on our 20 most popular websites list. source https://freshysites.com/" },
    { name: "Google2", link: "https://www.google.com/", description: "There’s no doubt that Google is the most popular search engine, but this year it remains at the top spot as the most popular website on the internet. Over 40k searches are reported each second on Google — which translates to over 1.2 trillion searches per year! No matter what someone wants to know, Google is the go-to. Not only that, but Google also now serves as the hub for a plethora of other web services including Google Docs, Google Calendar, Google Drive and many more. source https://freshysites.com/" },
    { name: "YouTube2", link: "https://www.youtube.com/", description: "This website jumped up the list two spots this year as it’s impressive stats continue to climb. Almost 5 billion videos are watched on YouTube every single day. YouTube gets over 30 million visitors per day. Every minute, over 300 minutes of video is uploaded to YouTube. We expect to see YouTube in the top three for a long time to come. source https://freshysites.com/" },
    { name: "Amazon2", link: "https://www.amazon.com/", description: "Down one spot from last year, Amazon is still one of the top three most popular website on the internet and dominates the online shopping world. From Amazon.com, site visitors can purchase their favorite books, clothing, home goods, groceries and more! And, if they sign up for the Prime membership, those purchases can be delivered to their front door within just a day or two — for free! With such a wide variety of goods and such amazing service, it’s no wonder Amazon is one of the leaders again this year. source https://freshysites.com/" },
    { name: "Chase2", link: "https://www.chase.com/", description: "One of the “Big Four” banks in the United States, Chase manages nearly $3 trillion in assets and runs over 5,000 branches across the country. According to the company, almost half of American households have a Chase banking account, and the majority of those customers make use of the company’s digital portal. Chase’s robust online banking service lets account holders handle a myriad of tasks from paying bills to depositing checks. Chase’s considerable daily online traffic has earned it the 20th spot on our 20 most popular websites list. source https://freshysites.com/" },
]

const clickComp = <StarPurple500Icon sx={{ fontSize: 48 }} />

const optionsLeft = {
    clickComp,
    arrContent: itemsSample,
    numberOfItems: 7,
    distancePixFromCenter: 150,
    itemHeight: 50,
    itemWidth: 80,
    itemHeightBetween: 10, polyLineXSpace: 10, styleWrap: {}
}
const optionsRight = {
    clickComp,
    arrContent: itemsSample,
    numberOfItems: 8,
    distancePixFromCenter: 60,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10, styleWrap: {}
}

const CirclesMotionX4 = ({ name, classNameWrap = "wrapCircles", styleWrapAll = {}, listHeightPlusPx = 0, plusHeightTopBottom = 0, isCompressed = false, arrPosOptions = [ [ "left", optionsRight ], [ "right", optionsRight ], [ "top", optionsRight ], [ "bottom", optionsLeft ] ], ySpaceBetweenTopBottom = 0 }) => {
    const [ runItems, setRunItems ] = useState(true)
    const { notInView, isCountedTwice } = useContext(NotInViewContext);
    const isMounted = useRef(false);


    const controlsWrap = useAnimation()


    const handleTap = useCallback((event, info, { position }) => {
        event.stopPropagation();
        if (notInView) {
            controlsWrap.stop();
            // controlsWrap.start("animate");
        } else {
            controlsWrap.start("animate");
        }
    }, [ notInView, controlsWrap ]);

    const handleOnItemTap = useCallback((event, info, { iItem }) => {
        event.stopPropagation();
        setRunItems(true)
    }, [ setRunItems ])

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        if (!isMounted.current) {
            controlsWrap.stop()
            return
        }
        const isMount = true
        let timerRunCircles
        if (notInView && !isCountedTwice) {
            isMounted.current && controlsWrap.stop()
            return
        } else {
            timerRunCircles = setTimeout(() => isMount && isMounted.current && controlsWrap.start("animate"), 500);

            return () => {
                controlsWrap.stop()
                clearTimeout(timerRunCircles)
            };
        }
    }, [ controlsWrap, notInView, isCountedTwice ]);

    const arrCircles = []
    const arrHeights = []
    let minHeightTopBottom = 0
    const arrWrapHeights = []
    const arrPositions = []

    let polylinePointsLeftX2Y2Plus0PlusLast
    let polylinePointsRightX2Y2Plus0PlusLast
    let polylinePointsRightX2Y2Plus0PlusLastRev
    let polygonPointsX2Y2Build
    let y2StartShift

    for (const [ _, v ] of arrPosOptions.entries()) {
        const [ position, options ] = v

        const { clickComp,
            arrContent,
            distancePixFromCenter,
            itemHeight,
            itemWidth,
            itemHeightBetween, polyLineXSpace, styleShiftAbs = {} } = options
        let { numberOfItems, styleWrap = {} } = options
        numberOfItems = Math.min(numberOfItems, arrContent.length)
        const clickPosComp = clickComp

        let angleStartCorr
        switch (position) {
            case "left":
                angleStartCorr = -90
                break;
            case "top":
                angleStartCorr = 0
                break;
            case "bottom":
                angleStartCorr = 180
                break;
            case "right":
                angleStartCorr = 90
                // yCoeff = -1
                break;
            default:
                break;
        }

        const selectedItems = arrContent.slice(0, numberOfItems)
        const yMinDist = itemHeight + itemHeightBetween

        const wrapHeight = [ "top", "bottom" ].includes(position) ? Math.max(distancePixFromCenter, (numberOfItems / 2) * yMinDist) - itemHeightBetween : Math.max(2 * distancePixFromCenter, (numberOfItems) * yMinDist) - itemHeightBetween

        const remainder = numberOfItems % 2
        const halfStartPoint = remainder === 1 ? Math.floor(numberOfItems / 2) + 1 : (numberOfItems / 2)

        let y2PrevOrig = 0
        let y2Prev = 0
        let x2Prev = 0
        let angle2
        let isDeclining = false
        let y2Highest = -999
        let y2Lowest = 999
        let yFirst
        let y2First
        const diffStartSecondHalf = 0
        let x2Max = -999
        let x2Min = 999

        const arrXY = []
        const arrX2Y2 = []
        let polygonPointsX2Y2 = ""
        let polylinePointsLeftXY = ""
        let polylinePointsLeftX2Y2 = ""
        let polylinePointsRightXY = ""
        let polylinePointsRightX2Y2 = ""

        const circleItems = selectedItems.map(

            (content, iItem) => {
                const angle = getAngleOfPointOnCircle(iItem + 0.5, numberOfItems, 360, angleStartCorr)
                const angleInRads = getAngleInRadsFromDeg(angle)
                let [ x, y ] = getXYAtDistanceFromCenter(angleInRads, distancePixFromCenter)

                angle2 = getAngleOfPointOnCircle(iItem + 0.5, numberOfItems, 180, angleStartCorr)
                const angleInRads2 = getAngleInRadsFromDeg(angle2)
                let [ x2, y2 ] = getXYAtDistanceFromCenter(angleInRads2, distancePixFromCenter)

                if (iItem === 0) {
                    yFirst = y
                    y2First = y2
                }

                switch (position) {
                    case "left":
                        y = y - yFirst
                        y2 = y2 - y2First
                        break;
                    case "right":
                        y = y - yFirst
                        y2 = y2 - y2First
                        y2 = -y2
                        break;
                    case "top":
                        x2 = -x2
                        switch (true) {
                            case (remainder === 1 && iItem === halfStartPoint - 1):
                                x2 = x2 - (itemWidth / 2)
                                break;
                            case (remainder === 0 && iItem === halfStartPoint - 1):
                                x2 = x2 - itemWidth
                                break;
                            case (remainder === 0 && iItem === halfStartPoint):
                                break;
                            case iItem < halfStartPoint - remainder:
                                x2 = x2 - itemWidth
                                break;
                            default:
                                x2 = x2 + diffStartSecondHalf
                                break;
                        }
                        break;
                    case "bottom":
                        switch (true) {
                            case (remainder === 1 && iItem === halfStartPoint - 1):
                                x2 = x2 - (itemWidth / 2)
                                break;

                            case (remainder === 0 && iItem === halfStartPoint - 1):
                                x2 = x2 - itemWidth // / 2
                                break;

                            case (remainder === 0 && iItem === halfStartPoint):
                                break;

                            case iItem < halfStartPoint - remainder:
                                x2 = x2 - itemWidth
                                break;
                            default:
                                x2 = x2 + diffStartSecondHalf
                                break;
                        }

                        break;

                    default:
                        break;
                }

                const y2Orig = y2
                // const x2Orig = x2

                if (iItem > 0) {
                    if ([ "left", "right" ].includes(position)) {
                        y2 = y2Prev + yMinDist
                    } else {
                        switch (true) {
                            case (iItem === halfStartPoint && remainder === 0):
                                if (x2 !== x2Prev) {
                                    y2 = y2Prev
                                }
                                break;
                            case (iItem === halfStartPoint && remainder === 1):
                                isDeclining = y2Orig > y2PrevOrig
                                y2 = isDeclining ? y2Prev + yMinDist : y2Prev - yMinDist
                                break;

                            default:
                                isDeclining = y2Orig > y2PrevOrig
                                y2 = isDeclining ? y2Prev + yMinDist : y2Prev - yMinDist
                                break;
                        }
                    }
                }
                y2PrevOrig = y2Orig
                y2Prev = y2
                x2Prev = x2
                y2Highest = y2Highest < y2 ? y2 : y2Highest
                y2Lowest = y2Lowest > y2 ? y2 : y2Lowest
                x2Max = x2Max < x2 ? x2 : x2Max
                x2Min = x2Min > x2 ? x2 : x2Min
                const [ x2rev, y2rev ] = getXYAtDistanceFromCenterRev(angleInRads2, distancePixFromCenter)
                const xLine45 = normalizeValueIntoOtherScale(iItem, 0, numberOfItems - 1, 0, 200);

                const itemOptions = { content: selectedItems[ iItem ], position, itemWidth, itemHeight, x, y, angle, angle2, x2, y2, xLine45, x2rev, y2rev }

                arrXY.push([ x, y ])
                arrX2Y2.push([ x2, y2 ])
                if (position === "left") {
                    const leftSpacedX2 = x2
                    const rightSpacedX2 = x2 + itemWidth
                    polylinePointsLeftXY += `${x - polyLineXSpace},${y} `
                    polylinePointsRightXY += `${x + itemWidth + polyLineXSpace},${y} `

                    polylinePointsLeftX2Y2 += `${leftSpacedX2},${y2} `
                    polylinePointsRightX2Y2 += `${rightSpacedX2},${y2} `
                    // create polygon, but better would be to have up 1 point more and down 1 point more
                    polygonPointsX2Y2 = `${leftSpacedX2},${y2} ${polygonPointsX2Y2} ${rightSpacedX2},${y2}`
                }
                if (position === "right") {
                    const leftSpacedX2 = x2
                    const rightSpacedX2 = x2 + itemWidth
                    polylinePointsLeftXY += `${x - polyLineXSpace},${y} `
                    polylinePointsRightXY += `${x + itemWidth + polyLineXSpace},${y} `

                    polylinePointsLeftX2Y2 += `${leftSpacedX2},${y2} `
                    polylinePointsRightX2Y2 += `${rightSpacedX2},${y2} `
                    polygonPointsX2Y2 = `${leftSpacedX2},${y2} ${polygonPointsX2Y2} ${rightSpacedX2},${y2}`
                }

                return (
                    <CirclesMotionItemX4
                        itemOptions={itemOptions}
                        iItem={iItem}
                        runItems={runItems}
                        handleOnItemTap={handleOnItemTap}
                        key={`${name} - ${iItem}`}
                    />
                )
            }
        )

        const listHeight = y2Highest - y2Lowest + itemHeight
        const listWidth = x2Max - x2Min + itemWidth


        let stylePos = {};
        let stylePosWrapItems = { top: 0 };
        let classNamePos = "";

        switch (position) {
            case "left":
                classNamePos = "left";
                styleWrap = isCompressed ? { top: `50px` } : styleWrap;
                stylePos = {
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    ...styleShiftAbs,
                };
                break;
            case "right":
                classNamePos = "right";
                styleWrap = isCompressed ? { bottom: `50px` } : styleWrap;
                stylePos = {
                    right: 0,
                    marginRight: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    ...styleShiftAbs,
                };
                break;
            case "top":
                classNamePos = "top";
                styleWrap = isCompressed
                    ? { right: `${Math.round(listWidth * 0.5)}px` }
                    : styleWrap;
                stylePos = {
                    top: 0,
                    marginTop: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    ...styleShiftAbs,
                };
                stylePosWrapItems = {};
                break;
            case "bottom":
                classNamePos = "bottom";
                styleWrap = isCompressed
                    ? { left: `${Math.round(listWidth * 0.5)}px` }
                    : styleWrap;
                stylePos = {
                    bottom: 0,
                    marginBottom: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    ...styleShiftAbs,
                };
                stylePosWrapItems = {};
                break;
            default:
                break;
        }

        // add 1 point up + 1 point down for polygon
        if ([ "left", "right" ].includes(position)) {
            const [ x2Point1, y2Point1 ] = arrX2Y2[ 0 ];
            const [ x2Point2, y2Point2 ] = arrX2Y2[ 1 ];

            const y2Point0 = y2Point1 - (y2Point2 - y2Point1);
            const x2Point0 = y2Point0 * (x2Point2 - x2Point1) / (y2Point2 - y2Point1);
            y2StartShift = y2Point0 / 2;

            const last = arrX2Y2.length - 1;
            const [ x2PointL, y2PointL ] = arrX2Y2[ last ];
            const [ x2PointL1, y2PointL1 ] = arrX2Y2[ last - 1 ];

            const y2PointLL = y2PointL + (y2PointL - y2PointL1);
            const x2PointLL = y2PointLL * (x2PointL1 - x2PointL) / (y2PointL1 - y2PointL);

            polylinePointsLeftX2Y2Plus0PlusLast = `${x2Point0},${y2Point0} ${polylinePointsLeftX2Y2} ${x2PointLL},${y2PointLL}`;
            polylinePointsRightX2Y2Plus0PlusLast = `${x2Point0 + itemWidth},${y2Point0} ${polylinePointsRightX2Y2} ${x2PointLL + itemWidth},${y2PointLL}`;
            polylinePointsRightX2Y2Plus0PlusLastRev = polylinePointsRightX2Y2Plus0PlusLast.split(" ").reverse().join(" ");
            polygonPointsX2Y2Build = `${polylinePointsLeftX2Y2Plus0PlusLast} ${polylinePointsRightX2Y2Plus0PlusLastRev}`;
        }

        arrCircles.push({
            position,
            clickPosComp,
            circleItems,
            listHeight,
            listWidth,
            x2Min,
            wrapHeight,
            classNamePos,
            stylePos,
            itemHeight,
            itemWidth,
            stylePosWrapItems,
            distancePixFromCenter,
            arrXY,
            arrX2Y2,
            polylinePointsLeftXY,
            polylinePointsLeftX2Y2,
            polylinePointsRightXY,
            polylinePointsRightX2Y2,
            polygonPointsX2Y2,
            polygonPointsX2Y2Build,
            y2StartShift,
            polylinePointsLeftX2Y2Plus0PlusLast,
            polylinePointsRightX2Y2Plus0PlusLast,
            polylinePointsRightX2Y2Plus0PlusLastRev
        })
        // console.log("arrCircles", arrCircles)
        minHeightTopBottom = [ "top", "bottom" ].includes(position) ? plusHeightTopBottom + listHeight : plusHeightTopBottom
        arrHeights.push(listHeight)
        arrWrapHeights.push(wrapHeight)
        arrPositions.push(position)
    }

    const maxArrHeights = Math.max(...arrHeights) + listHeightPlusPx
    const listMaxHeight = (arrPositions.includes("top") && arrPositions.includes("bottom")) ? Math.max(maxArrHeights, minHeightTopBottom + ySpaceBetweenTopBottom + listHeightPlusPx) : Math.max(maxArrHeights, minHeightTopBottom + listHeightPlusPx)
    const wrapMaxHeight = Math.max(...arrWrapHeights) + listHeightPlusPx
    return (
        <CirclesMotionListX4
            runItems={runItems}
            listMaxHeight={listMaxHeight}
            arrCircles={arrCircles}
            className="wrapCircles"
            handleTap={handleTap}
            controlsWrap={controlsWrap}
            positions={arrPositions}
            wrapHeight={wrapMaxHeight}
            styleWrapAll={styleWrapAll}
            notInView={notInView && !isCountedTwice}
        />
    )
}


export default CirclesMotionX4