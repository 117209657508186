
import React from "react"
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BowListLazy from "../../_shared/shaping/BowListLazy"
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";

const bowListOptionsSingle = {
  listHeightPx: "auto",
  primarySecondarySeparator: "|",
}
const bowListOptionsDoubleMd = {
  listHeightPx: "auto",
  primarySecondarySeparator: "|",
}
const bLOptionsL = {
  bowDirection: "left",
  motionDirection: "left",
}
const bLOptionsDownL = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingTopPx: 0,
}
const bLOptionsUpL = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingBottomPx: 0,
}
const bLOptionsMidL = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingTopPx: 0,
  listPaddingBottomPx: 0,
}

const styleBLBordersR20 = {
  borderTopRightRadius: "20%",
  borderBottomRightRadius: "20%",
}
const styleBLBordersTR20 = {
  borderTopRightRadius: "20%",
}
const styleBLBordersBR20 = {
  borderBottomRightRadius: "20%",
}

const styleSxWrap = {
  flexGrow: 1,
  width: '100%',
  "&.MuiPaper-root": {
    borderRadius: "20px",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  "& .tabs": {
    bgcolor: "unset",

    "& .MuiTabs-flexContainer": {
      borderBottomStyle: "groove",
      borderColor: (theme) => alpha(theme.palette.primary.light, 0.2),
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      maxWidth: "50%",
      "&.Mui-selected": {
        bgcolor: "primary2.main",
        color: "primary2.contrastText",
        borderTopStyle: "groove",
        borderLeftStyle: "groove",
        borderRightStyle: "groove",

        borderTopRightRadius: "50%",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    "& .tabPanel": {
      display: "flex",
      "& .boxPanel": {
        minHeight: "320px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "wrap",
        flexGrow: 1,
        p: [ 0, 1, 2, 3 ],
      }
    },
  },
  ".MuiListItemText-root": {
    flex: "1 1 auto",
    minWidth: 0,
    my: "4px",
  }
}



function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="tabPanel"
      {...other}
    >
      {value === index && (

        <Box
          className="boxPanel"
        >
          {children}
        </Box>

      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const TabsPriklady = () => {
  const theme = useTheme()
  const [ tabValue, setTab ] = React.useState(0);
  const deviceSize = useBreakpoint()

  let fontSizeDouble = "small"
  let fontSizeSingle = "small"

  switch (deviceSize) {
    case "xs":
      bowListOptionsSingle.itemHeightPx = 50
      bowListOptionsSingle.itemsXDistancePx = 20
      bowListOptionsDoubleMd.itemHeightPx = 40
      bowListOptionsDoubleMd.itemsXDistancePx = 20
      // fontSizeDouble = "unset"
      // fontSizeSingle = "unset"
      fontSizeDouble = "small"
      fontSizeSingle = "small"
      break;
    case "sm":
      bowListOptionsSingle.itemHeightPx = 35
      bowListOptionsSingle.itemsXDistancePx = 40
      bowListOptionsDoubleMd.itemHeightPx = 35
      bowListOptionsDoubleMd.itemsXDistancePx = 40
      fontSizeDouble = "small"
      fontSizeSingle = "small"
      break;
    case "md":
      bowListOptionsSingle.itemHeightPx = 30
      bowListOptionsSingle.itemsXDistancePx = 40
      bowListOptionsDoubleMd.itemHeightPx = 45
      bowListOptionsDoubleMd.itemsXDistancePx = 20
      fontSizeDouble = "small"
      fontSizeSingle = "small"
      break;
    default:
      bowListOptionsSingle.itemHeightPx = 30
      bowListOptionsSingle.itemsXDistancePx = 40
      bowListOptionsDoubleMd.itemHeightPx = 45
      bowListOptionsDoubleMd.itemsXDistancePx = 20
      fontSizeDouble = "small"
      fontSizeSingle = "small"
      break;
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };


  const styleBLUp = {
    boxShadow: theme.shadows[ 21 ],
    marginBottom: 0,
  }
  const styleBLMid = {
    marginTop: 0,
    marginBottom: 0,
  }
  const styleBLDown = {
    boxShadow: theme.shadows[ 21 ],
    marginTop: 0,
  }

  return (
    <Box component="div"
      className="MuiPaper-root"
      sx={styleSxWrap}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        scrollButtons="auto"
        aria-label="scrollable auto tabs prikl"
        className="tabs"
      >
        <Tab
          label="Príklad - jednoduchý program"
          {...a11yProps(0)}
          icon={<ListIcon />}
          sx={{
            paddingX: [ 0, 1, 2 ],
            fontSize: [ "smaller", "small", ],
          }}
          key="tabSimple"
        />
        <Tab
          label="Príklad - komplexný program"
          {...a11yProps(1)}
          icon={<ListAltIcon />}
          sx={{
            paddingX: [ 0.25, 1, 2 ], fontSize: [ "smaller", "small", ],
          }}
          key="tabcomplex"
        />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <BowListLazy
          name="BL-tabsPriklady-Vypocty"
          options={{
            // nameStyleList: "secondary",
            // nameStyleItem: "primary",
            tick: 2,
            ...bowListOptionsSingle,
            ...bLOptionsL,
            styleList: {
              marginBottom: 0,
              ...styleBLBordersR20,
              fontSize: fontSizeSingle,
            },
          }}
          key="BL-tabsPriklady-Vypocty">
          {[
            "výpočty pre namerané dáta (Microsoft Excel)",
            "inventarizácia položiek vrátane vyhľadávania (Excel, Access)",
            "generovanie vyplnených formulárov pre tlač pri kliknutí na riadok záznamu||napr. generovanie faktúr a ich evidencia, atd. (Excel, Access)"
          ]}
        </BowListLazy>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <BowListLazy
              name="BL-tabsPriklady-Ovladanie"
              options={{
                // nameStyleList: "secondary",
                // nameStyleItem: "primary",
                keepBgColor: true,
                tick: 2,
                angles: "up",
                ...bowListOptionsDoubleMd,
                ...bLOptionsUpL,
                styleList: {
                  ...styleBLUp,
                  ...styleBLBordersTR20,
                  fontSize: fontSizeDouble,
                },
              }} key="BL-tabsPriklady-Ovladanie">
              {[
                "ovládanie cez formulár, menu alebo dashboard",
                "načítanie vstupných dát",
              ]}
            </BowListLazy>
            <Box pl={[ 1, 2, 3, 4, 5 ]} pr={0} >
              <BowListLazy
                name="BL-tabsPriklady-ZAccess"
                options={{
                  nameStyleList: "primary",
                  nameStyleItem: "secondary",
                  keepBgColor: true,
                  angles: "mid",
                  ...bowListOptionsDoubleMd,
                  ...bLOptionsMidL,
                  styleList: {
                    ...styleBLMid,
                    fontSize: fontSizeDouble,
                  },
                }}
                key="BL-tabsPriklady-ZAccess">
                {[
                  "- z Access databázy",
                  "- z inej (nie Access) databázy",
                  "- z viacerých Excel súborov",
                  "- z viacerých textových súborov",
                  "- z internetu",
                ]}
              </BowListLazy>
            </Box>
            <BowListLazy
              name="BL-tabsPriklady-Spracovanie"
              options={{
                // nameStyleList: "secondary",
                // nameStyleItem: "primary",
                keepBgColor: true,
                angles: "down", tick: 1,
                ...bowListOptionsDoubleMd,
                ...bLOptionsDownL,
                styleList: {
                  ...styleBLDown,
                  ...styleBLBordersBR20,
                  fontSize: fontSizeDouble,
                },
              }}
              key="BL-tabsPriklady-Spracovanie">
              {[
                "spracovanie načítaných dát rôznymi Excel funkciami",
                "vytvorenie grafov na porovnanie dát",
                "vytvorenie tabuliek určených pre tlač",
              ]}
            </BowListLazy>
          </Grid>
          <Grid item xs={12} md={6}>
            <BowListLazy
              name="BL-tabsPriklady-VytvorenieInter"
              options={{
                // nameStyleList: "secondary",
                // nameStyleItem: "primary",
                keepBgColor: true,
                angles: "up", tick: 1,
                ...bowListOptionsDoubleMd,
                ...bLOptionsUpL,
                styleList: {
                  ...styleBLUp,
                  ...styleBLBordersTR20,
                  fontSize: fontSizeDouble,
                },
              }} key="BL-tabsPriklady-VytvorenieInter">
              {[
                "vytvorenie interaktívnych pivot tabuliek pre rôzne agregačné scenáre",
                "vyplnenie formulárov vo Word alebo Exceli pre tlač",
                "uloženie výstupných dát",
              ]}
            </BowListLazy>
            <Box pl={[ 1, 2, 3, 4, 5 ]} pr={0} >
              <BowListLazy
                name="BL-tabsPriklady-DoAccess"
                options={{
                  nameStyleList: "primary",
                  nameStyleItem: "secondary",
                  keepBgColor: true,
                  angles: "mid",
                  ...bowListOptionsDoubleMd,
                  ...bLOptionsMidL,
                  styleList: {
                    ...styleBLMid,
                    fontSize: fontSizeDouble,
                  },
                }}
                key="BL-tabsPriklady-DoAccess">
                {[
                  "- do Access databázy (formuláre, reporty)",
                  "- do inej databázy",
                  "- do Excel súboru",
                  "- do textového súboru",
                ]}
              </BowListLazy>
            </Box>
            <BowListLazy
              name="BL-tabsPriklady-Pristup"
              options={{
                // nameStyleList: "secondary",
                // nameStyleItem: "primary",
                keepBgColor: true,
                angles: "down", tick: 2,
                ...bowListOptionsDoubleMd,
                ...bLOptionsDownL,
                styleList: {
                  ...styleBLDown,
                  ...styleBLBordersBR20,
                  fontSize: fontSizeDouble,
                },
              }}
              key="BL-tabsPriklady-Pristup">
              {[
                "prístup k výstupným dátam cez formulár",
                "prezentácia výstupných dát v súbore alebo na papieri",
              ]}
            </BowListLazy>
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  )
};

export default React.memo(TabsPriklady);
