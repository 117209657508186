import React from 'react';
import Card2 from "./card2"

const getArrCardsNature = ({ imagesFiles, itemMaxWidthPx = 200 }) => {

    const imagesNodes = imagesFiles.nodes

    const arrCards = imagesNodes.map((imgNode, i) => {

        const image = imgNode.childImageSharp.gatsbyImageData
        const { name } = imgNode
        const cardOptions = {
            imgName: name,
            imgSrc: image.images.fallback.src,
            itemMaxWidthPx
        }
        return {
            name: name,
            comp: (
                <Card2 options={cardOptions} key={`${imgNode.base}--${i}`} />
            ),
            label: name,
            value: name,
        }
    })
    return arrCards
}
export default getArrCardsNature
