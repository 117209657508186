import React from "react"
// https://github.com/everweij/react-laag
import { useLayer, useHover, Arrow } from "react-laag";
import { motion } from "framer-motion";
import useTheme from '@mui/material/styles/useTheme';
import Box from "@mui/system/Box";

const styleSxDivTooltipBox = {
  position: "relative",
  maxWidth: "96px",
  opacity: 0,
  zIndex: 4,
  bgcolor: "primary.main",
  color: "primary.contrastText",
  fontSize: 12,
  px: 2,
  py: 1,
  lineHeight: 1.15,
  borderRadius: "3px",
  width: "100px",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  "&.selected": {
    bgcolor: "primary2.main",
    color: "primary2.contrastText",
  }
}

const varDivTooltipBox = () => {
  return {
    initial: false,
    animate: {
      opacity: [ 0.5, 1 ],
      rotateY: [ 90, 0 ],
      transition: {
        duration: 0.3,
      },
    },
  }
}


export default function TooltipCM({ children, text, itemSelected }) {
  // We use `useHover()` to determine whether we should show the tooltip.
  // Notice how we're configuring a small delay on enter / leave.
  const [ isOver, hoverProps ] = useHover({ delayEnter: 1500, delayLeave: 100 });
  const theme = useTheme()

  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: "top-center",
    triggerOffset: 8,// small gap between wrapped content and the tooltip
    arrowOffset: 5,
    onParentClose: () => {
      // console.log("TooltipCM onParentClosing")
      // console.log("onParentClosing fromTooltipCM arrowProps", arrowProps)
    }
  });

  // arrowProps.style = { ...arrowProps.style, fontSize: theme.spacing(1) }
  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  let trigger;
  if (isReactText(children)) {
    trigger = (
      <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  } else {
    // In case of an react-element, we need to clone it in order to attach our own props
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps
    });
  }

  // We're using framer-motion for our enter / exit animations.
  // This is why we need to wrap our actual tooltip inside `<AnimatePresence />`.
  // The only thing left is to describe which styles we would like to animate.
  // <AnimatePresence initial={false} key="presTooltipCm">
  return (
    <>
      {trigger}
      {renderLayer(
        isOver && (
          <Box component={motion.div}
            variants={varDivTooltipBox()}
            className={`tooltip-box ${itemSelected === true ? "selected" : ""}`}
            sx={styleSxDivTooltipBox}
            initial="initial"
            animate="animate"
            {...layerProps}
            key={`varDivTooltipBox-${text}`}
          >
            {text}
            <Arrow
              {...arrowProps}
              backgroundColor={itemSelected ? theme.palette.primary2.main : theme.palette.primary.main}
              borderColor={theme.palette.primary.contrastText}
              borderWidth={1}
              size={16}
            />
          </Box>
        )
      )}
    </>
  );
}
// </AnimatePresence>

function isReactText(children) {
  return [ "string", "number" ].includes(typeof children);
}
