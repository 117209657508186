import React, { useState, useRef, useCallback, useEffect, useMemo, memo, Fragment } from 'react'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'; //useReducedMotion
import { Box } from "@mui/system";
import useDimensions from 'react-cool-dimensions'; // https://github.com/wellyshen/react-cool-dimensions  https://hackernoon.com/react-hook-to-measure-an-elements-size-and-handle-responsive-components-ge3l3vj9
import { getWavedSvgPathDsBetween2Points } from '../../utils/getWave';
import { useBreakpoint } from './contextBreakpointMediaQ';
import { getWaveConfig } from '../../utils/getWave';
import ChildrenIterationToWrapEachChildsWithDivAssignRef from './ChildrenIterationToWrapEachChildsWithDivAssignRef';
import * as stylesClay from "../../styles/clay.module.css"


const defaultWavesOptionsCCh = {
    waves: {
        wave5: getWaveConfig("waves1phase05step10af5part05"),
        wave2: getWaveConfig("waves20phase05step10af10part05"),
    },
    styles: [ { fill: "red" }, {} ]
}

const defaultBaseOptionsCCh = {
    columns: 2,
    styleItem: { padding: "1rem" },
    isMultiplePaths: true,
    isSvgPathHorizontalWithTransformAngle: false
}

const styleSxWrap = {
    "&.wrapRefs": {
        px: [ 0.25, 0.5, 1, 2 ],
        py: [ 0.5, 1, 4, 5 ],
        "& .rootRef": {
            position: "relative",
            width: "100%",
            height: "100%",
            "& .itemWrap": {
                padding: [ 0.5, 1, 2 ],
                "& .itemText": {
                    maxWidth: '50%',
                    py: [ 0.5, 1, 2 ],
                    px: [ 1.5, 2, 4 ],
                    // "& h4": {
                    //     m: 0,
                    // },
                    borderRadius: '3px',
                    width: [ "100%", "auto" ],
                    cursor: "pointer",
                    "&>div": {
                        width: "100%",
                    }
                },
                "& .itemMark": {

                },
            },

            "& .svg": {
                position: "absolute",
                top: 0,
                left: 0,
                cursor: "pointer",

                "& .path": {
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    "&.path-0": {
                    },
                },
            },
            "& .boxAround": {
                position: "absolute",
                display: 'inline-block',
            },

        },
    }
}




// from array of points find lowest x and lowest y and get all points back with subtracted xMin and yMin    
const getNormalizedPointsStartingZero = (arrP) => {
    const [ xMin, yMin ] = arrP.reduce(([ minX, minY ], [ x, y ]) => [ Math.min(minX, x), Math.min(minY, y) ], [ Infinity, Infinity ]);
    const normalized = arrP.map(([ x, y ]) => [ x - xMin, y - yMin ]);
    return [ normalized, xMin, yMin ];
}

function getPointPairsFromPoints(arrP) {
    const pairs = [];
    for (let i = 1; i < arrP.length; i++) {
        const pair = { start: arrP[ i - 1 ], end: arrP[ i ] };
        pairs.push(pair);
    }
    return pairs;
}

// for each pair get back pair having subtracted first point values
const getNormalizedPointPairsToZero = (pairs) => {
    const normalizedPairs = pairs.map(({ start, end }) => {
        const [ startX, startY ] = start;
        const [ endX, endY ] = end;
        const xDif = endX - startX;
        const yDif = endY - startY;
        return { start: [ 0, 0 ], end: [ xDif, yDif ] };
    });
    return normalizedPairs;
}

// measured position of ref in the DOM via getBoundingClientRect()
function getMeasuredRefMiddlePoint({ ref, xBias, yBias }) {
    const rect = ref.getBoundingClientRect();
    const { top,
        left, height, width } = rect;
    const middlePoint = [ Math.round(left - xBias + width / 2), Math.round(top - yBias + height / 2) ];
    ref.style.backgroundColor = "maroon";
    return middlePoint;
}



const getUhloprieckaOfRightAngleTriangleFrom2Points = (start, end) => {  //p1 = { x: 3, y: 4 }, p2 = { x: 9, y: 6 }
    const xOdvesna = end[ 0 ] - start[ 0 ]
    const yOdvesna = end[ 1 ] - start[ 1 ]
    const uhlopriecka = Math.sqrt((yOdvesna ** 2) + (xOdvesna ** 2))
    return uhlopriecka
}

const getAngleOfPointOnCircle = ({ p, center, radius = 5, isDecreasingX, isDecreasingY, shouldCalcFromSinus = true }) => {  // { x: 9, y: 6 }
    // either angleDegFromCos or angleDegFromSin should give the same result
    if (shouldCalcFromSinus) {
        const sinAngle = (p[ 1 ] - center[ 1 ]) / radius
        let angleRadFromSin
        switch (true) {
            case isDecreasingX && isDecreasingY:
                angleRadFromSin = Math.PI - Math.asin(sinAngle)
                break;
            case isDecreasingX && !isDecreasingY:
                angleRadFromSin = Math.PI - Math.asin(sinAngle)
                break;
            case !isDecreasingX && isDecreasingY:
                angleRadFromSin = Math.asin(sinAngle)
                break;
            case !isDecreasingX && !isDecreasingY:
                angleRadFromSin = Math.asin(sinAngle)
                break;
            default:
                break;
        }
        const angleDegFromSin = angleRadFromSin * 180 / Math.PI
        return angleDegFromSin

    } else {
        const cosAngle = (p[ 0 ] - center[ 0 ]) / radius
        let angleRadFromCos
        switch (true) {
            case isDecreasingX && isDecreasingY:
                angleRadFromCos = Math.PI / 2 + Math.acos(cosAngle)
                break;
            case isDecreasingX && !isDecreasingY:
                angleRadFromCos = Math.PI / 2 + Math.acos(cosAngle)
                break;
            case !isDecreasingX && isDecreasingY:
                angleRadFromCos = Math.PI / 2 - Math.acos(cosAngle)
                break;
            case !isDecreasingX && !isDecreasingY:
                angleRadFromCos = Math.PI / 2 - Math.acos(cosAngle)
                break;

            default:
                break;
        }
        const angleDegFromCos = angleRadFromCos * 180 / Math.PI
        return angleDegFromCos

    }
}

// get obj with arrays of pairs of positions for neighbouring refs and these normalized that in pair starting point is [0,0],
const getSuccessivePointPairsOfRefsMeasuredPositions = ({ refsEl, containerTop, containerLeft }) => {
    if (!containerTop) return  //[]
    const midPoints = refsEl.current.map((ref, i) => {
        return getMeasuredRefMiddlePoint({ ref, xBias: containerLeft, yBias: containerTop });
    });
    const [ normalizedToZero,
        // xMin, yMin 
    ] = getNormalizedPointsStartingZero(midPoints)
    const successivePairs = getPointPairsFromPoints(normalizedToZero);
    const normalizedPairs = getNormalizedPointPairsToZero(successivePairs)
    return {
        refsPoints: midPoints,
        normalizedPairs,
    }
}

// get pairs of points calculated into same y and calculate transform angle to mimic original pair
// suggested that svg path'd for resulted pairs are later applied with calculated rotateZ transformation
// idea is to get this way svg viewport as minimum as possible
const getEquivalentHorizontalPairs_TransformAngle = (pairs) => {
    if (!pairs) return;

    const angles = [];
    const horizontalPairs = [];

    for (const { start, end } of pairs) {
        const radius = getUhloprieckaOfRightAngleTriangleFrom2Points(start, end);
        const radiusRounded = Math.round(radius);
        const isDecreasingX = start[ 0 ] > end[ 0 ];
        const isDecreasingY = start[ 1 ] > end[ 1 ];
        const angle = getAngleOfPointOnCircle({ p: [ ...end ], center: [ ...start ], radius, isDecreasingX, isDecreasingY });
        angles.push(angle);
        const horizontalPair = { start: [ start[ 0 ], start[ 1 ] ], end: [ start[ 0 ] + radiusRounded, start[ 1 ] ] };
        horizontalPairs.push(horizontalPair);
    }

    return { angles, horizontalPairs };
}

// for each horizontal pair calculate d path or multiple ds via getWavedSvgPathDsBetween2Points()
// also get predictable smallest possible svg viewport data from wave amplitude applied, no need to measure real svg in DOM to fit svg viewport to not best minimum (rectangles)
const getArrDsFromHorizontalPointsPairsWithAngle = ({ horizontalPairs, wavesOptions }) => {
    if (!horizontalPairs) return
    const arrDPairs = []
    const arrSvgData = []
    horizontalPairs.forEach((pair) => {
        const { start, end } = pair
        const svgWidth = Math.abs(end[ 0 ] - start[ 0 ])
        // const svgHeight=Math.abs(end[0]- start[0])
        const directAmplitude = 10
        const svgHeight = 2 * directAmplitude
        arrSvgData.push({ svgWidth, svgHeight })
        const startXY = start
        const endXY = end
        const dPathPair = getWavedSvgPathDsBetween2Points({ ...wavesOptions, directAmplitude, startXY, endXY })
        arrDPairs.push(dPathPair)
    })
    return {
        ds: arrDPairs,
        arrSvgData,
    }
}

const getAnimationDataForDirection = (itemWrapA, itemTextA, svgPathA, boxOffsetA) => {
    const delayPauseItem = itemWrapA.transition?.delay
    const durationItem = itemWrapA.transition?.duration
    const delayPauseItemText = itemTextA.transition?.delay
    const durationItemText = itemTextA.transition?.duration
    const delayPausePathBox = svgPathA.transition?.delay
    const durationPathBox = svgPathA.transition?.duration
    const delayPauseCycle = delayPauseItem + delayPauseItemText + delayPausePathBox
    const durationCycle = durationItem + durationItemText + durationPathBox + delayPauseCycle
    return { itemWrapA, itemTextA, svgPathA, boxOffsetA, delayPauseItem, durationItem, delayPauseItemText, durationItemText, delayPausePathBox, durationPathBox, delayPauseCycle, durationCycle }
}

const adaptAnimationFramerMotion = ({
    animationFramerMotion,
    startBg,
    startColor,
    endBg,
    endColor,
}) => {
    const { forward, back } = animationFramerMotion;
    const { itemTextA } = forward;
    const { animation } = itemTextA;

    animation.backgroundColor = [ startBg, endBg ];
    animation.color = [ startColor, endColor ];

    const backItemTextA = back.itemTextA;
    backItemTextA.animation.backgroundColor = [ endBg, startBg ];
    backItemTextA.animation.color = [ endColor, startColor ];

    return animationFramerMotion;
};

export const getAnimationOptionsCCh = (props) => {
    const {
        svgPathColor = "#950",
        svgFillColor = "#082",
        startBg = "#fed",
        startColor = "#0f0",
        endBg = "#444",
        endColor = "#11f",
        // delayAnimationMs = 3000,
        animationFramerMotion = animationFramerMotionDefault,
    } = props
    return {
        svgPathColor,
        svgFillColor,
        styleItemText: {
            backgroundcolor: startBg,
            color: startColor,
        },
        // delayAnimationMs,
        animationFramerMotion: adaptAnimationFramerMotion({ animationFramerMotion, startBg, startColor, endBg, endColor }),
    }
}


const animationFramerMotionDefault = {
    forward: {
        itemWrapA: {
            transition: {
                duration: 1,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                opacity: 1,
            },
            delayPauseItem: 0,
        },
        itemTextA: {
            transition: {
                duration: 1,
                ease: "easeInOut",
                // type: "spring", bounce: 0,
                delay: 1,
            },
            animation: {
                scale: [ 1, 1.1 ],
                backgroundColor: [ "#ffffff", "#c58003" ],
                color: [ "#000", "#45e" ],

            },
            delayPauseItemText: 3,
        },
        svgPathA: {
            transition: {
                duration: 1,
                ease: "easeInOut",
                delay: 1,
                times: [ 0, 1 ],
            },
            animation: {
                pathLength: 1,
                fill: "#457",
            },
            delayPausePathBox: 1,
        },
        boxOffsetA: {
            transition: {
                duration: 1,
                ease: "easeInOut",
                delay: 1,
            },
            animation: {
                offsetDistance: [ "100%", "0%" ],
                borderRadius: [ "50%", "0%" ],
            },
        },
    },
    back: {
        itemWrapA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                opacity: 0.5,
            },
            delayPauseItem: 0,
        },
        itemTextA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                scale: [ 1.1, 1 ],
                backgroundColor: [ "#c58003", "#ffffff" ],
                color: [ "#45e", "#000" ],
            },
            delayPauseItemText: 0,
        },
        svgPathA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
                times: [ 0, 1 ],
            },
            animation: {
                pathLength: [ 1, 0 ],
                // pathOffset: [ 0, -1 ],
                // opacity: [ 1, 0 ],
            },
            delayPausePathBox: 0,
        },
        boxOffsetA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                offsetDistance: [ "0%", "100%" ],
                borderRadius: [ "0%", "50%" ],
            },
        },
    }
}

const defaultAnimationOptionsCCh = getAnimationOptionsCCh({
    svgPathColor: "#950",
    svgFillColor: "#054",
    startBg: "#fed",
    startColor: "#0f0",
    endBg: "#444",
    endColor: "#11f",
    styleItemText: { backgroundColor: "#f3d", color: "#480" },
    // delayAnimationMs: 3000,
    animationFramerMotion: animationFramerMotionDefault,
})


/** ConnectChildrenWithWavesAnimation
 * outer wrap all children with div
 * for each child, wrap it with div, inside wrap adds div "itemMark" with assigned ref and measure ref position via getBoundingClientRect
 * create pairs of neighbour ref positions
 * also absolute positioned svg is added into outer wrap
 * via getWavedSvgPathDsBetween2Points() calculates svg path d between these paired points according wavesOptionsCCh
 * returns array of arrays of d, outer array length = number of pairs
 * animate appearing and disappearing svg path triggered with getting into view or out of view
 * onTap toggle animation
 * @param  {} ({children
 * @param  {} baseOptionsCCh=defaultBaseOptionsCCh
 * @param  {} animationOptionsCCh=defaultAnimationOptionsCCh
 * @param  {} wavesOptionsCCh=defaultWavesOptionsCCh
 * @param  {} }
 */
const ConnectChildrenWithWavesAnimation = ({
    children,
    baseOptionsCCh = defaultBaseOptionsCCh,
    animationOptionsCCh = defaultAnimationOptionsCCh,
    wavesOptionsCCh = defaultWavesOptionsCCh,
    name = "",
    delayAnimation = 0.6,
}) => {
    const {
        columns,
        styleItem,
        isMultiplePaths,
        styleItemTextBase = {}
    } = baseOptionsCCh
    const {
        svgPathColor,
        svgFillColor,
        styleItemText,
        // animationFramerMotion
    } = animationOptionsCCh
    // console.log("ConnectChildrenWithWavesAnimation name: ", name)
    const numberOfChilds = children.length

    const deviceSize = useBreakpoint();
    const cols = deviceSize === "xs" ? 1 : columns

    const styleItemTextFinal = { ...styleItemTextBase, ...styleItemText }
    styleItemTextFinal.maxWidth = cols === 1 ? "100%" : "40%"

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    const refValue = useRef({ forwards: true })
    const refIsUserAction = useRef(false)

    // refsEl for array of each child's ref
    const refsEl = useRef([]);

    // store not changing props 
    const refConstForHooks = useRef({ wavesOptionsCCh, baseOptionsCCh, animationOptionsCCh })

    // state for rectangle dimension of rootRef
    const [ rootRectViewport, setRootRectViewport ] = useState(false)


    // rootRef assigned to div wrapper around children - to measure its dimension rectangle
    const rootRef = useRef()
    const renderCounts = useRef(0)
    // hook to find dimension rectangle of rootRef via onResize observe
    const {
        observe,
        // currentBreakpoint,
        // unobserve, 
        // width,
        // height, 
        // entry
    } = useDimensions({

        onResize: (
            // {
            // observe,
            // currentBreakpoint
            // unobserve, width, height, 
            // entry
            // }
        ) => {
            // Triggered whenever the size of the target is changed...

            // unobserve(); // To stop observing the current target element
            // observe(); // To re-start observing the current target element
            // Now the event callback will be triggered when breakpoint is changed
            // we can also access the "currentBreakpoint" here

            // const rootContentRect= entry?.contentRect
            const rootRectBoundingClient = rootRef.current?.getBoundingClientRect()
            setRootRectViewport(rootRectBoundingClient)
        },
    });

    const isRoot = !!rootRectViewport

    const rootRectLeftVp = isRoot ? rootRectViewport.left : undefined
    const rootRectTopVp = isRoot ? rootRectViewport.top : undefined
    // const rootRectHeightVp = isRoot ? rootRectViewport.height : undefined
    const rootRectWidthVp = isRoot ? rootRectViewport.width : undefined

    // makeRef - function at the end passed to each child to enhance it with a ref
    // and this ref is pushed to array refsEl
    const makeRef = useCallback((el, i) => {
        if (el && refsEl.current.length !== numberOfChilds) {
            refsEl.current.push(el);
        }
    }, [ numberOfChilds ]);

    // successivePointPairsOfRefsXminYmin: obj (each pair for each neigbouring 2 child's (refs) positions) of array of path's d
    const successivePointPairsOfRefs = useMemo(() => getSuccessivePointPairsOfRefsMeasuredPositions(
        {
            refsEl,
            containerTop: rootRectTopVp,
            containerLeft: rootRectLeftVp,
        }), [ rootRectTopVp, rootRectLeftVp,
    ])

    const refsPoints = successivePointPairsOfRefs?.refsPoints
    const successiveNormalizedPointPairsOfRefs = successivePointPairsOfRefs?.normalizedPairs

    const anglesHorizontalPairs = useMemo(() => getEquivalentHorizontalPairs_TransformAngle(successiveNormalizedPointPairsOfRefs), [ successiveNormalizedPointPairsOfRefs ])

    const angles = anglesHorizontalPairs?.angles
    const horizontalPairs = anglesHorizontalPairs?.horizontalPairs
    const horizontalDs_Angles_StartPoints_arrSvgData = useMemo(() => getArrDsFromHorizontalPointsPairsWithAngle(
        {
            horizontalPairs,
            wavesOptions: refConstForHooks.current.wavesOptionsCCh
        }), [ horizontalPairs ])

    const arrDs = horizontalDs_Angles_StartPoints_arrSvgData?.ds
    const arrSvgData = horizontalDs_Angles_StartPoints_arrSvgData?.arrSvgData

    const handleTapToggleAppearDisappearAnim = () => {
        if (isMounted.current) runOnetimeAnimationCallb()
    }

    const ctrlAnimSvgPaths = useAnimation()
    const ctrlAnimBox = useAnimation()
    const ctrlAnimItem = useAnimation()
    const ctrlAnimItemText = useAnimation()

    const seqForward = useCallback(() => {
        const { itemWrapA, itemTextA, svgPathA, boxOffsetA } = refConstForHooks.current.animationOptionsCCh.animationFramerMotion.forward
        const appliedAnimationDelay = refIsUserAction.current ? 0 : delayAnimation
        let {
            delayPauseItem, durationItem, delayPauseItemText, durationItemText,
            durationPathBox, durationCycle } = getAnimationDataForDirection(itemWrapA, itemTextA, svgPathA, boxOffsetA)
        if (renderCounts.current < 3) {
            delayPauseItem = 0.1
            durationItem = 0.1
            delayPauseItemText = 0.1
            durationItemText = 0.1
            durationPathBox = 0.1
            durationCycle = 0.1
        }
        ctrlAnimItem.start(
            (i) => (
                {
                    ...itemWrapA.animation,
                    transition: {
                        ...itemWrapA.transition,
                        delay: appliedAnimationDelay + i * durationCycle,
                    },
                })
        )

        ctrlAnimItemText.start(
            (i) => (
                {
                    ...itemTextA.animation,
                    transition: {
                        ...itemTextA.transition,
                        delay: appliedAnimationDelay + i * durationCycle + durationItem + delayPauseItem,
                    },
                })
        )

        ctrlAnimSvgPaths.start(
            ({ i, ii, numberOfPaths, angle }) => (
                {
                    ...svgPathA.animation,
                    // fill: svgFillColor,
                    opacity: 1,
                    transition: {
                        ...svgPathA.transition,
                        delay: appliedAnimationDelay + ii * durationPathBox / numberOfPaths + i * durationCycle + durationItem + durationItemText + delayPauseItem + delayPauseItemText,
                    },
                })
        )
        ctrlAnimBox.start(
            (i) => (
                {
                    ...boxOffsetA.animation,
                    transition: {
                        ...boxOffsetA.transition,
                        delay: appliedAnimationDelay + i * durationCycle + durationItem + durationItemText + delayPauseItem + delayPauseItemText,
                    },
                })
        )
        refValue.current.forwards = !refValue.current.forwards
    }
        , [ ctrlAnimBox, ctrlAnimItem, ctrlAnimItemText, ctrlAnimSvgPaths ])


    const seqBack = useCallback(() => {
        const { itemWrapA, itemTextA, svgPathA, boxOffsetA } = refConstForHooks.current.animationOptionsCCh.animationFramerMotion.back;

        let {
            durationItem,
            durationItemText,
            durationPathBox,
            durationCycle
        } = getAnimationDataForDirection(itemWrapA, itemTextA, svgPathA, boxOffsetA);


        if (renderCounts.current < 3) {
            durationItem = 0.1
            durationItemText = 0.1
            durationPathBox = 0.1
            durationCycle = 0.1
        }

        const transitionDelay = (i) => (numberOfChilds - i - 1) * durationCycle + durationItemText;

        isMounted.current && ctrlAnimBox.start((i) => ({
            ...boxOffsetA.animation,
            transition: {
                ...boxOffsetA.transition,
                delay: transitionDelay(i),
            },
        }));

        isMounted.current && ctrlAnimSvgPaths.start(({ i, ii, numberOfPaths }) => ({
            ...svgPathA.animation,
            opacity: 0,
            transition: {
                ...svgPathA.transition,
                delay: (numberOfPaths - ii) * durationPathBox / numberOfPaths + transitionDelay(i),
            },
        }));

        isMounted.current && ctrlAnimItemText.start((i) => ({
            ...itemTextA.animation,
            transition: {
                ...itemTextA.transition,
                delay: transitionDelay(i) + durationItem,
            },
        }));

        isMounted.current && ctrlAnimItem.start((i) => ({
            ...itemWrapA.animation,
            transition: {
                ...itemWrapA.transition,
                delay: transitionDelay(i),
            },
        }));

        refValue.current.forwards = !refValue.current.forwards;
    }, [ numberOfChilds, ctrlAnimBox, ctrlAnimItem, ctrlAnimItemText, ctrlAnimSvgPaths ]);

    const seqBackWithoutDelays = useCallback(() => {
        const { itemWrapA, itemTextA, svgPathA, boxOffsetA } = refConstForHooks.current.animationOptionsCCh.animationFramerMotion.back;

        let {
            durationItem,
            durationItemText,
            durationPathBox,
            durationCycle
        } = getAnimationDataForDirection(itemWrapA, itemTextA, svgPathA, boxOffsetA);


        if (renderCounts.current < 3) {
            durationItem = 0.1
            durationItemText = 0.1
            durationPathBox = 0.1
            durationCycle = 0.1
        }

        // const transitionDelay = (i) => (numberOfChilds - i - 1) * durationCycle + durationItemText;

        isMounted.current && ctrlAnimBox.start((i) => ({
            ...boxOffsetA.animation,
            transition: {
                ...boxOffsetA.transition,
                // delay: transitionDelay(i),
            },
        }));

        isMounted.current && ctrlAnimSvgPaths.start(({ i, ii, numberOfPaths }) => ({
            ...svgPathA.animation,
            opacity: 0,
            transition: {
                ...svgPathA.transition,
                // delay: (numberOfPaths - ii) * durationPathBox / numberOfPaths + transitionDelay(i),
            },
        }));

        isMounted.current && ctrlAnimItemText.start((i) => ({
            ...itemTextA.animation,
            transition: {
                ...itemTextA.transition,
                // delay: transitionDelay(i) + durationItem,
            },
        }));

        isMounted.current && ctrlAnimItem.start((i) => ({
            ...itemWrapA.animation,
            transition: {
                ...itemWrapA.transition,
                // delay: transitionDelay(i),
            },
        }));

        refValue.current.forwards = !refValue.current.forwards;
    }, [ numberOfChilds, ctrlAnimBox, ctrlAnimItem, ctrlAnimItemText, ctrlAnimSvgPaths ]);

    const runOnetimeAnimationCallb =
        async () => {
            if (!rootRectLeftVp) return

            if (refValue.current.forwards) {
                seqForward()
            } else {
                seqBack()
            }
        }


    // svgsWithWavedPathsMem - memfunction returning svg with path for multiple d with animation settings to appear and disappear point by point
    const svgsWithWavedPathsMem = useMemo(() => {
        if (!rootRectLeftVp || !rootRectWidthVp) return;
        return isMultiplePaths
            ? arrDs.map(([ dBig, dMulti ], i) => {
                const numberOfPaths = dMulti.length;
                const isFirstCol = i % 2 === 0;
                const f = isFirstCol ? -1 : 1;
                const svgLeft = refsPoints[ i ][ 0 ];
                const { svgWidth, svgHeight } = arrSvgData[ i ];
                const svgTop = refsPoints[ i ][ 1 ] + f * svgHeight / 2;
                const viewboxTop = -svgHeight / 2;
                const paths = dMulti.map((d, ii) => ({
                    id: `path-${i}`,
                    d: `${d.join(" ")}`,
                    className: `path path-${ii}`,
                    initial: {
                        pathLength: 0,
                        pathOffset: 0,
                        opacity: 0,
                    },
                    animate: ctrlAnimSvgPaths,
                    custom: { i, ii, numberOfPaths, angle: angles[ i ] },
                    viewport: { amount: 0.8 },
                    stroke: ii === 0 ? svgFillColor : svgPathColor,
                    strokeDasharray: "0 1",
                    style: {
                        fill: ii === 0 ? svgPathColor : svgFillColor,
                    },
                    key: `path-${i}--${ii}`,
                }));
                return (
                    <svg
                        className={`svg ${stylesClay.clay}`}
                        width={svgWidth}
                        height={svgHeight}
                        viewBox={`0 ${viewboxTop} ${svgWidth} ${svgHeight}`}
                        style={{
                            opacity: 1,
                            position: 'absolute',
                            left: `${svgLeft}px`,
                            top: `${svgTop}px`,
                            transformOrigin: `0px 0px`,
                            transform: `rotateZ(${angles[ i ]}deg)`,
                        }}
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        key={`svg-${i}`}
                    >
                        {paths.map((path) => (
                            <motion.path {...path} />
                        ))}
                    </svg>
                );
            }) :
            arrDs.map(([ dMulti, dBig ], i) => (
                < motion.path
                    d={dBig.join(" ")}
                    className="path"

                    initial={{
                        pathLength: 0,
                        pathOffset: 0,
                        //fill:  alpha(svgFillColor, 0), 
                        opacity: 0,
                    }}
                    animate={ctrlAnimSvgPaths}
                    custom={{ i, ii: 1, numberOfPaths: 1 }}
                    viewport={{ amount: 0.8 }}
                    stroke={svgPathColor}
                    strokeDasharray="0 1"
                    style={{
                        rotateZ: angles[ i ],
                    }}
                    key={`bigPath-${i}`}
                />
            ))


    }, [
        rootRectLeftVp, rootRectWidthVp, isMultiplePaths,
        // rootRectTopVp,horizontalDs_Angles_StartPoints_arrSvgData,
        arrDs, arrSvgData, ctrlAnimSvgPaths, svgPathColor, refsPoints, angles, svgFillColor
    ])


    const divsFollowingWavedSvgsPathsMem = useMemo(() => {
        if (!rootRectLeftVp || !rootRectWidthVp) return null;

        const divs = [];
        const arrDsLength = arrDs.length;

        const leftValues = refsPoints.map((point) => point[ 0 ]);
        const topValues = refsPoints.map((point) => point[ 1 ]);

        // const anglesLength = angles.length;
        const anglesCopy = angles.slice();

        for (let i = 0; i < arrDsLength; i++) {
            const left = leftValues[ i ];
            const top = topValues[ i ];
            const angle = anglesCopy[ i ];

            const dBig = arrDs[ i ][ 0 ];
            // const dMulti = arrDs[ i ][ 1 ];

            const boxAroundStyle = {
                left: `${left}px`,
                top: `${top}px`,
                transformOrigin: "0px 0px",
                transform: `rotateZ(${angle}deg)`,
            };

            const motionDivStyle = {
                offsetDistance: "100%",
                borderRadius: "50%",
                offsetPath: `path("${dBig.join(" ")}")`,
                offsetRotate: "auto 90deg",
            };

            divs.push(
                <div className="boxAround" style={boxAroundStyle} key={`boxAround-${i}`}>
                    <motion.div
                        initial={false}
                        animate={ctrlAnimBox}
                        custom={i}
                        style={motionDivStyle}
                    >
                        {/* ✏️ */}
                        🖌️
                    </motion.div>
                </div>
            );
        }

        return <>{divs}</>;
    }, [ rootRectLeftVp, rootRectWidthVp, ctrlAnimBox, refsPoints, angles, arrDs, rootRectTopVp ]);//rootRectTopVp, 

    const handleViewportEnter = (entry) => {
        refIsUserAction.current = false
        renderCounts.current++;
        // const notInView = !rootRectLeftVp
        // console.log("%C ViewportEnter will start seqForward ConnectChildrenWithWavesAnimation", "color:red", name, !notInView)
        // if (!notInView) {
        // if (renderCounts.current > 2) {
        seqForward();
        // setTimeout(() => {
        //     seqForward();
        // }, 500); // Delay of 0.5 seconds (500 milliseconds)
        // }
    };


    return (
        <Box
            className={`wrapRefs ${stylesClay.clayShadowRightDown}`}
            sx={styleSxWrap}
        >
            <AnimatePresence>
                <motion.div
                    className={`rootRef`}
                    ref={(el) => {
                        observe(el); // Set the target element for measuring
                        rootRef.current = el; // Share the element for other purposes
                    }}
                    onTapStart={(e, info) => {
                        refIsUserAction.current = true
                        if (isMounted.current) runOnetimeAnimationCallb()
                        refIsUserAction.current = false
                    }}
                    onTapCancel={e => {
                        if (isMounted.current) {
                            ctrlAnimSvgPaths.stop()
                            ctrlAnimBox.stop()
                            ctrlAnimItem.stop()
                            ctrlAnimItemText.stop()

                        }
                    }}
                    viewport={{
                        // margin:"0px -20px 0px 100px"
                        amount: 0.5,
                    }}
                    onViewportEnter={handleViewportEnter}
                    // onViewportEnter={entry => {
                    //     // console.log("%C ViewportEnter will start seqForward ConnectChildrenWithWavesAnimation", "color:red", name,)
                    //     seqForward()
                    // }}
                    onViewportLeave={(entry) => {
                        // if (isMounted.current) {
                        //     renderCounts.current++;

                        //  console.log("%C ViewportEnter will start seqBack ConnectChildrenWithWavesAnimation", "color:red", name,)
                        // seqBack()
                        // if (isMounted.current) {
                        // if (!notInView) {
                        // seqForward.stop();
                        ctrlAnimBox.stop();
                        ctrlAnimItem.stop();
                        ctrlAnimItemText.stop();
                        ctrlAnimSvgPaths.stop()
                        // seqBackWithoutDelays()
                        // seqBack()
                        // }}
                        // }
                    }}
                    key="CCWA-rootRef"
                >

                    <ChildrenIterationToWrapEachChildsWithDivAssignRef
                        columns={cols}
                        numberOfChilds={numberOfChilds}
                        ctrlAnimItem={ctrlAnimItem}
                        ctrlAnimItemText={ctrlAnimItemText}
                        makeRef={makeRef}
                        styleItem={styleItem}
                        styleItemText={styleItemTextFinal}
                    >
                        {children}
                    </ChildrenIterationToWrapEachChildsWithDivAssignRef>

                    {svgsWithWavedPathsMem}
                    {divsFollowingWavedSvgsPathsMem}
                </motion.div>
            </AnimatePresence>
        </Box>
    )
}



// const MemoizedConnectChildrenWithWavesAnimation = memo(ConnectChildrenWithWavesAnimation,
//     (prevProps, nextProps) => {
//         // Perform a deep comparison of the animationKey prop
//         const areEqualBaseOptionsCCh = JSON.stringify(prevProps.baseOptionsCCh) === JSON.stringify(nextProps.baseOptionsCCh);
//         const areEqualAnimationOptionsCCh = JSON.stringify(prevProps.animationOptionsCCh) === JSON.stringify(nextProps.animationOptionsCCh);
//         const areEqualWavesOptionsCCh = JSON.stringify(prevProps.wavesOptionsCCh) === JSON.stringify(nextProps.wavesOptionsCCh);

//         // Perform a shallow comparison of the rest of the props
//         const areOtherPropsEqual = Object.keys(prevProps).every(key => {
//             // if (key !== 'stylesTop' || 'stylesRight' || 'stylesBottom' || 'stylesLeft' || "children") return true;
//             if (key !== "children") return true;
//             return prevProps[ key ] === nextProps[ key ];
//         });
//         // console.log("MemoizedBowList areOtherPropsEqual", areOtherPropsEqual)

//         // Only update the component if any of the props have changed
//         return areEqualBaseOptionsCCh && areEqualAnimationOptionsCCh && areEqualWavesOptionsCCh && areOtherPropsEqual;
//     })

// const MemoizedConnectChildrenWithWavesAnimation = React.memo(
//     ConnectChildrenWithWavesAnimation,
//     (prevProps, nextProps) => {
//         let isNameEqual = false
//         isNameEqual = prevProps.name === nextProps.name;
//         return isNameEqual
//     }
// );

export default ConnectChildrenWithWavesAnimation
// export default MemoizedConnectChildrenWithWavesAnimation




// const compareProps = (prevProps, nextProps) => {
//     return JSON.stringify(prevProps) === JSON.stringify(nextProps);
//     // return _.isEqual(prevProps, nextProps);
// }




