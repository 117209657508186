import React, { ReactNode, useContext, useEffect, useRef, memo } from 'react';
import { motion, useAnimation, useInView, VariantLabels } from 'framer-motion';

interface NotInViewContextType {
  notInView: boolean;
  isCountedTwice: boolean;
}

export const NotInViewContext = React.createContext<NotInViewContextType>({
  notInView: false,
  isCountedTwice: false,
});

type AmountType = 'some' | 'all' | number;

interface AnimateScrollIntoViewportProps {
  index?: number;
  name: string;
  margin: string;
  amount: AmountType;
  threshold: number | number[];
  once: boolean;
  animateFramer: VariantLabels | {};
  exitFramer: VariantLabels | {};
  styleToMerge: React.CSSProperties;
  styleToMergeImportant: React.CSSProperties;
  delay: number;
  durationIn: number;
  durationOut: number;
  easing: 'easeInOut' | 'easeIn';
  children: ReactNode;
}

const AnimateScrollIntoViewport: React.FC<AnimateScrollIntoViewportProps> = ({
  name,
  margin = '-200px 0px -200px 0px',
  amount = 'some',
  threshold = [0, 0.25, 0.5, 0.75, 1],
  once = false,
  animateFramer = { opacity: 1 },
  exitFramer = { opacity: 0 },
  styleToMerge = { opacity: 0 },
  styleToMergeImportant = {},
  delay = 0,
  durationIn = 0.15,
  durationOut = 0.05,
  easing = 'easeInOut',
  children,
}) => {
  // console.log("AnimateScrollIntoViewport name: ", name)
  const ref = useRef<HTMLDivElement>(null);
  const animControls = useAnimation();
  const isInView = useInView(ref, { once, margin, amount });
  const refInViewSwitchesCount = useRef(0);

  useEffect(() => {
    refInViewSwitchesCount.current += 1;
    if (refInViewSwitchesCount.current > 2) {
      animControls.start(isInView ? "come" : "go");
    }
  }, [isInView, animControls]);

  const varInOut = {
    come: {
      ...animateFramer,
      transition: { duration: durationIn, delay, ease: easing, staggerChildren: 0.05, when: 'beforeChildren', delayChildren: 0.1 },
    },
    go: {
      ...exitFramer,
      transition: { duration: durationOut, delay: 0 },
    },
  };

  return (
    <motion.div
      ref={ref}
      id={name}
      className="a-s-v"
      variants={varInOut}
      initial={false}
      animate={animControls}
      style={{
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        ...(refInViewSwitchesCount.current < 3 ? {} : styleToMerge),
        ...styleToMergeImportant,
      }}
      key={name}
    >
      <NotInViewContext.Provider
        value={{
          notInView: !isInView,
          isCountedTwice: refInViewSwitchesCount.current > 2,
        }}>
        {children}
      </NotInViewContext.Provider>
    </motion.div>
  );
};

const MemoizedAnimateScrollIntoViewport = React.memo(
  AnimateScrollIntoViewport,
  (prevProps, nextProps) => {
    let isNameEqual = false
    isNameEqual = prevProps.name === nextProps.name;
    return isNameEqual
  }
);


export default MemoizedAnimateScrollIntoViewport;
// export default AnimateScrollIntoViewport;