import React from 'react'

class FlipAnimatedBetweenAnimationKeys extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.staticSqTransform = props.transformSquare ? props.transformSquare : ""
    }

    static defaultProps = {
        duration: 2000,
        easing: 'ease-in-out',
    }


    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.animationKey !== this.props.animationKey
            && this.ref.current
            && this.ref.current.getBoundingClientRect) {
            return this.ref.current.getBoundingClientRect();
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.animationKey !== this.props.animationKey
            && this.ref.current
            && this.ref.current.getBoundingClientRect) {
            const first = snapshot;
            const last = this.ref.current.getBoundingClientRect();

            const invert = {
                top: first.top - last.top,
                left: first.left - last.left,
            };

            if (invert.top === 0 && invert.left === 0) {
                return;
            }

            const { duration, easing } = this.props;
            // batch all element.animate calls on the animate queue to avoid too much
            // successive forced synchronous layouts
            requestAnimationFrame(() => {
                this.ref.current.animate([
                    { transform: `${this.staticSqTransform} translate(${invert.left}px, ${invert.top}px)` },
                    { transform: `${this.staticSqTransform} translate(0,0)` },
                ], {
                    duration,
                    easing,
                });
            });
        }
    }

    render() {
        const { children: child } = this.props;

        if (typeof child === 'function') {
            return child({ innerRef: this.ref });
        }

        return (
            React.cloneElement(child, { ref: this.ref })
        )
    }
}

// const MemoizedFlipAnimatedBetweenAnimationKeys = React.memo(
//     FlipAnimatedBetweenAnimationKeys,
//     (prevProps, nextProps) => {
//         // Perform a deep comparison of the animationKey prop
//         const areAnimationKeysEqual = JSON.stringify(prevProps.animationKey) === JSON.stringify(nextProps.animationKey);
//         console.log("MemoizedFlipAnimatedBetweenAnimationKeys prevProps", prevProps)
//         console.log("MemoizedFlipAnimatedBetweenAnimationKeys nextProps", nextProps)
//         console.log("MemoizedFlipAnimatedBetweenAnimationKeys JSON.stringify(prevProps.animationKey)", JSON.stringify(prevProps.animationKey))
//         console.log("MemoizedFlipAnimatedBetweenAnimationKeys JSON.stringify(nextProps.animationKey)", JSON.stringify(nextProps.animationKey))
//         console.log("MemoizedFlipAnimatedBetweenAnimationKeys areAnimationKeysEqual", areAnimationKeysEqual)
//         // Perform a shallow comparison of the rest of the props
//         debugger
//         const areOtherPropsEqual = Object.keys(prevProps).every(key => {
//             if (key === 'animationKey' || "children") return true;
//             return prevProps[ key ] === nextProps[ key ];
//         });
//         console.log("MemoizedFlipAnimatedBetweenAnimationKeys areOtherPropsEqual", areOtherPropsEqual)

//         // Only update the component if any of the props have changed
//         return areAnimationKeysEqual && areOtherPropsEqual;
//     }
// );

export default FlipAnimatedBetweenAnimationKeys
// export default MemoizedFlipAnimatedBetweenAnimationKeys
