
import React from "react";
import { MotionValue } from "framer-motion";
import RotatingItem from "./RotatingItem";

interface RotatingListProps {
    rotatingItems: { nameItem: string; iItem: number }[];
    aroundPositions: { angle: number; left: number; top: number, width?: number, height?: number }[];
    classWrapRotatingItem: string;
    angleIncrement: number;
    styleRotating: React.CSSProperties;
    // handleOnClick: ({ e, iItem }: { e: MouseEvent<Element, MouseEvent>; iItem: number; }) => void;
    selectedIndex: number;
    angleM: MotionValue<number>;
    deviceSize: string;
    isInView: boolean;
}

const RotatingList: React.FC<RotatingListProps> = (props) => {
    const {
        rotatingItems,
        aroundPositions,
        classWrapRotatingItem,
        angleIncrement,
        styleRotating,
        // handleOnClick,
        selectedIndex,
        angleM,
        deviceSize,
        isInView,
    } = props
    const numberPos = rotatingItems.length
    return (
        <>
            {rotatingItems.map((item, i) => {
                const { nameItem, iItem } = item
                return (
                    <RotatingItem
                        nameItem={nameItem}
                        iItem={iItem}
                        classRotating={classWrapRotatingItem}
                        styleRotating={styleRotating}
                        firstAngle={aroundPositions[iItem].angle}
                        angleIncrement={angleIncrement}
                        // numberPositions={numberPos}
                        // handleOnClick={handleOnClick}
                        selectedIndex={selectedIndex}
                        angleM={angleM}
                        deviceSize={deviceSize}
                        key={`rotatingItem-${nameItem}`}
                        // aroundPositions={aroundPositions}
                        isInView={isInView}
                    />
                )
            })}
        </>
    )
}

export default RotatingList