import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh, myFramesPadding } from "../../_shared/IntroTextyConnected";
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const IntroTextMatPredstavuWebStrankyCCh = ({ colors = {} }) => {
    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;
    const myAnimationOptionsCCh = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="Vase-web-stranky-v-buducnosti" className="toc toc0"><span>Vaše webstránky v budúcnosti?</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="buducnosti"
            >
                <div>Pohľad do budúcna je dôležitý. Keď už sú webstránky vytvorené, ďalšie nedpredpokladané funkcie môžu byť komplikovanejšie a prácnejšie - teda pri dramatickejšej zmene.</div>
                <div>Dôležitejšie je ale mať predstavu o súčasnosti.</div>
                <div>aké informácie by mali webstránky od vás sprostredkovať, aký materiál máte k dispozícii, váš názor na pridávanie článkov</div>
                <div>aký dizajn - váš náhľad na vizuálne prevedenie.</div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2"
                    // myFrames={myFrames}
                    myFramesPadding={myFramesPadding}
                >
                    <div className="waved">Preberieme s vami všetky detaily. Všetko, čo budete potrebovať.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default IntroTextMatPredstavuWebStrankyCCh;