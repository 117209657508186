import React from "react";
import { RADIUS, ITEM_SIZE, MARGIN_RIGHT } from "./constantsCM"

import {
  usePresence,
  animate,
  useTransform
} from "framer-motion";

// utility function that splits up an animation between 0 and 1 into two parts
// while maintaining there relative progress
function splitAnimation({ partA, partB }) {
  return function split(value) {
    const splitAt = 0.2;
    if (value < splitAt) {
      return partA(value / splitAt);
    }
    return partB((value - splitAt) / (1 - splitAt));
  };
}

// this hook takes a motion-value (between 0 - 1), the index of the menu-item
// and the total number of items.
// it transforms these parameters into coordinates and scale (x, y, scale)
function useCircleMotionStyles(value, index, nrOfItems) {
  const offset = 0.0;
  const percentage = index / nrOfItems;

  const transformX = splitAnimation({
    partA: () => 0,
    partB: progress => {
      const value = percentage * progress - offset;

      return RADIUS * Math.cos(Math.PI * value * 2);
    }
  });
  const transformY = splitAnimation({
    partA: progress => progress * -RADIUS,
    partB: progress => {
      const value = percentage * progress - offset;
      return RADIUS * Math.sin(Math.PI * value * 2);
    }
  });

  const x = useTransform(value, transformX);
  const y = useTransform(value, transformY);
  const scale = useTransform(value, value => value / 2 + 0.5);
  return { x, y, scale };
}

function useCircleMotionStyles2(index, nrOfItems) {
  const offset = 0.0;
  const percentage = index / nrOfItems;
  const x = RADIUS * Math.cos(Math.PI * (percentage - offset) * 2)
  const y = RADIUS * Math.sin(Math.PI * (percentage - offset) * 2)
  return { x, y, scale: 1.1 };
}



// this hook takes a motion-value (between 0 - 1), the index of the menu-item,
// the total number of items and the current layer-side.
// it transforms these parameters into horizontal positions (x).
function useBarMotionStyles(value, index, nrOfItems, layerSide) {
  const x = useTransform(value, value => {
    const stepSize = ITEM_SIZE + MARGIN_RIGHT;
    const maxIndex = nrOfItems - 1;

    if (layerSide === "left") {
      return value * (index - maxIndex) * stepSize + stepSize * maxIndex;
    }

    return value * index * stepSize;
  });
  return { x, left: 0, opacity: value };
}

// This hook will trigger an animation on the motion-value depending
// on the state it is in -> `isPresent`
function usePresenceAnimation(value, transition) {
  const [ isPresent, safeToRemove ] = usePresence();

  React.useLayoutEffect(() => {
    if (isPresent) {
      animate(value, 1, transition);
    } else {
      animate(value, 0, { ...transition, onComplete: safeToRemove });
    }
  }, [ isPresent, safeToRemove, value, transition ]);

  return isPresent;
}

function usePresenceAnimation2(transition) {
  const [ isPresent, safeToRemove ] = usePresence();

  React.useLayoutEffect(() => {
    if (isPresent) {
      animate(0, 1, transition);
    } else {
      animate(1, 0, { ...transition, onComplete: safeToRemove });
    }
  }, [ isPresent, safeToRemove, transition ]);

  return isPresent;
}


// Utility hook that tracks whether the component has mounted or not
function useDidMount() {
  const [ didMount, setMount ] = React.useState(false);

  React.useEffect(() => {
    setMount(true);
  }, []);

  return didMount;
}

export { useCircleMotionStyles, useCircleMotionStyles2, usePresenceAnimation2, useBarMotionStyles, usePresenceAnimation, useDidMount }