export function createWavePathOnTopLinedToBottomToLeftAndConnected2(boxWidth, boxHeight, waveAmplitude, waveCount, paddingLeft, paddingRight, svgWidth) {
    const pathStartX = 0 - paddingLeft / 2 - svgWidth / 4;
    const pathStartY = boxHeight / 2;
    const pathStartNumber = `${pathStartX} ${boxHeight / 2}`;
    let pathData = `M${pathStartNumber}`; // Start in the middle of the left side
    if (!boxWidth || !boxHeight || !waveAmplitude || !waveCount) { return pathData; }
    // console.log("paddingLeft", paddingLeft)
    // console.log("paddingRight", paddingRight)
    const waveWidth = boxWidth / waveCount;
    // console.log('waveWidth', waveWidth)
    // Create the waves
    let lastEndX = pathStartX;
    for (let i = 0; i < waveCount; i++) {
        const startX = waveWidth * i + pathStartX;
        // console.log('startX', startX)
        const peakX = startX + waveWidth / 2;
        const endX = startX + waveWidth + paddingLeft / 2 + paddingRight / 2 + svgWidth / 4;
        lastEndX = endX;
        // console.log('peakX', peakX)
        // console.log('endX', endX)
        const controlY1 = (i % 2 === 0) ? boxHeight / 2 - waveAmplitude : boxHeight / 2 + waveAmplitude;
        const controlY2 = (i % 2 === 0) ? boxHeight / 2 + waveAmplitude : boxHeight / 2 - waveAmplitude;

        pathData += ` C${startX},${controlY1} ${peakX},${controlY2} ${endX},${boxHeight / 2}`;
    }

    // Close the path
    // pathData += ` L${boxWidth},${boxHeight} L0,${boxHeight} Z`;
    // pathData += ` L${boxWidth},${boxHeight} L${pathStartNumber},${boxHeight} L${pathStartNumber}`;
    // pathData += ` L${lastEndX},${boxHeight} L${pathStartNumber},${boxHeight} L${pathStartNumber},${boxHeight / 2}`;
    //pathData += ` L ${lastEndX} ${boxHeight} L ${pathStartNumber} ${boxHeight} L ${pathStartNumber} ${boxHeight / 2}`;
    pathData += ` L ${lastEndX} ${boxHeight} L ${pathStartX} ${boxHeight} L ${pathStartX} ${pathStartY}`;
    // console.log('pathData', pathData)
    return pathData;
}

//export function createWavePathOnTopLinedToBottomToLeftAndConnected(boxWidth, boxHeight, boxLeft, boxTop, waveAmplitude, waveCount, paddingLeft, paddingRight, svgWidth) {
export function createWavePathOnTopLinedToBottomToLeftAndConnected(boxWidth, boxHeight, boxLeft, boxTop, waveAmplitude, waveCount, paddingLeft, paddingRight, paddingTop, paddingBottom, svgWidth = 0) {
    // Adjust the box dimensions and position to account for padding
    // boxWidth -= paddingLeft + paddingRight;
    // // boxHeight -= paddingTop + paddingBottom;
    // boxLeft += paddingLeft;
    // boxTop += paddingTop;
    // console.log("boxLeft,boxTop,", boxLeft, boxTop)
    const pathStartX = 0 // boxLeft // 0 //- paddingLeft / 2 - svgWidth / 4;
    const pathStartY = boxHeight / 2;
    const pathStartNumber = `${pathStartX} ${boxHeight / 2}`;
    let pathData = `M${pathStartNumber}`; // Start in the middle of the left side
    if (!boxWidth || !boxHeight || !waveAmplitude || !waveCount) { return pathData; }
    // console.log("paddingLeft", paddingLeft)
    // console.log("paddingRight", paddingRight)
    const waveWidth = boxWidth / waveCount;
    // console.log('waveWidth', waveWidth)
    // Create the waves
    let lastEndX = pathStartX;
    for (let i = 0; i < waveCount; i++) {
        const startX = waveWidth * i + pathStartX;
        // console.log('startX', startX)
        const peakX = startX + waveWidth / 2;
        const endX = startX + waveWidth //+ paddingLeft / 2 + paddingRight / 2 + svgWidth / 4;
        lastEndX = endX;
        // console.log('peakX', peakX)
        // console.log('endX', endX)
        const controlY1 = (i % 2 === 0) ? boxHeight / 2 - waveAmplitude : boxHeight / 2 + waveAmplitude;
        const controlY2 = (i % 2 === 0) ? boxHeight / 2 + waveAmplitude : boxHeight / 2 - waveAmplitude;

        pathData += ` C${startX},${controlY1} ${peakX},${controlY2} ${endX},${boxHeight / 2}`;
    }

    // Close the path
    // pathData += ` L${boxWidth},${boxHeight} L0,${boxHeight} Z`;
    // pathData += ` L${boxWidth},${boxHeight} L${pathStartNumber},${boxHeight} L${pathStartNumber}`;
    // pathData += ` L${lastEndX},${boxHeight} L${pathStartNumber},${boxHeight} L${pathStartNumber},${boxHeight / 2}`;
    // pathData += ` L ${lastEndX} ${boxHeight} L ${pathStartNumber} ${boxHeight} L ${pathStartNumber} ${boxHeight / 2}`;
    pathData += ` L ${lastEndX} ${boxHeight} L ${pathStartX} ${boxHeight} L ${pathStartX} ${pathStartY}`;
    // console.log('pathData', pathData)
    return pathData;
}
export function generateRandomColor() {
    const randInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    return `rgb(${randInt(0, 255)}, ${randInt(0, 255)}, ${randInt(0, 255)})`;
}

export function getMousePositionFromEvent(event, parentLeft = 0, parentTop = 0) {
    const mouseX = (event.pageX || event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft);
    const mouseY = (event.pageY || event.clientY + document.body.scrollTop + document.documentElement.scrollTop)
    return {
        mouseX: mouseX - parentLeft,
        mouseY: mouseY - parentTop,
    };
}


export function getViewBoxFromPath(path) {
    // Split the path into its components
    const components = path.split(' ');

    // Initialize min and max coordinates
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

    // Iterate over the components
    for (let i = 0; i < components.length; i++) {
        // If the component is a coordinate pair
        if (!isNaN(components[ i ]) && !isNaN(components[ i + 1 ])) {
            // Update min and max x
            minX = Math.min(minX, parseFloat(components[ i ]));
            maxX = Math.max(maxX, parseFloat(components[ i ]));

            // Update min and max y
            minY = Math.min(minY, parseFloat(components[ i + 1 ]));
            maxY = Math.max(maxY, parseFloat(components[ i + 1 ]));

            // Skip the next component since we've already processed it
            i++;
        }
    }

    // Return the viewBox values
    return `${minX} ${minY} ${maxX - minX} ${maxY - minY}`;
}

// Usage:
// const path = "M0 250 C0,150 255,350 510,250 L 510 500 L 0 500 L 0 250";
// console.log(getViewBoxFromPath(path));  // Outputs: "0 150 510 350"



export function horizontalToVerticalPath(horizontalPath) {
    const commands = horizontalPath.split(' ');

    return commands.map(command => {
        if (command.startsWith('M') || command.startsWith('Q') || command.startsWith('T')) {
            const [ letter, ...coords ] = command.split(/(?<=\D)(?=\d)|(?<=\d)(?=\D)/g);
            const [ x, y ] = coords.map(Number);
            return `${letter}${y} ${x}`;
        } else {
            return command;
        }
    }).join(' ');
}


// usage
// const twoWavesPath = "M0 50 Q25 100 50 50 T100 50 T150 50 T200 50";
// const verticalWavesPath = horizontalToVerticalPath(twoWavesPath);

export function createAbsVerticalWavesPath(numWaves, height, width) {
    let path = `M${width / 2} 0 `;
    const endPointX = width / 2;

    for (let i = 0; i < numWaves; i++) {
        const controlPointX = width;
        const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
        const endPointY = (i + 1) * height / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }

    return path;
}
// usage
// const twoVerticalWavesPath = createAbsVerticalWavesPath(2, 200, 50);


// export function createVerticalWavesPath2(numWaves, height, width) {
//     let path = `M${width / 2} 0 `;
//     const endPointX = width / 2;

//     for (let i = 0; i < numWaves; i++) {
//         const controlPointX = i % 2 === 0 ? 0 : width;
//         const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
//         const endPointY = (i + 1) * height / numWaves;

//         path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
//     }
//     return path;
// }
// export function createVerticalWavesPathBefore(numWaves, height, width, amplitudeChange = 0) {
//     let path = `M${width / 2} 0 `;
//     const endPointX = width / 2;

//     for (let i = 0; i < numWaves; i++) {
//         const controlPointX = i % 2 === 0 ? amplitudeChange : width - amplitudeChange;
//         const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
//         const endPointY = (i + 1) * height / numWaves;

//         path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
//     }
//     return path;
// }

export function createVerticalWavesPath(numWaves, height, width, amplitude = width / 2) {
    const maxAmplitude = width
    const zeroAmplitude = width / 2
    let path = `M${zeroAmplitude} 0 `;
    const endPointX = zeroAmplitude;

    for (let i = 0; i < numWaves; i++) {
        const controlPointX = i % 2 === 0 ? zeroAmplitude - amplitude : zeroAmplitude + amplitude;
        const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
        const endPointY = (i + 1) * height / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createVerticalWavesPathWithIncreasingAmplitude(numWaves, height, width, maxAmplitude = width) {
    const zeroAmplitude = width / 2;
    let path = `M${zeroAmplitude} 0 `;
    const endPointX = zeroAmplitude;

    const amplitudeStep = maxAmplitude / numWaves;
    for (let i = 0; i < numWaves; i++) {
        const amplitude = i * amplitudeStep;

        const controlPointX = i % 2 === 0 ? zeroAmplitude - amplitude : zeroAmplitude + amplitude;
        const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
        const endPointY = (i + 1) * height / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createVerticalWavesPathWithDecreasingAmplitude(numWaves, height, width, maxAmplitude = width) {
    const zeroAmplitude = width / 2;
    let path = `M${zeroAmplitude} 0 `;
    const endPointX = zeroAmplitude;

    const amplitudeStep = maxAmplitude / numWaves;
    for (let i = 0; i < numWaves; i++) {
        const amplitude = maxAmplitude - i * amplitudeStep;

        const controlPointX = i % 2 === 0 ? zeroAmplitude - amplitude : zeroAmplitude + amplitude;
        const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
        const endPointY = (i + 1) * height / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createVerticalWavesPathWithIncreasingDecreasingAmplitude(numWaves, height, width, maxAmplitude = width) {
    const zeroAmplitude = width / 2;
    let path = `M${zeroAmplitude} 0 `;
    const endPointX = zeroAmplitude;

    const amplitudeStep = maxAmplitude / (numWaves / 2);
    for (let i = 0; i < numWaves; i++) {
        let amplitude;
        if (i < numWaves / 2) {
            // First half: increasing amplitude
            amplitude = i * amplitudeStep;
        } else {
            // Second half: decreasing amplitude
            amplitude = maxAmplitude - (i - numWaves / 2) * amplitudeStep;
        }

        const controlPointX = i % 2 === 0 ? zeroAmplitude - amplitude : zeroAmplitude + amplitude;
        const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
        const endPointY = (i + 1) * height / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createVerticalWavesPathWithDecreasingIncreasingAmplitude(numWaves, height, width, maxAmplitude = width) {
    const zeroAmplitude = width / 2;
    let path = `M${zeroAmplitude} 0 `;
    const endPointX = zeroAmplitude;

    const amplitudeStep = maxAmplitude / (numWaves / 2);
    for (let i = 0; i < numWaves; i++) {
        let amplitude;
        if (i < numWaves / 2) {
            // First half: decreasing amplitude
            amplitude = maxAmplitude - i * amplitudeStep;
        } else {
            // Second half: increasing amplitude
            amplitude = (i - numWaves / 2) * amplitudeStep;
        }

        const controlPointX = i % 2 === 0 ? zeroAmplitude - amplitude : zeroAmplitude + amplitude;
        const controlPointY = (i * height / numWaves) + (height / (numWaves * 2));
        const endPointY = (i + 1) * height / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

// export function createHorizontalWavesPathBefore(numWaves, width, height, amplitudeChange) {
//     let path = `M0 ${height / 2} `;
//     const endPointY = height / 2;

//     for (let i = 0; i < numWaves; i++) {
//         const controlPointY = i % 2 === 0 ? amplitudeChange : height - amplitudeChange;
//         const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
//         const endPointX = (i + 1) * width / numWaves;

//         path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
//     }
//     return path;
// }

export function createHorizontalWavesPath(numWaves, width, height, amplitude = height) {
    let path = `M0 ${height / 2} `;
    const endPointY = height / 2;

    for (let i = 0; i < numWaves; i++) {
        const controlPointY = i % 2 === 0 ? height / 2 - amplitude : height / 2 + amplitude;
        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createHorizontalWavesPathWithIncreasingAmplitude(numWaves, width, height, maxAmplitude = height) {
    let path = `M0 ${height / 2} `;
    const endPointY = height / 2;

    const amplitudeStep = maxAmplitude / numWaves
    for (let i = 0; i < numWaves; i++) {
        const amplitude = i * amplitudeStep

        const controlPointY = i % 2 === 0 ? height / 2 + amplitude : height / 2 - amplitude;

        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createHorizontalWavesPathWithDecreasingAmplitude(numWaves, width, height, maxAmplitude = height) {
    let path = `M0 ${height / 2} `;
    const endPointY = height / 2;

    const amplitudeStep = maxAmplitude / numWaves
    for (let i = 0; i < numWaves; i++) {
        const amplitude = (numWaves - i - 1) * amplitudeStep

        const controlPointY = i % 2 === 0 ? height / 2 + amplitude : height / 2 - amplitude;

        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}


export function createHorizontalWavesPathWithIncreasingDecreasingAmplitude(numWaves, width, height, maxAmplitude = height) {
    let path = `M0 ${height / 2} `;
    const endPointY = height / 2;
    const iHalf = Math.floor(numWaves / 2)
    // const amplitudeStep = maxAmplitude / numWaves
    const amplitudeStep = maxAmplitude / iHalf
    for (let i = 0; i < iHalf; i++) {
        const amplitude = i * amplitudeStep

        const controlPointY = i % 2 === 0 ? height / 2 + amplitude : height / 2 - amplitude;

        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    for (let i = iHalf; i < numWaves; i++) {
        const amplitude = (numWaves - i - 1) * amplitudeStep

        const controlPointY = i % 2 === 0 ? height / 2 + amplitude : height / 2 - amplitude;

        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export function createHorizontalWavesPathWithDecreasingIncreasingAmplitude(numWaves, width, height, maxAmplitude = height) {
    let path = `M0 ${height / 2} `;
    const endPointY = height / 2;
    const iHalf = Math.floor(numWaves / 2)
    // const amplitudeStep = maxAmplitude / numWaves
    const amplitudeStep = maxAmplitude / iHalf
    // for (let i = iHalf; i < numWaves; i++) {
    for (let i = 0; i < iHalf; i++) {
        const amplitude = (iHalf - i) * amplitudeStep

        const controlPointY = i % 2 === 0 ? height / 2 + amplitude : height / 2 - amplitude;

        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    // for (let i = 0; i < iHalf; i++) {
    for (let i = iHalf; i < numWaves; i++) {
        const amplitude = (i - iHalf) * amplitudeStep

        const controlPointY = i % 2 === 0 ? height / 2 + amplitude : height / 2 - amplitude;

        const controlPointX = (i * width / numWaves) + (width / (numWaves * 2));
        const endPointX = (i + 1) * width / numWaves;

        path += `Q${controlPointX} ${controlPointY} ${endPointX} ${endPointY} `;
    }
    return path;
}

export const createWavesFuncMap = {
    "absVertical": createAbsVerticalWavesPath,
    "vertical": createVerticalWavesPath,
    "verticalIncreasing": createVerticalWavesPathWithIncreasingAmplitude,
    "verticalDecreasing": createVerticalWavesPathWithDecreasingAmplitude,
    "verticalIncreasingDecreasing": createVerticalWavesPathWithIncreasingDecreasingAmplitude,
    "verticalDecreasingIncreasing": createVerticalWavesPathWithDecreasingIncreasingAmplitude,
    "horizontal": createHorizontalWavesPath,
    "horizontalIncreasing": createHorizontalWavesPathWithIncreasingAmplitude,
    "horizontalDecreasing": createHorizontalWavesPathWithDecreasingAmplitude,
    "horizontalIncreasingDecreasing": createHorizontalWavesPathWithIncreasingDecreasingAmplitude,
    "horizontalDecreasingIncreasing": createHorizontalWavesPathWithDecreasingIncreasingAmplitude,
}

// Step 1: Create a function normalizePath that takes a path string as input
export function normalizeSvgPathNotHavingCommasIntoWithCommas(path) {
    // Step 2: Use the replace function with a regular expression to replace any spaces between numbers with commas
    return path.replace(/(\d+(\.\d+)?) (\d+(\.\d+)?)/g, '$1,$3');
}

export function convertPathToCommaSeparated(path) {
    return path.replace(/(\d+)\s(\d+)/g, '$1,$2');
}


export function scaleSvgPath(path, scaleFactor) {
    return path.replace(/(\d+\.?\d*)/g, (match) => parseFloat(match) * scaleFactor);
}

export function convertHorizontalPathToVertical(path) {
    const commands = path.split(' ');

    for (let i = 0; i < commands.length; i++) {
        if (!isNaN(commands[ i ])) {
            // Swap x and y coordinates
            [ commands[ i ], commands[ i + 1 ] ] = [ commands[ i + 1 ], commands[ i ] ];
            i++; // Skip next iteration because we've already processed the next command
        }
    }

    return commands.join(' ');
}

export function convertHorizontalPathWithCommasToVertical(path) {
    const commands = path.split(' ');
    const result = [];

    for (let i = 0; i < commands.length; i++) {
        let command = commands[ i ][ 0 ];
        const points = commands[ i ].slice(1).split(',');

        for (let j = 0; j < points.length; j += 2) {
            const x = points[ j ];
            const y = points[ j + 1 ];
            result.push(`${command}${y},${x}`);
            command = ' ';
        }
    }

    return result.join(' ');
}
