import React, { memo } from 'react'
import { useInView } from 'framer-motion'
import { StaticQuery, graphql } from "gatsby"
// import useNatureImages from "../../_shared/useNatureImages";
import getArrCardsNature from "../../_shared/getArrCardsNature"
import CircularMenu from "../../_shared/circular-menu/CircularMenu";

function CircularMenuNature({ data, ...rest }) {

    const refContainerCircleMenu = React.useRef(null)
    const isInView = useInView(refContainerCircleMenu)

    // const imagesFiles = useNatureImages();
    const imagesFiles = data.natureImgsFiles;
    const arrCardsNature = getArrCardsNature({ imagesFiles })
    return (
        <div
            id="circ"
            ref={refContainerCircleMenu}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: 'transparent'
            }}
        >
            <CircularMenu items={arrCardsNature} isInView={isInView} />
        </div>
    )



    // const imagesFiles = data.natureImgsFiles;
    // const arrCardsNature = getArrCardsNature({ imagesFiles })
    // return (
    //     <div>
    //         {data.natureImgsFiles.toString()}
    //     </div>
    // )

}

export default function CircularMenuNatureInsertData(props) {
    return (
        <StaticQuery
            query={graphql`
    query NatureImageQuery {

natureImgsFiles: allFile(
filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "galleries/nature-200"}, extension: {regex: "/(jpg)|(jpeg)|(png)/"}}
) {
    totalCount
    nodes {
id
base
name
childImageSharp {
id
gatsbyImageData(layout: FIXED)
}
}
}
}
  `}
            render={data => <CircularMenuNature data={data} {...props} />}
        />
    )
}


// export default CircularMenuNatureInsertData

