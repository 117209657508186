import React, { useState } from 'react'
import { motion, useAnimation, } from 'framer-motion'

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import * as stylesClay from "../../styles/clay.module.css"


const styleSxItem = ({ itemWidth, itemHeight, stylePosition }) => ({
    "& .tooltipWrap": {
        textAlign: "center",
    },

    "& .circleItem": {
        position: "absolute",
        display: "inline-grid",
        zIndex: 1,
        minWidth: itemWidth,
        height: itemHeight,
        paddingX: "10px",
        // borderRadius: "2px", // "50%",
        // bgcolor: "secondary2.main",
        // color: "secondary2.contrastText",
        cursor: "pointer",
        ...stylePosition,
        "&.bottom": {
            bottom: 0,
        },
        "&:hover": {
            // cursor: "pointer",
            borderRadius: "50%",
        },
        "& .descr": {
            zIndex: 2,
            position: "absolute",
            width: "max-content",
            height: "fit-content",
            left: 0,
            backgroundColor: "yellow", // "rgba(97, 97, 97, 0.92)",
            borderRadius: "20px",
            color: "#fff",
            padding: "4px 8px",
            fontSize: "0.6875rem",
            maxWidth: "300px",
            margin: "2px",
            wordWrap: "break-word",
            // fontWeight: 700,
            marginTop: "14px",
        },
        "& .link": {
            color: "currentColor",
            textDecoration: 'unset',
            fontSize: '0.8rem',
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .avatar": { width: 24, height: 24 }
    },
})

const varCircleItem = ({ iItem, x, y, angle, angle2, x2, y2, xLine45, x2rev, y2rev, itemHeight, itemWidth }) => {
    return {
        initial: false,
        animate: {
            x: [ 0, x, x2 ],
            y: [ 0, y, y2 ],
            scale: 1,
            opacity: 1,
            transition: {
                duration: 2,
                type: "spring",
                stiffness: 300,
                damping: 10,
            },
        },
        exit: {
            x: 0, y: 0,
            scale: 0,
            opacity: 0,
            transition: {
                duration: 1,
                type: "spring",
                stiffness: 300,
                damping: 10,
            },
        },
        whileHover: {
            x: [ 0, 2, 0 ],
            y: [ 0, 2, 0 ],
            opacity: 1,
        }
    }
}

const CirclesMotionItemX4 = ({
    itemOptions,
    iItem,
    runItems,
    handleOnItemTap
}) => {
    const { content, position, x, y, angle, angle2, x2, y2, xLine45, x2rev, y2rev, itemHeight, itemWidth } = itemOptions
    const [ showDescr, setShowDescr ] = useState(false)
    const handleClose = (e) => {
    }

    const handleOpen = (e) => {
        e.stopPropagation();
    }

    const handleMouseEnter = e => {
        e.stopPropagation();
        e.bubbles = false;
        setShowDescr(true)
    }
    const handleMouseLeave = e => {
        setShowDescr(false)
        e.stopPropagation();
        e.bubbles = false;
    }

    const itemControls = useAnimation()
    let stylePosition

    switch (position) {
        case "left":
            stylePosition = { left: 0 }
            break;
        case "right":
            stylePosition = { right: 0 }
            break;
        case "top":
            stylePosition = {
            }
            break;
        case "bottom":
            stylePosition = {
            }
            break;
        default:
            break;
    }

    const dataContent = JSON.stringify(content)

    return (
        <Box
            sx={styleSxItem({ itemWidth, itemHeight, stylePosition })}
        >

            <Tooltip
                open={showDescr}
                onClose={handleClose}
                onOpen={handleOpen}
                title={content.description}
                arrow
                className="tooltipWrap"
            >

                <motion.div
                    data-content={dataContent}
                    className={`circleItem ${stylesClay.clay} ${position === "bottom" ? "bottom" : ""}`}
                    variants={varCircleItem({ iItem, x, y, angle, angle2, x2, y2, xLine45, x2rev, y2rev, itemHeight, itemWidth })}
                    onTap={(e, info) => {
                        handleOnItemTap(e, info, { iItem })
                        itemControls.start("animate")
                    }}
                    style={{ x: 0, y: 0, opacity: 0 }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    key={`varCircleItem-${iItem}`}
                >
                    <a
                        href={content.link}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                    >
                        {content.name}
                    </a>
                </motion.div>
            </Tooltip>
        </Box>
    )
}

export default CirclesMotionItemX4
