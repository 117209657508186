import React, { FC, ReactNode, CSSProperties } from 'react'
import Box from '@mui/system/Box'
import { keyframes } from "@emotion/react";
import svgHyperbola from "../../images/svvgNormally/hyperbola.svg"

const keyframesRotateZ = keyframes({
    "0%": {
        opacity: 1,
        transform: `rotateZ(360deg) scale3d(1, 1, 1)`,
    },

    "50%": {
        opacity: 0.2,
        transform: `rotateZ(180deg) scale3d(0.3, 0.3, 0.3)`,

    },

    "100%": {
        opacity: 1,
        transform: `rotateZ(0deg) scale3d(1, 1, 1)`,
    }
})

const keyframesAvatar = keyframes({
    "0%": {
        opacity: 1,
        transform: `rotateZ(360deg) scale3d(1, 1, 1)`,
    },

    "50%": {
        opacity: 1,
        transform: `rotateZ(180deg) scale3d(0.8, 0.8, 0.8)`,
    },

    "100%": {
        opacity: 1,
        transform: `rotateZ(0deg) scale3d(1, 1, 1)`,
    }
})

const keyframesTranslate = keyframes({
    "0%": {
        opacity: 0.2,
        transform: 'rotateZ(0deg) translate3d(-10px, -10px, -10px)'
    },

    "50%": {
        opacity: 1,
        transform: 'rotateZ(180deg) translate3d(10px, 10px, 10px)'
    },

    "100%": {
        opacity: 0.2,
        transform: 'rotateZ(360deg) translate3d(-10px, -10px, -10px)'
    }
})

export const configKeyframes = {
    keyframesRotateZ: keyframesRotateZ,
    keyframesTranslate,
    keyframesAvatar: keyframesAvatar,
}



const optionsDefault = {
    animKeyframes: configKeyframes.keyframesRotateZ,
    isImg: true,
    isBefore: true,
    isAfter: true,
    isImgBefore: true,
    isImgAfter: true,
    imgBefore: svgHyperbola,
    imgAfter: svgHyperbola,
    img: svgHyperbola,
    heightBase: 200,
    widthBase: 200,
    ratioHeightImg: 1.5,
    ratioWidthImg: 1.5,
    diameterRotating: 350,
    ratioHeightBefore: 1.0,
    ratioWidthBefore: 1.0,
    ratioHeightAfter: 1.0,
    ratioWidthAfter: 1.0,
    animDuration: 8,
    animDelay: 0.4,
    animationIterationCount: 2,
    bgColorBefore: 'rgb(26 212 23 / 11%)',
    bgColorAfter: 'rgb(212 23 23 / 11%)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 10px 10px 10px inset, rgba(0, 0, 0, 0.05) 15px 15px 15px, rgba(0, 0, 0, 0.05) 15px 15px 15px, rgba(255, 255, 255, 0.11) -10px -10px 15px inset',
    boxShadowBefore: "rgba(0, 0, 0, 0.05) 10px 10px 10px inset, rgba(0, 0, 0, 0.05) 15px 15px 15px, 15px 15px 15px rgba(0, 0, 0, 0.05), inset -10px -10px 15px #e000008a",
    boxShadowAfter: `inset 10px 10px 10px rgb(0 0 0 / 5%), 15px 15px 15px rgb(0 0 0 / 5%), 15px 15px 15px rgb(0 0 0 / 5%), inset -10px -10px 15px #5000008a`,
}

const optionsAvatar = {
    animKeyframes: configKeyframes.keyframesAvatar,
    isImg: true,
    isBefore: false,
    isAfter: false,
    isImgBefore: false,
    isImgAfter: false,
    imgBefore: svgHyperbola,
    imgAfter: svgHyperbola,
    img: svgHyperbola,
    heightBase: 70,
    widthBase: 70,
    ratioHeightImg: 0.95,
    ratioWidthImg: 0.95,
    diameterRotating: 150,
    ratioHeightBefore: 1.0,
    ratioWidthBefore: 1.0,
    ratioHeightAfter: 1.0,
    ratioWidthAfter: 1.0,
    animDuration: 18,
    animDelay: 14.4,
    animationIterationCount: 5,
    bgColorBefore: 'rgb(26 212 23 / 11%)',
    bgColorAfter: 'rgb(212 23 23 / 11%)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 10px 10px 10px inset, rgba(0, 0, 0, 0.05) 15px 15px 15px, rgba(0, 0, 0, 0.05) 15px 15px 15px, rgba(255, 255, 255, 0.11) -10px -10px 15px inset',
    boxShadowBefore: "rgba(0, 0, 0, 0.05) 10px 10px 10px inset, rgba(0, 0, 0, 0.05) 15px 15px 15px, 15px 15px 15px rgba(0, 0, 0, 0.05), inset -10px -10px 15px #e000008a",
    boxShadowAfter: `inset 10px 10px 10px rgb(0 0 0 / 5%), 15px 15px 15px rgb(0 0 0 / 5%), 15px 15px 15px rgb(0 0 0 / 5%), inset -10px -10px 15px #5000008a`,
}

export const configOptionsImg3dUpright = {
    optionsDefault,
    optionsAvatar,
}

interface StyleSxProps {
    isImg: boolean;
    isBefore: boolean;
    isAfter: boolean;
    isImgBefore: boolean;
    isImgAfter: boolean;
    heightBase: number;
    widthBase: number;
    imgBefore: string;
    imgAfter: string;
    heightImg: number;
    widthImg: number;
    heightBefore: number;
    widthBefore: number;
    heightAfter: number;
    widthAfter: number;
    topBefore: number;
    leftBefore: number;
    topAfter: number;
    leftAfter: number;
    diameterRotating: number;
    animDuration: number;
    animDelay: number;
    animName: string;
    animationIterationCount: number;
    bgColorBefore: string;
    bgColorAfter: string;
    boxShadow: string;
    boxShadowBefore: string;
    boxShadowAfter: string;
}

const styleSx = ({ isImg, isBefore, isAfter, isImgBefore, isImgAfter, heightBase, widthBase, imgBefore, imgAfter, heightImg, widthImg, heightBefore, widthBefore, heightAfter, widthAfter, topBefore, leftBefore, topAfter, leftAfter, diameterRotating, animDuration, animDelay, animName, animationIterationCount, bgColorBefore, bgColorAfter, boxShadow, boxShadowBefore, boxShadowAfter }: StyleSxProps) => {
    return {
        "&.wrapAMOC": {
            display: "contents",
            perspective: `1000px`,
            transformStyle: "preserve-3d",
            position: "relative",
            "& .wrapItemAv": {
                position: 'relative',
                display: "inline-block",
                borderRadius: '50%',
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.15)',
                transformOrigin: `${diameterRotating}px`,
                animationName: `${animName}`,
                animationIterationCount: animationIterationCount,
                animationTimingFunction: 'linear',
                animationDuration: `${animDuration}s`,
                animationDelay: `${animDelay}s`,
                zIndex: '99999',
                perspective: `1000px`,
                transformStyle: "preserve-3d",
                height: `${heightBase}px`,
                width: `${widthBase}px`,
                left: `${-(diameterRotating - widthBase / 2)}px`, //  '0px',
                top: `0px`,
                "&:before": isBefore ? {
                    content: '\'\'',
                    position: 'absolute',
                    top: `${-topBefore}px`,
                    left: `${-leftBefore}px`,
                    height: `${heightBefore}px`,
                    width: `${widthBefore}px`,
                    borderRadius: '50%',
                    backgroundColor: `${bgColorBefore}`,
                    backgroundImage: isImgBefore ? `url(${imgBefore})` : null,
                    boxSizing: 'border-box',
                    transformOrigin: "center",
                    boxShadow: boxShadowBefore,
                    transform: 'rotate3d(1, 1, 1, 90deg)',
                    backgroundOrigin: "content-box",
                } : null,
                "&:after": isAfter ? {
                    content: '\'\'',
                    position: 'absolute',
                    top: `${-topAfter}px`,
                    left: `${-leftAfter}px`,
                    height: `${heightAfter}px`,
                    width: `${widthAfter}px`,
                    borderRadius: '50%',
                    backgroundColor: `${bgColorAfter}`,
                    backgroundImage: isImgAfter ? `url(${imgAfter})` : null,
                    boxSizing: 'border-box',
                    boxShadow: boxShadowAfter,
                    transformOrigin: "center",
                    transform: 'rotate3d(1, 1, 1, 180deg)',
                    backgroundOrigin: "content-box",
                } : null,
            },


            "& .animated": {
                animationDuration: `${animDuration}s`,
                animationDelay: `${animDelay}s`,
                animationIterationCount: animationIterationCount,
                animationTimingFunction: 'linear'
            },

            "& .bounce": {
                animationName: `${animName}`,
                animationDirection: 'reverse'
            },

            "& .img": {
                display: 'inline-flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap',

                overflow: 'hidden',
                borderRadius: '100%',
                height: `${heightImg}px`,
                width: `${widthImg}px`,
                top: `${(heightBase - heightImg) / 2}px`,
                left: `${(widthBase - widthImg) / 2}px`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: '90%',
                position: 'relative',
                zIndex: '1',
                boxShadow: boxShadow,
            },
        },
    } as CSSProperties

}

interface Img3dUprightMovingProps {
    options: Img3dUprightMovingOptions;
}

interface Img3dUprightMovingOptions {
    animKeyframes: ReturnType<typeof keyframes> | string;
    isImg: boolean;
    isBefore: boolean;
    isAfter: boolean;
    isImgBefore: boolean;
    isImgAfter: boolean;
    imgBefore: string;
    imgAfter: string;
    img: string;
    heightBase: number;
    widthBase: number;
    ratioHeightImg: number;
    ratioWidthImg: number;
    diameterRotating: number;
    ratioHeightBefore: number;
    ratioWidthBefore: number;
    ratioHeightAfter: number;
    ratioWidthAfter: number;
    animDuration: number;
    animDelay: number;
    animationIterationCount: number;
    bgColorBefore: string;
    bgColorAfter: string;
    boxShadow: string;
    boxShadowBefore: string;
    boxShadowAfter: string;
    children?: ReactNode;
}

const Img3dUprightMoving: FC<Img3dUprightMovingProps> = (props: Img3dUprightMovingProps) => {

    const mergedOptions = { ...optionsDefault, ...props.options }
    const {
        isImg,
        isBefore,
        isAfter,
        isImgBefore,
        isImgAfter,
        imgBefore,
        imgAfter,
        img,
        diameterRotating,
        heightBase,
        widthBase,
        ratioHeightImg,
        ratioWidthImg,
        ratioHeightBefore,
        ratioWidthBefore,
        ratioHeightAfter,
        ratioWidthAfter,
        animDuration,
        animDelay,
        animKeyframes,
        animationIterationCount,
        bgColorBefore,
        bgColorAfter,
        boxShadow,
        boxShadowBefore,
        boxShadowAfter,
        children
    } = mergedOptions

    const heightImg = ratioHeightImg * heightBase
    const widthImg = ratioWidthImg * widthBase

    const heightBefore = ratioHeightBefore * heightBase
    const widthBefore = ratioWidthBefore * widthBase
    const heightAfter = ratioHeightAfter * heightBase
    const widthAfter = ratioWidthAfter * widthBase

    const topBefore = (heightBefore - heightBase) / 2
    const leftBefore = (widthBefore - widthBase) / 2
    const topAfter = (heightAfter - heightBase) / 2
    const leftAfter = (widthAfter - widthBase) / 2

    return (
        <Box
            className="wrapAMOC"
            sx={styleSx({ isImg, isBefore, isAfter, isImgBefore, isImgAfter, heightBase, widthBase, imgBefore, imgAfter, heightImg, widthImg, heightBefore, widthBefore, heightAfter, widthAfter, topBefore, leftBefore, topAfter, leftAfter, diameterRotating, animDuration, animDelay, animName: animKeyframes, animationIterationCount, bgColorBefore, bgColorAfter, boxShadow, boxShadowBefore, boxShadowAfter })}
            data-textquote="quote-text-10"
        >
            <div
                className="wrapItemAv"
            >
                <div
                    className="img animated bounce"
                    style={{
                        backgroundImage: isImg ? `url(${img})` : undefined,
                    }}
                >
                    {children}
                </div>
            </div>
        </Box>
    )
}



// Img3dUprightMoving.defaultProps = {
//     options: {
//         ...optionsDefault,
//     }
// };

export default Img3dUprightMoving