const getHomeAnimatingTextsForTypewriter = (t, color = "#27ae60", bgColor = "white") =>
    t.typeString('My name is')
        .pauseFor(500)
        .deleteChars(10)
        .pauseFor(300)
        .deleteAll()
        .typeString('Mein <strong>Name</strong> ist')
        .pauseFor(2500)
        .deleteChars(10)
        .pauseFor(300)
        .deleteAll()
        .typeString('<strong>Web7</strong> je')
        // .callFunction(() => {
        //     console.log('String typed out!');
        // })
        .pauseFor(2500)
        .deleteChars(10)
        .deleteAll()
        .pauseFor(300)
        .typeString('<p>✨ Web7.sk ✨ for 🌟 you</p>')
        .typeString(`<strong>Web7.sk</strong> je váš`)
        .pauseFor(300)
        .typeString(`<p style="font-style: italic;">web <span style="background-color: ${bgColor}; color: ${color}; border-bottom: 2px solid #000;">developer</span></p>`)
        .pauseFor(300)
        .typeString(`<p style="font-style: italic;">web <span style="background-color: ${bgColor}; color: ${color}; border-bottom: 2px solid #000;">dizajnér</span></p>`)
        .pauseFor(300)
        .typeString(`<span style="font-style: italic;">web <span style="background-color: ${bgColor}; color: ${color}; border-bottom: 2px solid #000;">administrátor</span></span>`)
        .start()



export { getHomeAnimatingTextsForTypewriter }