import React, { lazy, Suspense } from 'react'
const WavedFrameAnimationForChildren = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'WavedFrameAnimationForChildrenLazy' */ './WavedFrameAnimationForChildren'))

const WavedFrameAnimationForChildrenLazy = (props) => {
    return (
        <Suspense
        // fallback={<div>Loading WavedFrameAnimationForChildren ...</div>}
        >
            <WavedFrameAnimationForChildren {...props} />
        </Suspense>
    )
}

export default WavedFrameAnimationForChildrenLazy