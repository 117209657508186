import React from "react";
import Typography from '@mui/material/Typography';
import VideoTag from "../../_shared/videoTag"

const Inventura = () => {

  return (
    <>
      <Typography variant="h4">
        Inventúra
      </Typography>

      <Typography sx={{ fontSize: [ "smaller", "small" ] }} >
        Program pre reporty (pivot tabuľky) k zdrojovým dátam. Prehľad v
        reportoch. Reporty prispôsobené pre maximálne možné zobrazenie na
        displej a tlač. Možné filtrovanie dát pre report. Možná úprava reportu, vygenerovanie a uloženie ďalšieho reportu.
      </Typography>
      <VideoTag src="/sf/vba-inv/inventura.mp4" title="App Inventúra" />
    </>
  );
};

export default Inventura;

