import React from 'react';
import SpinningCardsCarousel from "../../_shared/spinningCardsCarousel"
import useSImagesW200_4 from "../../_shared/useSImagesW200_4"

const S_4CardsSpinningCarousel = ({ options = {} }) => {
  const { index = 1 } = options
  const sImagesFiles = useSImagesW200_4();
  const imagesNodes = sImagesFiles.nodes
  const imagesNumber = sImagesFiles.totalCount;
  const defaultOptions = { index, makePolygonCarousel: true, spinningTimeInSeconds: 16, spinningClockwise: true }
  const imgOptions = { imagesNodes, imagesNumber }

  const spinningCarouselOptions = { ...defaultOptions, ...options, ...imgOptions }
  return (
    <SpinningCardsCarousel options={{ ...spinningCarouselOptions, imgWidth: 200, switchAxes: true }} />
  );
};

export default S_4CardsSpinningCarousel;
