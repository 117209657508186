import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, Paper } from '@mui/material';

const ApiRoutesListForTemperatureMeasurementApp = () => {
    return (
        // <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
            <Paper elevation={3}
                sx={{
                    my: [ 1, 2, 3 ],
                    px: [ 0.5, 1, 2 ],
                    py: [ 1, 2 ],
                    textAlign: 'center',
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    "& .MuiTypography-root": {
                        fontSize: [ "smaller", "small", "medium" ],
                    }
                }}>
                <Typography variant="h5" component="div"
                    gutterBottom
                    sx={{
                        fontSize: [ "medium", "large" ],
                        py: [ 1, 2 ],
                    }}
                >
                    Základné HTTP endpointy
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    /api/users
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Manažment registrovaných používateľov.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    /api/files
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Manažment generovania, downloadu súborov s dátami z databázy.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    /api/crons
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Manažment nastavenia, spúšťania cron jobov na servere.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    /api/temp1
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Manažment nastavenia a spúšťania merania teploty na snímači 1.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    /api/ping
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Ping na prebudenie api servera.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default ApiRoutesListForTemperatureMeasurementApp;