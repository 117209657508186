import React, { lazy, Suspense } from 'react'

const BowList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'BowListLazy' */ './BowList'))

const BowListLazy = (props) => {
    return (<div>
        <Suspense
        // fallback={<div>Loading BowList ...</div>}
        >
            <BowList {...props} />
        </Suspense>
    </div>
    )
}
export default BowListLazy