import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface AnimationButtonProps extends IconButtonProps {
    handleClickStartStopAnimation: () => void;
}


const AnimationButton = (props: AnimationButtonProps) => {
    const { handleClickStartStopAnimation, children, ...other } = props
    return <IconButton
        color="primary"
        aria-label="animate"
        onClick={handleClickStartStopAnimation}
        sx={{
            zIndex: 2,
            "& svg": {
                fontSize: 'large',
            }
        }}
        {...other} // pass down any other props of IconButton
    >
        {children}
    </IconButton>;
}

export default AnimationButton