import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const ReactForVisitors = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });


    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="react-for-visitors" className="toc"><span>Výhody React web stránok pre návštevníkov</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="react-for-visitors"
            >
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="loading-speed" className="waved toc toc0"><span>Rýchlosť načítania</span></h4>
                    <div>React optimalizuje výkon webových stránok pomocou virtuálneho DOM, čo vedie k rýchlejšiemu načítaniu webstránky.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="riadenie" className="waved toc toc0"><span>Interaktivita</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>React umožňuje vytvárať bohaté užívateľské rozhrania s vysokou mierou interaktivity. Návštevníci tak majú lepšiu užívateľskú skúsenosť.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="stability" className="waved toc toc0"><span>Stabilita</span></h4>
                    <div>React je podporovaný spoločnosťou Facebook a má silnú komunitu, čo dlhodobo zaručuje stabilitu a spoľahlivosť webových stránok.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="zber" className="waved toc toc0"><span>Responzívnosť</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>React podporuje vývoj responzívnych web stránok, automaticky sa prispôsobujúcich rôznym veľkostiam obrazovky.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="dovera" className="waved toc toc0"><span>Jednotnosť</span></h4>
                    <div>React umožňuje vytvárať konzistentné užívateľské rozhrania pomocou opätovne použiteľných komponentov. Pre návštevníkov to znamená ľahšiu orientáciu na stránkach.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};

export default ReactForVisitors;