import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";

export default function AlertOkDialog({
    children,
    btnOKdescription,
    opened,
    handleClose,
    ...rest
}) {

    return (
        <div>
            <Dialog
                open={opened}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose} color="primary"
                    // autoFocus
                    >
                        {btnOKdescription}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}