import React from "react"
import { motion } from "framer-motion";
import useTheme from "@mui/system/useTheme";
import Box from "@mui/system/Box";

const styleSxBowWrap = ({ angle }) => ({
    opacity: 0.5,
    position: "absolute",
    textAlign: "center",
    fontSize: "8px",

    "& .svgBow": {
        opacity: 1,
        position: "relative",
        transform: `rotate(${angle}deg)`,
        "& .svgBowPath": {
            fill: "none",
            stroke: "black",
            strokeWidth: 2,
            strokeLinecap: "round",
        },
    },
})

const varBowWrap = ({ palette, shadow }) => {
    return {
        initial: false,
        animate: {
            opacity: 1,
            rotateZ: [ null, 180, 0, 90 ],
            transition: {
                duration: 1.5,
                when: "beforeChildren",
            },

        },
        exit: {
            opacity: 0.5,
            y: -50,
            transition: {
                staggerChildren: 0.01,
                duration: 0.2,
            },
        },
    }
}

const varTextPath = (({ palette, fontSize }) => {
    return {
        initial: false,
        animate: {
            fontSize: [ 8, fontSize ],
            fill: palette.secondary.main,
            transition: {
                duration: 0.5,
            }
        },
        exit: {
            pathLength: 0,
            pathSpacing: 0.0,
            fillOpacity: 0,
            transition: { duration: 0.2 }
        },
        whileHover: {
            stroke: palette.primary2.main,
            fill: palette.primary.main,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            scale: 0.8,
            stroke: palette.primary2.main,
            fill: palette.primary.main,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
})


const BowSvg = (({ arrText = [], coef1 = 1, depth1 = 250, coef2 = 1, depth2 = 50, length = 500, angle = 0, styleBow = {}, stylePath = {}, styleText = {}, fontSize = 24 }) => {

    const theme = useTheme()
    const palette = theme.palette
    const isText = arrText.length > 0 ? true : false
    const x1 = 0
    const y1 = 0
    const y2 = length
    const halfLength = length / 2

    const d = `M ${x1} ${y1} Q ${x1 + coef1 * depth1} ${halfLength} ${x1} ${y2} ${x1 + coef2 * depth2} ${halfLength} ${x1} ${y1}`

    const xEnd = length * Math.sin(angle)
    const yEnd = length * Math.cos(angle)
    const maxDepth = Math.max(depth1, depth2)
    const width = (maxDepth + xEnd) / 2 + 2 * fontSize
    const height = yEnd + 2 * fontSize
    return (
        <Box component={motion.div}
            variants={varBowWrap({ palette: theme.palette, shadow: theme.shadows[ 11 ] })}
            sx={styleSxBowWrap({ angle })}
            key="varBowWrap"
        >
            <svg
                height={height}
                viewBox={`-${fontSize} -${fontSize} ${width} ${height}`}
                xmlns="http://www.w3.org/2000/svg"
                className="svgBow"
                style={styleBow}
            >
                <path
                    id="bow"
                    d={d}
                    className="svgBowPath"
                    style={stylePath}
                />
                {isText && (
                    <text
                        style={styleText}
                    >
                        <motion.textPath xlinkHref="#bow"
                            variants={varTextPath({ palette, fontSize })}
                            key="texPath"
                        >
                            {arrText}
                        </motion.textPath>
                    </text>
                )}
            </svg>
        </Box>
    )
})

export default BowSvg

