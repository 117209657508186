import React, { Suspense, lazy } from 'react'

const AbsImagesGrouped2 = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AbsImagesGrouped2Lazy' */ './AbsImagesGrouped2'))

const AbsImagesGrouped2Lazy = (props) => {
    return (<Suspense fallback={<div>Loading AbsImagesGrouped2 ...</div>}>
        <AbsImagesGrouped2 {...props} />
    </Suspense>
    )
}

export default AbsImagesGrouped2Lazy