import { getWaveConfigNoPartCoef } from '../../utils/getWave';


const WavesSingleConfiguration = {
    wConcaveH: getWaveConfigNoPartCoef("waves05phase0step2af1"),
    wConcaveM: getWaveConfigNoPartCoef("waves05phase0step20af4"),
    wConcaveL: getWaveConfigNoPartCoef("waves05phase0step10af10"),
    wConvexH: getWaveConfigNoPartCoef("waves05phase1step2af1"),
    wConvexM: getWaveConfigNoPartCoef("waves05phase1step2af4"),
    wConvexL: getWaveConfigNoPartCoef("waves05phase1step2af10"),
    w1M: getWaveConfigNoPartCoef("waves1phase0step2af4"),
    w1MP: getWaveConfigNoPartCoef("waves1phase05step2af4"),
    w4MP: getWaveConfigNoPartCoef("waves4phase05step2af4"),
    w4Maf10: getWaveConfigNoPartCoef("waves4phase0step2af10"),

}


const animDefault = {
    inAnim: {
        duration: 1,
        transition: {
            ease: "easeInOut",
            delay: 1,
        },
        animation: {
            pathLength: 1,
            pathOffset: [ 1, 0 ],
            opacity: [ 0, 1 ],
        },
    },
    outAnim: {
        duration: 1,
        transition: {
            ease: "easeInOut",
            delay: 1,
        },
        animation: {
            pathLength: 0,
            pathOffset: [ 0, -1 ],
            opacity: [ 1, 0 ],
        },
    },
}




const WavesConfiguration = (partCoef) => {
    const { wConcaveH, wConcaveM, wConcaveL, wConvexH, wConvexM, wConvexL, w1M, w1MP } = WavesSingleConfiguration
    return {
        w1M: [ {
            ...w1M,
            partCoef: partCoef ? partCoef : 1,
        } ],
        w1MP: [ { ...w1MP, partCoef: partCoef ? partCoef : 1 } ],
        w1ConcaveH:
            [ { ...wConcaveH, partCoef: partCoef ? partCoef : 1 } ],
        w1ConcaveM: [ {
            ...wConcaveM, partCoef: partCoef ? partCoef : 1,
        } ],
        w1ConcaveL: [ {
            ...wConcaveL, partCoef: partCoef ? partCoef : 1,
        } ],
        w2ConcaveH: [
            { ...wConcaveH, partCoef: partCoef ? partCoef : 0.5 },
            { ...wConcaveH, partCoef: partCoef ? partCoef : 0.5 },
        ],
        w2ConcaveM: [
            { ...wConcaveM, partCoef: partCoef ? partCoef : 0.5 },
            { ...wConcaveM, partCoef: partCoef ? partCoef : 0.5 },
        ],
        w2ConcaveL: [
            { ...wConcaveL, partCoef: partCoef ? partCoef : 0.5 },
            { ...wConcaveL, partCoef: partCoef ? partCoef : 0.5 },
        ],
        w3ConcaveH: [
            { ...wConcaveH, partCoef: partCoef ? partCoef : 0.35 },
            { ...wConcaveH, partCoef: partCoef ? partCoef : 0.3 },
            { ...wConcaveH, partCoef: partCoef ? partCoef : 0.35 },
        ],
        w3ConcaveM: [
            { ...wConcaveM, partCoef: partCoef ? partCoef : 0.35 },
            { ...wConcaveM, partCoef: partCoef ? partCoef : 0.3 },
            { ...wConcaveM, partCoef: partCoef ? partCoef : 0.35 },
        ],
        w3ConcaveL: {
            w1L: { ...wConcaveL, partCoef: partCoef ? partCoef : 0.35 },
            w2L: { ...wConcaveL, partCoef: partCoef ? partCoef : 0.3 },
            w3L: { ...wConcaveL, partCoef: partCoef ? partCoef : 0.35 },
        },
        w1ConvexH:
            [ { ...wConvexH, partCoef: partCoef ? partCoef : 1 } ],

        w1ConvexM: [ {
            ...wConvexM, partCoef: partCoef ? partCoef : 1,
        } ],
        w1ConvexL: [ {
            ...wConvexL, partCoef: partCoef ? partCoef : 1,
        } ],
        w2ConvexH: [
            { ...wConvexH, partCoef: partCoef ? partCoef : 0.5 },
            { ...wConvexH, partCoef: partCoef ? partCoef : 0.5 },
        ],
        w2ConvexM: [
            { ...wConvexM, partCoef: partCoef ? partCoef : 0.5 },
            { ...wConvexM, partCoef: partCoef ? partCoef : 0.5 },
        ],
        w2ConvexL: [
            { ...wConvexL, partCoef: partCoef ? partCoef : 0.5 },
            { ...wConvexL, partCoef: partCoef ? partCoef : 0.5 },
        ],
        w3ConvexH: [
            { ...wConvexH, partCoef: partCoef ? partCoef : 0.35 },
            { ...wConvexH, partCoef: partCoef ? partCoef : 0.3 },
            { ...wConvexH, partCoef: partCoef ? partCoef : 0.35 },
        ],
        w3ConvexM: [
            { ...wConvexM, partCoef: partCoef ? partCoef : 0.35 },
            { ...wConvexM, partCoef: partCoef ? partCoef : 0.3 },
            { ...wConvexM, partCoef: partCoef ? partCoef : 0.35 },
        ],
        w3ConvexL: [
            { ...wConvexL, partCoef: partCoef ? partCoef : 0.35 },
            { ...wConvexL, partCoef: partCoef ? partCoef : 0.3 },
            { ...wConvexL, partCoef: partCoef ? partCoef : 0.35 },
        ],
    }
}

const baseWavedFramesT6R2B4L2RedCrimson = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        arrCoefsPadding: [ -1, -1, -1, -1, - 1, -1 ],
        waves: [
            ...WavesConfiguration(1 / 6).w3ConvexM,
            ...WavesConfiguration(1 / 6).w3ConcaveH,
        ],
        createPaddings: false,
        styles: [ { fill: "red" }, { fill: 'crimson' }, { fill: "red" }, { fill: 'crimson' }, { fill: "red" }, { fill: 'crimson' } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false,
        styles: [ { fill: 'crimson' } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        arrCoefsPadding: [ -1, -0.5, -0.5, -1 ],
        waves: [
            {
                ...WavesSingleConfiguration.wConvexM, partCoef: 0.25,
            },
            {
                ...WavesSingleConfiguration.w4Maf10, partCoef: 0.25,
            },
            {
                ...WavesSingleConfiguration.w4Maf10, partCoef: 0.25,
            },
            {
                ...WavesSingleConfiguration.wConvexM, partCoef: 0.25,
            },
        ],
        createPaddings: false,
        styles: [ { fill: "red" }, { fill: 'crimson' }, { fill: "red" }, { fill: 'crimson' } ],

        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: [
            {
                ...WavesSingleConfiguration.wConcaveM, partCoef: 0.5,
            },
            {
                ...WavesSingleConfiguration.wConcaveM, partCoef: 0.5,
            },
        ],
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false,
        styles: [ { fill: "brown" }, { fill: 'yellow' } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}

const baseWavedFramesT2R6CircB2L4RedCrimson = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false,
        styles: [ { fill: 'crimson', strokeWidth: 3, stroke: "black" }, { fill: 'crimson', strokeWidth: 3 } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        // configPointPair: "startMaxEndMin",
        isMultiplePaths: true,
        arrCoefsPadding: [ -1, 1, -1, -1, 1, -1 ],
        // arrCoefsPadding: [ -1 / 3, 2 * 1 / 3, - 1, - 0.9, 2 / 3, -1 / 3 ],
        // arrCoefsPadding: [ -1, 2 * 1 / 3, - 1, - 0.9, 2 / 3, -1 ],
        waves: [
            ...WavesConfiguration(1 / 6).w3ConvexM,
            // ...WavesConfiguration(1 / 6).w3ConcaveH,
            ...WavesConfiguration(1 / 6).w3ConvexH,
        ],
        createPaddings: false,
        styles: [ { fill: "red", strokeWidth: 13, stroke: "black" }, { fill: 'crimson', strokeWidth: 13, stroke: "black" }, { fill: "red", strokeWidth: 13 }, { fill: 'crimson' }, { fill: "red" }, { fill: 'crimson' } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: [
            {
                ...WavesSingleConfiguration.wConcaveM, partCoef: 0.5,
            },
            {
                ...WavesSingleConfiguration.wConcaveM, partCoef: 0.5,
            },
        ],
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false,
        styles: [ { fill: "brown" }, { fill: 'yellow' } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        arrCoefsPadding: [ -1, -0.5, -0.5, -1 ],
        waves: [
            {
                ...WavesSingleConfiguration.wConvexM, partCoef: 0.25,
            },
            {
                ...WavesSingleConfiguration.w4Maf10, partCoef: 0.25,
            },
            {
                ...WavesSingleConfiguration.w4Maf10, partCoef: 0.25,
            },
            {
                ...WavesSingleConfiguration.wConvexM, partCoef: 0.25,
            },
        ],
        createPaddings: false,
        styles: [ { fill: "red" }, { fill: 'crimson' }, { fill: "red" }, { fill: 'crimson' } ],

        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },

}

const framePaddingsDefault = { paddingLeft: 25, paddingRight: 25, paddingTop: 25, paddingBottom: 25 }

const framePaddings = { paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }
// const getWavedFrame= (theme) => ({
//     optionsFrames: {
//         top: {
//             createPaddings: false,
//             styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main },{ fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ,{ fill: theme.palette.warning.main }, { fill: theme.palette.info.main }  ],
//         },
//         right: {
//             createPaddings: false,
//             styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
//         },
//         left: {
//             createPaddings: false,
//             styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
//         },
//         bottom: {
//             createPaddings: false,
//             styles: [ { fill: "green" }, { fill: 'lime' } ],
//         },
//     }
// })


// const getWavedFrame = (styles) => ({
//     optionsFrames: {
//         top: {
//             createPaddings: false,
//             styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main }, { fill: theme.palette.warning.main }, { fill: theme.palette.info.main }, { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
//         },
//         right: {
//             createPaddings: false,
//             styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
//         },
//         left: {
//             createPaddings: false,
//             styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
//         },
//         bottom: {
//             createPaddings: false,
//             styles: [ { fill: "green" }, { fill: 'lime' } ],
//         },
//     }
// })


// const setStyle=(wavedConfig, stylesTRBL=[])={
//     wavedConfig.top.style=stylesTRBL[0]
//     wavedConfig.right.style=stylesTRBL[0]
// }



// const myFramesNoFill = {
const baseT2R2B2L2_NoFill = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ -1, -1 ],
        createPaddings: false,
        styles: [],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: true,
        styles: [],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: false,
        styles: [ { fill: "orange" }, { fill: "orange" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: true,
        styles: [],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}

// const myFrames = {
const baseT2R2B2L2 = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ -1, -1 ],
        createPaddings: false,
        styles: [ { fill: "var(--color-secondary2-main)" }, { fill: "var(--color-secondary-main)" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: false,
        styles: [ { fill: "var(--color-info-main)" }, { fill: "var(--color-warning-main)" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}


const baseT2R2B2L2_circ = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConvexM,
        arrCoefsPadding: [ -1, -1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConvexM,
        arrCoefsPadding: [ -1, -1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConvexM,
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ -1, -1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConvexM,
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ -1, -1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}

const baseT2R2B2L2_vrana = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConvexM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConvexM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConvexM,
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConvexM,
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}

const baseT2R2B2L2_strecha = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ -1, -1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ -1, -1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ -1, -1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ -1, -1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}

const baseT2R2B2L2_cushion = {
    top: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false, // true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    right: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        arrCoefsPadding: [ 1, 1 ],
        createPaddings: false, //true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        waves: WavesConfiguration(0.5).w2ConcaveM,
        createPaddings: false, //true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
    left: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: true,
        isHorizontal: false,
        waves: WavesConfiguration().w2ConcaveM,
        createPaddings: false, //true,
        styles: [ { fill: "red" }, { fill: "green" } ],
        arrCoefsPadding: [ 1, 1 ],
        inAnim: animDefault.inAnim,
        outAnim: animDefault.outAnim,
    },
}

// const myFramesUnderlined = {
const baseB2 = {
    bottom: {
        configPointPair: "startMaxEndMax",
        isMultiplePaths: false,
        waves: [
            {
                ...WavesSingleConfiguration.wConcaveM, partCoef: 0.1,
            },
            {
                ...WavesSingleConfiguration.wConcaveM, partCoef: 0.9,
            },
        ],
        arrCoefsPadding: [ -0.1, -1 ],

        inAnim: {
            duration: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 10,
            },
            animation: {
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
            },
        },
        outAnim: animDefault.outAnim,
    },
}





const NAMED_WAVED_FRAMES_CONFIGS = {
    defaultBaseT6R2B4L2: baseWavedFramesT6R2B4L2RedCrimson,
    baseWavedFramesT2R6B2L4: baseWavedFramesT2R6CircB2L4RedCrimson,
    baseB2: baseB2,
    baseT2R2B2L2: baseT2R2B2L2,
    baseT2R2B2L2_NoFill: baseT2R2B2L2_NoFill,
    baseT2R2B2L2_circ: baseT2R2B2L2_circ,
    baseT2R2B2L2_vrana: baseT2R2B2L2_vrana,
    baseT2R2B2L2_cushion: baseT2R2B2L2_cushion,
    baseT2R2B2L2_strecha: baseT2R2B2L2_strecha,
    // T6R2B4L2RedCrimson:


}
// const namedStyles

// const getOptionsWavedFrameConfig = (theme) => ({
//     option1: (theme) => optionsWavedFrame1,
// })

export { NAMED_WAVED_FRAMES_CONFIGS, WavesConfiguration, WavesSingleConfiguration }