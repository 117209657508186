import { useStaticQuery, graphql } from "gatsby";

const useSImagesW200_4 = () => {
  const data = useStaticQuery(
    graphql`
      {
 
        sImgsFiles: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "galleries/s-200-4"}}) {
          totalCount
          nodes {
            id
            base
            name
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
      }
    `
  );
  return data.sImgsFiles;
};
export default useSImagesW200_4;
// gatsbyImageData(layout: FIXED)

// hotelImgs: allFile(
//   filter: {
//     extension: { regex: "/(jpg)|(jpeg)|(png)/" }
//     sourceInstanceName: { eq: "hotelGalleryImages" }
//   }
// )


// original {
//   src
// }
// imgW200: resize(width: 200) {
//   src
// }

/*
              full: fluid(
                maxWidth: 1200
                quality: 85
                srcSetBreakpoints: [100, 300, 500, 700, 900]
              ) {
                aspectRatio
                ...GatsbyImageSharpFluid
              }
              
            thumbW160: childImageSharp {
              ...fragmentFluidMaxW160
            }
            ...fragmentFixedImgW200H200

            
  const data = useStaticQuery(
    graphql`
      {
        excelSliderImgs: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            sourceInstanceName: { eq: "dirImages" }
            relativeDirectory: { eq: "galleries/excel-slider-apps" }
          }
        ) {
          nodes {
            id
            base
            relativePath

            full: childImageSharp {
              id
              original {
                src
              }
              fluid(
                maxWidth: 1024
                quality: 85
                srcSetBreakpoints: [576, 768, 992, 1200]
              ) {
                aspectRatio
                ...GatsbyImageSharpFluid
              }
            }

            relativeDirectory
          }
        }
      }
    `
  );
*/
/*
            childImageSharp {
              id
              fluid(maxWidth: 1763) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
              thumb100: resize(height: 100) {
                src
              }
              imgH180: resize(height: 180) {
                src
              }
              imgW200: resize(width: 200) {
                src
              }
              imgW640: resize(width: 640) {
                src
              }
              imgW768: resize(width: 768) {
                src
              }
            }
*/