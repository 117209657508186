import React, { useEffect, useRef } from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'

import BowListLazy from "../../_shared/shaping/BowListLazy"
import Typography from "@mui/material/Typography";

const varWrapTitle = {
    init: false,
    come: {
        opacity: 1,
        y: [ 0, 25, 12, 0 ],
        transition: {
            duration: 0.3,
        }
    },
    go: {
        opacity: 0, y: 0,
        transition: { duration: 0.1 }
    },
}

const bLOptionsTitle = {
    itemHeightPx: 100,
    listHeightPx: "auto",
    primarySecondarySeparator: "|",
}

const styleRightBorders50 = {
    borderTopRightRadius: "50%",
}

const styleLeftBorders50 = {
    borderTopLeftRadius: "50%",
}
const bowListOptionsAddedListBothBowsLUnderT = {
    bowUnder: true,
    bowUnderOptions: {
        underDeepness: 50,
        bowdirection: "top",
    },
    bothBows: true,
    bowDirection: "left",
    motionDirection: "left",
}

const sxTitle = {
    "&.title": {
        m: 0,
        p: 0,
        textShadow: (theme) => theme.distinctShadow3,
        fontSize: [ "medium", "large", "x-large", "xx-large" ],
    },
}

const HomeTitle = ({ breakpoint, title }) => {

    const refWrap = useRef(null)
    const isInView = useInView(refWrap)

    const ctrAnim = useAnimation()

    useEffect(() => {
        let isMounted = true

        const seq = async () => {
            isMounted && isInView && await ctrAnim.start("come")
            isMounted && !isInView && ctrAnim.set("go")
        }
        seq()
        return () => {
            isMounted = false
            ctrAnim.stop()
        }
    }
        , [ isInView, ctrAnim ]
    )

    const styleListFinalXs = { paddingTop: 0, paddingBottom: 0, marginBottom: 0 }
    if ([ "xs", "sm" ].includes(breakpoint)) {
        styleListFinalXs.paddingLeft = 0
        styleListFinalXs.paddingRight = 0
    }

    const styleList = { ...styleRightBorders50, ...styleLeftBorders50, }

    return (<div ref={refWrap}>
        <BowListLazy
            name="BL-mainUp-title-home"
            options={{
                ...bLOptionsTitle,
                ...bowListOptionsAddedListBothBowsLUnderT,
                nameStyleList: "primary2",
                nameStyleItem: "secondary",
                disableAlignTextToBow: true,
                styleList: styleList,
                styleListFinal: styleListFinalXs,
            }}
            key="BL-mainUp-title-home"
        >
            {[ <div key="BL-mainUp-title-home-varWrapTitle" >
                <motion.div
                    variants={varWrapTitle}
                    animate={ctrAnim}
                    initial="init"
                    viewport={{
                        once: false,
                        amount: "some",
                    }}
                    className="title-home"
                    key="title-home"
                    style={{ opacity: 1, y: 0 }}
                >
                    <Typography
                        variant="h1"
                        className="title"
                        sx={sxTitle}
                    >
                        {title}
                    </Typography>
                </motion.div>
            </div>,
            ]}
        </BowListLazy>
    </div>
    )
}

export default HomeTitle
