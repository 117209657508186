import React, { lazy, Suspense } from 'react';

const ReactVsWordpress = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'ReactVsWordpressLazy' */ './ReactVsWordpress'));

function ReactVsWordpressLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading NeededFromOwnerForWebPages ...</div>}>
                <ReactVsWordpress {...props} />
            </Suspense>
        </div>
    );
}

export default ReactVsWordpressLazy;