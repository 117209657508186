import React from 'react'; // , { lazy, Suspense }
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildren';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected';
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"


const IntroTextNacoWebStrankyCCh = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="Preco-web-stranky" className="toc"><span>Prečo webstránky</span></h3>
            {/* <Suspense fallback={<div>Loading ConnectChildrenWithWavesAnimation + WavedFrameAnimationForChildren ...</div>}> */}
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="Preco-web-stranky"
            >
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                    stylesTop={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}
                    stylesRight={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}
                    stylesBottom={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}
                    stylesLeft={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}

                >
                    <h4 id="online" className="waved toc toc0"><span>Vaše centrum na internete</span></h4>
                    <div className="connectedDescr" >Webstránky ako centrum vašich informácií na internete.</div>
                    <div className="connectedDescr" >Tu si zákazníci, klienti alebo užívatelia nájdu informácie o vašich produktoch, službách alebo obsahu.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="riadenie" className="waved toc toc0"><span>Kontrola nad obsahom</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div className="connectedDescr" >Na rozdiel od sociálnych médií, svoje webstránky máte pod úplnou kontrolou a môžete si ich vždy upravovať podľa svojej značky a potrieb.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="seo" className="waved toc toc0"><span>Optimalizácia pre vyhľadávanie</span></h4>
                    <div className="connectedDescr" >SEO je dôležité pre dodávanie vašej informácie cieleným návštevníkom.</div>
                    <div className="connectedDescr" >Ak webstránky vlastníte, tak vám nič nebráni ich prispôsobovaniu pre vyhľadávače a vaši potenciálni zákazníci alebo užívatelia vás ľahšie nájdu.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="zber" className="waved toc toc0"><span>Zber údajov</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div className="connectedDescr" >S vlastnými webstránkami máte k dispozícii aj nástroje pre zber dát o vašich návštevníkoch a ich analýzu.</div>
                    <div className="connectedDescr" >Analýza dát vám môže poskytnúť cenné informácie pre zlepšenie nielen samotných web stránok, ale aj vašich produktov alebo služieb.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="dovera" className="waved toc toc0"><span>Dôveryhodnosť</span></h4>
                    <div className="connectedDescr" >Dobre vyzerajúce, funkčné webstránky zvýšujú vašu dôveryhodnosť a návštevníci budú dôverovať aj vašej značke alebo obsahu.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
            {/* </Suspense> */}
        </Box>

    );
};
export default IntroTextNacoWebStrankyCCh;
