import React, { useEffect, useContext, useRef, useCallback, CSSProperties, memo } from 'react'
import useTheme from '@mui/system/useTheme';
import Box from '@mui/system/Box';
import BoxOwnProps from '@mui/system/Box';
import Theme from '@mui/material/styles/createTheme';
import { alpha, emphasize } from '@mui/material';
import { motion, useAnimation, useInView, Transition, TargetAndTransition } from 'framer-motion';
import { useBreakpoint } from '../contextBreakpointMediaQ';
import { sepAbsSvgData, optionsSeparatorAbs, BaseOptionsSeparatorAbs, NamedSeparator } from './separatorAbsSvg2Source';
// import { sepAbsSvgData } from './separatorAbsSvg2Source.tsx';
import { NotInViewContext } from '../AnimateScrollIntoViewport';


type StyleSxWrapProps = {
    id: string;
    gradientBgColor: string;
    gradientBgColorEmph10perc: string;
    isRelative: boolean;
    isVertical: boolean;
    isInEdges: boolean;
    overflow: string;
    leftShift: number;
    direction: string;
    styleSvg: CSSProperties;
    transformWrapSep: string;
    height: number;
    widthWanted: number;
    width: number;
    svgXPadding: number;
    svgYPadding: number;
    style1Position: CSSProperties;
    style2Position: CSSProperties;
    styleWrapDown: CSSProperties;
    styleWrapDbl: CSSProperties;
    transform2: string;
    transform1: string;
    transformBase: string;
    style3: CSSProperties;
    heightTotal: number;
    svgWidth: number;
    quaTransform: string;
    wrapFirstGradientLevelOpacity: number;
}
const styleSxWrap: (props: StyleSxWrapProps) => BoxOwnProps<Theme>["sx"] = ({ id, gradientBgColor, gradientBgColorEmph10perc, isRelative, isVertical, isInEdges, overflow, leftShift, direction, styleSvg, transformWrapSep, height, widthWanted, width, svgXPadding, svgYPadding, style1Position, style2Position, styleWrapDown, styleWrapDbl, transform2, transform1, transformBase, style3, heightTotal, svgWidth, quaTransform, wrapFirstGradientLevelOpacity }) => ({
    [`&.wrapSep.${id}`]: {
        top: 0,
        my: [1, 2, 3, 4],
        lineHeight: 0,
        perspective: "1000px",
        transformStyle: "preserve-3d",

        "& .divider-2": {
            perspective: "inherit",
            transformStyle: "inherit",
            //  ...style2Position,
            "& .qua": {
            },

            "& .wrap3": {
            },
            "& .wrap4": {
            },
        },
        "& .divider-1": {
            perspective: "inherit",
            transformStyle: "inherit",
            "& .base": {
            },
            "& .wrapDown": {
            },
            "& .wrapDbl": {
            },
        },
        "& .svgSep": {
            position: "relative",
            display: "block",
            perspective: "inherit",
            transformStyle: "inherit",
            maxHeight: "100%",
            zIndex: 1,
            "& .sepPath": {
                zIndex: 2,
            }
        },
    }
})


export interface IOptionsSeparator extends BaseOptionsSeparatorAbs {
    strokeWidth?: number;
    animateTo?: TargetAndTransition;
    transitionTo?: Transition;
    animateBack?: TargetAndTransition;
    transitionExit?: Transition;
}

type SvgCompProps = {
    wrapClassName?: string;
    wrapStyle?: React.CSSProperties;
    styleSvg?: React.CSSProperties;
    svgDataName?: string;
    notInView?: boolean;
};

export const defaultOptions: IOptionsSeparator = {
    isRelative: false,
    isVertical: false,
    nameSvg: "normal",
    styleWrapSep: { borderRadius: '100% 0% 100% 0%' },
    styleWrapSepLast: {},
    countUnits: 1,
    countSvg: 1,
    styleSvg: {},
    overflow: "hidden",
    leftsShift: 0,
    direction: "column",
    transformWrapSep: "none",
    isInEdges: true,
    isQuatro: true,
    isDouble: true,
    styleWrapDown: {},
    styleWrapDbl: {},
    wrapFirstGradientLevelOpacity: 0,
    mirrorX: true,
    mirrorY: true,
    dPathOrderNotIn: [],
    isRotateXReverse: false,
    isRotateYReverse: false,
    ySymetrical: "-",
    paddingYSep: 100,
    paddingXSep: 20,
    heightsWanted: 0,
    widthsWanted: 0,
    svgXPadding: 0,
    svgYPadding: 0,
    style1Position: {},

    animateTo: {
        fill: ["#Eaa321", "#AED321", "#E57321"],
        pathLength: 1,
        // pathOffset: 0,
    },
    // fillColors: [ "#Eaa321", "#AED321", "#E57321" ],
    // exitFillColors: [ "#E57321", "#AED321", "#Eaa321" ],
    // strokeColor: "#aaa321",
    strokeWidth: 2, // 15,
    transitionTo: {
        fill: {
            duration: 0.5,
        },
        pathLength: {
            duration: 0.5,
        },
    },

    animateBack: {
        pathLength: [1, 0],
        fill: "rgb(229, 45, 51,1)",
        // pathOffset: -1,
    },
    transitionExit: {
        duration: 0.3,
    },
}

interface SeparatorAbsSvg2Props {
    optionsOrName?: any; // replace with actual type
    id?: string;
    name?: NamedSeparator;
    optionsSep?: Partial<IOptionsSeparator>; // replace with actual type
    passedControllingInViewport?: boolean;
    children?: React.ReactNode;
    rest?: any; // replace with actual type
}

function SeparatorAbsSvg2({
    optionsOrName = {},
    id = "neviem",
    name = "sepNormalAbs2Up2Down",
    optionsSep = {},
    passedControllingInViewport = true,
    children,
    rest }: SeparatorAbsSvg2Props) {
    // console.log("SeparatorAbsSvg2 id: ", id)
    const renderCount = useRef(0)

    const { isCountedTwice } = useContext(NotInViewContext);
    const ref = useRef(null)
    const isInView = useInView(ref);
    // console.log("SeparatorAbsSvg2 notInView, isInView", id, NotInViewContext, notInView, isInView)
    const isChangedInitialInView = true // renderCount.current > 2
    const resultNotInView = passedControllingInViewport ? (!isInView && !isCountedTwice) : (!isInView && isChangedInitialInView)
    const theme = useTheme();
    const sepFillColorDefault = theme.palette.primary2.dark;
    const fillColorsDefault = [theme.palette.primary2.light, theme.palette.primary2.main, sepFillColorDefault]
    const strokeColorDefault = theme.palette.primary2.light // theme.palette.primary2.contrastText

    // const optionsNamed = { ...optionsSeparatorAbs[name], ...optionsOrName }
    // const usedOptions = { ...defaultOptions, ...optionsNamed, ...optionsSep }
    const usedOptions = { ...defaultOptions, ...optionsSeparatorAbs[name], ...optionsOrName, ...optionsSep }

    useEffect(() => {
        if (!passedControllingInViewport) {
            renderCount.current++;
            // console.log("SeparatorAbsSvg2 count", renderCount.current)
        }
    }, [isInView, passedControllingInViewport])



    const {
        gradientBgColor = theme.palette.primary2.main,
        isRelative = false,
        isVertical = false,
        overflow = "hidden",
        leftsShift = 0,
        nameSvg = "wave5",
        countSvg = 1,
        direction = "column",
        isInEdges = true,
        dPathOrderNotIn = [],
        isQuatro = true,
        isDouble = true,
        mirrorX = true,
        mirrorY = true,
        isRotateXReverse = false,
        isRotateYReverse = false,
        ySymetrical = "--",
        paddingYSep = 100,
        paddingXSep = 20,
        heightsWanted = 0,
        widthsWanted = 0,
        svgXPadding = 0,
        svgYPadding = 0,
        strokeWidth = 2,
        animateTo,
        fillColors = fillColorsDefault,
        exitFillColors = [...fillColorsDefault].reverse(),
        strokeColor = strokeColorDefault,
        transitionTo, animateBack,
        transitionExit, whileHover, wrapFirstGradientLevelOpacity, styleWrapSepLast, wrapClass = "" } = usedOptions

    let {
        countUnits = 1,
        transformWrapSep = "none",
        styleSvg, styleWrapDbl, styleWrapDown, styleWrapSep, style1Position } = usedOptions
    // let { styleWrapSep, style1Position } = usedOptions
    const gradientBgColorEmph10perc = emphasize(gradientBgColor, 0.05)
    if (isInEdges) countUnits = 1

    let heightWanted: number
    let widthWanted: number
    let leftShift: number
    const deviceWidth = useBreakpoint()
    if (Array.isArray(heightsWanted)) {
        switch (deviceWidth) {
            case "xs":
                heightWanted = heightsWanted[0]
                break;
            case "sm":
                heightWanted = heightsWanted[1]
                break;
            case "md":
                heightWanted = heightsWanted[2]
                break;
            case "lg":
                heightWanted = heightsWanted[3]
                break;
            case "xl":
                heightWanted = heightsWanted[4]
                break;
            default:
                heightWanted = heightsWanted[4]
                break;
        }
    } else heightWanted = heightsWanted

    if (Array.isArray(widthsWanted)) {
        switch (deviceWidth) {
            case "xs":
                widthWanted = widthsWanted[0]
                break;
            case "sm":
                widthWanted = widthsWanted[1]
                break;
            case "md":
                widthWanted = widthsWanted[2]
                break;
            case "lg":
                widthWanted = widthsWanted[3]
                break;
            case "xl":
                widthWanted = widthsWanted[4]
                break;
            default:
                widthWanted = widthsWanted[4]
                break;
        }
    } else widthWanted = widthsWanted

    if (Array.isArray(leftsShift)) {
        switch (deviceWidth) {
            case "xs":
                leftShift = leftsShift[0]
                break;
            case "sm":
                leftShift = leftsShift[1]
                break;
            case "md":
                leftShift = leftsShift[2]
                break;
            case "lg":
                leftShift = leftsShift[3]
                break;
            case "xl":
                leftShift = leftsShift[4]
                break;
            default:
                leftShift = leftsShift[4]
                break;
        }
    } else leftShift = leftsShift

    const svgComp = sepAbsSvgData[nameSvg]
    const { viewBoxWidthOrig, svgWidth, viewBoxHeightOrig, dPath, dPathStyle } = svgComp // svgChildren,
    const viewBoxWidthStartOrig = 0
    const viewBoxHeightStartOrig = 0
    const height = heightWanted === 0 ? viewBoxHeightOrig : svgYPadding > 0 ? heightWanted + 2 * svgYPadding : heightWanted - 2 * svgYPadding
    const width = widthWanted === 0 ? viewBoxWidthOrig : widthWanted + 2 * svgXPadding
    const viewBoxHeight = viewBoxHeightOrig + strokeWidth / 2
    const viewBoxWidth = viewBoxWidthOrig + strokeWidth / 2
    const heightDouble = 2 * height

    const styleDirectionBase: React.CSSProperties = direction === "row" ? {
        top: 0 + paddingYSep,
        position: "absolute",
    } : {
    }

    style1Position = { ...styleDirectionBase, ...style1Position }
    let style2Position = { ...styleDirectionBase }
    if (countSvg > 1) {
        style1Position.display = "flex"
        style2Position.display = "flex"
    }
    if (direction === "column") {
        if (isInEdges === true) {
            style2Position.top = `{0 + paddingYSep}px}`
            style2Position.left = 0
            style1Position.bottom = `0 + paddingYSep}px`
            styleWrapSep = { display: "flex", flexDirection: "column", justifyContent: (!isRelative) ? "space-between" : countSvg > 1 ? "center" : "space-around", ...styleWrapSep } //justify-content: center;
        } else {
            styleWrapSep = { display: "flex", flexDirection: "column", justifyContent: countSvg > 1 ? "center" : "space-around", ...styleWrapSep } //justify-content: center;
            style1Position.flexDirection = "column"
            style1Position.position = isRelative ? "inherit" : "static"
            style2Position = { ...style2Position, ...style1Position }
            const shiftY = paddingYSep !== 0 ? paddingYSep : heightDouble
            style2Position.bottom = 0 // `${shiftY}px` //style2Position.bottom + 
        }
    }

    if (direction === "row") {

        if (isInEdges === true) {
            style1Position.left = 0 + paddingXSep
            style2Position.right = 0 + paddingXSep + width
        } else {
            styleWrapSep = { display: "flex", justifyContent: countSvg > 1 ? "center" : "space-around", ...styleWrapSep }
            style1Position.position = isRelative ? "inherit" : (transformWrapSep !== "none") ? "absolute" : "static"
            style2Position = { ...style1Position }
        }
    }

    style2Position = {
        ...style2Position,
        position: isRelative ? "relative" : "absolute",
        width: direction === "column" ? "100%" : "auto",
        height: direction === "row" ? "100%" : "auto",
    }

    style1Position = {
        ...style1Position,
        position: isRelative ? "relative" : "absolute",
        width: direction === "column" ? "100%" : isVertical ? "100%" : "auto",
        height: direction === "row" ? "100%" : "auto",
    }

    const rotateXBase = "rotateX(180deg)"
    const rotateYBase = "rotateY(180deg)"
    const rotateY1Part = isRotateYReverse ? rotateYBase : ""
    const rotateY2Part = isRotateYReverse ? "" : rotateYBase

    let transformQua = ""
    let transformBasePartSym = ""
    switch (ySymetrical) {
        case "up":
            transformQua = rotateYBase
            transformBasePartSym = ""

            break;
        case "down":
            transformQua = "none"
            transformBasePartSym = rotateYBase
            break;
        default:
            break;
    }

    let transform2 = "";
    let transform3 = ""
    switch (true) {
        case (mirrorX && mirrorY):
            transform2 = isRotateXReverse ? `${rotateY2Part}` : `${rotateXBase} ${rotateY2Part}`;
            transform3 = isRotateXReverse ? `${rotateY2Part}` : `${rotateXBase} ${rotateY2Part}`;
            break;
        case (mirrorX):
            transform2 = isRotateXReverse ? `${rotateY2Part}` : `${rotateY2Part} ${rotateXBase}`;
            transform3 = isRotateXReverse ? `${rotateY2Part}` : `${rotateY2Part} ${rotateXBase}`;
            break;
        case (mirrorY):
            transform2 = isRotateXReverse ? `${rotateY2Part}` : `${rotateY2Part} ${rotateXBase}`;
            transform3 = isRotateXReverse ? `${rotateY2Part}` : `${rotateY2Part} ${rotateXBase}`;
            break;
        default:
            break;
    }

    let transformBase = ""
    let transform1 = ""
    let heightTotal = 0

    switch (true) {
        case isQuatro:
            heightTotal = heightDouble
            transformBase = (paddingYSep > 0) ? `${transformBasePartSym}` : `${transformBasePartSym}`
            transform1 = (isRotateXReverse) ? `${rotateXBase} ${rotateY1Part}` : `${rotateY1Part}`
            break;
        case isDouble:
            heightTotal = heightDouble
            transform1 = isRotateXReverse ? `${rotateXBase} ${rotateY1Part}` : `${rotateY1Part}`
            transformBase = `${transformBasePartSym}`
            break;
        default:
            heightTotal = height
            break;
    }

    transform3 = isDouble ? transform1 : mirrorX ? `${transform1} ${transform2}` : transform2

    if (transformWrapSep === "none" && !!(styleWrapSep?.transform)) {
        transformWrapSep = styleWrapSep?.transform;
    } else {
        transformWrapSep = `${transformWrapSep} ${styleWrapSep.transform}`
    }

    if (transform3?.trim() === "")
        transform3 = "none";
    if (transform2?.trim() === "")
        transform2 = "none";
    if (transform1?.trim() === "")
        transform1 = "none";
    if (transformBase?.trim() === "")
        transformBase = "none";
    if (transformQua?.trim() === "")
        transformQua = "none";

    const style3 = {
        ...styleDirectionBase,
        transform: transform3,
        height: direction === "row" ? "100%" : "auto",
    }
    styleSvg = {
        ...styleSvg, paddingX: `${svgXPadding}px`,
        paddingY: `${svgYPadding}px`,
        width: (direction === "column") ? svgWidth : `${width}px`,
        height: (direction === "row") ? "100%" : `${height}px`,
    }
    styleWrapDbl = { ...styleWrapDbl, transform: transform2, height: direction === "row" ? "100%" : "auto", }
    styleWrapDown = {
        ...styleWrapDown, transform: transform1, position: direction === "row" ? "absolute" : "unset",
        top: direction === "row" ? 0 : "unset",
        height: direction === "row" ? "100%" : "auto",
    }

    const styleBase = {
        transform: transformBase,
        height: direction === "row" ? "100%" : "auto",
        left: direction === "row" ? 0 : "unset",
        width: (isRelative && direction === "column") ? "100%" : "auto",
        display: (!isRelative && direction === "row" && isInEdges) ? "inline" : (!isRelative && direction === "column" && !isInEdges) ? "inline" : "block"
    }
    const styleWrap4 = {
        height: direction === "row" ? "100%" : "auto",
        transform: transform2,
    }

    const styleQua = {
        right: direction === "row" ? 0 : "unset",
        height: direction === "row" ? "100%" : "auto",
        transform: transformQua,
        width: (isRelative === true && direction === "column") ? "100%" : "auto",
        display: (!isRelative && direction === "row") ? "inline" : "block"
    }

    const widthWrapSep = (direction === "column" && isRelative && widthWanted !== 0) ? width : "100%" //`calc(100% - ${2 * leftShift + 1}px)`
    const heightWrapSep = (direction === "row" && isRelative) ? height : "100%"
    styleWrapSep = {
        ...styleWrapSep, position: isRelative ? "relative" : "absolute",
        width: widthWrapSep,
        height: isVertical ? "100%" : heightWrapSep,
        left: leftShift > 0 ? `${leftShift}px` : "unset",
        overflow: overflow,
        background: `linear-gradient(0deg, ${alpha(gradientBgColorEmph10perc, wrapFirstGradientLevelOpacity)}  0%, ${gradientBgColorEmph10perc} 40%,${gradientBgColor} 40%,${gradientBgColor} 60%, ${gradientBgColorEmph10perc} 60%, ${alpha(gradientBgColorEmph10perc, 0)} 100%)`,
        transform: transformWrapSep,
    }



    const SvgComp: React.FC<SvgCompProps> = ({
        wrapClassName,
        wrapStyle,
        styleSvg,
        svgDataName,
        notInView = false,
    }) => {
        const controlsSvgPath = useAnimation()
        const isSvgMounted = useRef<boolean>(false);

        useEffect(() => {
            if (!isSvgMounted.current) return
            if (notInView) {
                controlsSvgPath.stop()
                return
            }

            isSvgMounted.current && controlsSvgPath.start("animate1")

            return () => {
                isSvgMounted.current && controlsSvgPath.stop()
            }
        }, [controlsSvgPath, notInView])

        useEffect(() => {
            isSvgMounted.current = true;
            return () => {
                isSvgMounted.current = false;
            }
        })



        const seqForward = useCallback(() => {
            isSvgMounted.current && controlsSvgPath.start(({
                notInView, i, animateTo, transitionTo, animateBack,
                transitionExit, whileHover, fillColors, exitFillColors
            }) => {
                const skewX = i % 2 === 0 ? [-40, 20, -40] : [40, -20, 40]
                return {
                    ...animateTo,
                    skewX: skewX,
                    fill: fillColors,
                    transition: {
                        ...transitionTo,
                        duration: 1,
                        delay: i * 0.1,
                        // repeatType: "loop",
                        repeat: Infinity,
                    }
                }
            })
        }, [controlsSvgPath])

        const seqBack = useCallback(() => {
            isSvgMounted.current && controlsSvgPath.start(
                ({ notInView, i, animateTo, transitionTo, animateBack, transitionExit, whileHover, fillColors, exitFillColors }) => (
                    {
                        ...animateBack,
                        skewX: 0,
                        fill: exitFillColors,
                        transition: {
                            ...transitionExit,
                            repeatType: "reverse",
                            delay: i * 0.5
                        }
                    })
            )
        }, [controlsSvgPath])

        const handleHoverStart = async (e: React.MouseEvent<SVGElement>, info: { [key: string]: any }, prop: string) => {
            isSvgMounted.current && controlsSvgPath.stop()

            isSvgMounted.current && seqForward()
        }

        const handleHoverEnd = async (e: React.MouseEvent<SVGElement>, info: { [key: string]: any }, prop: string) => {
            isSvgMounted.current && controlsSvgPath.stop()
            isSvgMounted.current && seqBack()

        }

        const svgHeight = isVertical ? undefined : height
        return (
            <div
                className={wrapClassName}
                style={wrapStyle}
            >
                {(!isNaN(height)) &&
                    <motion.svg
                        layout
                        className="svgSep"
                        style={styleSvg}
                        data-name={svgDataName}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={`${viewBoxWidthStartOrig} ${viewBoxHeightStartOrig} ${viewBoxWidth} ${viewBoxHeight}`}
                        height={svgHeight}
                        width={width}
                        preserveAspectRatio="none"
                        // variants={varSvg}
                        // animate={controlsSvg}
                        stroke="currentColor"
                        //         onHoverStart={(e, info) => handleHoverStart(e: React.MouseEvent<SVGElement, MouseEvent>, info: any, svgDataName: stringe, info, svgDataName)}
                        // onHoverEnd={(e, info) => handleHoverEnd(e: React.MouseEvent<SVGElement, MouseEvent>, info: any, svgDataName: string)}
                        onHoverStart={(e: MouseEvent, info: any) => handleHoverStart(e, info, svgDataName)}
                        onHoverEnd={(e: MouseEvent, info) => handleHoverEnd(e, info, svgDataName)}
                    // // onHoverEnd={e => { console.log("hover end") }}      
                    >
                        {Array.isArray(dPath) ?
                            dPath.map((v, i) => {
                                const dPathStyle2 = Array.isArray(dPathStyle) ? dPathStyle[i] : !!dPathStyle ? dPathStyle : {}
                                return (
                                    <motion.path
                                        custom={{ notInView, i, animateTo, transitionTo, animateBack, transitionExit, whileHover, fillColors, exitFillColors }}
                                        animate={controlsSvgPath}
                                        // initial={false}
                                        initial={{
                                            stroke: i % 0 === 0 ? fillColors[2] : fillColors[2],
                                        }}
                                        d={v}
                                        className="sepPath"
                                        // stroke={strokeColor}
                                        strokeWidth={strokeWidth}
                                        // pathLength={notInView ? 1 : 0}

                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeDasharray="0 1"
                                        style={{
                                            pathLength: resultNotInView ? 1 : 0,
                                            fill: resultNotInView ? alpha(fillColors[2], 0.8) : alpha(fillColors[2], 1),
                                            pathOffset: 0,
                                            ...dPathStyle2,
                                            // ...dPathStyle2[i],

                                        }}
                                        key={`d-${i}`}
                                    />)
                            }
                            ).filter((v, i) => {
                                return !dPathOrderNotIn.includes(i)
                            }) : (
                                <motion.path
                                    animate={controlsSvgPath}
                                    //    initial={false}
                                    custom={{ notInView, i: 0, animateTo, transitionTo, animateBack, transitionExit, whileHover, fillColors, exitFillColors }}
                                    initial={{
                                        pathLength: 0,
                                        fill: alpha(fillColors[1], 0.5),
                                        // opacity: 0,
                                        stroke: fillColors[2],
                                    }}
                                    // custom={{ notInView, i: 0, animateTo, transitionTo, animateBack, transitionExit, whileHover, fillColors, exitFillColors }}
                                    d={dPath}
                                    className="sepPath"
                                    stroke={strokeColor}
                                    strokeWidth={strokeWidth}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    pathLength={0}
                                    // strokeDasharray="0 1"
                                    style={{
                                        pathOffset: 0,
                                        ...dPathStyle as React.CSSProperties,
                                    }}
                                />)
                        }
                    </motion.svg>}
            </div >
        )
    }



    return (
        <div ref={ref} >
            {
                Array(countUnits).fill(0).map((v, i) => (
                    <Box
                        className={`wrapSep ${id} ${wrapClass}`}
                        sx={styleSxWrap({ id, gradientBgColor, gradientBgColorEmph10perc, isRelative, isVertical, isInEdges, overflow, leftShift, direction, styleSvg, transformWrapSep, height, widthWanted, width, svgXPadding, svgYPadding, style1Position, style2Position, styleWrapDown, styleWrapDbl, transform2, transform1, transformBase, style3, heightTotal, svgWidth, quaTransform: transformQua, wrapFirstGradientLevelOpacity })}
                        style={{ ...styleWrapSep, ...styleWrapSepLast }}
                        {...rest}
                        key={`sep-${i}`}
                    >
                        {isQuatro && <Box
                            className="divider-2"
                            style={style2Position}
                        >
                            <div
                                className="qua"
                                style={styleQua}
                            >
                                {isDouble &&
                                    <SvgComp
                                        wrapClassName="wrap4"
                                        wrapStyle={styleWrap4}
                                        styleSvg={styleSvg}
                                        svgDataName="Layer4"
                                        notInView={resultNotInView}
                                    />
                                }
                                <SvgComp
                                    wrapClassName="wrap3"
                                    wrapStyle={style3}
                                    styleSvg={styleSvg}
                                    svgDataName="Layer3"
                                    notInView={resultNotInView}
                                />
                            </div>
                        </Box>
                        }

                        <Box
                            className="divider-1"
                            style={style1Position}
                        >
                            {Array(countSvg).fill(0).map((vv, ii) => (
                                <div
                                    className="base"
                                    style={styleBase}
                                    key={`base-${ii}`}
                                >
                                    {isDouble &&
                                        <SvgComp
                                            wrapClassName="wrapDbl"
                                            svgDataName="Layer2"
                                            wrapStyle={styleWrapDbl}
                                            styleSvg={styleSvg}
                                            notInView={resultNotInView}
                                            key={`svgDoubled-${ii}`} />
                                    }
                                    {children}
                                    <SvgComp
                                        wrapClassName="wrapDown"
                                        svgDataName="Layer1"
                                        wrapStyle={styleWrapDown}
                                        styleSvg={styleSvg}
                                        notInView={resultNotInView}
                                        key={`svg-${ii}`} />
                                </div>
                            ))}
                        </Box>
                    </Box>
                )
                )
            }
        </div>
    )
}


const MemoizedSeparatorAbsSvg2 = React.memo(
    SeparatorAbsSvg2,
    (prevProps, nextProps) => {
        let isIdEqual = false
        isIdEqual = prevProps.id === nextProps.id;
        return isIdEqual
    }
);

export default MemoizedSeparatorAbsSvg2

// export default memo(SeparatorAbsSvg2)
