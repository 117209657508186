import React, { lazy, Suspense } from 'react';

const MyReactMultiCarousel2 = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'MyReactMultiCarousel2Lazy' */ './myReactMultiCarousel2'));

function MyReactMultiCarousel2Lazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading MyReactMultiCarousel2 ...</div>}>
                <MyReactMultiCarousel2 {...props} />
            </Suspense>
        </div>
    );
}

export default MyReactMultiCarousel2Lazy;