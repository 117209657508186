import React from 'react';
import { Paper, Typography, Button, Dialog, DialogContent, DialogTitle, Grow, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TemperatureMeasurementClientServerApp from './TemperatureMeasurementClientServerApp';
import SlideUpTransitionHoldingRef from '../../_shared/SlideUpTransitionHoldingRef';
const ClientServerAppTitle = "Meranie teplôt, ich ukladanie v databáze a ich dostupnosť pre zobrazenie v grafoch, pre stiahnutie súborov s nameranými teplotami."

const ClientServerAppShowcase = () => {
    const theme = useTheme();
    const [ open, setOpen ] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grow in={true} timeout={1000}>
            <Paper
                // id="showcase-temp-web7"
                elevation={3}
                sx={{
                    my: 4, padding: [ 0.5, 1, 2 ],
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    textAlign: 'center',
                    marginBottom: theme.spacing(4)
                }}>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        fontSize: [ "small", "larger", "medium" ],
                    }}
                >
                    Pozrite si konkrétnu web aplikáciu v architektúre klient - api server
                </Typography>
                <Button
                    href="https://temp.web7.sk"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="primary"
                    sx={{
                        color: "primary2.contrastText",
                        bgcolor: "primary2.main",
                        boxShadow: 16,
                        padding: theme.spacing(2),
                        textTransform: 'none',
                        '&:hover': {
                            bgcolor: "primary2.dark",
                        },
                    }}
                >
                    <Typography
                        variant="h3"
                        component="h3"
                        gutterBottom
                        sx={{
                            fontSize: [ "medium", "large" ],
                        }}
                    >
                        {ClientServerAppTitle}
                    </Typography>
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ marginTop: theme.spacing(2), fontSize: [ "small", "medium" ], }}
                    onClick={handleClickOpen}
                >
                    Viac info
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="md"
                    fullWidth
                    TransitionComponent={SlideUpTransitionHoldingRef}
                >
                    <DialogTitle sx={{
                        my: [ 1, 2, 3 ], padding: [ 0.5, 1, 2 ],
                        bgcolor: "primary.main",
                        color: "primary.contrastText",
                        textAlign: 'center',
                        fontSize: [ "medium", "large" ],
                    }}>{ClientServerAppTitle}</DialogTitle>
                    <DialogContent sx={{
                        px: [ 0.5, 2, 3 ],
                    }}>
                        <TemperatureMeasurementClientServerApp />
                    </DialogContent>
                </Dialog>
            </Paper>
        </Grow>
    );
};

export default ClientServerAppShowcase;