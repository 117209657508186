import React, { useRef, useEffect, useMemo } from 'react'
import Box from '@mui/system/Box';
import { SxProps } from "@mui/system";
import { motion, useMotionValue, useInView } from "framer-motion";
import RotatingList from './RotatingList';
import AroundList from './AroundList';
import { useBreakpoint } from '../../contextBreakpointMediaQ'



/**
 * Styles for the wrapping Box component of each item.
 * Defines flexbox layout and positions the inner absolute positioned elements.
 * Sets base styles for the inner rotating and surrounding elements.  
 */
/**
 * Styles for the wrapping Box component of each item.
 * Defines flexbox layout and positions the inner absolute positioned elements.  
 * Sets base styles for the inner rotating and surrounding elements.
*/
const styleSxWrapItem: SxProps = {
  display: "flex",
  flexDirection: "row",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  mx: 0,
  px: 0,
  my: 0,
  py: [0.5, 1, 2, 3],
  "& .outWrapRotating": {
    position: "absolute",
    "& #ellipseRotating": {
      position: "relative",
      borderRadius: "50%",
      boxShadow: 12,
      "& .rotatingItem": {
        transition: "all .7s ease",
        cursor: "pointer",
        "&.selectedRotating.spec": {
          bgcolor: "#ffa500",
          borderStyle: "groove",
          zIndex: 3,
          fontWeight: "bolder",
        },
        position: "absolute",
        zIndex: 2,
        textAlign: "center",
        bgcolor: "secondary.dark",
        color: "secondary.contrastText",
        borderRadius: "50%",
        transformOrigin: "left",
        fontSize: "10px",
        width: "100%",
        "&:nth-of-type(1)": {
          bgcolor: "success.main",
          color: "success.contrastText",
        },
        "&:nth-of-type(3)": {
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
        },
        "&:nth-of-type(4)": {
          bgcolor: "secondary2.main",
          color: "secondary2.contrastText",
        },
        "&:nth-of-type(5)": {
          bgcolor: "info.main",
          color: "info.contrastText",
        },
        "&:nth-of-type(6)": {
          bgcolor: "warning.main",
          color: "warning.contrastText",
        },
        "&:nth-of-type(7)": {
          bgcolor: "error.main",
          color: "error.contrastText",
        },
      },
    },
  },
  "& .outWrapAround": {
    position: "absolute",
    "& #wrapDivsAround": {
      position: "relative",
      "& .aroundItem": {
        transition: "all 0.7s ease",
        "&.selectedAround.spec": {
          bgcolor: "#ffa500",
          borderStyle: "groove",
          zIndex: 1,
          "& .MuiCardContent-root": {
            bgcolor: "#ffa500",
          },
          "& img": {
            filter: "hue-rotate(300deg)",
            borderRadius: "50%",
            borderWidth: "thin",
            borderColor: "#ffa500",
            borderStyle: "ridge",
          },
        },
        position: "absolute",
        bgcolor: "secondary2.main",
        color: "secondary2.contrastText",
        borderStyle: "solid",
        borderTopLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        "& .MuiTypography-root": {
          fontSize: 16,
        },
        "&:nth-of-type(1)": {
          bgcolor: "success.main",
          color: "success.contrastText",
        },
        "&:nth-of-type(3)": {
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
        },
        "&:nth-of-type(4)": {
          bgcolor: "secondary2.main",
          color: "secondary2.contrastText",
        },
        "&:nth-of-type(5)": {
          bgcolor: "info.main",
          color: "info.contrastText",
        },
        "&:nth-of-type(6)": {
          bgcolor: "warning.main",
          color: "warning.contrastText",
        },
        "&:nth-of-type(7)": {
          bgcolor: "error.main",
          color: "error.contrastText",

        },
      }
    },
  },
}


// interface ItemData {
//   imgName: string;
//   imgSrc: string;
//   itemMaxWidthPx: number;
// }

interface DivsAroundEllipseMotionProps {
  arrItemData: { //ItemData[
    rotating: string;
    cardOptions: {
      imgName: string;
      imgSrc: string;
      itemMaxWidthPx: number;
    }
  }[];
  rx?: number;
  ry?: number;
  offset?: number;
  widthAround?: number;
  heightAround?: number;
  cw?: boolean;
  sizesIntoStyle?: boolean;
  styleEllipse?: React.CSSProperties;
  styleDiv?: React.CSSProperties;
}

const timeDelay = 500
const taktInterval = 5000

const angleShiftToStartOnTopWanted = 360 - 90

const DivsAroundEllipseMotion = React.memo<DivsAroundEllipseMotionProps>(({ arrItemData, rx = 100, ry = 50, offset = 0, widthAround = 100, heightAround = 60, cw = true, sizesIntoStyle = false, styleEllipse = {}, styleDiv = {} }: DivsAroundEllipseMotionProps) => {

  const deviceSize = useBreakpoint()
  const angleM = useMotionValue(0)
  const refSelectedIndex = useRef(-1)
  const divsNumber = arrItemData.length
  const angleIncrement = useMemo(() => {
    return 360 / divsNumber;
  }, [divsNumber]);


  // const renderCountRef = useRef(0);
  const refWrap = useRef(null)
  const isInView = useInView(refWrap, {
    // root: ref,
    // root?: RefObject < Element >;
    // once?: boolean;
    // margin?: string;
    // amount?: "some" | "all" | number;
    // fallback?: boolean;
    once: false,

    margin: "50px 0px -100px 0px", //  top/right/bottom/left // "0px 0px", //'100px 100px'
  })

  useEffect(() => {
    if (window) {
      if (!isInView) return
      let ticks = 0
      const rotatePointersWrap = () => {
        ticks = ticks + 1
        angleM.set(ticks * angleIncrement)
      }
      rotatePointersWrap()
      let idIntervalRotation: number | undefined
      const timer = window.setTimeout(() => {
        idIntervalRotation = window.setInterval(rotatePointersWrap, taktInterval);
      }, timeDelay)

      return () => {
        angleM.set(0)
        if (idIntervalRotation) {
          clearInterval(idIntervalRotation)
          idIntervalRotation = undefined
        }
        clearTimeout(timer)
      }
    }
  }, [angleIncrement, deviceSize, angleM, isInView]);

  // useEffect(() => {
  //   renderCountRef.current += 1;
  //   console.log("DivsAroundEllipseMotion rerendered: ", renderCountRef.current)
  // });
  // const handleOnClick = ({e, iItem}) => {
  // const handleOnClick = ({e, iItem}: {e: MouseEvent<Element, MouseEvent>; iItem: number }) => {

  //   refSelectedIndex.current = iItem === refSelectedIndex.current ? false : true // iItem
  // }
  // const handleOnClick = ({ e, iItem }: { e: MouseEvent<Element, MouseEvent>; iItem: number }) => {
  //   refSelectedIndex.current = iItem === refSelectedIndex.current ? -1 : iItem;
  // };

  const ellipseDimensions = useMemo(() => {
    return {
      width: rx * 2,
      height: ry * 2
    };
  }, [rx, ry]);

  const defStyleEllipse = useMemo(() => {
    return {
      width: `${ellipseDimensions.width}px`,
      height: `${ellipseDimensions.height}px`
    }
  }, [ellipseDimensions]);


  const ellipseStyle = {
    width: `${ellipseDimensions.width}px`,
    height: `${ellipseDimensions.height}px`,
    ...styleEllipse
  };

  const styleDivAround = sizesIntoStyle ? {
    width: `${widthAround}px`,
    height: `${heightAround}px`,
    lineHeight: `${heightAround}px`
  } : {};


  const styleWrapItem = {
    height: `${ellipseDimensions.height + 2 * widthAround}px`
  }

  // const styleWrapRotating = {
  //   ...styleDivAround,
  //   ...styleDiv,
  // }

  const styleRotating = {
    ...styleDivAround,
    ...styleDiv,
    top: `${ry}px`,
    left: `${rx}px`,
    width: `${rx}px`,
  }

  const rotatingItems = useMemo(() => {
    return arrItemData.map(({ rotating }, i) => ({
      nameItem: rotating,
      iItem: i,
    }));
  }, [arrItemData]);

  const aroundItems = useMemo(() => {
    return arrItemData.map(({ rotating, cardOptions }, i) => ({
      iItem: i,
      // ...cardOptions,
      cardOptions,
    }));
  }, [arrItemData]);

  // Calculate positions around ellipse 
  const positionsAround = useMemo(() => {
    // logic to calculate positions
    const positions = []
    for (var i = 0; i < divsNumber; i++) {
      const topAroundItem = ry + -ry * Math.cos((360 / divsNumber / 180) * (i + offset) * Math.PI)
      const leftAroundItem = rx + rx * (cw ? Math.sin((360 / divsNumber / 180) * (i + offset) * Math.PI) : -Math.sin((360 / divsNumber / 180) * (i + offset) * Math.PI))

      const left = leftAroundItem < rx ? leftAroundItem - widthAround : leftAroundItem
      const top = topAroundItem < ry ? topAroundItem - heightAround : topAroundItem
      const angleDiv = angleShiftToStartOnTopWanted + angleIncrement * (i + offset) //-90

      positions.push({
        angle: Math.round(angleDiv),
        top: Math.round(top),
        left: Math.round(left),
        width: widthAround,
        height: heightAround,
      });
    }
    return positions
  }, [rx, ry, angleIncrement, divsNumber, cw, heightAround, widthAround, offset]);



  return (
    <div>
      <Box
        component="div"
        ref={refWrap}
        sx={styleSxWrapItem}
        style={styleWrapItem}
      >
        <div
          className="outWrapRotating"
        >
          <motion.div
            id="ellipseRotating"
            initial="initial"
            animate="enter"
            style={ellipseStyle}
            key="divsAroundEllipseMotion"
          >
            <RotatingList
              deviceSize={deviceSize as string}
              rotatingItems={rotatingItems}
              aroundPositions={positionsAround}
              classWrapRotatingItem="rotatingItem"
              angleIncrement={angleIncrement}
              styleRotating={styleRotating}
              selectedIndex={refSelectedIndex.current}
              // handleOnClick={handleOnClick}
              angleM={angleM}
              isInView={isInView}
            />

          </motion.div>
        </div>
        <div
          className="outWrapAround"
        >
          <div
            id="wrapDivsAround"
            style={defStyleEllipse}
          >
            <AroundList
              deviceSize={deviceSize as string}
              aroundItems={aroundItems}
              aroundPositions={positionsAround}
              selectedIndex={refSelectedIndex.current}
              // handleOnClick={handleOnClick}
              angleM={angleM}
              classNameAround="aroundItem"
              isInView={isInView}
            />
          </div>
        </div>
      </Box >
    </div >
  )
})

export default DivsAroundEllipseMotion

