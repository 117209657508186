import React from "react";
import { useState, useRef, useLayoutEffect } from "react";
import { Box } from "@mui/system";
import Grid from '@mui/material/Grid';
import { motion, useTransform, useScroll, } from "framer-motion";
import SparkleSvg from "../../_shared/sparklesRandomly/sparkleSvg";
import AcUnitTwoToneIcon from '@mui/icons-material/AcUnitTwoTone';
import useFaceImages from "../../_shared/useFaceImages";
import { getArrFaceAvatarsSrcNames } from "../../_shared/avatarFaceByImgName";


const images = [
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517405768-H6LWVS2XAUPMQQ7XRE55/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3671.jpg?format=1500w",
        background: "#C24B47",
        left: 5,
        top: "1%",
        photo: '🥏', //🐶

    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517421363-VG59T210GQM2W7XN4BC5/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3678.jpg?format=1500w",
        background: "#A46F4C",
        left: "10px",
        top: "2%",
        // photo: '🛸', //🐰
        photo: <SparkleSvg size={200} />

    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517404209-F28KZOMYLY64VTD1MHPE/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3674.jpg?format=1500w",
        background: "#F2CFA6",
        left: "25px",
        top: "3%",
        // photo: '💸', //🐭
        photo: <div>💸<AcUnitTwoToneIcon color="secondary" fontSize="20" sx={{ borderStyle: "dotted", borderRadius: "50%", borderColor: "warning.main" }} /></div>
    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517422421-D62FIDV7H8B2O03CP7W8/ke17ZwdGBToddI8pDm48kKAwwdAfKsTlKsCcElEApLR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UegTYNQkRo-Jk4EWsyBNhwKrKLo5CceA1-Tdpfgyxoog5ck0MD3_q0rY3jFJjjoLbQ/DSCF3676.jpg?format=1500w",
        background: "#58742A",
        left: "-20px",
        top: "50%",
        photo: '🕊️', //🐹

    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517441059-6G4IJG7EAG2N0LHUCZNF/ke17ZwdGBToddI8pDm48kKAwwdAfKsTlKsCcElEApLR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UegTYNQkRo-Jk4EWsyBNhwKrKLo5CceA1-Tdpfgyxoog5ck0MD3_q0rY3jFJjjoLbQ/DSCF3689.jpg?format=1500w",
        background: "#765647",
        left: 30,
        top: "30%",
        photo: '🪁', //🐻
    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517441464-0UIL75S4LGXKELR6GQZT/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3690.jpg?format=1500w",
        background: "#F40046",
        left: "-1%",
        top: "70%",
        photo: '🦇', //🦊
    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517452033-A8IP0HFYHW3FCEDF89DN/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3694.jpg?format=1500w",
        background: "#14555C",
        left: "5%",
        top: 100,
        photo: '🦟', //🐼
    },
    {
        src:
            "https://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517453280-E1RZEJ0K8CEMYC8V1946/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3695.jpg?format=1500w",
        background: "#F0D2B7",
        left: -10,
        top: "90%",
        photo: '🐝', //🐨
    },
    {
        src:
            "dhttps://images.squarespace-cdn.com/content/v1/5b475b2c50a54f54f9b4e1dc/1564517453280-E1RZEJ0K8CEMYC8V1946/ke17ZwdGBToddI8pDm48kMW07nDPUi4QpkaiOZOMjjN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Nv1eI2eQHYQ5hCOGwfHNc2oGbzw7ZizcAH76AsE0075/DSCF3695.jpg?format=1500w",
        background: "#F0D2B7",
        left: -10,
        top: "90%",
        fontSize: "50px",
        width: "300px",
        borderStyle: "none",
        photo: '🖅🏰🏕️🏖️🗼🌃🌄🌉🌇', //🐨
    },
];


const styleSxWrap = {
    height: "500px",
    width: "100%",
    m: "0 auto",
    perspective: "1200px",
    py: "100px",
    position: "relative",
    "& h1": {
        display: "block",
        fontSize: "17vw",
        textTransform: "uppercase",
        fontWeight: "bolder",
        color: "primary.error",
        position: "fixed",
        top: "-50px",
        left: "50%",
        transform: "translateX(-50%)",
        lineHeight: "13vw",
        letterSpacing: "-0.5rem",
    },
    "& img.avatar-mask": {
        // Apply the mask
        WebkitMaskImage: "radial-gradient(circle, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 75%)",
        maskImage: "radial-gradient(circle, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 75%)",

        // Ensure the mask applies to the entire image
        WebkitMaskSize: "cover",
        maskSize: "cover",

        // Optional: Center the mask
        WebkitMaskPosition: "center",
        maskPosition: "center",
    },
    "& .grid": {
        "& .gridItem": {
            textAlign: 'center',
            position: "relative",
            "& .image-container": {
                mb: "50px",
                zIndex: 1,
                perspective: "400px",
                "& .overlay": {
                    "& .MuiAvatar-img": {
                        // borderRadius: "50%",
                        // borderStyle: "dashed",
                    },
                    top: 0,
                    left: 0,
                    maxWidth: "100%",
                    position: "absolute",
                    fontSize: "150px",
                    // "borderStyle": "groove",
                    "borderRadius": "50%",
                    "& img": {
                        maxWidth: "100%",
                        transform: "translateZ(0)",
                    }
                }
            },
        },
    }
}

const ParallaxMotion = () => {

    const faceImagesFiles = useFaceImages();
    const arrFaceAvatarsSrcData = getArrFaceAvatarsSrcNames(faceImagesFiles)
    const refCont = useRef()
    const { scrollYProgress } = useScroll({ container: refCont })

    const allAvatars = arrFaceAvatarsSrcData.map((image, j) => <img alt={`${image.imgName}`} src={image.imgSrc} className="MuiAvatar-img avatar-mask" key={`${image.imgName}-${j}`} />)

    return (
        <Box component="div"
            className="container"
            sx={styleSxWrap}
            ref={refCont}
        >
            <Grid container spacing={2} className="grid" style={{ scaleY: scrollYProgress }}>

                <Grid item xs={4} className="gridItem" key="gr1">
                    {images.slice(0, 3).map((image, i) => (
                        <ParallaxImage scrollYProgressCont={scrollYProgress} key={`image1${i}`}
                            index={i} {...image} >
                            {image.photo}
                        </ParallaxImage>
                    ))}
                </Grid>
                <Grid item xs={4} className="gridItem" key="gr2">
                    {images.slice(3, 6).map((image, i) => (
                        <ParallaxImage scrollYProgressCont={scrollYProgress} key={`image2${i + 4}`}
                            index={i + 3} {...image} >
                            {image.photo}
                        </ParallaxImage>
                    ))}
                </Grid>
                <Grid item xs={4} className="gridItem" key="gr3">
                    {images.slice(6, 9).map((image, i) => (
                        <ParallaxImage scrollYProgressCont={scrollYProgress} key={`image3${i + 8}`}
                            index={i + 6} {...image} >
                            {image.photo}
                        </ParallaxImage>
                    ))}
                    {arrFaceAvatarsSrcData.map((image, j) => {
                        const avatar = <img alt={`${image.imgName}`} src={image.imgSrc} className="MuiAvatar-img avatar-mask" />
                        return (
                            <ParallaxImage scrollYProgressCont={scrollYProgress} key={`img${j + 12}`}
                                index={j + 9} {...image} >
                                {avatar}
                            </ParallaxImage>
                        )
                    })}
                    {
                        <ParallaxImage scrollYProgressCont={scrollYProgress} key={`imag${25}`}
                            index={20} filter="sepia(1)" backgroundColor="orange" >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>

                                {allAvatars}
                            </div>
                        </ParallaxImage>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default React.memo(ParallaxMotion)




const ParallaxImage = ({ children, scrollYProgressCont, src,
    index, ...style }) => {
    const [ elementTop, setElementTop ] = useState(0);
    const ref = useRef(null);
    const { scrollY, scrollYProgress } = useScroll();
    const koefDirection = index % 2 === 1 ? 1 : -1
    const yMotion = useTransform(scrollY, [ elementTop, elementTop + 1 ], [ 0, -0.23 + koefDirection * index * 0.04 ], {
        clamp: false
    });

    const xMotion = useTransform(scrollYProgress, value => (Math.sin(value * (100 + 2 * index)) * (100 + index * 4)))
    const rotateYMotion = useTransform(scrollYProgress, value => 0.5 * (value * (100 + 2 * index)) * (100 + index * 4))

    useLayoutEffect(() => {
        const element = ref.current;
        setElementTop(element.offsetTop + element.getBoundingClientRect().top);
    }, [ ref ]);

    return (
        <div ref={ref} className="image-container" key={`div-${index}`}>
            <motion.div
                className="overlay clay"
                style={{
                    y: yMotion,
                    x: xMotion,
                    // rotateY: rotateYMotion, 
                    backgroundColor: "transparent",
                    ...style
                }}
                key={`m-${index}`}
            >
                {children}
            </motion.div>
        </div>
    );
};