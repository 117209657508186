import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
// import withStyles from '@mui/styles/withStyles';
import styled from '@mui/material/styles/styled';
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector from '@mui/material/StepConnector';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { ContextTripStepper } from "../../_shared/contextTripStepper"


const StyledTripStepConnector = styled(StepConnector)(({ theme }) => ({
  /* styles using theme if needed */
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));



function QontoStepIcon(props) {
  const { active, completed } = props;

  return (
    <div
      className={clsx("qontoroot", {
        "active": active,
      })}
    >
      {completed ? <Check className="completed" /> : <div className="circle" />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  /* styles using theme if needed */
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));



function StepIcon(props) {
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx("stepiconroot", {
        "active": active,
        "completed": completed,
      })}
    >
      {icons[ String(props.icon) ]}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};


const styleSxWrap = {
  width: '100%',
  "& .fl": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  "& .instructions": {
    mt: 2,
    mb: 1,
    width: "100%",
    textAlign: "center",
    "&.MuiTypography-root.MuiTypography-body1": {
      color: "secondary.contrastText",
    }
  },
  "& .button": {
    m: 1,
  },
  "& .qontoroot": {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    "&.active": {
      color: '#784af4',
    },
    "& .circle": {
      width: 8,
      height: 8,
      borderRadius: '50%',
      bgcolor: 'currentColor',
    },
    "& .completed": {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  },
  "& .stepiconroot": {
    bgcolor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    "&.active": {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    "&.completed": {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  },

}



function getStepsAlaska() {
  return [ 'Check all info on "www.alaska-best-cruise.com"', 'Book your cruise', 'Pay with a credit card' ];
}

function getStepsBike() {
  return [ 'Everything on "www.best-trip-bicycle.com"', 'Select your options', 'It is free - just get your bicycle in the morning' ];
}

function getStepsZoo() {
  return [ 'Everything on "www.best-trip-zoo-birds.com"', 'Select your options. Have some hat to take with', 'Ask the reception to wake you up, the day starts very early' ];
}


function getStepContent(step) {
  switch (step) {
    case 0:
      return 'All trips are awesome!!! ';
    case 1:
      return 'Everybody wants it, so be quick!';
    case 2:
      return 'All main credit cards supported.';
    default:
      return 'Unknown step';
  }
}

export default function SteppersTrip() {

  const [ activeStep, setActiveStep ] = React.useContext(ContextTripStepper);

  const stepsAlaska = getStepsAlaska();
  const stepsBicycle = getStepsBike()
  const stepsZoo = getStepsZoo()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box component="div"
      sx={styleSxWrap}
    >
      <div className="fl">
        <DirectionsBoatIcon />
        { }
        <Typography
          className="instructions"
        >
          Alaska Cruise
        </Typography>
      </div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {stepsAlaska.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="fl">
        <DirectionsBikeIcon />
        <Typography className="instructions ">
          Bicycle Trip
        </Typography>
      </div>
      <Stepper alternativeLabel activeStep={activeStep}
        connector={<StyledTripStepConnector />}
      >
        {stepsBicycle.map((label) => (

          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="fl">
        <AccessAlarmIcon />
        <Typography className="instructions">
          Birds World
        </Typography>
      </div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={
          <ColorlibConnector />
        }
      >
        {stepsZoo.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === stepsAlaska.length ? (
          <div>
            <Typography
              className="instructions"
            >
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}
              className="button"
            >
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className="instructions" >{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}
                className="button"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className="button"
              >
                {activeStep === stepsAlaska.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Box>
  );
}

