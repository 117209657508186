import React from 'react';
import clsx from 'clsx';
import useTheme from '@mui/material/styles/useTheme';
import styled from '@mui/material/styles/styled';
import useResizeObserver from "use-resize-observer";
import { keyframes } from "@emotion/react";
import CarouselCard from "./carouselCard"
import { useBreakpoint } from './contextBreakpointMediaQ';

const spinnerFramesX1 = keyframes({
  "0%": {
    transform: "rotateX(0deg) rotateY(0deg) rotateZ(0deg)",
  },
  "100%": {
    transform: "rotateX(360deg) rotateY(360deg) rotateZ(360deg)",
  }
})


const spinnerFramesX2 = keyframes({
  "0%": {
    transform: "rotateX(360deg) rotateY(360deg) rotateZ(360deg)"
  },
  "100%": {
    transform: "rotateX(0deg) rotateY(0deg) rotateZ(0deg)"
  },
})

const spinnerFramesCW = keyframes({
  "0%": {
    transform: "rotateY(0deg)",
  },
  "100%": {
    transform: "rotateY(360deg)",
  },
})

const spinnerFramesCCW = keyframes({
  "0%": {
    transform: "rotateY(360deg)"
  },
  "100%": {
    transform: "rotateY(0deg)"
  }
})


const StyledDivSpinnerWrapper = styled("div")({
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  perspective: "1000px",
  ".spinnerBase": {
    animationTimingFunction: "linear",
    // animationIterationCount: "infinite",
    animationIterationCount: 2,
    transformStyle: "preserve-3d",
  },
  ".spinnerCW": {
    animationName: `${spinnerFramesCW}`,
  },
  ".spinnerCCW": {
    animationName: `${spinnerFramesCCW}`,
  },

  ".spinnerX1": {
    animationName: `${spinnerFramesX1}`,
  },
  ".spinnerX2": {
    animationName: `${spinnerFramesX2}`,
  }
})


const SpinningCardsCarousel = ({ options, size } = {}) => {
  const { ref, width, } = useResizeObserver();
  const currentWidth = Math.round(width)
  const theme = useTheme()
  const deviceSize = useBreakpoint()

  let translateXPxDistance
  switch (deviceSize) {
    case 'xl':
      translateXPxDistance = theme.spacing(4)
      break;
    case 'lg':
      translateXPxDistance = theme.spacing(3)
      break;
    case 'md':
      translateXPxDistance = theme.spacing(2)
      break;
    case 'sm':
      translateXPxDistance = theme.spacing(1)
      break;
    case 'xs':
      translateXPxDistance = theme.spacing(0)
      break;
    default:
      break;
  }

  const { imagesNodes, imagesNumber, makePolygonCarousel = false, switchAxes = false, spinningTimeInSeconds = 16, spinningClockwise = true, imgWidth, verticalOpen = true, styleVertical = {} } = options
  let spinnerStyle = {
    animationDuration: `${spinningTimeInSeconds}s`,
    display: "flex",
  }

  if (switchAxes) {
    spinnerStyle.width = `${currentWidth * 0.5}px`
    spinnerStyle.flexDirection = "row"
    spinnerStyle.position = "absolute"
    spinnerStyle.top = 0
    spinnerStyle.height = `${currentWidth * 0.3}px`
  } else {
    if (verticalOpen) {
      spinnerStyle.top = 0
      spinnerStyle.height = `${imagesNumber * 50}px`
    }
    spinnerStyle.position = "absolute"
    spinnerStyle.flexDirection = "column"
  }
  spinnerStyle = {
    ...spinnerStyle,
    ...styleVertical,
  }

  let spinnerClass

  if (switchAxes) {
    spinnerClass = spinningClockwise ? clsx("spinnerBase", "spinnerX1") : clsx("spinnerBase", "spinnerX2")
  } else {
    spinnerClass = spinningClockwise ? clsx("spinnerBase", "spinnerCW") : clsx("spinnerBase", "spinnerCCW")
  }

  const angleStep = 360 / imagesNumber;

  let itemMaxWidthPxResp
  const styleCard = {}
  const styleCardWrapper = {}
  if (currentWidth === 0) {
    itemMaxWidthPxResp = imgWidth
  } else {
    if (makePolygonCarousel === true) {
      itemMaxWidthPxResp = currentWidth / (imagesNumber / 2)
      if (itemMaxWidthPxResp < imgWidth) {
        styleCard.width = `${itemMaxWidthPxResp}px`
      }
    } else {
      itemMaxWidthPxResp = currentWidth / (imagesNumber / 2)
    }
  }
  const translateZPxDistance = makePolygonCarousel ? Math.floor(Math.min(itemMaxWidthPxResp, imgWidth) / (2 * Math.sin(Math.PI / (imagesNumber)))) : 0;
  const arrSpinningItems = imagesNodes.map((imgNode, i) => {
    const image = imgNode.childImageSharp.gatsbyImageData
    const { name } = imgNode
    const carouselItemOptions = { imgName: name, imgSrc: image.images.fallback.src, itemMaxWidthPx: itemMaxWidthPxResp, rotateYAngle: (i + 1) * angleStep, translateXPxDistance, translateZPxDistance, styleCardWrapper, styleCard, switchAxes }

    return (
      <CarouselCard
        carouselItemOptions={carouselItemOptions}
        key={`${imgNode.base}--${i}`}
      />
    )
  });


  return (
    <StyledDivSpinnerWrapper
      ref={ref}
      className="MuiBox-root"
    >
      <div
        className={spinnerClass}
        style={spinnerStyle} >
        {arrSpinningItems}
      </div>
    </StyledDivSpinnerWrapper>
  );
};

export default SpinningCardsCarousel

