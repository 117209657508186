import React, { useCallback } from "react";
import { motion, } from "framer-motion";
// https://github.com/everweij/react-laag
import { useLayer } from "react-laag";

import DescriptionIcon from '@mui/icons-material/Description';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from '@mui/icons-material/Add';
import useTheme from '@mui/material/styles/useTheme';
import ButtonCM from "./buttonCM";
import MenuItemCM from "./MenuItemCM";
import { CONTAINER_SIZE, ITEM_SIZE, MARGIN_RIGHT, BUTTON_SIZE, RADIUS } from "./constantsCM"


const defaultIitems2 = [
    { comp: (<div><CodeIcon /><DescriptionIcon /></div>), value: "1", label: "Image 11" },
    { comp: (<div>2</div>), value: "image 2", label: "Image 22" },
    { comp: (<div><MusicNoteIcon /></div>), value: "image 3", label: "Image 33" },
    { comp: (<div>4</div>), value: "image 4", label: "Image 44" },

]

const onClickDefault = value => console.log(`You clicked on item '${value}'!`)

// const halfCircle = CONTAINER_SIZE / 2 //-BUTTON_SIZE/4 +ITEM_SIZE/4

// get the width and height of the layer given the current side the layers
// is on with respect to the trigger
const varMenu = {
    initial: false,
    animate: (isInView) => (
        isInView ? {
            scale: 1,
            opacity: 1
        } : {
            scale: 0,
            opacity: 0
        }
    ),
    transition: {
        staggerChildren: 0.2,
        duration: 0.1,
    }
}


const initialState = { isOpen: true, isClosing: false, indexItemSelected: -1 }

function reducerMenu(state, action) {

    switch (action.type) {
        case 'isOpen':
            return { ...state, isOpen: action.payload };
        case 'isClosing':
            return { ...state, isClosing: action.payload }
        case 'indexItemSelected':
            return { ...state, indexItemSelected: action.payload }
        case 'close':
            return { isOpen: false, isClosing: true, indexItemSelected: -1 }
        case 'closing':
            return { isOpen: true, isClosing: true, indexItemSelected: -1 }
        case 'initial':
            return initialState

        default:
            throw new Error();
    }
}


const indexItemSelectedNotSelected = -1

const CircularMenu = ({
    items = defaultIitems2, isInView, onClick = onClickDefault, ...rest }) => {

    const theme = useTheme()
    const [ state, dispatch ] = React.useReducer(reducerMenu, initialState)

    function getMenuDimensions(layerSide) {
        const centerSize = CONTAINER_SIZE + ITEM_SIZE + MARGIN_RIGHT;
        const totalWidth =
            ITEM_SIZE * items.length + (MARGIN_RIGHT * items.length - 1);
        const mnuDim = {
            width: layerSide === "center" ? centerSize : totalWidth,
            height: layerSide === "center" ? centerSize : ITEM_SIZE
        }
        return mnuDim;
    }

    const handleClickBtn = useCallback(() => {

        state.isOpen ? (state.indexItemSelected === indexItemSelectedNotSelected ? dispatch({ type: "close" }) : dispatch({ type: "isClosing", payload: true })) : dispatch({ type: "initial" })
    }, [ state.indexItemSelected, state.isOpen ])
    // small helper function to close the menu
    function close() {
        dispatch({ type: "close" })
    }
    // when the user clicks on a menu-item, the entire menu
    // should close before handing-off the clicked item to the
    // outside world

    const handleClickMenuItem = useCallback(({ value, label, index }) => {
        dispatch({ type: "indexItemSelected", payload: state.indexItemSelected === index ? -1 : index })
    }, [ state.indexItemSelected, ])

    const confirmItemEndToMenu = useCallback((isSelectedItemFinished) => {
        dispatch({ type: "isOpen", payload: !isSelectedItemFinished })
    }, [])

    const styleBtnSize = {
        position: "absolute",
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
        zIndex: 2,
    }


    const { renderLayer, triggerProps, layerProps, layerSide } = useLayer({
        // container: refContainerCurrent, // refContainer.current,
        isOpen: state.isOpen, // only position when the menu is open
        onOutsideClick: close, // close when clicked outside the menu
        overflowContainer: false, // keep the menu within it's container
        // overflowContainer: true, // keep the menu within it's container
        // auto: true, // automatically find the best placement possible
        auto: false, // automatically find the best placement possible
        snap: false, // stick to the possible-placements (don't position in between)
        placement: "center", // Yes, it's also possible to place the layer horizontally / vertically centered
        // possiblePlacements: [
        //     "top-center",
        //     "bottom-center",
        //     "left-center",
        //     "right-center"
        // ],
        possiblePlacements: "all",
        // possiblePlacements: [
        //     "top-center",
        //     "bottom-center",
        //     "left-center",
        //     "right-center"
        // ],
        triggerOffset: ITEM_SIZE / 2, // we want a gap between the menu-items and the button
        // container:"circ",
        containerOffset: 0, // keep some distance to the containers edges
        // arrowOffset:20,
        // So, what happens here?
        // In cases where the dimensions of the layer depend on the layer-side
        // we need to help a little so react-laag can anticipate the layers dimensions when calculating
        // the positions, without having to do a render first.
        // In other situations this may also prevent an infinite loop of position updates
        layerDimensions: getMenuDimensions,
        // onParentClose:()=>{console.log("parentClosing")}
        // this is an important one: when the root-menu closes, we want all nested
        // menu's to close as well. Therefore, we can utilize this `onParentClose` props
        // to instruct `useHover` in this case to force-close possible nested menu's
        // onParentClose: close
        onParentClose: (props) => {
            console.log("onParentClosing", props)
        }
    });

    const menuDimensions = getMenuDimensions(layerSide)

    // this width, height is not used  == let width, height = 0 to see and have access to click in layer under with btn
    const styleMenuSize = {
        width: `${menuDimensions.width}px`,
        height: `${menuDimensions.height}px`,
    }

    const styleShift = layerSide === "center" ? { position: "relative", top: RADIUS, left: RADIUS } :
        { position: "relative" }

    return (
        <div
            key="circMenu"
            style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...styleMenuSize
            }}
        >
            {renderLayer(
                state.isOpen && (
                    <motion.div
                        variants={varMenu}
                        initial="initial"
                        animate="animate"
                        custom={isInView}
                        transition="transition"
                        ref={layerProps.ref}
                        className="menu"
                        style={{
                            backgroundColor: theme.palette.primary2.main,
                            ...layerProps.style,
                            // ...styleMenuSize,
                            zIndex: '1',
                            scale: 0,
                            opacity: 0
                        }}
                        key="varMenu-bla"
                    >
                        <div
                            style={styleShift}

                        >
                            {items.map(({ comp, label, value }, index) => {
                                return (
                                    <MenuItemCM
                                        key={`${value}-${index}`}
                                        comp={comp}
                                        label={label}
                                        index={index}
                                        nrOfItems={items.length}
                                        layerSide={layerSide}
                                        itemSelected={index === state.indexItemSelected ? true : false}
                                        closing={index === state.indexItemSelected ? state.isClosing : false}
                                        handleClickItemInParent={handleClickMenuItem}
                                        confirmItemEndToMenu={confirmItemEndToMenu}
                                    />
                                )
                            })}
                        </div>
                    </motion.div>
                )
            )}
            <ButtonCM
                {...rest}
                {...triggerProps}
                handlClickButtonInParent={handleClickBtn}
                style={styleBtnSize}
                isOpen={state.isOpen}
            >
                <AddIcon />
            </ButtonCM>
        </div>
    );
}

export default CircularMenu
