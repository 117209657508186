import React from 'react'
import ChildWrap from './ChildWrap'


const ChildrenIterationToWrapEachChildsWithDivAssignRef = React.memo(
    ({
        children,
        columns,
        numberOfChilds,
        ctrlAnimItem,
        makeRef,
        ctrlAnimItemText,
        styleItem,
        styleItemText,
    }) => {
        const getFlexDirection = (colOrder) => {
            switch (columns) {
                case 1:
                    return "row";
                case 2:
                    return colOrder === 1 ? "row" : "row-reverse";
                default:
                    return "row";
            }
        };

        const getJustifyContent = (colOrder) => {
            switch (columns) {
                case 1:
                    return "left";
                case 2:
                    return colOrder === 1 ? "left" : "right";
                default:
                    return "left";
            }
        };

        return children.map((child, i) => {
            const colOrder = (i + 1) % columns;
            const flexDirection = getFlexDirection(colOrder);
            const justifyContent = getJustifyContent(colOrder);

            const childWrapProps = {
                i,
                ctrlAnimItem,
                makeRef,
                ctrlAnimItemText,
                styleItem: { ...styleItem, flexDirection, justifyContent },
                styleItemText,
                key: `childWrap-${i}`,
            };

            return <ChildWrap {...childWrapProps}>{child}</ChildWrap>;
        });
    },
    (prevProps, nextProps) => {
        return (
            prevProps.columns === nextProps.columns &&
            prevProps.numberOfChilds === nextProps.numberOfChilds
        );
    }
);

export default ChildrenIterationToWrapEachChildsWithDivAssignRef