import React, { memo } from 'react'
import Box from '@mui/material/Box'
import { motion } from 'framer-motion'
import * as stylesClay from "../../styles/clay.module.css"

const ChildWrap = ({
    i,
    makeRef,
    ctrlAnimItem,
    ctrlAnimItemText,
    children,
    styleItem,
    styleItemText,
}) => {
    // const prefersReducedMotion = useReducedMotion();

    return <Box component={motion.div}
        className={`itemWrap`}
        initial={false}
        animate={ctrlAnimItem}
        custom={i}
        viewport={{ amount: 0.8 }}
        sx={{
            ...styleItem,
            display: "flex",
            alignItems: "center",
            opacity: 0.5,
            justifyContent: [ 'center', 'flex-start' ],
        }}
        key={`varItemWrap-${i}`}
    >
        <motion.div
            className={`itemText ${stylesClay.clay}`}
            initial={false}
            animate={ctrlAnimItemText}
            custom={i}
            whileFocus="whileFocus"
            viewport={{ amount: 0.8 }}

            style={{
                ...styleItemText,
                scale: 1,
            }}
            key={`varItemText-${i}`}
        >
            {children}
        </motion.div>
        <motion.div
            className="itemMark"
            ref={(el) => makeRef(el, i)}
            animate={{
                x: [ 0, i % 2 === 0 ? -200 : 200, 0 ],
            }}
            transition={{
                delay: 10,
            }}
            style={{
                margin: '10px',
                width: "10px",
                height: "10px",
                x: 0,
            }} />

    </Box>;
}

export default ChildWrap