import React from "react"


const styleSvgWrapper = {
    "keyframes comeInOut": {
        "0%": {
            transform: "scale(0)"
        },
        "50%": {
            transform: "scale(1)"
        },
        "100%": {
            transform: "scale(0)",
        }
    },
    position: "absolute",
    display: "block",
    "@media (prefersReducedMotion: noPreference)": {
        // animation: `${comeInOut} 700ms forwards`,
        animation: `$comeInOut 700ms forwards`,
    },
}


const styleSvg = {
    "keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(180deg)",
        }
    },
    display: "block",
    "@media (prefersReducedMotion: noPreference)": {
        // animation: `${spin} 1000ms linear`
        animation: `$spin 1000ms linear`
    }
}

const defaultSvgPath = 'M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 17.9837 26.5 25.5Z'
const defaultSvgViewBox = "0 0 68 68"
const DEFAULT_COLOR = '#FFC700';


const SparkleSvg = ({ svgPath = defaultSvgPath, svgViewBox = defaultSvgViewBox, size = 20, color = DEFAULT_COLOR, style }) => {
    //    // const path =
    //     'M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 17.9837 26.5 25.5Z';
    return (
        <span style={{ ...styleSvgWrapper, ...style }}>
            <svg style={styleSvg} width={size} height={size} viewBox={svgViewBox} fill="none">
                <path d={svgPath} fill={color} />
            </svg>
        </span>
    );
};


export default SparkleSvg