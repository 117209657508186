import React from 'react';
import CardsAroundEllipseMotion from "../../_shared/CardsAroundEllipseMotion"
import useNatureImages from "../../_shared/useNatureImages"

const NatureCardsAroundEllipseMotion = () => {

    const imagesFiles = useNatureImages();
    const imagesNodes = imagesFiles.nodes

    return (
        <CardsAroundEllipseMotion imagesNodes={imagesNodes} rx={200} ry={400} offset={0} widthAround={250} heightAround={50} cw={true} sizesIntoStyle={true} />
    )
}
export default React.memo(NatureCardsAroundEllipseMotion)
