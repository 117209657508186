import React from 'react';
import { Container, Typography, Box, Button, List, ListItem, ListItemText, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ApiRoutesListForTemperatureMeasurementApp from './ApiRoutesListForTemperatureMeasurementApp';

const TemperatureMeasurementClientServerApp = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="md"
            sx={{
                p: [ 0.5, 2, 3 ],
                borderRadius: "20px", // theme.shape.borderRadius,
                boxShadow: theme.shadows[ 2 ],
                bgcolor: "primary2.main",
                color: "primary2.contrastText",
            }}>
            <Box id="showcase-temp-web7"
                sx={{
                    textAlign: 'center',
                    marginBottom: theme.spacing(4)
                }}>

                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        fontSize: [ "smaller", "small", "medium" ],
                        padding: [ 0.5, 1, 2 ],
                    }}
                >
                    Aplikácia je rozdelená na dve samostatné časti: klient a API server. Klient je vyvinutý v React.js a API server v Node.js.
                </Typography>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        fontSize: [ "smaller", "small", "medium" ],
                    }}
                >
                    Klient zobrazuje namerané dáta, graf, súbory s dátami, nastavenia pre cron joby bežiace na servere, nastavenia pre extra merania, používateľovi zalogovanému na klientovi.
                </Typography>
            </Box>
            <Paper elevation={3} sx={{ my: [ 1, 2, 3 ], padding: [ 0.5, 1, 2 ], bgcolor: "primary.main", color: "primary.contrastText" }}>
                <Typography variant="h3" component="h3" gutterBottom
                    sx={{
                        fontSize: [ "small", "medium", "large" ],
                        textAlign: "center",
                    }}
                >
                    Klientská aplikácia
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "smaller", "small", "medium" ],
                    }}
                >
                    Klientská aplikácia posiela HTTP požiadavky na endpointy definované na API servere a spracováva dáta vrátené odtiaľ a prezentuje ich používateľovi. Vytvára grafy.
                </Typography>
                <List
                    sx={{
                        "& .MuiListItem-root": {
                            "& .MuiListItemText-root .MuiTypography-root": {
                                fontSize: [ "smaller", "small", "medium" ],
                            }
                        }
                    }}
                >
                    <ListItem>
                        <ListItemText
                            primary="Zalogovaný používateľ s oprávneniami môže meniť nastavenia cron jobov spúšťaných na API serveri. Môže ich aj spustiť a zastaviť."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Zalogovaný používateľ ako administrátor môže registrovať nových používateľov aj meniť ich zaradenie, oprávnenie, aj vymazať už registrovaných používateľov."
                        // primaryTypographyProps={{ color: theme.palette.secondary.main }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Zalogovaný používateľ s oprávneniami má možnosť downloadu súborov s dátami z merania aj uploadu .xlsx súborov na zobrazenie."
                        // primaryTypographyProps={{ color: theme.palette.secondary.main }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Zalogovaný používateľ s oprávneniami môže spustiť extra merania z teplotného snímača a zobraziť namerané dáta (in real-time, websocket)."
                        // primaryTypographyProps={{ color: theme.palette.secondary.main }}
                        />
                    </ListItem>
                </List>
            </Paper>
            <Paper elevation={3} sx={{ my: [ 1, 2, 3 ], padding: [ 0.5, 1, 2 ], bgcolor: "primary.main", color: "primary.contrastText" }}>
                <Typography variant="h3" component="h3" gutterBottom
                    sx={{
                        fontSize: [ "small", "medium", "large" ],
                        textAlign: "center",
                    }}
                >
                    API server
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "smaller", "small", "medium" ],

                    }}
                >
                    API server má definované HTTP endpointy, kde po príjme HTTP požiadavky od klientského programu spúšťa časť programu, zistí dáta z databázy, spracuje ich a pošle v HTTP odozve späť.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "smaller", "small", "medium" ],
                    }}
                >
                    Logika kódu je rôzna a zahŕňa aj kontrolu prítomnosti informácie o zalogovanom používateľovi v klientovi v HTTP požiadavke.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "smaller", "small", "medium" ],
                    }}
                >
                    Používateľ musí byť zaregistrovaný v databáze a oprávnený, aby v HTTP odozve dostal žiadané dáta.
                </Typography>
                <List
                    sx={{
                        "& .MuiListItem-root": {
                            "& .MuiListItemText-root .MuiTypography-root": {
                                fontSize: [ "smaller", "small", "medium" ],
                            }
                        }
                    }}
                >
                    <ListItem>
                        <ListItemText
                            primary="Poskytuje HTTP endpointy s kódom na manažovanie nastavení (a ukladanie v databáze) cron jobov spúšťaných na API serveri ."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Poskytuje HTTP endpointy na manažovanie registrovaných používateľov (a ukladanie v databáze)."
                        // primaryTypographyProps={{ color: theme.palette.secondary.main }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Poskytuje HTTP endpointy pre vytvorenie a download súborov .json, csv, .xlsx, .pdf s nameranými dátami uloženými v databáze."
                        // primaryTypographyProps={{ color: theme.palette.secondary.main }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Umožnuje spúšťanie priameho merania z teplotného snímača a získanie nameraných dát (in real-time, websocket)."
                        // primaryTypographyProps={{ color: theme.palette.secondary.main }}
                        />
                    </ListItem>
                </List>
            </Paper>

            <Box sx={{ my: [ 2, 3 ] }}>
                <Typography variant="h3" component="h3" gutterBottom
                    sx={{
                        fontSize: [ "small", "medium", "large" ],
                        textAlign: "center",
                    }}
                >
                    API Server - vlastnosti
                </Typography>
                <List
                    sx={{
                        "& .MuiListItem-root": {
                            "& .MuiListItemText-root .MuiTypography-root": {
                                fontSize: [ "smaller", "small", "medium" ],
                            }
                        }
                    }}>
                    <ListItem>
                        <ListItemText primary="HTTP-only cookies" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="JWT autentifikácia s Passport" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Helmet pre zabezpečenie HTTP headers" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="CORS s vymenovanými povolenými doménami pre HTTP požiadavky a ich dynamická kontrola" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Obmedzenie počtu HTTP požiadaviek za časový úsek na prevenciu útoku" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Session management s MongoDB store" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Pripájaný k databázovému serveru MongoDb" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="WebSocket" />
                    </ListItem>
                </List>
            </Box>

            <ApiRoutesListForTemperatureMeasurementApp />
        </Container>
    );
};

export default TemperatureMeasurementClientServerApp;