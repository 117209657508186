import { createHorizontalWavesPath, createHorizontalWavesPathWithIncreasingAmplitude, createHorizontalWavesPathWithDecreasingAmplitude, createHorizontalWavesPathWithIncreasingDecreasingAmplitude, createHorizontalWavesPathWithDecreasingIncreasingAmplitude, createVerticalWavesPath, createVerticalWavesPathWithIncreasingAmplitude, createVerticalWavesPathWithDecreasingAmplitude, createVerticalWavesPathWithIncreasingDecreasingAmplitude, createVerticalWavesPathWithDecreasingIncreasingAmplitude } from "./utilities"


export const horizontalWavesPaths = (wavesNumber, width, height) => {
    return {
        straight: createHorizontalWavesPath(wavesNumber, width, height, 0),
        increasingAmplitude: createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
        decreasingAmplitude: createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
        increasingDecreasingAmplitude: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, width, height),
        decreasingIncreasingAmplitude: createHorizontalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, width, height),
    }
}
export const verticalWavesPaths = (wavesNumber, height, width) => {
    return {
        straight: createVerticalWavesPath(wavesNumber, height, width, 0),
        increasingAmplitude: createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
        decreasingAmplitude: createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
        increasingDecreasingAmplitude: createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        decreasingIncreasingAmplitude: createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width),
    }
}

export const morphingPathsVertical = (wavesNumber, height, width) => {
    return {
        vFromDecreasingToIncreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
        ],
        vFromIncreasingToDecreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
        ],
        vFromIncreasingToIncreasingDecreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        ],
        vFromDecreasingToIncreasingDecreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        ],
        a: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            // createVerticalWavesPath(wavesNumber, height, width, -width),
            // createVerticalWavesPath(wavesNumber, height, width, width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width, width),
        ],
        b: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            // createVerticalWavesPath(wavesNumber, height, width, width),
            // createVerticalWavesPath(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width, width),
        ],
    }
}
export const morphingPathsVerticalSpring = (wavesNumber, height, width) => {
    return {
        vFromDecreasingToIncreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
        ],
        vFromIncreasingToDecreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
        ],
        vFromIncreasingToIncreasingDecreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        ],
        vFromDecreasingToIncreasingDecreasing: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        ],
        vFromDecreasingToIncreasingDecreasingH20: [
            createVerticalWavesPath(wavesNumber, 0.2 * height, width, 0),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        ],
        vFromDecreasingToIncreasingDecreasingW20: [
            createVerticalWavesPath(wavesNumber, height, 0.2 * width, 0),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
        ],
        a: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            // createVerticalWavesPath(wavesNumber, height, width, -width),
            // createVerticalWavesPath(wavesNumber, height, width, width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, height, width, width),
        ],
        b: [
            createVerticalWavesPath(wavesNumber, height, width, 0),
            // createVerticalWavesPath(wavesNumber, height, width, width),
            // createVerticalWavesPath(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithIncreasingAmplitude(wavesNumber, height, width, width),
            createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width),
            createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width, -width),
            createVerticalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, height, width, width),
        ],
    }
}



export const morphingPathsHorizontal = (wavesNumber, width, height) => {
    return {
        hFromDecreasingToIncreasing: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
        ],
        hFromIncreasingToDecreasing: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
        ],
        hFromIncreasingToDecreasingIncreasing: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, width, height),
        ],
        a: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            // createHorizontalWavesPath(13, width, height, -height),
            // createHorizontalWavesPath(13, width, height, height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height, height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height, -height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, width, height),
        ],
        b: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            // createHorizontalWavesPath(13, width, height, height),
            // createHorizontalWavesPath(13, width, height, -height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height, -height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, width, height),
        ],
    }
}

export const morphingPathsHorizontalSpring = (wavesNumber, width, height) => {
    return {
        hFromDecreasingToIncreasing: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
        ],
        hFromIncreasingToDecreasing: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
        ],
        hFromIncreasingToDecreasingIncreasing: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingIncreasingAmplitude(wavesNumber, width, height),
        ],
        a: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, 0.2 * width, height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, 0.2 * width, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, 0.2 * width, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, width, height),
        ],
        b: [
            createHorizontalWavesPath(wavesNumber, width, height, 0),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, width, height),
            createHorizontalWavesPathWithIncreasingAmplitude(wavesNumber, 0.2 * width, height),
            createHorizontalWavesPathWithDecreasingAmplitude(wavesNumber, 0.2 * width, height),
            createHorizontalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, 0.2 * width, height),
            createHorizontalWavesPathWithIncreasingDecreasingAmplitude(wavesNumber, width, height),
        ],
    }
}
export const morphingFuncMap = {
    horizontal: morphingPathsHorizontal,
    vertical: morphingPathsVertical,
    horizontalSpring: morphingPathsHorizontalSpring,
    verticalSpring: morphingPathsVerticalSpring,
}