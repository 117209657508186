// Assuming COLORS, LIGHT_MODE_COLORS, and DARK_MODE_COLORS are imported or defined in the same file
import { COLORS, LIGHT_MODE_COLORS, DARK_MODE_COLORS } from '../theme/constants';
const ALL_THEME_COLORS = {
    ...COLORS, // Assuming you want to include these as well
  ...LIGHT_MODE_COLORS,
//   ...DARK_MODE_COLORS,
};

const getValueOfColorOrThemeNamedColor = (colorOrThemeNamedColor, variant = 'main', contrast = false) => {
  const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(colorOrThemeNamedColor);
  const isValidRgb = /^rgb(a)?\(\s*\d+\s*,\s*\d+\s*,\s*\d+(\s*,\s*(0|1|0?\.\d+))?\s*\)$/i.test(colorOrThemeNamedColor);
  const isCssNamedColor = CSS.supports("color", colorOrThemeNamedColor);

  // Check if the colorOrThemedNamedColor is a key in the ALL_COLORS object
  const colorCategory = ALL_THEME_COLORS[colorOrThemeNamedColor];
  if (colorCategory && colorCategory[variant]) {
    // return contrast ? (colorCategory.contrastText || 'black') : colorCategory[variant];
    // return contrast ? (`${colorOrThemeNamedColor}.contrastText` || 'black') : `${colorOrThemeNamedColor}.${variant}`;
    return contrast ? (`var(--color-${colorOrThemeNamedColor}-text)` || 'black') : `var(--color-${colorOrThemeNamedColor}-${variant})`;
    // --color-primary-main 
} else if (isValidHex || isValidRgb || isCssNamedColor) {
    // If it's a valid color value
    return colorOrThemeNamedColor;
  }
  // If not, return a default color
  return contrast ? 'black' : 'white'; // Default colors can be adjusted as needed
};


export { getValueOfColorOrThemeNamedColor }


    // const getColorValue = (colorOrThemedNamedColor, variant = 'main', contrast = false) => {
    //     const themeColor = theme.palette[ colorOrThemedNamedColor ];
    //     const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(colorOrThemedNamedColor);
    //     const isValidRgb = /^rgb(a)?\(\s*\d+\s*,\s*\d+\s*,\s*\d+(\s*,\s*(0|1|0?\.\d+))?\s*\)$/i.test(colorOrThemedNamedColor);
    //     const isNamedColor = CSS.supports("color", colorOrThemedNamedColor);
    
    //     // Check if the colorOrThemedNamedColor is a key in the theme's palette
    //     if (themeColor) {
    //         return contrast ? `${colorOrThemedNamedColor}.contrastText` : `${colorOrThemedNamedColor}.${variant}`;
    //     } else if (isValidHex || isValidRgb || isNamedColor) {
    //         // If it's a valid color value
    //         return colorOrThemedNamedColor;
    //     }
    //     // If not, return a default color
    //     return contrast ? 'black' : 'white'; // Default colors can be adjusted as needed
    // };