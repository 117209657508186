
export const myFramesPadding = { paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10 }




export const myBaseOptionsCCh = {
    columns: 2,
    styleItemTextBase: {
        boxShadow: "0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2)",
    },
    paddingToPoint: "3rem",
    isMultiplePaths: true,
}


export const myAnimationFramerMotionCCh = {
    forward: {
        itemWrapA: {
            transition: {
                duration: 0.3,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                opacity: 1,
            },
            delayPauseItem: 0,
        },
        itemTextA: {
            transition: {
                duration: 0.3,
                ease: "easeInOut",
                delay: 1,
            },
            animation: {
                scale: [ 0.9, 1 ],
                backgroundColor: [ "#ffffff", "#c58003" ],
                color: [ "#000", "#45e" ],
            },
            delayPauseItemText: 1,
        },
        svgPathA: {
            transition: {
                duration: 0.5,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                pathLength: 1,
                pathOffset: 0,
            },
            delayPausePathBox: 0.5,
        },
        svgPathAGo: {
            transition: {
                duration: 0.5,
                ease: "easeInOut",
                delay: 0,
                times: [ 0, 1 ],
            },
            animation: {
                // pathLength: [ 1, 0 ],
                pathLength: 1,
                pathOffset: 1,

                // pathOffset: [ 0, -1 ],
                // opacity: [ 1, 0 ],
            },
            delayPausePathBox: 0.5,
        },
        boxOffsetA: {
            transition: {
                duration: 0.5,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                offsetDistance: [ "100%", "0%" ],
                scale: [ 1.5, 0.5 ],
                skewX: [ 30, 0 ],
                borderRadius: [ "50%", "0%" ],
            },
        },
        boxOffsetAGo: {
            transition: {
                duration: 0.5,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                offsetDistance: [ "100%", "0%" ],
                scale: [ 1.5, 0.5 ],
                skewX: [ 30, 0 ],
                borderRadius: [ "50%", "0%" ],

                //     offsetDistance: [ "0%", "100%" ],
                //     scale: [ 0.5, 1.5 ],
                //     skewX: [ 0, 30 ],
                //     borderRadius: [ "0%", "50%" ],
            },
        },
    },
    back: {
        itemWrapA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                opacity: 0.5,
            },
            delayPauseItem: 0,
        },
        itemTextA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                scale: [ 1, 0.9 ],
                backgroundColor: [ "#c58003", "#ffffff" ],
                color: [ "#45e", "#000" ],
            },
            delayPauseItemText: 0,
        },
        svgPathA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                pathLength: 1,
                pathOffset: -1,
            },
            delayPausePathBox: 0,
        },
        boxOffsetA: {
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0,
            },
            animation: {
                offsetDistance: [ "0%", "100%" ],
                scale: [ 0.5, 1.5 ],
                skewX: [ 0, 30 ],
                borderRadius: [ "0%", "50%" ],
            },
        },
    }
}




export const sxWrapItro = {
    "&.intro": {
        p: 0,
        my: 0, // [ 2, 2, 3, 4, 5 ],
        // fontSize: [ "0.7rem", "0.8rem", "1rem" ],
        // fontSize: [ "x-small", "x-small", "small" ],
        fontSize: [ "small", "small", "medium" ],
        "& .waved": {
            textAlign: 'center',
        },
        "& .connectedDescr": {
            p: "10px",
        },
    }
}

const defaultColors = {
    svgPathColor: "maroon",
    svgFillColor: "green",
    startBg: "orange",
    startColor: "cyan",
    endBg: "brown",
    endColor: "black",
}

export const getDefaultConnectColors = (palette) => ({
    svgPathColor: palette.primary.light,
    svgFillColor: palette.primary.contrastText,
    startBg: palette.primary.main,
    startColor: palette.primary.contrastText,
    endBg: palette.primary2.main,
    endColor: palette.primary2.contrastText,
})


