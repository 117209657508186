import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { horizontalWavesPaths, verticalWavesPaths } from '../animatedSvgImages/animateSvgInParentPaddingsConfig.js'

const defaultOptions = {
    wavesNumber: 13,
    wavesHeight: 50,
    wavesWidth: 50,
    // strokeColor: 'yellow',
    wavesStrokeDasharray: '0 1',
    wavesStrokeLinecap: 'round',
    wavesStrokeLinejoin: 'round',
    wavesStrokeMiterlimit: 4,
    // fillColor: 'transparent',
    wavesFillRule: 'evenodd',
    wavesClipPath: 'none',
    wavesClipRule: 'nonzero',
    wavesStrokeDashoffset: 0,
    wavesStrokeOpacity: 1,
    wavesFillOpacity: 1,
    wavesStrokeWidth: 1,

    rightPathName: "decreasingAmplitude",
    bottomPathName: "decreasingAmplitude",
    leftPathName: "increasingAmplitude",
    topPathPars: {
        wavesNumber: 13,
        svgPathName: "increasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    },
    rightPathPars: {
        wavesNumber: 23,
        svgPathName: "increasingDecreasingAmplitude",
        heightCoeff: 1,
        widthCoeff: 1,
    },
    bottomPathPars: {
        wavesNumber: 13,
        svgPathName: "decreasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    },
    leftPathPars: {
        wavesNumber: 13,
        svgPathName: "decreasingAmplitude",
        heightCoeff: 1,
        widthCoeff: 1,
    },
}


const SvgInParentPaddings = ({ refParent, options }) => {

    const mergedOptions = { ...defaultOptions, ...options };

    const { topPathPars, bottomPathPars, rightPathPars, leftPathPars, } = mergedOptions;
    let { fillColor, strokeColor, } = mergedOptions;

    const [ refParentWidth, setRefParentWidth ] = useState(0);
    const [ refParentHeight, setRefParentHeight ] = useState(0);
    const [ refParentPadding, setRefParentPadding ] = useState({ top: 0, right: 0, bottom: 0, left: 0 });

    const topPath = useMemo(() => horizontalWavesPaths(topPathPars.wavesNumber, topPathPars.widthCoeff * refParentWidth, topPathPars.heightCoeff * refParentPadding.top)[ topPathPars.svgPathName ], [ topPathPars, refParentWidth, refParentPadding ]);
    const bottomPath = useMemo(() => horizontalWavesPaths(bottomPathPars.wavesNumber, bottomPathPars.widthCoeff * refParentWidth, bottomPathPars.heightCoeff * refParentPadding.bottom)[ bottomPathPars.svgPathName ], [ bottomPathPars, refParentWidth, refParentPadding ]);
    const rightPath = useMemo(() => verticalWavesPaths(rightPathPars.wavesNumber, rightPathPars.heightCoeff * refParentHeight, rightPathPars.widthCoeff * refParentPadding.right)[ rightPathPars.svgPathName ], [ rightPathPars, refParentHeight, refParentPadding ]);
    const leftPath = useMemo(() => verticalWavesPaths(leftPathPars.wavesNumber, leftPathPars.heightCoeff * refParentHeight, leftPathPars.widthCoeff * refParentPadding.left)[ leftPathPars.svgPathName ], [ leftPathPars, refParentHeight, refParentPadding ]);


    const theme = useTheme();
    fillColor = fillColor ? fillColor : theme.palette.primary2.main
    strokeColor = strokeColor ? strokeColor : theme.palette.primary2.contrastText

    const setRefParentBoundingBoxValues = useCallback(() => {
        if (refParent.current) {
            const { width, height } = refParent.current.getBoundingClientRect();
            if (width === 0 || height === 0) return;
            setRefParentHeight(height);
            setRefParentWidth(width);
        }
    }, [ refParent ]);

    useEffect(() => {
        // Create a new ResizeObserver that updates the 'update' state to force a re-render
        const resizeObserver = new ResizeObserver(setRefParentBoundingBoxValues);

        // Start observing the parent element
        if (refParent.current) {
            resizeObserver.observe(refParent.current);
        }

        // Clean up function
        return () => {
            if (refParent.current) {
                resizeObserver.unobserve(refParent.current);
            }
        };
    }, [ refParent, setRefParentBoundingBoxValues ]);

    useEffect(() => {
        if (refParent?.current) {
            if (!refParentWidth) setRefParentBoundingBoxValues()
            if (!refParentWidth) return
            const style = window.getComputedStyle(refParent.current);
            setRefParentPadding({
                top: parseInt(style.paddingTop),
                right: parseInt(style.paddingRight),
                bottom: parseInt(style.paddingBottom),
                left: parseInt(style.paddingLeft),
            });
        }
    }, [ refParent, refParentWidth, setRefParentPadding, setRefParentBoundingBoxValues ]);
    // }, [ refParent, refParent.current?.offsetWidth, refParent.current?.offsetHeight, refParent.current?.offsetLeft, refParent.current?.offsetTop, refParent.current?.style, refParent.current?.style.paddingTop, refParent.current?.style.paddingRight, refParent.current?.style.paddingBottom, refParent.current?.style.paddingLeft, refParent.current?.style, setRefParentBoundingBoxValues, refParentWidth ]);

    return (
        <>
            {refParentPadding.top > 0 && (
                <div
                    className="paddingFrameTop"
                    style={{
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: `${refParentPadding.top}px`,
                        position: "absolute",
                    }}
                ></div>)}
            {refParentPadding.top > 0 && (<svg
                viewBox={`0 0  ${refParentWidth} ${refParentPadding.top}`}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: `${refParentPadding.top}px`,
                    width: "100%",
                }}
            >
                <path
                    d={topPath}
                    stroke={strokeColor}
                    fill={fillColor}
                />
            </svg>)}
            {refParentPadding.right > 0 && (<div
                className='paddingFrameRight'
                style={{
                    top: 0,
                    right: 0,
                    width: `${refParentPadding.right}px`,
                    height: "100%",
                    position: "absolute",
                }}
            ></div>)}
            {refParentPadding.right > 0 && (<svg
                viewBox={`0 0 ${refParentPadding.right} ${refParentHeight}`}
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: `${refParentPadding.right}px`,
                    height: "100%",

                }}
            >
                <path
                    d={rightPath}
                    stroke={strokeColor}
                    fill={fillColor}
                />
            </svg >)}
            {refParentPadding.bottom > 0 && (<div
                className='paddingFrameBottom'
                style={{
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: `${refParentPadding.bottom}px`,
                    position: "absolute",
                }}
            ></div>)}
            {refParentPadding.bottom > 0 && (<svg
                viewBox={`0 0  ${refParentWidth} ${refParentPadding.bottom}`}
                style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    height: `${refParentPadding.bottom}px`,
                    width: "100%",
                }}
            >
                <path
                    d={bottomPath}
                    stroke={strokeColor}
                    fill={fillColor}
                />
            </svg>)}
            {refParentPadding.left > 0 && (<div
                className='paddingFrameLeft'
                style={{
                    top: 0,
                    left: 0,
                    width: `${refParentPadding.left}px`,
                    height: "100%",
                    position: "absolute",
                }}
            ></div>)}
            {refParentPadding.left > 0 && (
                <svg
                    viewBox={`0 0 ${refParentPadding.left} ${refParentHeight}`}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: `${refParentPadding.left}px`,
                        height: "100%",
                    }}
                >
                    <path
                        d={leftPath}
                        stroke={strokeColor}
                        fill={fillColor}
                    />
                </svg>
            )}
        </>)
}


export default SvgInParentPaddings