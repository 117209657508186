import { useStaticQuery, graphql } from "gatsby";

const useNatureImages = () => {
  const data = useStaticQuery(
    graphql`
      {

          natureImgsFiles: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "galleries/nature-200"}}) {

          totalCount
          nodes {
            id
            base
            name
            childImageSharp {
              id
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      }
    `
  );
  return data.natureImgsFiles;
};
export default useNatureImages;
