import React from 'react'
import { Box } from "@mui/system"


const stDropBorders = {
    borderRound50Perc: {
        borderRadius: "50%",
    },
    borderDiffPerc37_54_46_46: {
        borderRadius: "37% 54% 46% 46%",
    },
    borderDiffPercFunc: (l, r, t, b) => ({
        borderRadius: `${l}% ${r}% ${t}% ${b}%`,
    }),
    borderDiffPxFunc: (l, r, t, b) => ({
        borderRadius: `${l}px ${r}px ${t}px ${b}px`,
    }),
}


export const dropShadowFuncs = {
    s1: (shadowColor) => `inset 10px 10px 10px rgb(0 0 0 / 5%), 15px 15px 15px rgb(0 0 0 / 5%), 15px 15px 15px rgb(0 0 0 / 5%), inset -10px -10px 15px ${shadowColor}`,
    s2: (shadowColor) => `inset 10px 10px 10px rgb(0 0 0 / 35%), 15px 15px 15px rgb(0 0 0 / 35%), 15px 15px 15px rgb(0 0 0 / 35%), inset -10px -10px 15px ${shadowColor}`,

}

const stReflShape = {

    roundBefore1: {
        top: '23%',
        left: '16.5%',
        width: '13.8%',
        height: '13.8%',
    },
    roundAfter: {
        top: '16.5%',
        left: '33%',
        width: '6.8%',
        height: '6.8%',
    },
    bandAfter: {
        top: '16.5%',
        left: '33%',
        width: '6.8%',
        height: '18.1%',
    },
}



const stSxDrop = ({ className, reflBgBefore, stReflShapeBefore, reflBgAfter, stReflShapeAfter, styleDropUsed }) => ({
    [ `&.${className}` ]: {
        position: 'relative',
        height: "100%",
        width: 'fit-content',
        maxWidth: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...styleDropUsed,
        "&::before": {
            content: '\'\'',
            position: 'absolute',
            borderRadius: '50%',
            background: reflBgBefore,
            ...stReflShapeBefore,
            transform: "rotate(50deg)",
        },
        "&::after": {
            content: '\'\'',
            position: 'absolute',
            borderRadius: '50%',
            background: reflBgAfter,
            ...stReflShapeAfter,
            transform: "rotate(60deg)",
        },

    },
})


/** DropEffect
 * result like put children into drop
 * @param  {} props
 */
const DropEffect = (props) => {
    const { boxShadowColor = "rgb(255 255 255 / 11%)", shadow = dropShadowFuncs.s1, stBorders = stDropBorders.borderRound50Perc, widthSameHeight = true, reflBgBefore = "rgb(255 255 255 / 11%)", stReflShapeBefore = stReflShape.roundBefore1, reflBgAfter = "rgb(255 255 255 / 11%)", stReflShapeAfter = stReflShape.roundAfter, className = "drop", stPassed = {}, styleDrop = {}, children, ...rest } = props
    const styleDropUsed = {
        boxShadow: shadow(boxShadowColor),
        ...stBorders,
        ...stPassed,
        ...styleDrop
    }
    if (widthSameHeight) styleDropUsed.aspectRatio = '1'

    return (
        <Box
            className={className}
            sx={
                stSxDrop({ className, reflBgBefore, stReflShapeBefore, reflBgAfter, stReflShapeAfter, styleDropUsed })
            }
            {...rest}
        >
            {children}
        </Box>
    )
}

const optionsDropEffect = {
    roundPerfect: {
        boxShadowColor: "rgb(255 255 255 / 11%)",
        shadow: dropShadowFuncs.s1,
        stBorders: stDropBorders.borderRound50Perc,
        widthSameHeight: true,
        reflBgBefore: "rgb(255 255 255 / 11%)",
        stReflShapeBefore: stReflShape.roundBefore1,
        reflBgAfter: "rgb(255 255 255 / 11%)",
        stReflShapeAfter: stReflShape.roundAfter,
    },
    diffBordersBand: {
        boxShadowColor: "rgb(255 255 255 / 11%)",
        shadow: dropShadowFuncs.s1,
        stBorders: stDropBorders.borderDiffPerc37_54_46_46,
        widthSameHeight: true,
        reflBgBefore: "rgb(255 255 255 / 11%)",
        stReflShapeBefore: stReflShape.roundBefore1,
        reflBgAfter: "rgb(255 255 255 / 11%)",
        stReflShapeAfter: stReflShape.bandAfter,
    }
}


export default DropEffect
export { optionsDropEffect }


