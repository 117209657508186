import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const ReactForOwner = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="react-for-owner" className="toc"><span>Výhody React pre Vás ako vlastníka web stránok</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="react-for-owner"
            >
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="components" className="waved toc toc0"><span>Časti web stránok sú v komponentoch</span></h4>
                    <div>Cez React sa vyvárajú komponenty, a tie sa dajú opätovné používať na viacerých miestach. Pre samotné programovanie to znamená lepšiu prehľadnosť a údržbu.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="performance" className="waved toc toc0"><span>Výkon</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>React používa virtuálny DOM, ktorý znižuje počet manipulácií s reálnym DOM a tým zvyšuje rýchlosť zobrazenia web stránok.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="flow" className="waved toc toc0"><span>Jednosmerný Tok Dát</span></h4>
                    <div>React používa koncept jednosmerného toku dát, ktorý zjednodušuje sledovanie zmien stavu a zlepšuje predvídateľnosť aplikácie.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="spa" className="waved toc toc0"><span>Podpora pre SPA a SSR</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>React podporuje vývoj jednostránkových aplikácií (SPA) a serverovo renderovaných aplikácií (SSR), čo umožňuje vytvárať rýchle a responzívne webové stránky.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="eco" className="waved toc toc0"><span>Rozsiahly ekosystém</span></h4>
                    <div>Množstvo knižníc a nástrojov pre React zjednodušuje a urýchľuje vývoj webových stránok a rozširuje ich o rôzne funkcie.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="community" className="waved toc toc0"><span>Komunita</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>React má silnú a aktívnu komunitu vývojárov, ktorá pravidelne prispieva k jeho vývoju a poskytuje podporu ostatným vývojárom.</div>
                </div>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};

export default ReactForOwner;