import React from "react"
import { motion } from "framer-motion"
import useFaceImages from "./useFaceImages"


export const getArrFaceAvatarsSrcNames = (faceImagesFiles) => {
  const faceImagesNodes = faceImagesFiles.nodes

  const arrFaceAvatarsSrcData = faceImagesNodes.map((imgNode, i) => {
    const image = imgNode.childImageSharp.gatsbyImageData
    const { name } = imgNode
    return { imgName: name, imgSrc: image.images.fallback.src }
  })
  return arrFaceAvatarsSrcData
}


const varAvatarFace = ({ i }) => {

  return {
    initial: false,
    enter: {
      opacity: 1,
      rotateZ: [ 0, 180, 0 ],
      y: [ -20, 0, 0 ],
      transition: {
        duration: 0.3,
        when: "afterChildren",
      },

    },

    exit: {
      rotateZ: 180,
      transition: {
        staggerChildren: 0.01,
        duration: 0.1,
      },
    },
    whileHover: {
      rotateY: 90,
      scale: 1.2,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.3
      }
    },
    whileTap: {
      rotateY: 90,
      scale: 0.8,
      transition: {
        repeat: 1,
        repeatType: "reverse",
        duration: 0.3
      }
    },
  }
}

const AvatarFaceByImgName = (props) => {
  const { imgName, className, i, children, ...rest } = props
  const faceImagesFiles = useFaceImages();

  const arrFaceAvatarsSrcData = getArrFaceAvatarsSrcNames(faceImagesFiles)
  const foundItem = arrFaceAvatarsSrcData.find(item => item.imgName === String(imgName))
  return (
    <motion.div
      className={`${className || ""} MuiAvatar-root`}
      aria-label="avatHeadFace"
      variants={varAvatarFace({ i })}
      {...rest}
      key={`AvatarFaceByImgName-${imgName}`}
    >
      {!!foundItem ? (<img
        alt={`${foundItem.imgName}`}
        src={foundItem.imgSrc}
        className="MuiAvatar-img"
      />) : "XY"}
    </motion.div>
  )
}

export default AvatarFaceByImgName

