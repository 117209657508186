import React, { lazy, Suspense } from 'react';

const MyLightGalleryProvider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'MyLightGalleryProviderLazy' */ './myLightGalleryProvider'));

function MyLightGalleryProviderLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading MyLightGalleryProvider ...</div>}>
                <MyLightGalleryProvider {...props} />
            </Suspense>
        </div>
    );
}

export default MyLightGalleryProviderLazy;