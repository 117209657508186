import React, { useEffect, useContext, useState, useRef } from "react";
import { motion, AnimatePresence, useReducedMotion } from "framer-motion";
import { wrap } from "popmotion";
import { Box } from "@mui/system";
// import { useRandomInterval } from "../../utils/functions"
import { NotInViewContext } from "./AnimateScrollIntoViewport";

const styleSxWrap = {
  position: "relative",
  height: "400px",

  "& .itemWrap": {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",

    "& .item": {
      width: "100%",
      height: "100%",
      display: "flex",
    },
  },
  "& .nextPrev": {
    top: "calc(50% - 20px)",
    position: "absolute",
    background: "red",
    borderRadius: "30px",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "18px",
    zIndex: 2,
    "&.next": {
      right: "20px"
    },
    "&.prev": {
      left: "20px",
      transform: "scale(-1)"
    },
  },
}

const varCarousel = ({ swipeStep, shouldReduceMotion }) => {

  return {
    initial: { x: swipeStep < 0 ? "150%" : "-150%" },
    enter: shouldReduceMotion ? {
      opacity: 1,
      transition: {
        duration: 2,
      }
    } : {
      x: 0,
      transition: {
        duration: 2,
      }
    },

    exit: shouldReduceMotion ? {
      opacity: 0,
      transition: {
        duration: 0.5
      }
    } : {
      x: swipeStep > 0 ? "150%" : "-150%",
      transition: {
        duration: 0.5
      }
    }
  }
}




/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};


const SwipingCarousel = (props) => {
  const { swipingCarouselOptions } = props
  const { arrComponents, swipeInterval } = swipingCarouselOptions //randomSwipeInterval

  const [swipeNumber, setSwipe] = useState(0);
  const [swipeStep, setSwipeStep] = useState(1);
  const refIdInterval = useRef(null);
  const { notInView, isCountedTwice } = useContext(NotInViewContext)

  const shouldReduceMotion = useReducedMotion()

  // Having 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const compIndex = wrap(0, arrComponents.length, swipeNumber);

  const swipeToNeigbouring = (newStep) => {
    setSwipe(swipeNumber + newStep);
    setSwipeStep(newStep);
  };

  useEffect(() => {
    if (window) {
      if (notInView && !isCountedTwice) {
        clearInterval(refIdInterval.current);
        refIdInterval.current = null;
        return
      }
      if (!refIdInterval.current) {
        refIdInterval.current = window.setInterval(() => {
          setSwipe(prev => prev + swipeStep)
        }, 10000);
      }
      return () => {
        clearInterval(refIdInterval.current);
        refIdInterval.current = null;
      }
    }
  },
    [swipeStep, notInView, isCountedTwice])


  return (
    <Box component="div"
      style={{
        position: "relative", height: "400px",
        overflow: "hidden",
      }}
      sx={styleSxWrap}
    >
      <div
        className="itemWrap"
      >
        <AnimatePresence
          // initial={false}
          // custom={swipeStep}
          // exitBeforeEnter
          mode="wait"
        // This will be used for components to resolve
        // exit variants. It's necessary as removed
        // components won't rerender with
        // the latest state (as they've been removed)
        // custom={direction}
        >
          <motion.div
            className="item"
            // custom={{ swipeStep, shouldReduceMotion }}
            variants={varCarousel({ swipeStep, shouldReduceMotion })}
            initial="initial"
            animate="enter"
            exit="exit"
            style={{
              opacity: shouldReduceMotion ? 0 : 1,
              x: swipeStep > 0 ? "-150%" : "150%",
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                swipeToNeigbouring(1);
              } else if (swipe > swipeConfidenceThreshold) {
                swipeToNeigbouring(-1);
              }
            }}
            key={compIndex}
          >
            {arrComponents[compIndex]}
          </motion.div>
        </AnimatePresence>
      </div>

      <button
        className="nextPrev next"
        onClick={(e) => {
          e.stopPropagation();
          e.bubbles = false; swipeToNeigbouring(1)
        }}
      >
        {"‣"}
      </button>
      <button
        className="nextPrev prev"
        onClick={(e) => {
          e.stopPropagation();
          e.bubbles = false; swipeToNeigbouring(-1)
        }}
      >
        {"‣"}
      </button>
    </Box>
  );
};

export default SwipingCarousel

