import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const WebPageGoodForVisitor = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="web-page-good-for-visitor" className="toc"><span>Vlastnosti dobrých web stránok</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="web-page-good-for-visitor"
            >
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <div className="connected">
                        <h4 id="rychlost" className="waved toc toc0"><span>Rýchlosť načítania</span></h4>
                        <div className="connectedDescr" >Rýchle načítanie stránky je kľúčové pre udržanie pozornosti návštevníka a zlepšenie pohody a pohodlia pri prezeraní.</div>
                    </div>
                </WavedFrameAnimationForChildrenLazy>
                <div className="connected">
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="navigacia" className="waved toc toc0"><span>Jednoduchosť navigácie</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div className="connectedDescr" >Intuitívna a jednoduchá navigácia tiež umožňuje návštevníkom ľahko nájsť to, čo hľadajú.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="responsive" className="waved toc toc0"><span>Responzívnosť</span></h4>
                    <div className="connectedDescr" >Webstránky by mali byť optimalizované pre rôzne veľkosti obrazovky, aby boli prístupné a funkčné na všetkých zariadeniach.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="obsah" className="waved toc toc0"><span>Kvalitný obsah</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div className="connectedDescr" >Užitočný a hodnotný obsah je dôležitý pre získanie a udržanie záujmu návštevníkov.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="design" className="waved toc toc0"><span>Estetický dizajn</span></h4>
                    <div className="connectedDescr" >Atraktívny a profesionálny dizajn môže zvýšiť dôveru návštevníkov a zlepšiť ich zážitok na vaších webstránkach.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="safe" className="waved toc toc0"><span>Bezpečnosť</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div className="connectedDescr" >Webstránky by mali byť bezpečné a chránené pred kybernetickými útokmi, aby návštevníci mohli bez obáv zdieľať svoje osobné údaje.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="access" className="waved toc toc0"><span>Prístupnosť</span></h4>
                    <div className="connectedDescr" >Webstránky by mali byť prístupné pre všetkých návštevníkov, vrátane tých s rôznymi druhmi postihnutia.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};

export default WebPageGoodForVisitor;
