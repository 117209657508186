import React, { useEffect, useRef, MouseEvent } from "react";
import { motion, useAnimation, MotionValue, transform } from "framer-motion";
import { Box } from "@mui/material";

interface RotatingItemProps {
    nameItem: string;
    iItem: number;
    styleRotating: React.CSSProperties;
    classRotating: string;
    firstAngle: number;
    angleIncrement: number;
    selectedIndex: number; //React.MutableRefObject<number>;
    // handleOnClick: ({ e, iItem }: { e: MouseEvent<Element, MouseEvent>; iItem: number; }) => void;
    angleM: MotionValue<number>;
    deviceSize: string;
    isInView: boolean;
}

const RotatingItem = (props: RotatingItemProps) => {
    const {
        nameItem,
        iItem,
        styleRotating,
        classRotating,
        firstAngle,
        angleIncrement,
        selectedIndex,
        // handleOnClick,
        angleM,
        deviceSize,
        isInView,
    } = props;

    const controlsR = useAnimation();
    const refRItem = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isInView) return;
        let ticks = 0;

        const rotateOnMotionAngleR = (latest: number) => {
            ticks = ticks + 1;
            const isOdd = (iItem + ticks) % 2 === 0 ? false : true;
            const newScale = isOdd
                ? transform(latest % 360, [0, 360], [1.1, 1.5])
                : 1;
            const newSkew = isOdd
                ? transform(latest % 360, [0, 360], [-10, 10])
                : 0;
            const angleNext = firstAngle + latest;

            controlsR.start({
                rotateZ: angleNext,
                scale: newScale,
                skew: newSkew,
                transition: {
                    duration: 1,
                },
            });
        };
        const unsubscribeAngleMR = angleM.onChange(rotateOnMotionAngleR);

        return () => {
            controlsR.stop();
            unsubscribeAngleMR();
        };
    }, [
        firstAngle,
        iItem,
        deviceSize,
        angleIncrement,
        angleM,
        controlsR,
        isInView,
    ]);

    // function onTap(event: MouseEvent,info: any,{ iItem }: { iItem: number }) {
    const handleTap = (event: MouseEvent<Element, MouseEvent>, info: any) => {
        // const isDeselect = selectedIndex.current === iItem;
        const isDeselect = selectedIndex === iItem;
        const divEl =
            event.target instanceof HTMLElement &&
                event.target.childElementCount > 0
                ? event.target
                : (event.target as HTMLElement).parentElement;
        const selectedRotating = divEl?.parentElement?.querySelector(
            ".selectedRotating"
        );
        selectedRotating?.classList.remove("selectedRotating");
        if (!isDeselect && divEl) {
            divEl.classList.add("selectedRotating", "spec");
        }
        const selectedAround = document.querySelector(
            "#wrapDivsAround > div.selectedAround"
        );
        selectedAround?.classList.remove("selectedAround");
        if (!isDeselect) {
            const aroundItem = document.querySelector(
                `#wrapDivsAround > div.aroundItem:nth-of-type(${iItem + 1})`
            );
            aroundItem?.classList.add("selectedAround", "spec");
        }

        event.preventDefault();
        event.stopPropagation();
        controlsR.start({
            rotateY: 360,
            transition: { duration: 0.4 },
        });
    }

    function onHover(event: MouseEvent, info: any) {
        controlsR.start({
            scale: 1.2,
            cursor: "pointer",
            transition: {
                duration: 0.2,
                repeat: 1,
                repeatType: "reverse",
            },
        });
    }

    return (
        <Box
            component={motion.div}
            ref={refRItem}
            initial="initial"
            animate={controlsR}
            // onTap={(e, info) => onTap(e, info, { iItem })}
            onTap={handleTap}
            onHoverStart={onHover}
            onHoverEnd={(e) => { }}
            className={classRotating}
            // onClick={(e) => handleOnClick({ e, iItem })}
            // onDoubleClick={(e) => handleOnClick({ e, iItem })}
            style={styleRotating}
            key={`divs-rotating-${iItem}`}
        >
            <button className="rotatingHand" onClick={(e) => e.stopPropagation()}>
                {nameItem}
            </button>
        </Box>
    );
};


export default RotatingItem