
// import { isObject2 } from "./functions"

const MASK_IMAGES_PATHS = {
    maskFrameSqBlur1b: "/sf/im/masks/frameSqBlur1b.svg",
    maskFrameSqBlur1bVert: "/sf/im/masks/frameSqBlur1bVert-exp.svg",
    maskChalk1: "/sf/im/masks/distortMasks-chalk1.svg",
    maskChalk1Vert: "/sf/im/masks/distortMasks-chalk1-vert.svg",
    maskChalkMaxBlurBefore: "/sf/im/masks/distortMasks-chalkMaxBlurBefore.svg",
    maskChalkMaxBlurBeforeVert: "/sf/im/masks/distortMasks-chalkMaxBlurBefore-vert.svg",
    maskDistortPixelSmear1MoreBlur: "/sf/im/masks/maskDistortPixelSmear1MoreBlur.svg",
    maskDistortPixelSmear1MoreBlurVert: "/sf/im/masks/maskDistortPixelSmear1MoreBlurVert.svg",
    maskDistortPixelSmear1MoreMoreMoreBlur: "/sf/im/masks/maskDistortPixelSmear1MoreMoreMoreBlur.svg",
    maskDistortPixelSmear1MoreMoreMoreBlurVert: "/sf/im/masks/maskDistortPixelSmear1MoreMoreMoreBlurVert.svg",

    maskDistortPixelSmear1LotBlur: "/sf/im/masks/maskDistortPixelSmear1LotBlur.svg",
    maskDistortPixelSmear1LotBlurVert: "/sf/im/masks/maskDistortPixelSmear1LotBlurVert.svg",
    tornEdges1: "/sf/im/masks/tornEdges1.svg",
    tornEdges1Vert: "/sf/im/masks/tornEdges1Vert.svg",

    // MaskFramesAClipGr: "/sf/im/masks/maskFramesA-exp-clipGr.svg",
    FrameMask4: "/sf/im/masks/FrameCloud1filledHoriz.svg",
    MaskFrame1: "/sf/im/masks/maskFrame1-exp.svg",
    MaskFrame1Vert: "/sf/im/masks/maskFrame1-exp-vert.svg",
    MaskStarsw10h3: "/sf/im/masks/maskStars-w10h3-exp.svg",
    MaskStarsh10w3: "/sf/im/masks/maskStars-w3h10-exp.svg",
    Ffmask3Simli: "/sf/im/masks/ffmask3-simliFilterx10.svg",
    Ffmask3SimliVert: "/sf/im/masks/ffmask3-simliFilterx10-vert.svg",
    Ffmask3SimliHoriz: "/sf/im/masks/ffmask3-simliFilterx10-horiz.svg",
    maskWaveChalk1H: "/sf/im/masks/maskWaveChalk1-H.svg",
    maskWaveChalk1V: "/sf/im/masks/maskWaveChalk1-V.svg",

    Mazanica: "/sf/im/masks/mazanica2.svg",
    maskCornersChalk1TopLeftP: "/sf/im/masks/maskCornersChalk1-TopLeftP.svg",
    maskCornersChalk1TopRight: "/sf/im/masks/maskCornersChalk1-TopRight.svg",
    maskCornersChalk1BottomLeft: "/sf/im/masks/maskCornersChalk1-BottomLeft.svg",
    maskCornersChalk1RightBottom: "/sf/im/masks/maskCornersChalk1-RightBottom.svg",

    maskChalk1CornerTR: "/sf/im/masks/maskChalk1CornerTR.svg",
    maskChalk1CornerTL: "/sf/im/masks/maskChalk1CornerTL.svg",
    maskChalk1CornerBL: "/sf/im/masks/maskChalk1CornerBL.svg",
    maskChalk1CornerBLChromolitho: "/sf/im/masks/maskChalk1CornerBLChromolitho.svg",
    maskChalk1CornerBR: "/sf/im/masks/maskChalk1CornerBR.svg",
    star1Black: "/sf/im/masks/star1Black.svg",
    star1Black25Blur: "/sf/im/masks/star1Black-25blur.svg",
}


export const maskPatternsHoriz = {
    maskP1: "/sf/im/masks/maskDistortLapping.svg",
    maskP2: "/sf/im/masks/maskStars-w10h3-exp.svg",
    maskP3: "/sf/im/masks/maskWaveChalk1-H.svg",
    maskP4: "/sf/im/masks/tornEdges1.svg",
    maskP5: "/sf/im/masks/wavesF-dbl-w1201-h222mergedPairs.svg",
    maskP8: "/sf/im/masks/distortMasks-chalkMaxBlurBefore.svg",
    maskP9: "/sf/im/masks/maskDistortPixelSmear1MoreMoreMoreBlur.svg",
}

export const maskPatternsVert = {
    maskP1V: "/sf/im/masks/maskDistortLappingVert.svg",
    maskP2V: "/sf/im/masks/maskStars-w3h10-exp.svg",
    maskP3V: "/sf/im/masks/maskWaveChalk1-V.svg",
    maskP4V: "/sf/im/masks/tornEdges1Vert.svg",
    maskP6V: "/sf/im/masks/wavesF-vert-dbl-h600-w222-.svg",
    maskP7V: "/sf/im/masks/wavesF-vert-dbl-h601-w44.svg",
    maskP8V: "/sf/im/masks/distortMasks-chalkMaxBlurBefore-vert.svg",
}

export const masksMap = {
    mask1: { name: "maskFrameSqBlur1b" },
    mask1V: { name: "maskFrameSqBlur1bVert" },
    mask2: { name: "maskChalk1" },
    mask2V: { name: "maskChalk1Vert" },
    mask3: { name: "maskChalkMaxBlurBefore" },
    mask3V: { name: "maskChalkMaxBlurBeforeVert" },
    mask4: { name: "maskDistortPixelSmear1MoreMoreMoreBlur" },
    mask4V: { name: "maskDistortPixelSmear1MoreMoreMoreBlurVert" },
    mask5: { name: "maskDistortPixelSmear1MoreBlur" },
    mask5V: { name: "maskDistortPixelSmear1MoreBlurVert" },
    // mask6 name: : "MaskFramesAClipGr",
    mask6: { name: "maskDistortPixelSmear1LotBlur" },
    mask6V: { name: "maskDistortPixelSmear1LotBlurVert" },
    mask7: { name: "FrameMask4" },
    mask8: { name: "MaskFrame1" },
    mask8V: { name: "MaskFrame1Vert" },
    mask9: { name: "MaskStarsw10h3" },
    mask9V: { name: "MaskStarsh10w3" },
    mask10: { name: "maskWaveChalk1H" },
    mask10V: { name: "maskWaveChalk1V" },
    mask11: { name: "Ffmask3SimliHoriz" },
    mask11V: { name: "Ffmask3SimliVert" },
    mask11Kruh: { name: "Ffmask3Simli" },
    mask12: { name: "tornEdges1" },
    mask12V: { name: "tornEdges1Vert" },
    mask13: { name: "Mazanica", aspectRatio: 1 },
    mask14: { name: "star1Black", aspectRatio: 398 / 385 },
    mask15: { name: "star1Black25Blur", aspectRatio: 398 / 385 },
    mask20TL: { name: "maskCornersChalk1TopLeftP", aspectRatio: 102.35445 / 61.210449 },
    mask20TR: { name: "maskCornersChalk1TopRight", aspectRatio: 102.35445 / 61.210449 },
    mask20BL: { name: "maskCornersChalk1BottomLeft", aspectRatio: 102.35445 / 61.210449 },
    mask20BR: { name: "maskCornersChalk1RightBottom", aspectRatio: 102.35445 / 61.210449 },
    mask21TL: { name: "maskChalk1CornerTL", aspectRatio: 128.26162 / 50.44249 },
    mask21TR: { name: "maskChalk1CornerTR", aspectRatio: 128.26162 / 50.44249 },
    mask21BL: { name: "maskChalk1CornerBL", aspectRatio: 128.26162 / 50.44249 },
    mask21BLa: { name: "maskChalk1CornerBLChromolitho", aspectRatio: 128.26162 / 50.44249 },
    mask21BR: { name: "maskChalk1CornerBR", aspectRatio: 128.26162 / 50.44249 },
}


export const getMaskStaticPathWithAspectRatio = (mask) => {
    if (!mask) return
    //  const isObject = isObject2(mask)

    const nameOrObj = masksMap[ mask ]
    const { name, aspectRatio } = nameOrObj
    return { path: MASK_IMAGES_PATHS[ name ], aspectRatio }

}
export const getMaskStaticPath = (mask) => {
    if (!mask) return;

    // Check if the mask exists in maskPatternsHoriz or maskPatternsVert
    if (maskPatternsHoriz.hasOwnProperty(mask)) {
        return maskPatternsHoriz[ mask ]
    } else if (maskPatternsVert.hasOwnProperty(mask)) {
        return maskPatternsVert[ mask ]
    }

    // If the mask doesn't exist in maskPatternsHoriz or maskPatternsVert, check masksMap
    const nameOrObj = masksMap[ mask ];
    if (!nameOrObj) return;

    const { name } = nameOrObj;
    return MASK_IMAGES_PATHS[ name ]
}

