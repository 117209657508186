import React from 'react'
import { motion } from 'framer-motion'
import Box from '@mui/system/Box'
import useTheme from '@mui/material/styles/useTheme';

const styleSxAllListsWrap = ({ listMaxHeight, classNameWrap }) => ({
    "&.allCMWrap": {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mx: 2,
        my: 0,
        padding: 0,

        height: listMaxHeight,
        [ `& .${classNameWrap}` ]: {
            display: "flex",
        },
        "& .wrapItems": {
            position: "absolute",
        },
    },
})

export const varWrapCircles = (notInView) => ({
    animate: notInView ? { transition: { duration: 0 } } : { transition: { staggerChildren: 0.05 } }
})

const CirclesMotionListX4 = ({ listMaxHeight, arrCircles, classNameWrap, handleTap, controlsWrap, styleWrapAll = {}, notInView }) => {
    const listMaxHalfHeight = Math.round(listMaxHeight / 2)
    const items = React.useMemo(() => CirclesAllPos({ arrCircles, handleTap, classNameWrap, listMaxHalfHeight, notInView }), [ arrCircles, handleTap, classNameWrap, listMaxHalfHeight, notInView ])

    return (
        <Box
            sx={styleSxAllListsWrap({ listMaxHeight, classNameWrap })}
            component={motion.div}
            variants={varWrapCircles(notInView)}
            initial="initial"
            animate={controlsWrap}
            className="allCMWrap"
            style={styleWrapAll}
            key="CirclesMotionListX4"
        >
            {items}
        </Box>
    )
}

export default CirclesMotionListX4



const varWrapIcon = ({ notInView, position, listWidth, listHeight }) => {
    let xTo = 0
    const xFrom = 0
    let yTo = 0
    const yFrom = 0
    switch (position) {
        case "left":
            xTo = 35 // listWidth - 35
            break;
        case "right":
            xTo = -35 //-listWidth + 35
            break;

        case "top":
            yTo = listHeight
            break;
        case "bottom":
            yTo = -listHeight
            break;

        default:
            break;
    }

    return {
        initial: notInView ? { y: yTo, x: xTo, rotateZ: 0, } : {
            y: yFrom,
            x: xFrom,
            rotateZ: 0,
        },
        animate: notInView ? { y: yTo, x: xTo, rotateZ: 0, transition: { duration: 0 } } : {
            y: [ null, yTo, yFrom ],
            x: [ null, xTo, xFrom ],
            rotateZ: 360,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 3,
                duration: 1.5,
            }
        },
        exit: {
            y: yFrom,
            x: xFrom,
            rotateZ: 0,
            transition: {
                when: "afterChildren",
            }
        },
        whileHover: {},
        whileTap: {},
    }
}

const CMPosList = ({ children, options }) => {
    const { notInView, clickPosComp, handleTap, position,
        classNameWrap = "wrap-pos",
        classNamePos, stylePos, stylePosWrapItems,
        listHeight,
        listWidth,
        x2Min,
        polygonPointsX2Y2Build,
        y2StartShift,
    } = options
    const theme = useTheme()

    const styleSxPosList = ({ classNamePos, stylePos, position, stylePosWrapItems, listHeight, listWidth }) => ({
        [ `&.${classNamePos}.wrapCMList` ]: {
            position: "absolute",
            ...stylePos,
            transformStyle: "preserve-3d",
            perspective: "1000px",
            "& .wrapIcon": {
                position: "absolute",
                [ position ]: "40px",
                display: "block",
                zIndex: 2,
                cursor: "pointer",
                "& svg": {
                    display: "block",
                    stroke: "red",
                    fill: "blue",
                }
            },
            [ `& .wrapItems.${position}` ]: stylePosWrapItems,
            "& .wrapSvg": {
                position: "absolute",
                display: "block",
                top: [ "left", "right" ].includes(position) ? stylePosWrapItems.top : "unset",
                "& svg": {
                    display: "block",
                    stroke: "red",
                    fill: "none",
                }
            },
        },
    })

    return (
        <Box
            sx={styleSxPosList({ classNamePos, stylePos, stylePosWrapItems, position })}
            className={`${classNameWrap} ${classNamePos} wrapCMList`}
            component={motion.div}
            style={{
                height: `${listHeight}px`,
                width: `${listWidth}px`,
            }}
            key={`CMPosList-Box-${position}`}
        >
            <motion.div
                className="wrapIcon"
                variants={varWrapIcon({ notInView, position, listWidth, listHeight })}
                onTap={(e, info) => {
                    handleTap(e, info, { position })
                }}
                style={{
                    rotateZ: 0,
                    x: 0,
                    y: 0,
                }}
                key={`CMPosList-varWrapIcon-${position}`}
            >
                {clickPosComp}
            </motion.div>
            <Box className={`wrapItems ${classNamePos}`}>
                {children}
            </Box>
            {[ "left", "right" ].includes(position) && (
                <motion.div
                    className="wrapSvg"
                    key={`CMPosList-wrapSvg-${position}`}
                >
                    <svg
                        viewBox={`${x2Min} ${y2StartShift} ${listWidth} ${listHeight}`}
                        height={listHeight}
                        width={listWidth}
                    >
                        <polygon
                            points={polygonPointsX2Y2Build}
                            style={{
                                fill: theme.palette.primary2.main,
                                stroke: "none",
                            }}
                        />
                    </svg>
                </motion.div>
            )}
        </Box>
    )
}

const CirclesOnePos = (props) => {
    const {
        v,
        notInView,
        handleTap,
        classNameWrap,
        listMaxHalfHeight,
    } = props;
    const {
        position,
        clickPosComp,
        circleItems,
        listHeight,
        listWidth,
        x2Min,
        classNamePos,
        stylePos,
        stylePosWrapItems,
        itemWidth,
        distancePixFromCenter,
        arrPosXY,
        arrPosX2Y2,
        polylinePointsLeftXY,
        polylinePointsLeftX2Y2,
        polylinePointsRightXY,
        polylinePointsRightX2Y2,
        polygonPointsX2Y2,
        polygonPointsX2Y2Build,
        y2StartShift,
        polylinePointsLeftX2Y2Plus0PlusLast,
        polylinePointsRightX2Y2Plus0PlusLast,
        polylinePointsRightX2Y2Plus0PlusLastRev,
    } = v;

    const options = {
        notInView,
        clickPosComp,
        handleTap,
        position,
        classNameWrap,
        classNamePos,
        stylePos,
        stylePosWrapItems,
        listMaxHalfHeight,
        distancePixFromCenter,
        listHeight,
        listWidth,
        x2Min,
        itemWidth,
        arrPosXY,
        arrPosX2Y2,
        polylinePointsLeftXY,
        polylinePointsLeftX2Y2,
        polylinePointsRightXY,
        polylinePointsRightX2Y2,
        polygonPointsX2Y2,
        polygonPointsX2Y2Build,
        y2StartShift,
        polylinePointsLeftX2Y2Plus0PlusLast,
        polylinePointsRightX2Y2Plus0PlusLast,
        polylinePointsRightX2Y2Plus0PlusLastRev,
    };
    // console.log("CirclesOnePos classNameWrap", classNameWrap)
    return (
        <CMPosList options={options} key={`cmpos-${position}`}>
            {circleItems}
        </CMPosList>
    );
};

const CirclesAllPos = ({ arrCircles, handleTap, classNameWrap, listMaxHalfHeight, notInView }) => (
    <>
        {arrCircles.map((v, i) => (
            <CirclesOnePos
                v={v}
                notInView={notInView}
                handleTap={handleTap}
                classNameWrap={classNameWrap}
                listMaxHalfHeight={listMaxHalfHeight}
                key={`onePos-${i}`} />
        ))}
    </>
);

