import React, { useContext } from "react";
import { StaticQuery, graphql } from "gatsby"
import useTheme from "@mui/material/styles/useTheme"
import { LightgalleryItem } from "react-lightgallery";
import MyLightGalleryProviderLazy from "../../_shared/myLightGallery/MyLightGalleryProviderLazy";
import MyReactMultiCarousel2Lazy from "../../_shared/myReactMultiCarousel/MyReactMultiCarousel2Lazy"
import { NotInViewContext } from "../../_shared/AnimateScrollIntoViewport";

const ExcelPraceLayout = (props) => {

  const { notInView, isCountedTwice } = useContext(NotInViewContext)
  const theme = useTheme()
  const themeBreakpointsValues = Object.values(theme.breakpoints.values)

  const responsive = {
    xl: {
      breakpoint: {
        max: 8000,
        min: themeBreakpointsValues[ 4 ] + 1,
      },
      items: 5,
    },
    lg: {
      breakpoint: {
        max: themeBreakpointsValues[ 4 ],
        min: themeBreakpointsValues[ 3 ] + 1,
      },
      items: 3,
    },
    md: {
      breakpoint: {
        max: themeBreakpointsValues[ 3 ],
        min: themeBreakpointsValues[ 2 ] + 1,
      },
      items: 4,
    },
    sm: {
      breakpoint: {
        max: themeBreakpointsValues[ 2 ],
        min: themeBreakpointsValues[ 1 ] + 1,
      },
      items: 2,
    },
    xs: {
      breakpoint: {
        max: themeBreakpointsValues[ 1 ],
        min: 0,
      },
      items: 1,
    },
  }

  const { data, deviceType } = props
  // const autoPlay = deviceType !== "xs" ? true : false
  const autoPlay = notInView ? false : isCountedTwice ? true : false
  const infinite = deviceType !== "xs" ? true : true
  const multiCarouselOptions = { responsive, deviceType, autoPlay, infinite }

  const group = "excelPrace"

  const excelPrace = data.excelSliderImgs.nodes
  const arrPraceSlidesOrig = excelPrace.map((imgNode, i) => {

    const { src } = imgNode.childImageSharp.original
    // const thumb = imgNode.childImageSharp.thumb100.src
    const thumb = imgNode.childImageSharp.thumb100.images.fallback.src
    const key = imgNode.base

    const srcSlider = imgNode.childImageSharp.imgSlider.images.fallback.src
    const alt = imgNode.base

    return (
      <LightgalleryItem
        group={group}
        src={src}
        thumb={thumb}
        key={key}
        subHtml={`<div>${imgNode.base}</div>`}
      >
        <img
          src={srcSlider}
          alt={alt}
        />
      </LightgalleryItem>

    )
  });


  return (
    <section
      style={{
        borderStyle: "ridge",
        borderWidth: "1px",
      }}
    >
      <MyLightGalleryProviderLazy galleryClassName="excel">
        <MyReactMultiCarousel2Lazy
          multiCarouselOptions={multiCarouselOptions}
          mySlider="Excel práce"
        >
          {arrPraceSlidesOrig}
        </MyReactMultiCarousel2Lazy>
      </MyLightGalleryProviderLazy>
    </section>
  );
};



function ExcelPraceReactMultiCarouselLightGallery(props) {
  return (
    <StaticQuery
      query={graphql`
      {
        excelSliderImgs: allFile(
            filter: {
              extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
              sourceInstanceName: {eq: "images"}, 
              relativeDirectory: {eq: "galleries/excel-slider-apps"}
          }
        ) {
          nodes {
            id
            base
            childImageSharp {
              id
              original {
                src
              }
              thumb100:gatsbyImageData(height: 100)
              imgSlider: gatsbyImageData(height: 182)
            }
          }
        }
      }
    `}
      render={data => <ExcelPraceLayout data={data} {...props} />}
    />
  )
}


export default React.memo(ExcelPraceReactMultiCarouselLightGallery)

