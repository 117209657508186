import { getWaveConfig } from '../../utils/getWave';

// coefP is factor for applying portion of padding

export const myWavesOptionsCCh = {
    waves: [
        getWaveConfig("waves1phase05step10af5part05"),
        getWaveConfig("waves20phase05step10af10part05"),
    ],
    styles: [ { fill: "red" }, {} ]
};
export const myFrameWaves = {
    top: {
        waves: [
            { config: getWaveConfig("waves1phase05step10af5part05"), coefX: 1, coefP: 1 },
            { config: getWaveConfig("waves20phase05step10af10part05"), coefX: 1, coefP: 1 },
        ],
        styles: [ { fill: "red" }, {} ]
    },
    right: {
        waves: [
            { config: getWaveConfig("waves1phase05step2af5part1"), coefX: 1, coefP: 1 },
        ]
    },
    bottom: {
        waves: [
            { config: getWaveConfig("waves10phase05step20af10part05"), coefX: 1, coefP: 1 },
            { config: getWaveConfig("waves2phase05step10af10part05"), coefX: 1, coefP: 1 },
        ]
    },
    left: {
        waves: [
            { config: getWaveConfig("waves1phase05step10af10part05"), coefX: 1, coefP: 1 },
            { config: getWaveConfig("waves2phase05step10af10part05"), coefX: 1, coefP: 1 },
        ]
    }
};
const myFrameWaves2 = {
    top: {
        waves: [
            getWaveConfig("waves20phase0step50af5part1"),
        ],
        styles: [ { fill: "red" }, {} ]
    },
    right: {
        waves: [
            getWaveConfig("waves1phase1step20af5part1"),
        ]
    },
    bottom: {
        waves: [
            getWaveConfig("waves1phase05step50af10part1"),
        ]
    },
    left: {
        waves: [
            getWaveConfig("waves1phase05step10af10part1"),
        ]
    }
};

const optionsWavedFrameUnderlined = {
    isMultiplePaths: true,
    optionsAnimation: {},
    configPointPair: "startMaxEndMax",
    configWaves: myFrameWaves,
    framePaddings: { paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10 },
    framesWanted: { bottom: { duration: 2 } }
}