// https://freshysites.com/web-design-development/most-popular-websites/

const popularWebsites = [
    { name: "Google", link: "https://www.google.com/", description: "There’s no doubt that Google is the most popular search engine, but this year it remains at the top spot as the most popular website on the internet. Over 40k searches are reported each second on Google — which translates to over 1.2 trillion searches per year! No matter what someone wants to know, Google is the go-to. Not only that, but Google also now serves as the hub for a plethora of other web services including Google Docs, Google Calendar, Google Drive and many more. source https://freshysites.com/" },
    { name: "YouTube", link: "https://www.youtube.com/", description: "This website jumped up the list two spots this year as it’s impressive stats continue to climb. Almost 5 billion videos are watched on YouTube every single day. YouTube gets over 30 million visitors per day. Every minute, over 300 minutes of video is uploaded to YouTube. We expect to see YouTube in the top three for a long time to come. source https://freshysites.com/" },
    { name: "Amazon", link: "https://www.amazon.com/", description: "Down one spot from last year, Amazon is still one of the top three most popular website on the internet and dominates the online shopping world. From Amazon.com, site visitors can purchase their favorite books, clothing, home goods, groceries and more! And, if they sign up for the Prime membership, those purchases can be delivered to their front door within just a day or two — for free! With such a wide variety of goods and such amazing service, it’s no wonder Amazon is one of the leaders again this year. source https://freshysites.com/" },
    { name: "Facebook", link: "https://www.facebook.com/", description: "Facebook is the leading social media platform across the world. Its innovative design has changed the way people communicate and live their lives. Every month, the website boasts an impressive 2.37 billion users. 1.66 billion people on average log onto Facebook daily and are considered daily active users (Facebook DAU) as of January 2020, which represents a 9% increase year over year. source https://freshysites.com/" },
    { name: "Yahoo", link: "https://www.yahoo.com/", description: "Still going strong is Yahoo.com. Yahoo was the leading search engine and news source for a very long time and still can hold it’s own as one of the most popular websites online. The model has changed a bit however as it really has found more traction for news, articles, finance, etc. vs purely a search engine. source https://freshysites.com/" },
    { name: "reddit", link: "https://www.reddit.com/", description: "reddit.com claims that it is the “Front Page of the Internet.” Created as a site for social sharing and social interaction, reddit.com has become extremely popular among internet users of all age groups and is our single biggest mover since last year, up 11 spots! On reddit users can upload their favorite articles, memes and graphics for the viewing pleasure of other users. Each post is then voted on and ranked by its popularity – with hopes of reaching the front page and gaining views. source https://freshysites.com/" },
    { name: "Wikipedia", link: "https://en.wikipedia.org/", description: "Wikipedia.org is an online encyclopedia that houses articles on nearly every historical event, location and topic you can think of. Many people turn to Wikipedia for answers to basic questions and beginner research. Wikipedia is building at a rate of over 1.8 edits per second, performed by editors from all over the world. Every day, the site gets over 13.89 million visits, making it one of the most popular websites on the Internet. source https://freshysites.com/" },
    { name: "eBay", link: "https://www.ebay.com/", description: "In 1995, Pierre Omidyar, an online hobbyist, launched AuctionWeb as a section on his personal website. The site’s first successful transaction was a broken laser pointer that sold for $14.83. By 1997, AuctionWeb had changed its name to eBay, and today, the e-commerce corporation has offices in 30 countries. eBay boasts 138 million accounts, and nearly 35 percent of U.S. mobile users have downloaded the company’s app onto their phones. eBay is headquartered in San Jose, California, and claimed over $10 billion in revenue for its last reporting round. source https://freshysites.com/" },
    { name: "Bing", link: "https://www.bing.com/", description: "Microsoft’s Bing may not be the most used search engine in the world, but it’s climbing the charts. First debuted by Steve Ballmer at the 2009 All Things Digital conference, as of today, Bing is the third most used search engine in the world, behind only Google and China’s Baidu. About 33 percent of Americans fire up Bing for their online searches. Additionally, Yahoo! uses the Bing engine for several platforms and products. The United Kingdom and Taiwan are the company’s second and third largest markets behind the United States." },
    { name: "Netflix", link: "https://www.netflix.com/", description: "Eager to emulate Amazon.com’s model, Reed Hastings and Marc Randolph conceived of Netflix in 1997. What started as a mail-order DVD service is now one of the top subscription streaming services on the planet. These days, the platform boasts 148 million users worldwide, with only China, Syria, North Korea, and Crimea denied access. International growth, however, has outpaced domestic gains. Since 2015, the number of new sign-ups outside of the United States has increased fourfold. Stateside account creation, however, has grown at a rate of less than 50 percent. Regardless, Netflix is an online powerhouse that doesn’t show signs of slowing down anytime soon. source https://freshysites.com/" },
    { name: "Office", link: "https://www.office.com/", metaDescr: "Collaborate for free with online versions of Microsoft Word, PowerPoint, Excel, and OneNote. Save documents, spreadsheets, and presentations online, in OneDrive. Share them with others and work together at the same time.", description: "A lightweight, cloud-based suite of Microsoft’s Office products, including Word, Excel, PowerPoint, and OneNote, Office.com launched in 2008 and has undergone several iterations and name changes. Today, it’s the 11th most popular website on our list. Its popularity is linked to its prevalence in offices across the world in addition to the software’s popularity among freelancers. Office.com also accommodates Outlook, People, Calendar, and OneDrive. source https://freshysites.com/" },
    { name: "Instructure", link: "https://www.instructure.com/", description: "Instructure may not be a household name, but this educational technology company ranks as the 12th most accessed online platform for 2020. Based in Salt Lake City, Utah, and founded by two BYU graduate students, Instructure is the publisher and developer of a web-based learning management program called Canvas. It also maintains the Canvas Network, a massive open online course platform. source https://freshysites.com/" },
    { name: "Shopify", link: "https://www.shopify.com/", description: "Shopify allows businesses and entrepreneurs to build digital storefronts using a WYSIWYG interface — no coding knowledge required. The Canadian company offers several helpful features, including marketing reporting functionality, payment processing, shipping services, and various customer engagement tools. What started as a small startup in 2004 is now a multinational corporation that made over $1.5 billion in 2019. Over one million sellers and businesses rely on the platform to run their operations, and the site has processed over $100 billion in sales throughout its lifetime, landing Shopify in the 13th spot on our “most popular websites” list." },
    { name: "Twitch", link: "https://www.twitch.tv/", description: "The 14th most popular website is Twitch. A subsidiary of Amazon.com, Twitch clicked onto the scene in 2011. A live streaming site that is popular among video game enthusiasts, Twitch now broadcasts esports events and tournaments in addition to the personal streams of account holders. In recent years, the platform has attracted all types of content producers from independent news shows to movie review channels. By 2018, 2.2 million broadcasters had set up accounts on Twitch, and 15 million users access the platform daily. To date, the website partners with over 27,000 third-party channels. The overwhelming majority — 81.5 percent — of Twitch users are male, and 55 percent fall into the coveted advertising demographic of 18- to 34-year-olds." },
    { name: "CNN", link: "https://www.cnn.com/", description: "Mogul Ted Turner launched the Cable News Network — aka CNN — in 1980. At the time, it was the first U.S. channel to offer 24-hour news coverage. As of 2018, 90.1 million American homes subscribe to the channel through their cable packages. CNN launched online in 1995, and it now ranks as one of the most popular news websites in the world. The platform is a mix of professional reporting, blogs, and live streaming. As of 2016, CNN maintains four versions of its website: an American version, an Arabic version, a Spanish version, and an international version. According to CNN’s press room statistics, its digital platform “is the #1 online news destination across all platforms, with more unique visitors and video starts than any other competitor.”" },
    { name: "Linkedin", link: "https://www.linkedin.com/", description: "One of the most active professional networking sites on the web, LinkedIn, a subsidiary of Microsoft, earns the 16th spot on FreshySites’ 20 most popular sites list. People use LinkedIn to post resumes and articles. Businesses use it to post jobs and as an HR recruiting tool. As of 2019, LinkedIn has about 630 million registered users across 150 countries. After the United States, the biggest markets for LinkedIn are India, China, Brazil, and the United Kingdom." },
    { name: "Instagram", link: "https://www.instagram.com/", description: "One of the most popular social media sites on the planet, the Facebook subsidiary Instagram — aka IG or Insta — allows users to post and comment on photos and videos. The site launched in 2010 and gained one million users within two months. Today, about one billion people worldwide maintain active accounts that they access at least once a month. More impressively, 500 million people use Instagram Stories every single day. Instagram was the fourth most downloaded app from 2010-2019, and it is available in 32 languages." },
    { name: "Intuit", link: "https://www.intuit.com/", description: "An online platform that caters to small businesses, Intuit offers accounting, financial, and tax preparation software. Intuit’s two main platforms are QuickBooks and TurboTax. It also offers popular corporate products like ProConnect Tax Online and several payroll products. Fortune ranks Intuit number 11 on its 100 Best Companies to Work For list, and in 2019, the company earned $6.8 billion in revenue. Since many Intuit clients rely on the platform for their daily business operations, it enjoys high traffic volumes and lands at number 18 on our list." },
    { name: "NY Times", link: "https://www.nytimes.com/", description: "Though frequently lambasted as “failing,” the New York Times has turned its fortunes around over the past several years. Currently, the news media company boasts 4.5 million subscribers, with more than 3.5 million users paying for one or more of the publisher’s online products. Hailed by many as a so-called “paper of record,” the majority of Times subscribers check the site multiple times a day, making it a high-traffic destination. The stability of the company, however, is dependent on digital growth, and it hopes to reach 10 million digital subscribers over the next five years. source https://freshysites.com/" },
    { name: "Chase", link: "https://www.chase.com/", description: "One of the “Big Four” banks in the United States, Chase manages nearly $3 trillion in assets and runs over 5,000 branches across the country. According to the company, almost half of American households have a Chase banking account, and the majority of those customers make use of the company’s digital portal. Chase’s robust online banking service lets account holders handle a myriad of tasks from paying bills to depositing checks. Chase’s considerable daily online traffic has earned it the 20th spot on our 20 most popular websites list. source https://freshysites.com/" },
]



const popularWebsitesSlovensko = [
    { name: "sme.sk", link: "https://www.sme.sk/", title: "SME.sk | Najčítanejšie správy na Slovensku", description: "Rýchle a dôveryhodné správy zo Slovenska, sveta i Vášho regiónu. Prihlásenie do Post.sk.", server: "cloudflare" },
    { name: "aktuality.sk", link: "https://www.aktuality.sk/", title: "Aktuálne správy a spravodajstvo na Slovensku a vo svete | Aktuality.sk", description: "Denne aktualizované spravodajstvo z domova i zo sveta Správy z oblasti ekonomiky, kultúry a spoločenského života Predpoveď počasia, horoskopy, TV program a kurzový lístok" },
    { name: "google.sk", link: "https://www.google.sk/", title: "Google", description: "", server: "gws" },
    { name: "bazos.sk", link: "https://www.bazos.sk/", title: "Bazos.sk - Inzercia, bazár", description: "Bezplatná inzercia, online bazár kúpte si nový byt alebo predaj staré auto, toto všetko hravo zvládne náš Bazoš Vaše inzeráty", server: "nginx" },
    { name: "edupage.org", link: "https://www.edupage.org/?lang=sk", title: "aSc EduPage", description: "Ucelený školský systém. EduPage je školský systém plne integrovaný s našim svetovo známym rozvrhovým systémom. Dobrý rozvrh je kľúčový pre väčšinu školských úloh – od zadávania učiva, sledovania dochádzky, objednávania miestností, prideľovania domácich úloh až po e-learning. Keďže rozvrh sa mení denne, jeho hladká integrácia s ostatnými procesmi v škole je nevyhnutná. EduPage poskytuje veľa funkcií. Môžete si vybrať len niektoré, ale krásu synergie pocítite až keď začnete používať viaceré, alebo ideálne všetky.", server: "Apache" },
    { name: "topky.sk", link: "https://www.topky.sk/", title: "Topky.sk | Online spravodajstvo", description: "Politika, ekonomika, šport, novinky o celebritách a prominentoch len zaujímavé správy Počasie, TV program, horoskopy, hry a zábava", server: "Apache" },
    { name: "zoznam.sk", link: "https://www.zoznam.sk/", title: "Zoznam slovenského internetu", description: "Prehľadný Zoznam odkazov slovenského internetu aktuálne informácie, spravodajstvo, firmy, mapy, skrátka všetko čo potrebujete nájsť", server: "Apache" },
    { name: "pravda.sk", link: "https://www.pravda.sk/", title: "Pravda", description: "www pravda sk správy, ktorým môžete veriť Prehľad aktuálnych správ a udalostí online Z domova, zo zahraničia, politika, šport, Slovensko, Česko, Európska únia", server: "nginx" },
    { name: "cas.sk", link: "https://www.cas.sk/", title: "Nový Čas | Najčítanejší online denník na Slovensku", description: "Aktuálne správy z domova aj zo sveta, škandály, krimi aj šport na jednom mieste Najčerstvejšie informácie si prečítate online na titulke Čas sk", server: "Apache" },
    { name: "dennikn.sk", link: "https://dennikn.sk/", title: "Denník N - Nezávislý denník", description: "Nezávislý denník", server: "" },
    { name: "azet.sk", link: "https://www.azet.sk/", title: "Azet.sk - vaše správy a informácie na jednom mieste", description: "Dajte domov Vášmu internetu S portálom Azet sk nájdete informácie na jednom mieste Ponúka služby katalógu firiem, bazáru, aktuality, šport, počasie, kalendár a iné", server: "" },
    { name: "heureka.sk", link: "https://www.heureka.sk/", title: "Heureka.sk — Porovnávajte a nakupujte s prehľadom", description: "Heureka sk je nákupný radca, kde vyberiete ten najlepší produkt, porovnáte ceny z tisícov internetových obchodov a pohodlne nakúpite", server: "nginx" },
    { name: "alza.sk", link: "https://www.alza.sk/", title: "Alza.sk - najväčší obchod s počítačmi a elektronikou | Alza.sk", description: "Najväčší obchod s počítačmi a elektronikou ✓ 10 predajní, 450 AlzaBoxov a veľký showroom v Bratislave ✓ PC zostavy, notebooky, mobily, monitory, televízor", server: "nginx" },
    { name: "pluska.sk", link: "https://www1.pluska.sk/", title: "Plus JEDEN DEŇ", description: "Plus 1 deň, správy z domova, správy zo zahraničia, správy zo showbiznisu, rozhovory", server: "cloudflare" },
    { name: "hnonline.sk", link: "https://hnonline.sk/", title: "HNonline.sk - Správy z politiky, ekonomiky a financií", description: "Najaktuálnejšie spravodajstvo z domova, z ekonomiky a financií A k tomu najkvalitnejšie lifestyle správy na slovenskom internete", server: "" },
    { name: "shmu.sk", link: "https://www.shmu.sk/", title: "SHMÚ.sk - Meteo / Počasie / Hydrológia / Kvalita ovzdušia", description: "Slovenský hydrometeorologický ústav (SHMÚ) je špecializovaná organizácia vykonávajúca hydrologickú a meteorologickú službu na národnej aj medzinárodnej úrovni. Činnosť SHMÚ sa riadi najmä zákonom 201/2009 Z.z. o štátnej hydrologickej službe a štátnej meteorologickej službe. Slovenský hydrometeorologický ústav zriadilo 1. januára 1969 Ministerstvo lesného a vodného hospodárstva. SHMÚ pokračoval v meteorologickej a hydrologickej službe, ktoré pôsobili na území Slovenska približne od polovice 19. storočia. V súčasnosti je SHMÚ organizáciou v pôsobnosti Ministerstva životného prostredia SR.  Spôsob financovania SHMÚ určuje jeho zaradenie medzi príspevkové organizácie.    SHMÚ monitoruje kvantitatívne a kvalitatívne parametre stavu ovzdušia a vôd na území Slovenskej republiky, zhromažďuje, overuje, hodnotí, archivuje a interpretuje údaje a informácie o stave a režime ovzdušia a vôd, popisuje deje v atmosfére a hydrosfére, tvorí a vydáva meteorologické a hydrologické predpovede, výstrahy a informácie. Údaje, informácie a výsledky štúdií poskytuje užívateľom a verejnosti.", server: "" },
    { name: "kukaj.to", link: "https://kukaj.io/", title: "Kukaj.to - Raj online filmov a seriálov", description: "Raj všetkých online filmov a seriálov úplne zadarmo a navyše bez otravných reklám Web kukaj to je plný zaujímavých a predovšetkým originálnych funkcií, ktoré ocenia všetci fanúšikovia filmov a seriálov Prehrávanie na webe je možné ihneď bez nutnosti registrácie", server: "cloudflare" },
    { name: "markiza.sk", link: "https://www.markiza.sk/", title: "Televízia Markíza", description: "", server: "nginx" },
    { name: "gov.sk", link: "https://www.slovensko.sk/", title: "Titulná stránka -... - UPVS", description: "Ústredný portál verejnej správy", server: "" },
    { name: "mall.sk", link: "https://www.mall.sk/", title: "MALL.SK - domáce spotrebiče, elektronika, notebooky, televízory, outdoor, hobby a záhrada, hračky, chovateľské potreby", description: "Spoľahlivý obchod MALL SK s najširšou ponukou na sklade z kategórií domáce spotrebiče, elektronika, notebooky, šport, dom a záhrada, televízory Výhodné ceny, splátky, rýchle dodanie", server: "cloudflare" },
    { name: "centrum.sk", link: "https://www.centrum.sk/", title: "Centrum.sk", description: "Aktuálne ekonomické spravodajstvo, komentáre, blogy Všetko čo potrebujete pre orientáciu vo svete financií a biznisu", server: "cloudflare" },
    { name: "startitup.sk", link: "https://www.startitup.sk/", title: "Startitup.sk - Biznis, eko a gastro portál, ktorý posúva Slovensko vpred", description: "Startitup sk Portál o biznise, technológiách a štartovacia čiara pre množstvo mladých ľudí", server: "cloudflare" },
    { name: "modrykonik.sk", link: "https://www.modrykonik.sk/", title: "Modrý koník - Všetko pre mamičky a budúce mamičky", description: "Modrý koník stránka pre mamičky a budúce mamičky, bazár na detské oblečenie, hračky, autosedačky, kočíky", server: "nginx" },
    { name: "dobrenoviny.sk", link: "https://www.dobrenoviny.sk/", title: "Dobré noviny - najviac pozitívnych správ", description: "Dobré noviny najviac pozitívnych správ, aktuálne správy", server: "nginx" },
    { name: "lidl.sk", link: "https://www.lidl.sk/", title: "Lidl.sk | Správna voľba", description: "Na Lidl sk nájdete aktuálnu ponuku v predajniach, ako aj tisícky nových produktov trvalo v online shope Jedinečné produkty za Lidl ceny Doprava zadarmo nad 50 € Každý týždeň nové produkty Vrátenie zdarma", server: "myracloud" },
    { name: "flashscore.sk", link: "https://www.flashscore.sk/", title: "FlashScore.sk: futbal - live výsledky a futbal livescore", description: "Live výsledky, aktuálne livescore futbal online Livescore na FlashScore sk poskytuje priebežné výsledky vo futbale z viac ako 1000 futbalových líg Live skóre, výsledky, tabuľky, zostavy a detaily zápasov", server: "nginx" },
    { name: "slsp.sk", link: "https://www.slsp.sk/", title: "Osobné financie – Slovenská sporiteľňa", description: "Slovenská sporiteľňa je najväčšou komerčnou bankou na Slovensku Ponúkame najmodernejšie služby vo finančnom svete vrátane najobľúbenejšieho mobilného bankovania George", server: "Apache" },
    // { name: "", link: "", title: "", description: "", server: "" },
]

const cssWebsites = [
    { name: "ModernCSS.dev", link: "https://moderncss.dev/", title: "Modern CSS Solutions for Old CSS Problem", description: "A series examining modern CSS solutions to problems Stephanie Eckles 5t3ph , a seasoned frontend developer, has been solving for 14 years", server: "Netlify" },
    { name: "SmolCSS", link: "https://smolcss.dev/", title: "Minimal snippets for modern CSS layouts and components", description: "Minimal snippets for modern CSS layouts and components, created by Stephanie Eckles of ModernCSS dev", server: "Netlify" },
    { name: "CSS Grid", link: "https://cssgrid.io/", title: "CSS Grid", description: "CSS Grid — Learn all about CSS Grid with Wes Bos in this free video series!", server: "cloudflare" },
    { name: "Kevin Powell's articles", link: "https://www.kevinpowell.co/articles/", title: "Kevin Powell's articles", description: "My name is Kevin and I teach people how to make the web and how to make it look good while they are at it", server: "Netlify" },
    { name: "CSS-TRICKS", link: "https://css-tricks.com/", title: "CSS-Tricks - Tips, Tricks, and Techniques on using Cascading Style Sheets.", description: "Daily articles about CSS, HTML, JavaScript, and all things related to web design and development", server: "cloudflare" },
    { name: "DevTools Css Tips", link: "https://devtoolstips.org/tag/css/", title: "DevTools Css Tips", description: "DevTools Css Tips", server: "GitHub.com" },
    { name: "w3schools CSS Tutorial", link: "https://www.w3schools.com/css/default.asp", title: "w3schools CSS Tutorial", description: "Well organized and easy to understand Web building tutorials with lots of examples of how to use HTML, CSS", server: "" },
    { name: "Chromium Blog", link: "https://blog.chromium.org/", title: "Chromium Blog", description: "Chromium Blog", server: "GSE", rss: "http://blog.chromium.org/atom.xml" },
    { name: "CSS — Smashing Magazine", link: "https://www.smashingmagazine.com/category/css/", title: "Category CSS — Smashing Magazine", description: "Articles all around CSS, JavaScript, front end, UX and design For designers and web developers", server: "Netlify" },
    { name: "CSS Buttons IO", link: "https://cssbuttons.io/", title: "CSS Buttons, checkboxes, and more! Copy paste CSS & HTML", description: "Choose from numerous UI components and get the CSS for the ones you like Everything is open source and free to use", server: "LiteSpeed" },
    { name: "web.dev Learn CSS", link: "https://web.dev/learn/css/", title: "Learn CSS", description: "An evergreen CSS course and reference to level up your web styling expertise", server: "" },
    { name: "Chrome DevTools - Chrome Developers", link: "https://developer.chrome.com/docs/devtools/", title: "Chrome DevTools - Chrome Developers", description: "Chrome DevTools is a set of web developer tools built directly into the Google Chrome browser", server: "Google Frontend" },
    { name: "Josh Comeau's CSS articles", link: "https://www.joshwcomeau.com/tutorials/css/", title: "", description: "Friendly tutorials for developers Focus on React, CSS, Animation, and more!", server: "" },
    { name: "Google Developers - Learn CSS", link: "https://developers.google.com/learn?text=css", title: "Google Developers - Learn CSS", description: "Google Developers - Learn CSS", server: "Google Frontend" },
    { name: "The most insightful stories about CSS - Medium", link: "https://medium.com/tag/css", title: "The most insightful stories about CSS - Medium", description: "Read stories about CSS on Medium Discover smart, unique perspectives on CSS and the topics that matter most to you", server: "cloudflare" },
    { name: "CSS documentation — DevDocs", link: "https://devdocs.io/css/", title: "CSS documentation — DevDocs", description: "Fast, offline, and free documentation browser for developers Search 100 docs in one web app HTML, CSS, JavaScript...", server: "" },
    { name: "Css - DEV Community", link: "https://dev.to/t/css", title: "Css - DEV Community", description: "css content on DEV Community", server: "Cowboy" },
    { name: "CSS Grid by Example", link: "https://gridbyexample.com/", title: "CSS Grid by Example", description: "CSS grid by Example", server: "Netlify" },
    { name: "Article Collections For Web Designers", link: "https://imjignesh.com/blog/", title: "Article Collections For Web Designers", description: "esources and in depth articles related to web development languages These articles cover un discussed topics of web design UI UX with interactive examples", server: "cloudflare" },
    { name: "HTML CSS Tutorial -demo2s", link: "https://www.demo2s.com/html-css/html-css.html", title: "HTML CSS Tutorial -demo2s", description: "HTML CSS Tutorial -demo2s", server: "AmazonS3" },
    { name: "HTML & CSS: Learn HTML, CSS, Bootstrap, Sass & More — SitePoint", link: "https://www.sitepoint.com/html-css/", title: "HTML & CSS: Learn HTML, CSS, Bootstrap, Sass & More — SitePoint", description: "Keep up to date and learn HTML CSS with the web s best resource for web developers and designers Sitepoint", server: "AmazonS3" },
    { name: "Codier – Front-end Coding Challenges", link: "https://codier.io/", title: "Codier – Front-end Coding Challenges", description: "Explore and attempt front end coding challenges on Codier", server: "Cowboy" },
    { name: "Learn to Code HTML & CSS - Shay Howe", link: "https://learn.shayhowe.com/html-css/", title: "Learn to Code HTML & CSS- Shay Howe", description: "Learn to Code HTML & CSS", server: "" },
    { name: "Learn to Code Advanced HTML & CSS- Shay Howe", link: "https://learn.shayhowe.com/advanced-html-css/", title: "Learn to Code Advanced HTML & CSS- Shay Howe", description: "Learn to Code Advanced HTML & CSS", server: "" },
    { name: "HTML & CSS Is Hard | A friendly web development tutorial - internetingishard", link: "https://www.internetingishard.com/html-and-css/", title: "HTML & CSS Is Hard | A friendly web development tutorial - internetingishard", description: "HTML & CSS Is Hard | A friendly web development tutorial", server: "" },
    { name: "CSS Vocabulary", link: "http://apps.workflower.fi/vocabs/css/en#at-keyword", title: "CSS Vocabulary", description: "CSS Vocabulary", server: "Apache/2.2.22 (Ubuntu)" },
    { name: "Learn to style HTML using CSS - Learn web development | MDN", link: "https://developer.mozilla.org/en-US/docs/Learn/CSS", title: "Learn to style HTML using CSS - Learn web development | MDN", description: "CSS Cascading Style Sheets is used to style and lay out web pages — for example, to alter the font, color, size, and spacing of your content, split it into multiple columns, or add animations and other decorative features This module provides a gentle beginning to your path towards CSS mastery with the basics of how it works, what the syntax looks like, and how you can start using it to add styling to HTML", server: "AmazonS3" },
    { name: "Flexbox Froggy - A game for learning CSS flexbox", link: "http://flexboxfroggy.com/", title: "Flexbox Froggy - A game for learning CSS flexbox", description: "Flexbox Froggy - A game for learning CSS flexbox", server: "cloudflare" },
    { name: "CSS Reference - A free visual guide to CSS", link: "https://cssreference.io/", title: "CSS Reference - A free visual guide to CSS", description: "CSS Reference is a free visual guide to CSS It features the most popular properties, and explains them with illustrated and animated examples", server: "cloudflare" },
    { name: "Learn CSS Grid - A Guide to Learning CSS Grid | Jonathan Suh", link: "https://learncssgrid.com/", title: "Learn CSS Grid - A Guide to Learning CSS Grid | Jonathan Suh", description: "A comprehensive guide to help you understand and learn CSS Grid Layout, by Jonathan Suh", server: "nginx" },
    { name: "CSS introduction - web4college", link: "https://www.web4college.com/css/", title: "CSS introduction - web4college", description: "HTML provides the basic layout of a website But CSS cascading style sheet styles the layout of a website The styling includes the designing of lists, tables, navigation bar, side bar etc", server: "cloudflare" },
    { name: "CSS - freeCodeCamp.org", link: "https://www.freecodecamp.org/news/tag/css/", title: "CSS - freeCodeCamp.org", description: "CSS - freeCodeCamp.org", server: "cloudflare" },
    { name: "Grid Garden - A game for learning CSS grid", link: "http://cssgridgarden.com/", title: "Grid Garden - A game for learning CSS grid", description: "Grid Garden - A game for learning CSS grid", server: "cloudflare" },
    { name: "Beginners CSS Tutorial For Web Designers - coderepublics", link: "https://www.coderepublics.com/CSS/", title: "Beginners CSS Tutorial For Web Designers - coderepublics", description: "CSS is used to control the style of a web page in a simple and easy way CSS is the acronym for Cascading Style Sheet CSS is used to design HTML tags and a widely used language on the web", server: "cloudflare" },
    { name: "Getting Started with CSS - Sabe.io", link: "https://sabe.io/classes/css", title: "Getting Started with CSS - Sabe.io", description: "CSS is what makes the web beautiful It describes how HTML should be displayed and how to layout elements Take this class and get familiar with CSS!", server: "cloudflare" },
    { name: "CSS3 Tutorial - An Ultimate Guide for Beginners - tutorialrepublic", link: "https://www.tutorialrepublic.com/css-tutorial/", title: "CSS3 Tutorial - An Ultimate Guide for Beginners - tutorialrepublic", description: "CSS Tutorials for Beginners Learn the fundamentals of the latest CSS3 style sheet language in simple and easy steps and create beautifully designed website", server: "Apache/2.4.41 (Ubuntu)" },
    { name: "CSS - GeeksforGeeks", link: "https://www.geeksforgeeks.org/css-introduction/?ref=lbp", title: "CSS - GeeksforGeeks", description: "A Computer Science portal for geeks It contains well written, well thought and well explained computer science and programming articles, quizzes and practice competitive programming company interview Questions", server: "nginx" },
    { name: "CSS Guidelines (2.2.5) – High-level advice and guidelines for writing sane, manageable, scalable CSS", link: "https://cssguidelin.es/", title: "High level advice and guidelines for writing sane, manageable, scalable CSS", description: "High level advice and guidelines for writing sane, manageable, scalable CSS", server: "cloudflare" },
    { name: "CSS Entries Archive - Codrops", link: "https://tympanus.net/codrops/css_reference/", title: "CSS Entries Archive - Codrops", description: "CSS Entries Archive - Codrops", server: "Apache" },
    { name: "Flexbox playground", link: "https://codepen.io/enxaneta/full/adLPwv", title: "Flexbox playground", description: "Play with Flexbox examples on CodePen", server: "cloudflare" },
]


export { popularWebsites, popularWebsitesSlovensko, cssWebsites }