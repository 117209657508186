import React from "react";
import Typography from '@mui/material/Typography';
import VideoTag from "../../_shared/videoTag"

const Dashboard = () => {
  return (
    <>
      <Typography variant="h4">
        Dashboard
      </Typography>
      <Typography sx={{ fontSize: [ "smaller", "small", ] }}>
        Dashboard - interaktívne reporty s grafmi ovládane priamo na Excel
        liste. Aj takýto je možno vytvoriť bez využitia VBA programovania (= bez makier = stačí .xlsx).
      </Typography>
      <VideoTag src="/sf/exc-dash/dash.mp4" title="App Dashboard" />
    </>
  );
};

export default Dashboard;
