import React, { Children, useState, useContext, useEffect, useCallback, useRef } from 'react';
import * as faia from "./animatingColumnsOrderingWithFlip.module.css";
import { NotInViewContext } from './AnimateScrollIntoViewport';
import { useInView } from "framer-motion";
import FlipAnimatedBetweenAnimationKeys from './FlipAnimatedBetweenAnimationKeys';
import AnimationButton from './AnimationButton';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

interface Props {
    children: React.ReactNode;
    transformSquare?: string;
    squareClass?: string;
    styleBlank?: React.CSSProperties;
}

const AnimatingChildrenColumnsOrderingWithFlip = ({ children, transformSquare = '', squareClass = "", styleBlank = {} }: Props) => {
    const { isCountedTwice } = useContext(NotInViewContext);
    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref);
    const arrayChildren = Children.toArray(children);
    const [animation, setAnimation] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [timeInterval, setTimeInterval] = useState(3);
    const [columnsOrder, setColumnsOrder] = useState<number[]>(() => arrayChildren.map((_v, i) => i).sort(() => Math.random() - 0.5));

    const refIntervalId = useRef<NodeJS.Timeout | number | null>(null);

    const clearAndSetInterval = useCallback(() => {
        if (refIntervalId.current) clearInterval(refIntervalId.current as NodeJS.Timeout);
        refIntervalId.current = setInterval(() => {
            setColumnsOrder(prevOrder => [...prevOrder].sort(() => Math.random() - 0.5));
        }, timeInterval * 1000);
    }, [timeInterval]);

    useEffect(() => {
        if (isInView && (isClicked || (isCountedTwice && animation))) {
            clearAndSetInterval();
        } else if (!isInView || !animation) {
            if (refIntervalId.current) clearInterval(refIntervalId.current as NodeJS.Timeout);
        }
        return () => {
            if (refIntervalId.current) clearInterval(refIntervalId.current as NodeJS.Timeout);
        };
    }, [isInView, isClicked, animation, isCountedTwice, clearAndSetInterval]);

    const handleToggleAnimationPlay = useCallback(() => {
        setIsClicked(prev => !prev);
        setAnimation(prev => !prev);
        if (!animation) clearAndSetInterval();
    }, [animation, clearAndSetInterval]);

    return (
        <div className={`${faia.faiaWrap} flip`} ref={ref}>
            <div className={faia.grid}>
                {columnsOrder.map((id, index) => (
                    <FlipAnimatedBetweenAnimationKeys duration={timeInterval * 1000}
                        transformSquare={transformSquare}
                        animationKey={columnsOrder}
                        key={id}>
                        <div className={`${faia.square} square ${squareClass}`}
                            style={transformSquare ? { transform: transformSquare } : {}}>
                            {arrayChildren[id]}
                        </div>
                    </FlipAnimatedBetweenAnimationKeys>
                ))}
            </div>
            <AnimationButton handleClickStartStopAnimation={handleToggleAnimationPlay}>
                {animation ? <StopCircleIcon /> : <PlayCircleOutlineIcon />}
            </AnimationButton>
        </div>
    );
}

export default AnimatingChildrenColumnsOrderingWithFlip;