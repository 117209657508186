import React from "react"
import Container from '@mui/material/Container';
import LocalServices from "./LocalServices"

const styleSxFoot = {
  my: 0,
  p: [ 0.5, 1, 2, 3 ],
  "& .footer": {
    overflow: "hidden",
  }
}
const Footer = () => {
  return (
    <Container maxWidth="false"
      sx={styleSxFoot}
    >
      <footer className="footer">
        <LocalServices />
      </footer>
    </Container>
  )
}

export default Footer

