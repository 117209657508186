import React, { lazy, Suspense } from 'react'
const ConnectChildrenWithWavesAnimation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'ConnectChildrenWithWavesAnimationLazy' */ './ConnectChildrenWithWavesAnimation'))

const ConnectChildrenWithWavesAnimationLazy = (props) => {
    return (
        <div>
            <Suspense
            // fallback={<div>Loading ConnectChildrenWithWavesAnimation ...</div>}
            >
                <ConnectChildrenWithWavesAnimation {...props} />
            </Suspense>
        </div>
    )
}

export default ConnectChildrenWithWavesAnimationLazy