import React from "react"
// https://github.com/joshwcomeau/blog/blob/master/src/utils/index.js

function useToggle(initialValue = false) {
  const [ value, setValue ] = React.useState(initialValue);
  const toggle = React.useCallback(() => {

    setValue(v => !v);
  }, []);
  return [ value, toggle ];
}


const randomizeFromArray = (myArray) => {
  return myArray[ Math.floor(Math.random() * myArray.length) ];
}


// Utility helper for random number generation
const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const useRandomInterval = (callback, minDelay, maxDelay) => {
  const timeoutId = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  React.useEffect(() => {
    const isEnabled =
      typeof minDelay === 'number' && typeof maxDelay === 'number';
    if (isEnabled) {
      const handleTick = () => {
        const nextTickAt = random(minDelay, maxDelay);
        timeoutId.current = window.setTimeout(() => {
          savedCallback.current();
          handleTick();
        }, nextTickAt);
      };
      handleTick();
    }
    return () => window.clearTimeout(timeoutId.current);
  }, [ minDelay, maxDelay ]);
  const cancel = React.useCallback(function () {
    window.clearTimeout(timeoutId.current);
  }, []);
  return cancel;
};



const QUERY = '(prefers-reduced-motion: no-preference)';
const isRenderingOnServer = typeof window === 'undefined';
const getInitialState = () => {
  // For our initial server render, we won't know if the user
  // prefers reduced motion, but it doesn't matter. This value
  // will be overwritten on the client, before any animations
  // occur.
  return isRenderingOnServer ? true : !window.matchMedia(QUERY).matches;
};

function usePrefersReducedMotion() {
  const [ prefersReducedMotion, setPrefersReducedMotion ] = React.useState(getInitialState);

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);
    const listener = (event) => {
      setPrefersReducedMotion(!event.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, []);

  return prefersReducedMotion;
}


// https://letsbuildui.dev/articles/reducing-motion-in-animations
const useReducedMotion = () => {
  const motionMatchMedia = window.matchMedia('(prefers-reduced-motion)');
  const [
    shouldReduceMotion,
    setShouldReduceMotion,
  ] = React.useState(motionMatchMedia.matches);

  React.useEffect(() => {
    function determineMatch() {
      if (motionMatchMedia.matches) {
        setShouldReduceMotion(true);
      } else {
        setShouldReduceMotion(false);
      }
    }

    motionMatchMedia.addEventListener("change", determineMatch);

    return () => {
      motionMatchMedia.removeEventListener("change", determineMatch);
    };
  }, [ motionMatchMedia ]);


  return shouldReduceMotion;
}


const range = function (start, end, step) {
  var range = [];
  var typeofStart = typeof start;
  var typeofEnd = typeof end;

  if (step === 0) {
    throw TypeError('Step cannot be zero.');
  }

  if (typeof end === 'undefined' && typeof 'step' === 'undefined') {
    end = start;
    start = 0;
    typeofStart = typeof start;
    typeofEnd = typeof end;
  }

  if (typeofStart === 'undefined' || typeofEnd === 'undefined') {
    throw TypeError('Must pass start and end arguments.');
  } else if (typeofStart !== typeofEnd) {
    throw TypeError('Start and end arguments must be of same type.');
  }

  typeof step === 'undefined' && (step = 1);

  if (end < start) {
    step = -step;
  }

  if (typeofStart === 'number') {
    while (step > 0 ? end >= start : end <= start) {
      range.push(start);
      start += step;
    }
  } else if (typeofStart === 'string') {
    if (start.length !== 1 || end.length !== 1) {
      throw TypeError('Only strings with one character are supported.');
    }

    start = start.charCodeAt(0);
    end = end.charCodeAt(0);

    while (step > 0 ? end >= start : end <= start) {
      range.push(String.fromCharCode(start));
      start += step;
    }
  } else {
    throw TypeError('Only string and number types are supported');
  }

  return range;
};


// https://www.joshwcomeau.com/snippets/react-hooks/use-interval/
function useInterval(callback, timeInterval) {
  const intervalId = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  React.useEffect(() => {
    if (window) {
      const tick = () => savedCallback.current();
      if (typeof timeInterval === 'number') {
        intervalId.current = window.setInterval(tick, timeInterval);
        return () => window.clearInterval(intervalId.current);
      }
    }
  }, [ timeInterval ]);
  return intervalId.current;
}


function useIntervalWithDelay(callback, timeInterval, delay) {
  const refCurrentValues = React.useRef({});
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  React.useEffect(() => {
    if (window) {
      const tick = () => savedCallback.current();
      if (typeof timeInterval === 'number') {
        refCurrentValues.current.delayId = window.setTimeout(async () => {
          refCurrentValues.current.intervalId = window.setInterval(tick, timeInterval);
        }, delay)
        return () => {
          window.clearInterval(refCurrentValues.current.intervalId)
          refCurrentValues.current.intervalId = null
          window.clearTimeout(refCurrentValues.current.delayId)
          refCurrentValues.current.delayId = null
        };
      }
    }
  }, [ timeInterval, delay ]);
  return [ refCurrentValues.current.intervalId, refCurrentValues.current.delayId ];
}



const getPointsOnCircle = ((radius, numberOfP, widthOfP) => {
  const arr = []
  for (let i = 0; i < numberOfP; i++) {
    const angle = (i / (numberOfP / 2)) * Math.PI; // Calculate the angle at which the element will be placed.
    // For a semicircle, we would use (i / numNodes) * Math.PI.
    const x = (radius * Math.cos(angle)) + (widthOfP / 2); // Calculate the x position of the element.
    const y = (radius * Math.sin(angle)) + (widthOfP / 2); // Calculate the y position of the element.
    arr.push({ 'id': i, 'x': x, 'y': y });
  }
  return arr;
  // return []
})



const getMaxScreenwidthFromMaterialUIBreakpoints = (deviceSize, breakpoints) => {

  //   theme.breakpoints.values
  // {xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920}
  // theme.breakpoints.keys
  // (5) ["xs", "sm", "md", "lg", "xl"]
  const arrValues = Object.values(breakpoints.values)
  //  [0, 600, 960, 1280, 1920]

  const index = breakpoints.keys.indexOf(deviceSize)
  const indexUp = (index + 1 > arrValues.length - 1) ? index : index + 1
  const screenwidth = arrValues[ indexUp ]
  return screenwidth
}


const getRandomItem = (arr) => {
  const randomItem = arr[ ~~(Math.random() * arr.length) ];
  return randomItem
}

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = window.setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const sample = (arr, len = 1) => {
  const output = [];

  for (let i = 0; i < len; i++) {
    output.push(arr[ Math.floor(Math.random() * arr.length) ]);
  }

  return output;
};

const generateId = (len = 4) => {
  // prettier-ignore
  const characters = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z' ];

  return sample(characters, len).join('');
};


const getSvgViewboxToFitPaths = (svgChildren) => {
  if (!svgChildren) return [ undefined, "0 0 0 0" ]
  if (svgChildren?.length === 0) return [ undefined, "0 0 0 0" ]
  const { xMin, xMax, yMin, yMax } = [ ...svgChildren ].reduce((acc, el) => {
    const bBox = el.getBBox();
    const x = Math.round(bBox.x)
    const y = Math.round(bBox.y)
    const w = Math.round(bBox.width)
    const h = Math.round(bBox.height)

    acc.xMin = Math.min(acc.xMin, x)
    acc.yMin = Math.min(acc.yMin, y)
    acc.xMax = Math.max(acc.xMax, x + w)
    acc.yMax = Math.max(acc.yMax, y + h)
    return acc;
  }, {
    xMin: 10000, yMin: 10000, xMax: -10000, yMax: -10000,
  })
  const viewbox = { startX: xMin - 2, startY: yMin - 2, width: xMax - xMin + 4, height: yMax - yMin + 4 };
  const viewboxString = `${viewbox.startX} ${viewbox.startY} ${viewbox.width} ${viewbox.height}`;
  return [ viewbox, viewboxString ]
}

const getViewBoxString = (svgViewboxAdapted) => {
  if (!svgViewboxAdapted) return

  const { startX, startY, width, height } = svgViewboxAdapted
  const svgViewboxAdaptedString = `${startX} ${startY} ${width} ${height}`
  console.log("getViewBoxString", svgViewboxAdaptedString)
  return svgViewboxAdaptedString
}



function isObject(object) {
  return object != null && typeof object === 'object';
}

const isObject2 = obj => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

function isShallowEqualObjects(object1, object2) {
  debugger
  const areObjects = isObject(object1) && isObject(object2)
  if (!areObjects) return false
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (object1[ key ] !== object2[ key ]) {
      return false;
    }
  }
  return true;
}


function isDeepEqualObjects(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[ key ];
    const val2 = object2[ key ];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isDeepEqualObjects(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}


function isEqualArraysPrimitives(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[ index ]);
}

function isEqualArraysDeepPojoButUndefined(a, b) {
  // one edge case. Since undefined is not a valid JSON value, JSON.stringify() converts undefined to null. to provide wrong true compare when had null and undefined converted to null
  return Array.isArray(a) &&
    Array.isArray(b) &&
    JSON.stringify(a) === JSON.stringify(b);
}


function createEnum(arrStrings) {
  const enumObject = {};
  for (const val of arrStrings) {
    enumObject[ val ] = val;
  }
  return Object.freeze(enumObject);
}

// { Up: 'Up', Down: 'Down', Left: 'Left', Right: 'Right' }
// createEnum(['Up', 'Down', 'Left', 'Right']);
const getCircleRadiusFrom2PointsForPaddingHeight = ({ exists = true, point1 = [ 0, 0 ], point2 = [ 0, 0 ], paddingKolmo = 100 }) => {
  if (!exists) return
  if (!point1 || !point2) return
  const [ x1, y1 ] = point1
  const [ x2, y2 ] = point2
  // rovnica kružnice (x-x0)^2+(y-y0)^2=r^2
  // V karteziánskej súradnicovej sústave (x, y) je kružnica so stredom (x0, y0) a polomerom r množina všetkých bodov (x, y) takých, že

  // {\displaystyle \left(x-x_{0}\right)^{2}+\left(y-y_{0}\right)^{2}=r^{2}}{\displaystyle \left(x-x_{0}\right)^{2}+\left(y-y_{0}\right)^{2}=r^{2}}
  // Ak sa stred kružnice nachádza v počiatku súradníc (0, 0), táto rovnica sa zjednoduší na

  // {\displaystyle x^{2}+y^{2}=r^{2}}{\displaystyle x^{2}+y^{2}=r^{2}}

  // Parametrické rovnice kružnice sú:
  // x=x0+r*cos(phi ) 
  // y=y0+r*sin(phi ) 

  // kde:
  // x0 a y0 sú súradnice stredu kružnice.
  // r je polomer kružnice.
  // φ je uhol 0 ≤ φ < 2π spojnice bodu na kružnici a stredu s osou x ako parameter.
  // Vlastnosti kružnice:
  // 1. Má konštantnú krivosť rovnajúcu sa hodnote 1/r
  // 2. Dĺžka kružnicového oblúka je
  // l=r*phi
  // pričom phi je uhol oblúka v radiánoch
  // sugg. x0=0, y0=0
  const uhlopriecka = Math.sqrt((y2 - y1) ^ 2 + (x2 - x1) ^ 2)
  // rovnica kuznice r = Math.sqrt(x1 ^ 2 + y1 ^ 2)
  // Nalezení středu z oblouku
  // Je-li známa pouze kružnice nebo její část, lze následujícím způsobem nalézt střed: vezměte dvě nerovnoběžné tětivy, zkonstruujte kolmice na jejich středy a zjistěte jejich průsečík. Poloměr r tohoto částečného kruhu lze spočítat z délky L tětivy a vzdálenosti D ze středu tětivy do nejbližšího bodu kružnice různými vzorce včetně:


  // Znázornění tětivy
  // (z geometrického odvození)

  // {\displaystyle r={\frac {{\left({\frac {L}{2}}\right)}^{2}+D^{2}}{2D}}}r={\frac  {{\left({\frac  {L}{2}}\right)}^{2}+D^{2}}{2D}}; {\displaystyle r={\frac {L^{2}}{8D}}+{\frac {D}{2}}}{\displaystyle r={\frac {L^{2}}{8D}}+{\frac {D}{2}}}
  // (z trigonometrického odvození)

  // {\displaystyle r={\frac {L}{\ 2\left(\sin 2(\operatorname {arctg} {\frac {L}{2D}})\right)}}}r={\frac  {L}{\ 2\left(\sin 2(\operatorname {arctg}{\frac  {L}{2D}})\right)}}; {\displaystyle r={\frac {(L/2)}{\sin {\biggl (}2\mathrm {arctg} {\Bigl (}{\frac {D}{(L/2)}}{\Bigr )}{\biggr )}}}}{\displaystyle r={\frac {(L/2)}{\sin {\biggl (}2\mathrm {arctg} {\Bigl (}{\frac {D}{(L/2)}}{\Bigr )}{\biggr )}}}}

  const r = ((uhlopriecka / 2) ^ 2 + paddingKolmo ^ 2) / (2 * paddingKolmo)
  return r
}


// if keys are char/string
const sortObjectByKeysString = (obj) => Object.fromEntries(Object.entries(obj).sort());
// let obj = { c: 3, a: 1 };
// obj = sortObject(obj)

// if keys are numbers
const sortObjectByKeysNumbers = (obj) => Object.fromEntries(Object.entries(obj).sort((a, b) => a - b));
// let obj = { 3: 'c', 1: 'a' };
// obj = sortObject(obj)






export { debounce, useToggle, randomizeFromArray, useRandomInterval, usePrefersReducedMotion, useReducedMotion, range, useInterval, useIntervalWithDelay, getPointsOnCircle, getMaxScreenwidthFromMaterialUIBreakpoints, getRandomItem, sample, generateId, getSvgViewboxToFitPaths, getViewBoxString, isObject, isObject2, isShallowEqualObjects, isDeepEqualObjects, isEqualArraysPrimitives, isEqualArraysDeepPojoButUndefined, createEnum, getCircleRadiusFrom2PointsForPaddingHeight, sortObjectByKeysString, sortObjectByKeysNumbers }