import React from 'react';
import { Box, Slider, Typography } from '@mui/material';

type ATProps = {
    duration: number,
    setDuration: React.Dispatch<React.SetStateAction<number>>,
    title: string,
    // children?: React.ReactNode
}

const AnimationCycleTimeSlider = (props: ATProps) => {
    const { duration = 2, setDuration, title = "Čas animácie" } = props
    return <Box>
        <Typography id="duration-slider" gutterBottom sx={{
            fontSize: ["small", "small"]
        }}>
            {title}
        </Typography>
        <Slider
            value={duration}
            onChange={(event, value) => setDuration(value as number)}
            aria-labelledby="duration-slider"
            valueLabelDisplay="auto"
            step={0.5}
            marks
            min={0.5}
            max={10}
            sx={{ zIndex: 2 }}
        />
    </Box>;
}

// AnimationCycleTimeSlider.defaultProps = {
//     title: "Čas animácie",
//     duration: 2
// }

export default AnimationCycleTimeSlider
