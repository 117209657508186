import React, { FC } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface Card1Props {
    options: {
        imgName: string;
        imgSrc: string;
        itemMaxWidthPx: number;
        classNameCard: string;
        styleSxCard?: React.CSSProperties;
        styleCardWrapper?: React.CSSProperties;
    };
}

const defaultStyleCardWrapper: React.CSSProperties = {
    position: "relative",
    maxWidth: "100%",
    height: "100%",
};

const Card1: FC<Card1Props> = ({ options }) => {
    const { imgName, imgSrc, itemMaxWidthPx, classNameCard, styleSxCard = {}, styleCardWrapper = {} } = options;

    const styleCard: React.CSSProperties = {
        maxWidth: `${itemMaxWidthPx}px`,
    };

    return (
        <Box
            key={`${imgName}-cardWrapper`}
            style={{ ...defaultStyleCardWrapper, ...styleCardWrapper }}
        >
            <Card
                key={`${imgName}-card`}
                className={`${classNameCard} card1`}
                sx={{ ...styleSxCard, ...styleCard }}
                raised={true}
                elevation={10}
                variant="elevation"
            >
                <img src={imgSrc} alt={imgName} />
                <CardContent>
                    <Typography variant="body2" component="p">
                        {imgName}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Card1;