import React from "react";
import Typography from '@mui/material/Typography';
import { Link } from "gatsby";
import VideaFinstat from "./VideaFinstat";

const Finstat = () => (
  <>
    <Typography variant="h4">
      Excel plugin - Finstat dáta
    </Typography>
    <Typography sx={{ fontSize: [ "smaller", "small", ] }} >
      Program sťahuje dáta z internetu, ukladá ich prehľadne v Exceli a v
      databáze. Rozširuje možnosti reportov ponúkaných na webe. Generuje SQL
      dopyty a pivot tabuľky. Optimalizovaný na sťahovanie dát, aj na prácu v
      Exceli a s databázou. Detaily v{" "}
      <Link to="/sf/vba-finst/finst-help.pdf" type="application/pdf" target="_blank" >finst-help.pdf</Link>
      .
    </Typography>

    <VideaFinstat />
  </>
);

export default Finstat;
