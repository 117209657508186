import React, { lazy, Suspense } from "react";

const SeparatorAbsSvg2 = lazy(() => import(/* webpackMode:"lazy", webpackChunkName: "SeparatorAbsSvg2Lazy" */ "./SeparatorAbsSvg2"))

const SeparatorAbsSvg2Lazy = (props) => {
    return <div>
        <Suspense
        // fallback={<div>Loading SeparatorAbsSvg2 ...</div>} 
        >
            <SeparatorAbsSvg2 {...props} />
        </Suspense>
    </div>
}

export default SeparatorAbsSvg2Lazy