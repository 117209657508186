import React, { Fragment, memo, ReactNode } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { alpha } from '@mui/material/styles';
// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";
import Fade from '@mui/material/Fade';

import Objednavky from "./tabObjednavky";
import Inventura from "./tabInventura";
import Finstat from "./TabFinstat";
import Dashboard from "./tabDashboard";
import Faktury from "./tabFaktury";
import IconTasks from "../../_shared/IconTasks"
import IconInvoiceDollars from "../../_shared/IconInvoiceDollars"
import IconChartLine from "../../_shared/IconChartLine"
import IconInvoice from "../../_shared/IconInvoice"

const styleSxWrap = {
  "&.MuiPaper-root": {
    flexGrow: 1,
    width: '100%',
    color: "currentColor",
    // color: "unset", // "currentColor",
    bgcolor: 'unset',
    "& .tabs": {
      position: "relative",
      "& .tab": {
        border: "1px solid rgba(255,255,255,0.6)",
        boxShadow: "inset 0px 20px 20px 10px rgb(0 0 0 / 20%), 0px 18px 28px 2px rgb(0 0 0 / 14%), 0px 7px 34px 6px rgb(0 0 0 / 12%)",
        borderTopRightRadius: "50%",
        borderTopLeftRadius: "50%",
        backdropFilter: "blur(2px)",
        transition: "transform 500ms ease-out",
        overflow: "hidden",

        "&::before": {
          content: '""',
          position: "absolute",
          top: "0%",
          left: "-125%",
          bgcolor: (theme: { palette: { common: { white: string; }; }; }) => alpha(theme.palette.common.white, 0.3),
          width: "60%",
          height: "100%",
          transform: "skew(45deg)",
          transition: "left 500ms ease-out",
        },
        "&:hover": {
          transform: "translateY(-2%)",
          "&::before": {
            left: "150%",
          },
        },
        "&.Mui-selected": {
          bgcolor: "primary2.main",
          color: "primary2.contrastText",
          boxShadow: "inset 0px 20px 20px 2px rgb(0 0 0 / 20%), 0px 18px 28px 2px rgb(0 0 0 / 14%), 0px 7px 34px 6px rgb(0 0 0 / 12%)",
        }
      },
    },
    "& .tabPanel": {
      display: "flex",
      bgcolor: "primary2.main",
      color: "primary2.contrastText",
      fontSize: ["small", "small",],
      px: [0.5, 1, 2],
      "& p, & li": {
      },
      // "& .boxPanel": {


      // }
    },
  },
}


const sxBoxPanel = {
  minHeight: "640px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  flexWrap: "wrap",
  flexGrow: 1,
  px: [0.25, 1, 2],
  py: [0.25, 1, 2]
}

type TabPanelProps = {
  children: ReactNode,
  value: number,
  index: number
} & React.ComponentPropsWithoutRef<"div">



function TabPanel(props: TabPanelProps) {

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prace-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-prace-auto-tab-${index}`}
      className="tabPanel"
      {...other}
    >
      {value === index && (
        <Fade in={true} timeout={1500} >
          <Box p={3}
            className="boxPanel"
            sx={sxBoxPanel}
          >
            {children}
          </Box>
        </Fade>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `scrollable-prace-auto-tab-${index}`,
    'aria-controls': `scrollable-prace-auto-tabpanel-${index}`,
  };
}

const TabsPraceExcel = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };

  return (
    < >
      <Typography sx={{ fontSize: ['small', 'medium'] }}>Pre inšpiráciu</Typography>
      <Paper
        sx={styleSxWrap}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          // scrollButtons="auto"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable prace auto tabs"
          className="tabs"
        >
          <Tab
            label="Objednávky"
            {...a11yProps(0)}
            icon={
              <span style={{ marginBottom: '0px' }}>
                <FileCopyIcon />
                <AssessmentIcon />
              </span>
            }
            className="tab"
            sx={{
              paddingX: [0, 1, 2],
              fontSize: ["small", "small",],
            }}
            key="tabPrace-0"
          />

          <Tab label="Inventúra" {...a11yProps(1)}
            icon={<IconTasks />}
            className="tab"
            sx={{
              paddingX: [0, 1, 2],
              fontSize: ["small", "small",],
            }}
            key="tabPrace-1"
          />

          <Tab label="Finstat" {...a11yProps(2)}
            icon={<IconInvoiceDollars />}
            className="tab" sx={{
              paddingX: [0, 1, 2],
              fontSize: ["small", "small",],
            }} key="tabPrace-2" />
          <Tab
            label="Dashboard" {...a11yProps(3)}
            icon={<span style={{ marginBottom: '0px' }} ><DashboardIcon /><IconChartLine /></span>}
            className="tab"
            sx={{
              paddingX: [0, 1, 2],
              fontSize: ["small", "small",],
            }}
            key="tabPrace-3"
          />
          <Tab
            label="Faktúry" {...a11yProps(4)}
            icon={<IconInvoice />}
            className="tab"
            sx={{
              paddingX: [0, 1, 2],
              fontSize: ["small", "small",],
            }}
            key="tabPrace-4"
          />
        </Tabs>

        <TabPanel value={value} index={0} key="panelPrace-0">
          <Objednavky />
        </TabPanel>

        <TabPanel value={value} index={1} key="panelPrace-1">
          <Inventura />
        </TabPanel>
        <TabPanel value={value} index={2} key="panelPrace-2">
          <Finstat />
        </TabPanel>
        <TabPanel value={value} index={3} key="panelPrace-3">
          <Dashboard />
        </TabPanel>
        <TabPanel value={value} index={4} key="panelPrace-4">
          <Faktury />
        </TabPanel>
      </Paper>
    </>
  );
}
export default memo(TabsPraceExcel);
