import React, { useContext, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'

import BowListLazy from "../../_shared/shaping/BowListLazy"
import Typography from "@mui/material/Typography";
import { NotInViewContext } from '../../_shared/AnimateScrollIntoViewport';

const varLetter = ({ notInView }) => {
    return ({
        init: false,
        init2: notInView ? {
            opacity: 1, y: 0, transition: { duration: 0 }
        } : {
            opacity: 0, y: [ 0, 25, 12 ], transition: {
                duration: 0.3,
                // delay: notInView ? 0 : 0.1 
            }
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: notInView ? 0 : 0.01,
                // delay: notInView ? 0 : 0.1
            },
        },
    }
    )
}

const bowListOptionsHigh2 = {
    itemHeightPx: 100,
    listHeightPx: "auto",
    primarySecondarySeparator: "|",

}
const bowListOptionsAddedListBothBowsLUnderT = {
    bowUnder: true,
    bowUnderOptions: {
        underDeepness: 50,
        bowdirection: "top",
    },
    bothBows: true,
    bowDirection: "left",
    motionDirection: "left",
}

const styleBottomBorders20 = {
    borderBottomLeftRadius: "20%",
    borderBottomRightRadius: "20%",
}
const styleRightBorders50 = {
    borderTopRightRadius: "50%",
}

const styleLeftBorders50 = {
    borderTopLeftRadius: "50%",
}

const sxTitle = {
    "&.title": {
        m: 0,
        p: 0,
        fontSize: [ "medium", "large", "x-large" ],
        textShadow: (theme) => theme.distinctShadow3,
    },
}

// listPaddingTopPx = 0, listPaddingBottomPx = 0, listMarginTopPx = 0, listMarginBottomPx = 20,
const KontaktTitle = ({ breakpoint, title }) => {

    const { notInView } = useContext(NotInViewContext)
    const ctrAnim = useAnimation()

    useEffect(() => {
        const seq = async () => {
            await ctrAnim.start("init2")
            ctrAnim.start("animate")
        }
        if (notInView) {
            // ctrAnim.start("animate")
            ctrAnim.stop()
        } else {
            seq()
        }
    }
        , [ notInView, ctrAnim ]
    )
    const styleListFinalXs = { paddingTop: 0, paddingBottom: 0, marginBottom: 0 }
    if ([ "xs", "sm" ].includes(breakpoint)) {
        styleListFinalXs.paddingLeft = 0
        styleListFinalXs.paddingRight = 0
    }

    return <BowListLazy
        name="BL-mainUp-kontaktTitle"
        options={{
            nameStyleList: "primary2",
            nameStyleItem: "secondary",
            ...bowListOptionsHigh2,
            ...bowListOptionsAddedListBothBowsLUnderT,
            styleList: {
                ...styleRightBorders50, ...styleLeftBorders50,
            },
            disableAlignTextToBow: true,
            // listPaddingTopPx,
            // listPaddingBottomPx,
            // listMarginTopPx,
            // listMarginBottomPx,
            styleListFinal: styleListFinalXs,
        }}
        key="BL-mainUp-kontaktTitle"
    >
        {[ <div key="title-kontakt-wrap"  >
            <motion.div
                key="title-kontakt"
                variants={varLetter({ notInView })}
                animate={ctrAnim}
                initial="init"
                className="charInline"
                style={{ opacity: notInView ? 1 : 0, y: notInView ? 0 : 20, }}
            >
                <Typography
                    variant="h1"
                    className="title"
                    sx={sxTitle}
                >
                    {title}

                </Typography>
            </motion.div>
        </div>,
        ]}
    </BowListLazy>;
}

export default KontaktTitle
