import React from 'react';
import { Container, Typography, Box, Button, List, ListItem, ListItemText, Paper, Grid, Divider, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClientServerAppShowcase from './ClientServerAppShowcase';

const ClientServerAppGeneral = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="md"
            sx={{
                padding: [ 1, 2, 3, 4 ], // theme.spacing(4),
                borderRadius: "20px", // theme.shape.borderRadius,
                boxShadow: theme.shadows[ 2 ],
                bgcolor: "primary2.main",
                color: "primary2.contrastText",
            }}>
            <ClientServerAppShowcase />
            <Divider />
            <Paper
                elevation={3}
                sx={{
                    my: [ 1, 2, 3 ],
                    padding: [ 1, 2, 3 ],
                    bgcolor: "primary.main",
                    color: "primary.contrastText"
                }}
            >
                <Typography variant="h3" component="h3" gutterBottom
                    sx={{
                        fontSize: [ "small", "medium", "large" ],
                        textAlign: "center",
                    }}
                >
                    Klientská aplikácia
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "small", "larger", "medium" ],
                        marginBottom: 'unset'
                    }}
                >
                    Klientská aplikácia posiela HTTP požiadavky na endpointy definované na API servere a spracováva dáta vrátené odtiaľ a prezentuje ich používateľovi.
                </Typography>

            </Paper>
            <Paper elevation={3}
                sx={{ my: [ 1, 2, 3 ], padding: [ 1, 2, 3 ], bgcolor: "primary.main", color: "primary.contrastText" }}>
                <Typography variant="h3" component="h3" gutterBottom
                    sx={{
                        fontSize: [ "small", "medium", "large" ],
                        textAlign: "center",
                    }}
                >
                    API server
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "small", "larger", "medium" ],
                        marginBottom: 'unset'
                    }}
                >
                    API server má definované HTTP endpointy, kde po príjme HTTP požiadavky od klientského programu spúšťa časť programu, zistí dáta z databázy, spracuje ich a pošle v HTTP odozve späť.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "small", "larger", "medium" ],
                        marginBottom: 'unset'
                    }}
                >
                    Časti programu sú na rôznych endpointoch rôzne a zahŕňajú aj kontrolu prítomnosti informácie o zalogovanom používateľovi v klientovi v HTTP požiadavke.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom
                    sx={{
                        fontSize: [ "small", "larger", "medium" ],
                        marginBottom: 'unset'
                    }}
                >
                    Používateľ musí byť zaregistrovaný v databáze a oprávnený, aby v HTTP odozve dostal žiadané dáta.
                </Typography>
            </Paper>

            <Paper sx={{
                my: [ 1, 2, 3 ], bgcolor: "unset", color: "unset"
            }}>
                <Typography variant="h3" component="h3" gutterBottom sx={{
                    fontSize: [ "medium", "large" ], textAlign: 'center',
                }}>
                    Samostatný API server a samostatná klientská aplikácia - výhody
                </Typography>

                <List
                    sx={{
                        "& .MuiListItem-root": {
                            bgcolor: "primary.main", color: "primary.contrastText",
                            margin: theme.spacing(1),
                            mx: 0,
                            borderRadius: "20px",
                            boxShadow: theme.shadows[ 2 ],
                            "& .MuiListItemText-root .MuiTypography-root": {
                                "& .MuiGrid-item.firstCol": {
                                    textAlign: 'right',
                                },
                                "&.MuiListItemText-primary": {
                                    // color: "yellow",
                                    fontWeight: "bold",
                                    paddingBottom: '4px',
                                    textAlign: 'center',
                                },
                                fontSize: [ "small", "small", "medium" ],
                                // color: "primary2.contrastText",
                                // "& .firstWord": {
                                //     // textDecoration: "underline",
                                //     paddingRight: '2px',
                                // },
                                // "& .example": {
                                //     // textDecoration: "underline",
                                //     paddingRight: '2px',
                                // }
                            },
                        }
                    }}>
                    <ListItem>
                        <ListItemText
                            primary="Škálovateľnosť"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Umožňuje každému samostatnému softvérovému projektu (backend API server a frontend klient) rozširovať sa, rásť nezávisle podľa jeho konkrétnych potrieb.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            API server môže obsluhovať viacero klientov, vrátane webových, mobilných a aplikácií tretích strán.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Oddelenie zodpovedností, oddelenie cieľov, kde samostatné projekty dodávajú iba niektoré funkčnosti"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Každé samostatné softvérové projekty môžu byť vyvíjané, testované a nasadzované nezávisle, čo robí programový kód modulárnejším a ľahšie udržiavateľným.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Backend vývojári sa môžu sústrediť na hlavnú, podstatnú logiku a spracovanie dát, zatiaľ čo frontend vývojári sa môžu sústrediť na používateľský zážitok a interaktivitu.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Bezpečnosť"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            API server poskytujúci a ukladajúci dáta v databáze, môže implementovať robustné bezpečnostné opatrenia, ako je autentifikácia a autorizácia, na ochranu citlivých údajov.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Klient komunikuje iba s API serverom, čím sa znižujú možnosti útoku a zabezpečujú sa bezpečné dátové transakcie.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Flexibilita"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Rôzne tímy môžu pracovať na samostatnom frontende a samostatnom backende súčasne, pričom používajú rôzne, iné softvérové technológie, frameworky, knižnice.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Frontend môže byť postavený s Reactom, zatiaľ čo backend môže používať Node.js a Express, čo umožňuje väčšiu flexibilitu pri výbere najlepších nástrojov pre každú časť aplikácie.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Optimalizácia výkonu"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Samostatný API server môže byť optimalizovaný pre spracovanie dát a obchodnú logiku, zatiaľ čo samostatný klient môže byť optimalizovaný pre príjemný pocit používateľa pri prezentovaných informáciách a pohodlnú interaktivitu.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Náročné úlohy spracovania dát môžu byť riešené API serverom, čím sa uvoľní klient na poskytovanie plynulého a responzívneho používateľského rozhrania.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Použiteľnosť v rôznych projektoch"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Samostatný API server môže byť znovu použitý v rôznych projektoch alebo platformách, čím poskytuje konzistentný backend pre rôznych klientov.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Jeden API server môže obsluhovať webovú aplikáciu aj mobilnú aplikáciu, čím sa znižuje duplicita úsilia a zabezpečuje konzistentnosť.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Zlepšený vývojový pracovný postup"
                            secondary={
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="firstWord">
                                            Výhoda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Umožňuje paralelný vývoj, kde tímy na samostatnom frontende a samostatnom backende môžu pracovať nezávisle a integrovať svoju prácu bez problémov.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="firstCol">
                                        <Typography component="span" variant="body2" className="example">
                                            Príklad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span" variant="body2">
                                            Frontend vývojári môžu počas vývoja používať mock API, zatiaľ čo backend vývojári budujú skutočné API, čo vedie k rýchlejším vývojovým cyklom a lepšej spolupráci.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                </List>
            </Paper>
            <Divider />
            <ClientServerAppShowcase />
            <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" href="/kontakt"
                    sx={{ padding: theme.spacing(1, 4), fontSize: [ "medium", "large" ], }}>
                    Objednajte si u nás web aplikáciu klient - server
                </Button>
            </Box>
        </Container>
    );
};

export default ClientServerAppGeneral;