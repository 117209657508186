import React, { useContext, useRef } from "react";
import { motion, useInView } from "framer-motion";
import Box from "@mui/system/Box";
import { ContextIsHomeFirstMountDispatch } from "../../_shared/contextIsHomeFirstMount"
import { NotInViewContext } from "../../_shared/AnimateScrollIntoViewport";
import BowSvg from "../../_shared/shaping/bowSvg"
import imgBg from "../../../images/stred-veže.jpg"

const propsBowSvg = { arrText: [ "abd" ], coef1: 1, depth1: 450, coef2: 1, depth2: 50, length: 500, angle: 0, styleBow: {}, stylePath: {} }

const styleSxBlackbox = {
    backgroundImage: `url(${imgBg})`,
    height: "0%",
    width: "100%",
    opacity: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: 0,
    top: 0,
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    bgcolor: "primary2.main",
    borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
    borderWidth: "2px",
    borderColor: "error.dark",
    borderStyle: "solid",
    zIndex: 50,
    "& .title": {
        margin: 4,
        bgcolor: "rgba(96,53,25,0.45)",
        boxShadow: 12,
        borderRadius: 4,
        padding: 2,
        zIndex: 51,
        "& .charInline": {
            display: "inline-block",
            bgcolor: "background.paper",
        }
    }
}


const varBlackBox = {
    initial: false,
    animate: (duration) => ({
        height: [ "50vh", "50vh", "100vh", "100vh", "50vh", "50vh", "0vh", "0vh" ],
        width: [ "10%", "50%", "50%", "100%", "100%", "50%", "50%", "0%" ],
        // height: [ "0%", "100%", "0%" ],
        // height: "100%",
        // height: "100vh",
        // width: "100%",
        rotateZ: 0,
        skewX: 0,
        transition: {
            duration: duration,
            staggerChildren: 0.2,
            // repeat: 1,
            // repeatType: "reverse",
            // when: "beforeChildren",
            delay: 5,
        },
        transitionEnd: {
            display: "none",
        }
    }),
};

const varLetter = {
    initial: false,
    animate: {
        y: 0,
        rotateZ: 360,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 1,
            stiffness: 200,
        },
    },
}

const InitialTransition = ({ textTitle, duration }) => {

    const { notInView, isCountedTwice } = useContext(NotInViewContext);
    const ref = useRef(null)
    const isInView = useInView(ref)
    // console.log("ooooooooooo  InitialTransition notInView", notInView)
    const dispatch = React.useContext(ContextIsHomeFirstMountDispatch)
    return (
        <Box component={motion.div}
            ref={ref}
            className="InitialTransition"
            sx={styleSxBlackbox}
            style={{
                rotateZ: 45,
                // height: "0%",
                height: "0vh",
                width: "0%",
                // borderRadius: "50%",
                borderRadius: '49% 26% 22% 80% / 46% 60% 40% 57%',
                skewX: 10,
                // zIndex: 9999999,
            }}
            variants={varBlackBox}
            initial="initial"
            animate={(!isInView || !isCountedTwice) ? "" : "animate"}
            custom={duration}
            onAnimationComplete={() => {
                if (isInView && isCountedTwice) {
                    dispatch({ type: "isHomeFirstMount", payload: false })
                }
            }}
            key="blackBox"
        >
            <BowSvg {...propsBowSvg} arrText={Array.from(textTitle.repeat(5))} fontSize={24} />

            <div
                key="sentence"
            >
                <div
                    className="title"
                >
                    {Array.from(textTitle).map((char, i) => {
                        const key = `${char}-init-${i}`
                        return (
                            <motion.div
                                variants={varLetter}
                                className="charInline"
                                style={{ y: -100 }}
                                key={key}
                            >
                                {char}
                            </motion.div>
                        )
                    })}
                </div>
            </div>
        </Box>
    );
};

export default InitialTransition
