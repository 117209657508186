import React from "react"
import { motion } from "framer-motion";
import styled from '@mui/material/styles/styled';
import { Box } from "@mui/system";

const styleCardWrapper = {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "unset"
}

const styleCardP = {
    "&.MuiTypography-root": {
        margin: 0,
    }
}

const StyledDivCard = styled("div")({
    position: "absolute",
    background: "transparent",
    border: "none",
    boxShadow: "none",
    height: "inherit",
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    "& img": {
        opacity: 0,
        transform: "scale(2)",
        filter: "hue-rotate(90deg)",
        width: "100%"
    },
    "&.MuiCard-root": {
        overflow: "hidden",
    },
})


const varCardWrapper = () => {
    return {
        initial: false,
        animate: {
            rotateZ: 360,
            transition: {
                staggerChildren: 0.7,
                duration: 0.6,
                when: "beforeChildren"
            }
        },
        exit: {
            rotateZ: 180,
            opacity: 0.5,
            transition: {
                duration: 0.1
            },
        },
    }
}

const varImg = () => {
    return {
        initial: false,
        animate: {
            rotateZ: [ 0, 360 ],
            opacity: 1,
            scale: [ 2, 1 ],
            filter: [
                'hue-rotate(90)',
                'hue-rotate(0)'
            ],
            transition: {
                duration: 0.5,
                times: [ 0.1, 1 ],
            }
        },
        exit: {
            opacity: 0.5,
            scale: 0.5,
            transition: {
                duration: 0.1,
            },
        },
    }
}

const varCardContent = () => {

    return {
        initial: false,
        animate: {
            rotateZ: [ 0, 180 ],
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
                duration: 0.5,
                when: "beforeChildren"
            }
        },
        exit: {
            opacity: 0.5,
            scale: 0.5,
            transition: {
                duration: 0.1
            },
        },
    }
}

const varCardP = () => {
    return {
        initial: false,
        animate: {
            rotateZ: 180,
            transition: {
                delay: 4,
                duration: 0.5,
            }
        },
        exit: {
            opacity: 0.5,
            scale: 0.5,
            transition: {
                duration: 0.1
            },
        },
    }
}

const Card2 = ({ children, options }) => {

    const { imgName, imgSrc } = options

    const cardStyle = {}
    const cardWrapperStyle = {}

    return (
        <motion.div
            variants={varCardWrapper()}
            style={{ ...styleCardWrapper, ...cardWrapperStyle }}
            key={`${imgName}-cardWrapper`}
        >
            <StyledDivCard
                key={`${imgName}-card`}
                className="MuiPaper-root MuiCard-root"
                style={cardStyle}
            >
                <motion.img
                    variants={varImg()}
                    src={imgSrc}
                    alt={imgName}
                    key={imgName}
                />
                <motion.div
                    variants={varCardContent()}
                    style={{ opacity: 0.1, }}
                    key={`div-${imgName}`}
                >
                    <Box component={motion.p}
                        variants={varCardP()}
                        className="MuiTypography-root"
                        sx={styleCardP}
                        key={`Box-p-${imgName}`}
                    >
                        {imgName}
                    </Box>
                </motion.div>
            </StyledDivCard>
        </motion.div>
    )

}

export default Card2
