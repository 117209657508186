import React from 'react';
import SpinningCardsCarousel from "../../_shared/spinningCardsCarousel"
import useNatureImages from "../../_shared/useNatureImages"


const NatureCardsSpinningCarousel = ({ options = {} }) => {
  const { index = 1 } = options
  const natureImagesFiles = useNatureImages();
  const imagesNodes = natureImagesFiles.nodes
  const imagesNumber = natureImagesFiles.totalCount;
  const imgOptions = { imagesNodes, imagesNumber }

  const defaultOptions = { index, makePolygonCarousel: true, spinningTimeInSeconds: 16, spinningClockwise: true }
  const spinningCarouselOptions = { ...defaultOptions, ...options, ...imgOptions }

  return (
    <SpinningCardsCarousel options={{ ...spinningCarouselOptions, imgWidth: 200, switchAxes: true }} />
  );
};

export default NatureCardsSpinningCarousel;
