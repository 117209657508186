import React from 'react';
import Box from '@mui/system/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';



const styleSxWrap = {
    "& .paper": {
        padding: '6px 6px',
    },
    "& .secondaryTail": {
        bgcolor: "secondary.main",
    },
    "& .timelineConn": {
        bgcolor: 'secondary.main'
    },
    "& .timelineSep": {
    }
}

export default function HotelTimeline() {

    return (
        <Box
            sx={styleSxWrap}
        >
            <Typography variant="body2" component="p">
                Your Day Schedule
            </Typography>
            <Timeline align="alternate">

                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            9:30 am
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator className="timelineSep" >
                        <TimelineDot>
                            <FastfoodIcon />
                        </TimelineDot>
                        <TimelineConnector className="timelineConn" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className="paper" >
                            <Typography variant="h6" component="p">
                                Eat
                            </Typography>
                            <Typography>Your Breakfest</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            10:00 - 19.00
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator className="timelineSep" >
                        <TimelineDot color="primary">
                            <LaptopMacIcon />
                        </TimelineDot>
                        <TimelineConnector className="timelineConn" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="p">
                                Enjoy a beautiful day!
                            </Typography>
                            <Typography>Because it&apos;s awesome here!</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            12:30 am
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator className="timelineSep" >
                        <TimelineDot>
                            <FastfoodIcon />
                        </TimelineDot>
                        <TimelineConnector className="timelineConn" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="p">
                                Eat
                            </Typography>
                            <Typography>Your Lunch (optional)</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            19.00 - 20.30
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator className="timelineSep" >
                        <TimelineDot>
                            <FastfoodIcon />
                        </TimelineDot>
                        <TimelineConnector className="timelineConn" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="p">
                                Eat
                            </Typography>
                            <Typography>Your Dinner (optional)</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator className="timelineSep" >
                        <TimelineDot color="primary" variant="outlined">
                            <HotelIcon />
                        </TimelineDot>
                        <TimelineConnector className="secondaryTail" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="p">
                                Sleep
                            </Typography>
                            <Typography>Because you need rest</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator className="timelineSep" >
                        <TimelineDot color="secondary">
                            <RepeatIcon />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="p">
                                Relax every day
                            </Typography>
                            <Typography>Because this is the life you love!</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Box>
    );
}
