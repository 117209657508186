import React from "react";
import { getAngleOfPointOnCircle, getAngleInRadsFromDeg, getXYAtDistanceFromCenter, normalizeValueIntoOtherScale } from "./circleUtils"  //usePrefersReducedMotion,
import { Target, VariantLabels, AnimationControls, TargetAndTransition, Transition } from "framer-motion";


type Tinit = boolean | Target | VariantLabels
type Tanimate = AnimationControls | TargetAndTransition | VariantLabels | boolean
type Texit = TargetAndTransition | VariantLabels
type Ttransition = Transition

type TvarProps = {
    delayIndexDirectlyMs?: number,
    index?: number,
    indexDelayMs?: number
}
type Tprops = {
    initialAnimation?: boolean,
    animateTo?: TargetAndTransition,
    transitionTo?: Transition,
    timeoutMs?: number,
    from?: TargetAndTransition,
    delayIndexDirectlyMs?: number,
    index?: number,
    indexDelayMs?: number
}


const useBoop = (
    {
        initialAnimation = true,
        animateTo = {
            x: 50,
            y: 0,
            rotateX: 0,
            rotateY: 0,
            scale: 1.5,
        },
        transitionTo = {
            duration: 1,
            type: "spring",
            stiffness: 300,
            damping: 10,
        },
        timeoutMs = 1000,
        from = {
            x: 0,
            y: 0,
            rotateX: 0,
            rotateY: 0,
            scale: 1
        },
        delayIndexDirectlyMs = 0,
        index = 0,
        indexDelayMs = 200
    }: Tprops
) => {
    // const prefersReducedMotion = usePrefersReducedMotion();
    const [isBooped, setIsBooped] = React.useState(initialAnimation);
    const variants = (varProps: TvarProps) => {
        const { index, indexDelayMs, delayIndexDirectlyMs } = varProps
        if ((!!delayIndexDirectlyMs) || (!!indexDelayMs && !!index)) {
            transitionTo.delay = delayIndexDirectlyMs !== 0 ? delayIndexDirectlyMs! / 1000 : (!!index && !!indexDelayMs) ? index * indexDelayMs / 1000 : 0
        }
        animateTo.transition = transitionTo
        return {
            boop: animateTo,
            rest: from,
        }
    };

    React.useEffect(() => {
        if (window) {
            if (!isBooped) return;

            const timeoutId = window.setTimeout(() => {
                setIsBooped(false);
            }, timeoutMs);

            return () => {
                window.clearTimeout(timeoutId);
            };
        }
    }, [isBooped, timeoutMs]);


    const trigger = React.useCallback(() => {
        setIsBooped(true);
    }, []);

    return { isBooped, variants, trigger }; //, ,
};

type TstringTransition = {
    type: "spring",
    duration: number,
    stiffness: number,
    damping: number,
}

type TuseAngledBoopProps = {
    index?: number,
    // distancePxFromCenter = 142,
    wrapDiameterPx?: number,
    circleDiameterPx?: number,
    numberOfPoints?: number,
    animateTo?: TargetAndTransition,
    transitionTo?: TstringTransition,
    from?: TargetAndTransition,
    timeoutMs?: number,
    initialAnimation?: boolean
}



const useAngledBoop = ({
    index = 0,
    // distancePxFromCenter = 142,
    wrapDiameterPx = 400,
    circleDiameterPx = 30,
    numberOfPoints = 5,
    animateTo = { scale: 1 },
    transitionTo = {
        duration: 1,
        type: "spring",
        stiffness: 300,
        damping: 10,
    },
    from = { scale: 0.5 },
    timeoutMs = 1000,
    initialAnimation = true
}: TuseAngledBoopProps) => {
    //  circleDiameterPx = 2 * Math.PI *distancePxFromCenter  / numberOfPoints
    const distancePxFromCenter = Math.round(circleDiameterPx * (numberOfPoints - 1) / (2 * Math.PI)) * 1.3
    const angle = getAngleOfPointOnCircle(index, numberOfPoints)
    const angleInRads = getAngleInRadsFromDeg(angle)
    const [x, y] = getXYAtDistanceFromCenter(angleInRads, distancePxFromCenter)
    let indexDelayMs = normalizeValueIntoOtherScale(index, 0, numberOfPoints - 1, 450, 600);
    // `normalize` produces linear interpolation,
    // but I want there to be a *bit* of an ease;
    // I want it to appear to be slowing down,
    // as we get further into the circles.
    indexDelayMs *= 1 + index * 0.22;
    const friction = normalizeValueIntoOtherScale(index, 0, numberOfPoints - 1, 15, 40);
    animateTo.x = x
    animateTo.y = y
    from.x = 0
    from.y = 0
    transitionTo.damping = friction
    const delayIndexDirectlyMs = indexDelayMs
    const boop = useBoop({ animateTo, transitionTo, timeoutMs, from, delayIndexDirectlyMs, indexDelayMs, index, initialAnimation });
    return boop;
};

export { useAngledBoop, useBoop }