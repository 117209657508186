import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const IntroTextRychleWebStrankyCCh = ({ colors = {} }) => {
    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;
    const myAnimationOptionsCCh = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="Rychle-a-flexibilne-web-stranky-od-nas" className="intro toc toc0"><span>Rýchle a flexibilné webstránky od nás</span></h3>

            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="Rychle-a-flexibilne-web-stranky-od-nas"
            >
                <div>Môžte vytvorenie web stránok nechať na nás</div>
                <div>Ak je pre vás dôležitá rýchlosť web stránok a odlíšenie sa od ostatných a voľnosť</div>
                <div>že ak sa zajtra rozhodnete na webstránky nejakú logiku, funkčnosť, tak to technicky budete môcť realizovať</div>
                <div>že sa to dá - javascriptom na frontende aj na servere.</div>
                <div>Tvorba web stránok cez javascript programovanie je zložitejšia oproti použitiu šablóny Wordpressu, ale vo výsledku budú webstránky odpovedajúce dnešnej dobe.</div>
                <div>Objednajte si
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_NoFill"
                    >
                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>super rýchle a</div>
                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>super flexibilné webstránky </div>
                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>vykonávajúce funkcie, aké len budete chcieť</div>
                    </WavedFrameAnimationForChildrenLazy>
                    - vytvorené cez React</div>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default IntroTextRychleWebStrankyCCh;