import React from 'react';
import Fab from '@mui/material/Fab';

const styleSxBtnCm = ({ isOpen }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: isOpen ? "primary2.main" : "primary.main",
    transform: isOpen ? "scale(1.03)" : "scale(1)",

    "&:hover": {
        bgcolor: !isOpen ? "primary2.main" : "primary.main",
        transform: !isOpen ? "scale(1.03)" : "scale(1)",

    },
    "& svg": {
        borderRadius: "50%",
        borderStyle: "double",
        transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
        "&:hover": {
            transform: isOpen ? "rotate(45deg) scale(2)" : "rotate(0deg) scale(2)",
        }
    }
})

const ButtonCM = (props, ref) => {

    const { style, className, children, isOpen, handlClickButtonInParent } = props
    const handleClick = (e) => {
        handlClickButtonInParent()
    }

    return (
        <Fab
            ref={ref}
            style={style}
            className={className}
            sx={styleSxBtnCm({ isOpen })}
            onClick={(e) => handleClick(e)}
        >
            {children}
        </Fab>
    )
}

export default React.forwardRef(ButtonCM)
