import React from 'react'
import S_4CardsSpinningCarousel from "./s_4CardsSpinningCarousel";
import NatureCardsSpinningCarousel from "./natureCardsSpinningCarousel";
import SwipingCarousel from "../../_shared/SwipingCarousel"

const SwipingCaruselWithCards = () => {
    const swipeInterval = 3000

    const arrComponents = [
        <S_4CardsSpinningCarousel options={{ index: 1, makePolygonCarousel: false, verticalOpen: false, styleVertical: { justifyContent: "center", top: "20%" } }} key="1" />,
        <S_4CardsSpinningCarousel options={{ index: 5, makePolygonCarousel: true, verticalOpen: true, styleVertical: { justifyContent: "center", top: "20%" } }} key="5" />,
        <NatureCardsSpinningCarousel options={{ index: 2, styleVertical: { justifyContent: "space-around" }, }} key="2" />,
        <NatureCardsSpinningCarousel options={{ makePolygonCarousel: false, styleVertical: { justifyContent: "space-evenly" }, index: 3 }} key="3" />,
        <NatureCardsSpinningCarousel options={{ makePolygonCarousel: true, styleVertical: { justifyContent: "flex-start", top: "25%" }, index: 7 }} key="7" />,
        <NatureCardsSpinningCarousel options={{ makePolygonCarousel: false, switchAxes: true, styleVertical: { justifyContent: "space-evenly", top: "25%" }, index: 4 }} key="4" />
    ]

    const swipingCarouselOptions = { arrComponents, swipeInterval }

    return (
        <SwipingCarousel swipingCarouselOptions={swipingCarouselOptions} />
    )
}

export default SwipingCaruselWithCards