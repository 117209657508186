import React, { Fragment, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import { Link } from 'gatsby';
import BowListLazy from "./shaping/BowListLazy"
import AnimateScrollIntoViewport from './AnimateScrollIntoViewport';
import IconExternalLink from "./iconExternalLink"
import { useBreakpoint } from './contextBreakpointMediaQ';
import AnimatingChildrenColumnsOrderingWithFlip from './AnimatingChildrenColumnsOrderingWithFlip';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    "& .ine-mesta": {
        display: 'inline-flex',
        width: '100%',
        overflow: 'visible',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        "& h4": {
            paddingLeft: "5px",
            paddingLRight: "5px",
            paddingTop: "2px",
            paddingBottom: "2px",
        }
    },
    "&.MuiPaper-root": {
        backgroundColor: "unset",
        color: "unset",
        width: '100%',
        "& .MuiAccordionSummary-content": {
            minWidth: "100%",
            marginTop: 0,
            marginBottom: 0,
        },
        "& .MuiAccordionDetails-root": {
            flexDirection: "column"
        },
        "& .gridItem": {
            display: "flex",
            justifyContent: "center"
        },
        "& .gridItemMesta": {
            display: "flex",
            justifyContent: "center",
            pt: 0,
        },
        "& .extLink": {
            padding: "0 5px",
            color: "unset",
            borderRadius: '5px',
            boxShadow: theme.shadows[ 11 ],
            "& svg path": {
                fill: theme.palette.secondary2.main,
            }
        },
        "& .MuiSvgIcon-root": {
            userSelect: "none",
            width: "1em",
            height: "1em",
            display: "inline-block",
            fill: "currentColor",
            flexShrink: 0,
            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            fontSize: "1.5rem",
        }
    }
}))




const bLOptionsDouble = {
    itemHeightPx: 50,
    listHeightPx: "auto",
    itemsXDistancePx: 50,
    primarySecondarySeparator: "|",
}
const bLOptionsDoubleHNami = {
    itemHeightPx: 150,
    listHeightPx: "auto",
    itemsXDistancePx: 10,
    primarySecondarySeparator: "|",
}

const bLOptionsL = {
    bowDirection: "left",
    motionDirection: "left"
}

const bLOptionsR = {
    bowDirection: "right",
    motionDirection: "right"
}

const bLOptionsUpR = {
    bowDirection: "right",
    motionDirection: "right",
    listPaddingBottomPx: 0,
}

const bLOptionsMidR = {
    bowDirection: "left",
    motionDirection: "left",
    listPaddingTopPx: 0,
    listPaddingBottomPx: 0,
}

const styleBLBordersBR20 = {
    borderBottomRightRadius: "20%",
}

const styleBLBordersBLTR50px = {
    borderBottomLeftRadius: "50px",
    borderTopRightRadius: "50px",
}
const styleBLBordersBRTL50px = {
    borderBottomRightRadius: "50px",
    borderTopLeftRadius: "50px",
}


let fontSizeNami = 'small'
let fontSizeDbl = 'small'
const listPaddingTopPx = 10
const listPaddingBottomPx = 10
const listMarginTopPx = 10
const listMarginBottomPx = 10

function LocalServices() {

    const deviceSize = useBreakpoint()
    const theme = useTheme()
    const [ expanded, setExpanded ] = useState("panel1");


    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const styleItemPrimary = {
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[ 11 ],
    }

    const styleLBPrim = {
        // backgroundColor: theme.palette.primary.main,
        // color: "black", // theme.palette.primary.contrastText,
        boxShadow: theme.shadows[ 11 ],
        backgroundColor: 'inherit',
        color: 'inherit',
    }

    const styleLBSec = {
        // backgroundColor: theme.palette.secondary2.main,
        // color: theme.palette.secondary2.contrastText,
        backgroundColor: 'inherit',
        color: 'inherit',
        boxShadow: theme.shadows[ 21 ],
    }

    const styleListFinalXs = {}


    switch (deviceSize) {
        case "xs":
            bLOptionsDoubleHNami.itemHeightPx = 50 // 80
            bLOptionsDoubleHNami.itemsXDistancePx = 5
            bLOptionsDouble.itemHeightPx = 20
            // fontSizeNami = "x-small" // "0.7rem"
            // fontSizeDbl = "x-small" // "0.7rem"
            styleListFinalXs.paddingLeft = 0
            styleListFinalXs.paddingRight = 0
            break;
        case "sm":
            bLOptionsDoubleHNami.itemHeightPx = 50
            bLOptionsDouble.itemHeightPx = 20
            // fontSizeNami = "x-small" // "0.7rem"
            // fontSizeDbl = "x-small" // "0.7rem"
            styleListFinalXs.paddingLeft = 0
            styleListFinalXs.paddingRight = 0
            break;
        case "md":
            bLOptionsDoubleHNami.itemHeightPx = 40
            // fontSizeNami = "x-small" // "0.7rem"
            // fontSizeDbl = "x-small" // "0.7rem"
            bLOptionsDouble.itemHeightPx = 20
            break;
        default:
            bLOptionsDoubleHNami.itemHeightPx = 40
            // fontSizeNami = "x-small" // "0.7rem"
            // fontSizeDbl = "x-small" // "0.7rem"
            bLOptionsDouble.itemHeightPx = 20
            break;
    }


    return (
        <StyledAccordion
            expanded={expanded === 'panel1'}
            onChange={handleAccordionChange('panel1')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    alignContent="center"
                    style={{ width: '100%' }}
                >
                    <Grid item xs={12} sm={6}
                        className="gridItem"
                        sx={{ fontSize: [ "1.2rem", "1.4rem", "1.4rem" ] }}
                        key="colSluzby"
                    >
                        <AnimateScrollIntoViewport
                            name={`BL-LocalServices-TvorbaWebStranok-${deviceSize}`}
                            animateFramer={{ x: 0, opacity: 1 }}
                            exitFramer={{ x: -200, opacity: 1 }}
                            styleToMerge={{ x: -200, opacity: 1 }}
                            key={`BL-LocalServices-TvorbaWebStranok-${deviceSize}`}
                        >
                            <BowListLazy
                                name="BL-LocalServices-TvorbaWebStranok"
                                options={{
                                    ...bLOptionsDouble,
                                    ...bLOptionsL,
                                    styleList: {
                                        ...styleLBPrim, ...styleBLBordersBLTR50px,
                                        fontSize: fontSizeDbl,
                                    },
                                    styleItem: { ...styleLBSec, display: 'contents', },
                                    disableAlignTextToBow: true,
                                    listPaddingTopPx,
                                    listPaddingBottomPx,
                                    listMarginTopPx,
                                    listMarginBottomPx,
                                }}
                            >
                                {[ <Link to="/tvorba-web-stranky/"
                                    className="link"
                                    style={{
                                        display: 'contents',
                                        color: 'unset',
                                    }}
                                    key="local-web"
                                >
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                                        focusable="false"
                                        aria-hidden="true"
                                        data-testid="DoneIcon"
                                        key="svg-web"
                                    >
                                        <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                                    </svg>
                                    <div
                                        key="div-web"
                                    >&nbsp;Tvorba web stránok</div>
                                </Link>,
                                <Link
                                    to="/programovanie-excel/"
                                    className="link"
                                    key="local-prog"
                                    style={{
                                        display: 'contents',
                                        color: 'unset',
                                    }}
                                >
                                    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false"
                                        aria-hidden="true" data-testid="DoneIcon"
                                        key="svg-exc"
                                    >
                                        <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                                    </svg>
                                    <div
                                        key="div-exc"
                                    >&nbsp;Programovanie pre Excel</div>
                                </Link>
                                ]}
                            </BowListLazy>
                        </AnimateScrollIntoViewport>

                    </Grid>
                    <Grid item
                        xs={12} sm={6}
                        className="gridItem"
                        key="linky"
                        sx={{ fontSize: [ "1.2rem", "1.4rem", "1.4rem" ] }}
                    >
                        <AnimateScrollIntoViewport
                            name={`BL-LocalServices-Rok-${deviceSize}`}
                            animateFramer={{ x: 0, opacity: 1 }}
                            exitFramer={{ x: 200, opacity: 0.1 }}
                            styleToMerge={{ x: 200, opacity: 0.1 }}
                            key={`BL-LocalServices-Rok-${deviceSize}`}
                        >

                            <BowListLazy
                                name="BL-LocalServices-Rok"
                                options={{
                                    ...bLOptionsDouble,
                                    ...bLOptionsR,
                                    bowPosition: "right",
                                    alignAllwaysToLeft: false,
                                    styleList: {
                                        ...styleLBSec, ...styleBLBordersBRTL50px, fontSize: fontSizeDbl,
                                    },
                                    styleItem: { ...styleItemPrimary },
                                    listPaddingTopPx,
                                    listPaddingBottomPx,
                                    listMarginTopPx,
                                    listMarginBottomPx,
                                }}
                            >
                                {[
                                    <div
                                        key="web7date"
                                    >
                                        © {new Date().getFullYear()}
                                    </div>,
                                    <div
                                        className="extLink"
                                        key="linkKriz"
                                    >
                                        <a href="https://kriz.epocha.sk"
                                            rel="nofollow noopener noreferrer"
                                            target="_blank"
                                            className="extLink"
                                        >
                                            kriz.epocha.sk &nbsp;
                                            <IconExternalLink />
                                        </a>
                                    </div>,
                                    <a href="https://pam.epocha.sk"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank"
                                        key="linkPam"
                                        className="extLink"
                                    >
                                        pam.epocha.sk &nbsp;
                                        <IconExternalLink />
                                    </a>,
                                    <a href="https://pc123.sk"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank"
                                        key="linkPc123"
                                        className="extLink"
                                    >
                                        pc123.sk &nbsp;
                                        <IconExternalLink />
                                    </a>
                                ]}
                            </BowListLazy>
                        </AnimateScrollIntoViewport>

                    </Grid>

                </Grid>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingX: [ 0, 1, 2, 3 ]
                }}
            >
                <Grid
                    container
                    spacing={[ 1, 2, 3 ]}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    alignContent="center"
                    sx={{ fontSize: [ "1.2rem", "1.2rem", "1.2rem" ] }}
                >
                    <Grid item xs={12} sm={5} md={6}
                        // spacing={0}
                        className="gridItem"
                        key="col1MestaWrap"
                    >
                        <>
                            <Grid item xs={12} sm={12} md={6}
                                className="gridItem"
                                key="col1Mesta"
                            >
                                <AnimateScrollIntoViewport
                                    name={`BL-LocalServices-NajblizsieMesta-${deviceSize}`}
                                    animateFramer={{ x: 0, opacity: 1 }}
                                    exitFramer={{ x: 200, opacity: 0.1 }}
                                    styleToMerge={{ x: 200, opacity: 0.1 }}
                                    key={`BL-LocalServices-NajblizsieMesta-${deviceSize}`}
                                >
                                    <BowListLazy
                                        name="BL-LocalServices-NajblizsieMesta"
                                        options={{
                                            ...bLOptionsDouble,
                                            ...bLOptionsUpR,
                                            styleList: { ...styleLBSec, marginBottom: 0, borderTopLeftRadius: "50px", fontSize: fontSizeDbl, },
                                            styleItem: { ...styleItemPrimary },
                                            listPaddingTopPx,
                                            listPaddingBottomPx,
                                            listMarginTopPx,
                                            listMarginBottomPx,
                                        }}
                                    >
                                        {[
                                            "Najbližšie mestá:",
                                        ]}
                                    </BowListLazy>
                                </AnimateScrollIntoViewport>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}
                                className="gridItemMesta"
                                key="col2Mesta"
                            >
                                <AnimateScrollIntoViewport
                                    name={`BL-LocalServices-Mesta-${deviceSize}`}
                                    animateFramer={{ x: 0, opacity: 1 }}
                                    exitFramer={{ x: -200, opacity: 0.1 }}
                                    styleToMerge={{ x: -200, opacity: 0.1, paddingRight: "2.5rem" }}
                                    key={`BL-LocalServices-Mesta-${deviceSize}`}
                                >

                                    <BowListLazy
                                        name="BL-LocalServices-Mesta"
                                        options={{
                                            ...bLOptionsDouble, ...bLOptionsMidR, styleList: {
                                                ...styleLBPrim, marginTop: 0,
                                                ...styleBLBordersBR20
                                                , fontSize: fontSizeDbl,
                                            }, styleItem: { ...styleLBSec },
                                            listPaddingTopPx,
                                            listPaddingBottomPx,
                                            listMarginTopPx,
                                            listMarginBottomPx,
                                        }}
                                    >
                                        {[
                                            <h4 key="skal">Skalica</h4>,
                                            <h4 key="sen">Senica</h4>,
                                            <h4 key="hod">Hodonín</h4>,
                                            <h4 key="hol">Holíč</h4>,
                                            <h4 key="myj">Myjava</h4>,
                                            <h4 key="mal">Malacky</h4>,
                                            <h4 key="trn">Trnava</h4>,
                                            <h4 key="petr">Petrov</h4>,
                                            <h4 key="tre">Trenčín</h4>,
                                        ]}
                                    </BowListLazy>
                                </AnimateScrollIntoViewport>

                            </Grid>
                        </>

                    </Grid>
                    <Grid item xs={12} sm={5} md={6}
                        className="gridItem"
                        key="col2MestaWrap"
                    >
                        <AnimateScrollIntoViewport
                            name={`BLLocalServicesNamiPoskytovane-${deviceSize}`}
                            animateFramer={{ x: 0, opacity: 1 }}
                            exitFramer={{ x: 200, opacity: 0.11 }}
                            styleToMerge={{ x: 200, opacity: 0.1 }}
                            key={`BL-LocalServices-NamiPoskytovane-${deviceSize}`}
                        >
                            <BowListLazy
                                name={`BLLocalServicesNamiPoskytovane`}
                                options={{
                                    // name: `BLLocalServicesNamiPoskytovane-${currentSize}`,
                                    ...bLOptionsDoubleHNami,
                                    ...bLOptionsR,
                                    bowPosition: "right",
                                    styleList: { ...styleLBPrim, ...styleBLBordersBLTR50px, fontSize: fontSizeNami },
                                    styleItem: {
                                        // ...styleLBSec, 
                                        display: "flexbox",
                                        marginTop: 5,
                                    },
                                    listPaddingTopPx: 5,
                                    listPaddingBottomPx: 5,
                                    listMarginTopPx: 0,
                                    listMarginBottomPx: 0,
                                    styleListFinal: styleListFinalXs,
                                    itemMarginYPx: 10,
                                }}
                                key={`NamiPoskytovane`}
                            >
                                {[
                                    "Nami poskytované služy vo všeobecnosti nie sú závislé na osobných stretnutiach.",
                                    "Vystačíme aj s komunikáciou cez mobil, internet a úložiskami na cloude.",
                                    "Vytvoríme vám moderné webstránky aj keď sa nebudeme môcť stretnúť.",
                                    "Objednajte si webstránky u nás, hoci ste z inej časti Slovenska",
                                ]}
                            </BowListLazy>
                            <Box className="ine-mesta"
                                sx={{ fontSize: [ "x-small", "x-small", "x-small" ] }}
                                key="dalsie">
                                <AnimatingChildrenColumnsOrderingWithFlip styleGrid={{ height: '50px' }}>
                                    <h4 key="mich">Michalovce</h4>
                                    <h4 key="kos">Košice</h4>
                                    <h4 key="pop">Poprad</h4>
                                    <h4 key="zil">Žilina</h4>
                                    <h4 key="bb">Banská Bystrica</h4>
                                    <h4 key="nit">Nitra</h4>
                                    <h4 key="br">Bratislava</h4>
                                    <h4 key="br">Prešov</h4>
                                </AnimatingChildrenColumnsOrderingWithFlip>
                            </Box>
                        </AnimateScrollIntoViewport>
                    </Grid>
                </Grid>
                <div style={{ fontSize: "x-small" }}>František Chromek IČO: 53502965 Číslo živnostenského registra: 240-29518</div>
            </AccordionDetails>
        </StyledAccordion>
    )
}


export default LocalServices;
