import React from "react";
import Typography from '@mui/material/Typography';
import VideoTag from "../../_shared/videoTag"

const Objednavky = () => {

  return (
    <>
      <Typography variant="h4" sx={{ fontSize: "medium" }}>
        Objednávky
      </Typography>
      <Typography sx={{ fontSize: [ "small", "medium" ] }}>
        Aplikácia v Exceli riešiaca ponuky, objednávky a faktúry.
      </Typography>
      <div className="custom">
        <ul>
          <Typography sx={{ fontSize: [ "small", "medium" ] }}>
            Automatizované
          </Typography>
          <li>emailové odosielanie ponúk zákazníkom</li>
          <li>evidencia odoslaných emailov s ponukami</li>
          <li>spracovanie prijatých objednávok</li>
          <li>evidencia spracovaných objednávok</li>
          <li>vytvorenie dodacích listov</li>
          <li>evidencia vytvorených dodacích listov</li>
          <li>vytvorenie faktúr</li>
          <li>evidencia vytvorených faktúr</li>
          <li>tlač objednávok, dodacích listov a faktúr</li>
          <li>ukladanie evidovaných objednávok, dodacích listov a faktúr vo formáte xlsx aj pdf</li>
          <li>predpripravené pivot tabuľky a grafy analyzujúce objednané počty a ceny za časové obdobia (od jednotlivých dodávateľov, celkovo, s filtrami)</li>
        </ul>
      </div>
      <Typography sx={{ fontSize: [ "small", "medium" ] }}>
        Program generuje všetky potrebné súbory a adresárovú štruktúru.
      </Typography>
      <VideoTag src="/sf/vba-obj/obj.mp4" title="App Objednávky" />
    </>
  );
};

export default Objednavky;
