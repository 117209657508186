import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { morphingFuncMap } from '../animatedSvgImages/animateSvgInParentPaddingsConfig.js'
import { createWavesFuncMap } from "../animatedSvgImages/utilities.js"

import { getValueOfColorOrThemeNamedColor } from "../../../utils/colorUtilities"

const defaultOptions = {
    wavesNumber: 13,
    wavesHeight: 50,
    wavesWidth: 50,
    wavesStrokeDasharray: '0 1',
    wavesStrokeLinecap: 'round',
    wavesStrokeLinejoin: 'round',
    wavesStrokeMiterlimit: 4,
    wavesFill: 'transparent',
    wavesFillRule: 'evenodd',
    wavesClipPath: 'none',
    wavesClipRule: 'nonzero',
    wavesStrokeDashoffset: 0,
    wavesStrokeOpacity: 1,
    wavesFillOpacity: 1,
    wavesStrokeWidth: 1,
    fillColor: "warning",
    strokeColor: "warning",

    rightPathName: "decreasingAmplitude",
    bottomPathName: "decreasingAmplitude",
    leftPathName: "increasingAmplitude",
    topPathPars: {
        wavesNumber: 13,
        svgPathName: "increasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    },
    rightPathPars: {
        wavesNumber: 13,
        svgPathName: "increasingAmplitude",
        heightCoeff: 1,
        widthCoeff: 1,
    },
    bottomPathPars: {
        wavesNumber: 13,
        svgPathName: "decreasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    },
    leftPathPars: {
        wavesNumber: 13,
        svgPathName: "decreasingAmplitude",
        heightCoeff: 1,
        widthCoeff: 1,
    },
}

const defaultAnimationConfig = [
    {
        path: 'top',
        animations: [
            {
                type: 'morphing',
                func: "horizontal",
                waves: 13,
                key: 'a',
                duration: 2
            },
            {
                type: 'wave',
                func: "horizontal",
                waves: 13,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'morphing',
                func: "horizontalSpring",
                waves: 3,
                key: 'a',
                isSpring: true,
            },
            {
                type: 'wave',
                func: "horizontalIncreasingDecreasing",
                waves: 3,
                duration: 2
            },
        ],
    },
    {
        path: 'right',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsVertical(13, refParentHeight, refParentPadding.right).b,
                func: "vertical",
                waves: 13,
                key: 'b',
                duration: 2
            },
        ],
    },
    {
        path: 'bottom',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsHorizontal(3, refParentWidth, refParentPadding.bottom).b,
                func: "horizontal",
                waves: 3,
                key: 'b',
                duration: 2
            },
            {
                type: 'morphing',
                func: "horizontalSpring",
                waves: 13,
                key: 'b',
                isSpring: true,
            },
        ],
    },
    {
        path: 'left',
        animations: [
            {
                type: 'morphing',
                func: "vertical",
                waves: 13,
                key: 'a',
                duration: 2
            },
        ],
    },
]


const SvgInParentPaddingsAnimated = ({ refParent, options, animationConfig }) => {

    const mergedOptions = { ...defaultOptions, ...options };

    const { topPathPars, bottomPathPars, rightPathPars, leftPathPars, fillColor, strokeColor, } = mergedOptions;
    // let { fillColor, strokeColor, } = mergedOptions;

    // const isMounted = useRef(false);
    // const refWrap = useRef(null)
    const inView = useInView(refParent)
    const [ wasViewed, setWasViewed ] = useState(false);
    const refViewedCount = useRef(0);
    const [ refParentWidth, setRefParentWidth ] = useState(0);
    const [ refParentHeight, setRefParentHeight ] = useState(0);
    const [ refParentPadding, setRefParentPadding ] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
    const controlsPathTop = useAnimation();
    const controlsPathBottom = useAnimation();
    const controlsPathLeft = useAnimation();
    const controlsPathRight = useAnimation();


    // const theme = useTheme();
    // fillColor = fillColor ? fillColor : theme.palette.primary2.main
    // strokeColor = strokeColor ? strokeColor : theme.palette.primary2.contrastText
    const strokeColorIntoStyle = getValueOfColorOrThemeNamedColor(strokeColor, 'main', true)
    const fillColorIntoStyle = getValueOfColorOrThemeNamedColor(fillColor, 'main', false)
    // console.log("strokeColorIntoStyle", strokeColorIntoStyle)
    // console.log("fillColorIntoStyle", fillColorIntoStyle)
    const controlsForPath = useMemo(() => ({
        top: controlsPathTop,
        bottom: controlsPathBottom,
        left: controlsPathLeft,
        right: controlsPathRight
    }), [ controlsPathTop, controlsPathBottom, controlsPathLeft, controlsPathRight ]);

    const [ animationTrigger, setAnimationTrigger ] = useState(0);
    const restartAnimation = () => {
        setAnimationTrigger(prev => prev + 1);
    };

    animationConfig = animationConfig || defaultAnimationConfig;

    const setRefParentBoundingBoxValues = useCallback(() => {
        if (!refParent.current) return
        const { width, height } = refParent?.current.getBoundingClientRect();
        if (width === 0 || height === 0) return
        setRefParentHeight(height);
        setRefParentWidth(width);
    }, [ refParent ]);


    useEffect(() => {
        // Create a new ResizeObserver that updates the 'update' state to force a re-render
        const resizeObserver = new ResizeObserver(setRefParentBoundingBoxValues);
        const observedElement = refParent.current;
        // Start observing the parent element
        // if (refParent.current) {
        if (observedElement) {
            // resizeObserver.observe(refParent.current);
            resizeObserver.observe(observedElement);
        }

        // Clean up function
        return () => {
            // if (refParent.current) {
            if (observedElement) {
                // resizeObserver.unobserve(refParent.current);
                resizeObserver.unobserve(observedElement);
            }
        };
    }, [ refParent, setRefParentBoundingBoxValues ]);

    useEffect(() => {
        if (refParent.current) {
            if (!refParentWidth) setRefParentBoundingBoxValues()
            if (!refParentWidth) return
            const style = window.getComputedStyle(refParent.current);
            setRefParentPadding({
                top: parseInt(style.paddingTop),
                right: parseInt(style.paddingRight),
                bottom: parseInt(style.paddingBottom),
                left: parseInt(style.paddingLeft),
            });
        }
    }, [ refParent, refParentWidth, setRefParentPadding, setRefParentBoundingBoxValues ]);

    const dPathsTop = useMemo(() => {
        if (refParentWidth === 0) return []
        const config = animationConfig.find(config => config.path === 'top')
        const dPathsSum = []
        for (const animation of config.animations) {
            let dPaths = []
            if (animation.type === 'morphing') {
                const functionToCall = morphingFuncMap[ animation.func ];
                const outerFunc = functionToCall(animation.waves, refParentWidth, refParentPadding.top);
                dPaths = outerFunc[ animation.key ];
            } else if (animation.type === 'wave') {
                const functionToCall = createWavesFuncMap[ animation.func ];
                const argAfter = animation.argAfter
                const args = (argAfter !== undefined && argAfter !== null) ? [ animation.waves, refParentWidth, refParentPadding.top, argAfter ] : [ animation.waves, refParentWidth, refParentPadding.top ]
                const dPath = functionToCall(...args);
                dPaths = [ dPath ];
            }
            dPathsSum.push(dPaths)
        }
        return dPathsSum
    }, [ animationConfig, refParentWidth, refParentPadding.top ]);

    // console.log("dPathsTop", dPathsTop)

    const dPathsRight = useMemo(() => {
        if (refParentHeight === 0) return []
        const config = animationConfig.find(config => config.path === 'right')
        const dPathsSum = []
        for (const animation of config.animations) {
            let dPaths = []
            if (animation.type === 'morphing') {
                const functionToCall = morphingFuncMap[ animation.func ];
                const outerFunc = functionToCall(animation.waves, refParentHeight, refParentPadding.right);
                dPaths = outerFunc[ animation.key ];
            } else if (animation.type === 'wave') {
                const functionToCall = createWavesFuncMap[ animation.func ];
                const argAfter = animation.argAfter
                const args = (argAfter !== undefined && argAfter !== null) ? [ animation.waves, refParentHeight, refParentPadding.right, argAfter ] : [ animation.waves, refParentHeight, refParentPadding.right ]
                const dPath = functionToCall(...args);
                dPaths = [ dPath ];
            }
            dPathsSum.push(dPaths)
        }
        return dPathsSum
    }, [ animationConfig, refParentHeight, refParentPadding.right ]);

    // console.log("dPathsRight", dPathsRight)

    const dPathsBottom = useMemo(() => {
        if (refParentWidth === 0) return []
        const config = animationConfig.find(config => config.path === 'bottom')
        const dPathsSum = []
        for (const animation of config.animations) {
            let dPaths = []
            if (animation.type === 'morphing') {
                const functionToCall = morphingFuncMap[ animation.func ];
                const outerFunc = functionToCall(animation.waves, refParentWidth, refParentPadding.bottom);
                dPaths = outerFunc[ animation.key ];
            } else if (animation.type === 'wave') {

                const functionToCall = createWavesFuncMap[ animation.func ];
                const argAfter = animation.argAfter
                const args = (argAfter !== undefined && argAfter !== null) ? [ animation.waves, refParentWidth, refParentPadding.bottom, argAfter ] : [ animation.waves, refParentWidth, refParentPadding.bottom ]
                const dPath = functionToCall(...args);
                dPaths = [ dPath ];
            }
            dPathsSum.push(dPaths)
        }
        return dPathsSum
    }, [ animationConfig, refParentWidth, refParentPadding.bottom ]);

    // console.log("dPathsBottom", dPathsBottom)

    const dPathsLeft = useMemo(() => {
        if (refParentHeight === 0) return []
        const config = animationConfig.find(config => config.path === 'left')
        const dPathsSum = []
        for (const animation of config.animations) {
            let dPaths = []
            if (animation.type === 'morphing') {
                const functionToCall = morphingFuncMap[ animation.func ];
                const outerFunc = functionToCall(animation.waves, refParentHeight, refParentPadding.left);
                dPaths = outerFunc[ animation.key ];
            } else if (animation.type === 'wave') {
                const functionToCall = createWavesFuncMap[ animation.func ];
                const argAfter = animation.argAfter
                const args = (argAfter !== undefined && argAfter !== null) ? [ animation.waves, refParentHeight, refParentPadding.left, argAfter ] : [ animation.waves, refParentHeight, refParentPadding.left ]
                const dPath = functionToCall(...args);
                dPaths = [ dPath ];
            }
            dPathsSum.push(dPaths)
        }
        return dPathsSum
    }, [ animationConfig, refParentHeight, refParentPadding.left ]);

    // console.log("dPathsLeft", dPathsLeft)

    useEffect(() => {
        let isMounted = true;
        if (refParentWidth === 0 || refParentHeight === 0) return
        const stopControls = () => {
            controlsPathTop.stop()
            controlsPathRight.stop()
            controlsPathBottom.stop()
            controlsPathLeft.stop()
        }
        if (!inView) {
            stopControls()
            return
        }
        // if (inView && !wasViewed) {
        if (inView && refViewedCount.current < 2) {
            refViewedCount.current = refViewedCount.current + 1
            // First time in view, don't run animation but mark as seen
            // setWasViewed(true);
        }
        async function runPathAnimations(config) {
            const controls = controlsForPath[ config.path ];
            let i = -1
            for (const animation of config.animations) {
                i = i + 1
                const { isSpring, duration } = animation
                const dPathsMem = []
                if (config.path === 'top') {
                    dPathsMem[ i ] = dPathsTop[ i ]

                } else if (config.path === 'right') {
                    dPathsMem[ i ] = dPathsRight[ i ]
                } else if (config.path === 'bottom') {
                    dPathsMem[ i ] = dPathsBottom[ i ]
                } else if (config.path === 'left') {
                    dPathsMem[ i ] = dPathsLeft[ i ]
                }
                for (const dPath of dPathsMem[ i ]) {
                    if (!isMounted) return;
                    isMounted && await controls.start({
                        d: dPath,
                        transition: isSpring ? { type: "spring", damping: 1, stiffness: 10 } : { duration: duration }
                    });
                }
            }

        }
        async function seq1() {


            const pathsRevealAnimation = async () => {
                isMounted && await Promise.all([
                    controlsPathTop.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathRight.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathBottom.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathLeft.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                ]);
                isMounted && await Promise.all([
                    controlsPathTop.start({
                        pathLength: 0, pathOffset: -1,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathRight.start({
                        pathLength: 0, pathOffset: -1,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathBottom.start({
                        pathLength: 0, pathOffset: 1,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathLeft.start({
                        pathLength: 0, pathOffset: 1,
                        transition: {
                            duration: 2,
                        }
                    }),
                ]);
                isMounted && await Promise.all([
                    controlsPathTop.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathRight.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathBottom.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                    controlsPathLeft.start({
                        pathLength: 1, pathOffset: 0,
                        transition: {
                            duration: 2,
                        }
                    }),
                ]);
            }

            isMounted && await pathsRevealAnimation();
            const allAnimations = animationConfig.map(config => runPathAnimations(config));
            isMounted && await Promise.all(allAnimations);

        }
        refViewedCount.current > 1 && seq1()
        // if (refViewedCount.current > 1) {
        //     seq1()
        // } else {
        //     controlsPathTop.set({
        //         pathLength: 1, pathOffset: 0,
        //     })
        // }
        return () => {
            isMounted = false;
            controlsPathTop.stop()
            controlsPathRight.stop()
            controlsPathBottom.stop()
            controlsPathLeft.stop()
        };
    }, [ inView, wasViewed, animationTrigger, controlsPathLeft, controlsPathRight, controlsPathTop, controlsPathBottom, animationConfig, controlsForPath, refParentHeight, refParentWidth, refParentPadding.bottom, refParentPadding.left, refParentPadding.right, refParentPadding.top, dPathsBottom, dPathsLeft, dPathsRight, dPathsTop ]);

    // dForPathForMorphingLeft, dForPathForMorphingTop, dForPathForMorphingBottom, dForPathForMorphingTopSpring,dForPathForMorphingBottomSpring, dForPathForMorphingRight
    return (
        <>
            {refParentPadding.top > 0 && (
                <div
                    className="paddingFrameTop"
                    style={{
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: `${refParentPadding.top}px`,
                        position: "absolute",
                    }}
                ></div>)}
            {refParentPadding.top > 0 && (
                <svg
                    onClick={restartAnimation}
                    viewBox={`0 0  ${refParentWidth} ${refParentPadding.top}`}
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: `${refParentPadding.top}px`,
                        width: "100%",
                        cursor: "pointer",
                    }}
                >
                    <motion.path
                        d={dPathsTop[ 0 ][ 0 ]}
                        stroke={strokeColorIntoStyle}
                        fill={fillColorIntoStyle}
                        strokeDasharray="0 1"
                        initial={{ pathLength: 0, pathOffset: 1 }}
                        animate={controlsPathTop}
                    />
                </svg>)}
            {refParentPadding.right > 0 && (<div
                className='paddingFrameRight'
                style={{
                    top: 0,
                    right: 0,
                    width: `${refParentPadding.right}px`,
                    height: "100%",
                    position: "absolute",
                }}
            ></div>)}
            {refParentPadding.right > 0 && (<svg
                onClick={restartAnimation}
                viewBox={`0 0 ${refParentPadding.right} ${refParentHeight}`}
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: `${refParentPadding.right}px`,
                    height: "100%",
                    cursor: "pointer",
                }}
            >
                <motion.path
                    d={dPathsRight[ 0 ][ 0 ]}
                    stroke={strokeColorIntoStyle}
                    fill={fillColorIntoStyle}
                    strokeDasharray="0 1"
                    initial={{ pathLength: 0, pathOffset: 1 }}
                    animate={controlsPathRight}
                />
            </svg>
            )}
            {refParentPadding.bottom > 0 && (<div
                className='paddingFrameBottom'
                style={{
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: `${refParentPadding.bottom}px`,
                    position: "absolute",
                }}
            ></div>)}
            {refParentPadding.bottom > 0 && (<svg
                onClick={restartAnimation}
                viewBox={`0 0  ${refParentWidth} ${refParentPadding.bottom}`}
                style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    height: `${refParentPadding.bottom}px`,
                    width: "100%",
                    cursor: "pointer",
                }}
            >
                <motion.path
                    d={dPathsBottom[ 0 ][ 0 ]}
                    stroke={strokeColorIntoStyle}
                    fill={fillColorIntoStyle}
                    strokeDasharray="0 1"
                    initial={{ pathLength: 0, pathOffset: -1 }}
                    animate={controlsPathBottom} />
            </svg>)}
            {refParentPadding.left > 0 && (<div
                className='paddingFrameLeft'
                style={{
                    top: 0,
                    left: 0,
                    width: `${refParentPadding.left}px`,
                    height: "100%",
                    position: "absolute",
                }}
            ></div>)}
            {refParentPadding.left > 0 && (<svg
                onClick={restartAnimation}
                viewBox={`0 0 ${refParentPadding.left} ${refParentHeight}`}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: `${refParentPadding.left}px`,
                    height: "100%",
                    cursor: "pointer",
                }}
            >
                <motion.path
                    d={dPathsLeft[ 0 ][ 0 ]}
                    stroke={strokeColorIntoStyle}
                    fill={fillColorIntoStyle}
                    strokeDasharray="0 1"
                    initial={{ pathLength: 0, pathOffset: 1 }}
                    animate={controlsPathLeft} />
            </svg>
            )}
        </>)
}


// export default SvgInParentPaddingsAnimated

const MemoizedSvgInParentPaddingsAnimated = React.memo(SvgInParentPaddingsAnimated);

export default MemoizedSvgInParentPaddingsAnimated;