import React from "react";
import { MotionValue } from "framer-motion";
import AroundItem from "./AroundItem";

type AroundListProps = {
    aroundItems: {
        iItem: number, cardOptions: {
            imgName: string;
            imgSrc: string;
            itemMaxWidthPx: number;
        }
    }[];
    aroundPositions: any[];
    classNameAround: string;
    selectedIndex: number;
    // handleOnClick: ({ e, iItem }: { e: MouseEvent<Element, MouseEvent>; iItem: number; }) => void;
    angleM: MotionValue<number>;
    deviceSize: string;
    isInView: boolean;
};

const AroundList = (props: AroundListProps) => {
    const {
        aroundItems,
        aroundPositions,
        classNameAround,
        selectedIndex,
        // handleOnClick,
        angleM,
        deviceSize,
        isInView,
    } = props;

    return (
        <>
            {aroundItems.map((itemAr) => {  //: any, i: number
                const { iItem, cardOptions } = itemAr;
                return (
                    <AroundItem
                        iItem={iItem}
                        cardOptions={cardOptions}
                        positionFirst={aroundPositions[iItem]}
                        aroundPositions={aroundPositions}
                        classNameAround={classNameAround}
                        // handleOnClick={(params: { e: React.MouseEvent<HTMLDivElement>; iItem: number }) => handleOnClick(params)}
                        // handleOnClick={handleOnClick}  //{(e) => handleOnClick({ e, iItem })}
                        selectedIndex={selectedIndex}
                        angleM={angleM}
                        deviceSize={deviceSize}
                        isInView={isInView}
                        key={`aroundItem-${iItem}`}
                    />
                );
            })}
        </>
    );
};

export default AroundList