const getAngleInRadsFromDeg = (angle) => {
    // Trigonometry methods in JS use radians, not
    // degrees, so we need to convert.
    const angleInRads = (angle * Math.PI) / 180;
    return angleInRads
}


const getXYAtDistanceFromCenter = (angleInRads, distancePxFromCenter) => {
    // debugger
    // If this was meant to be reusable, this would
    // be configurable, but it's not, so it's
    // hardcoded. The # of pixels from the center
    // that our circle will bounce.
    // const distancePxFromCenter = 42;
    // Convert polar coordinages (angle, distance)
    // to cartesian ones (x, y), since JS uses
    // a cartesian coordinate system:
    const x = distancePxFromCenter * Math.cos(angleInRads);
    const y = distancePxFromCenter * Math.sin(angleInRads);
    return [ x, y ]
}

const getXYAtDistanceFromCenterRev = (angleInRads, distancePxFromCenter) => {
    // debugger
    // If this was meant to be reusable, this would
    // be configurable, but it's not, so it's
    // hardcoded. The # of pixels from the center
    // that our circle will bounce.
    // const distancePxFromCenter = 42;
    // Convert polar coordinages (angle, distance)
    // to cartesian ones (x, y), since JS uses
    // a cartesian coordinate system:
    const x = distancePxFromCenter * Math.cos(2 * Math.PI - angleInRads);
    const y = distancePxFromCenter * Math.sin(2 * Math.PI - angleInRads);
    // const x = distancePxFromCenter * Math.sin(angleInRads);
    // const y = distancePxFromCenter * Math.cos(angleInRads);
    return [ x, y ]
}

const getAngleOfPointOnCircle = (index, numberOfPoints, fullAngle = 360, angleStartCorr = -90) => {
    // Our star has 5 points across a 360-degree area.
    // Our first point should shoot out at 0 degrees,
    // our second at 72 degrees (1/5th of 360),
    // our third at 144 degrees, and so on.
    let angle = index * (fullAngle / numberOfPoints);
    // By default in JS, 0-degrees is the 3-o'clock
    // position, but I want my animation to start at
    // the 12-o'clock position, so I'll subtract
    // 90 degrees
    // angle -= 90;
    angle = angle + angleStartCorr;
    // Trigonometry methods in JS use radians, not
    // degrees, so we need to convert.
    // const angleInRads = (angle * Math.PI) / 180;
    return angle
}

const normalizeValueIntoOtherScale = (
    currentValue,
    currentScaleMin,
    currentScaleMax,
    newScaleMin = 0,
    newScaleMax = 1
) => {
    // `normalize` is commonly called "lerp",
    // as well as Linear Interpolation. It
    // maps a value from one scale to another.

    //
    // FIrst, normalize the value between 0 and 1.
    const standardNormalization =
        (currentValue - currentScaleMin) / (currentScaleMax - currentScaleMin);
    // Next, transpose that value to our desired scale.
    return (
        (newScaleMax - newScaleMin) * standardNormalization + newScaleMin
    );
    // use like this
    // let indexDelayMs = normalize(index, 0, numberOfPoints - 1, 450, 600);

    // or like this
    // In this case, I want the time to vary
    // between 450ms and 600ms, with the first
    // point being the slowest, and the last
    // one being the fastest.
    // const friction = normalize(index, 0, numberOfPoints - 1, 15, 40);

};

export {
    getAngleInRadsFromDeg,
    getXYAtDistanceFromCenter,
    getXYAtDistanceFromCenterRev,
    getAngleOfPointOnCircle,
    normalizeValueIntoOtherScale,
}