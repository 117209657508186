import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const IntroTextWordpressProblemCCh = ({ colors = {} }) => {
    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;
    const myAnimationOptionsCCh = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="Wordpress-ma-nevyhody" className="toc toc0"><span>Wordpress má niektoré nevýhody</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="wordpress"
            >
                <div>Môžte si spraviť veľmi pekné webstránky aj sami</div>
                <div>A ak ste zručnejší, vo Wordpresse si môžte spraviť aj zložitejšie veľmi pekné webstránky s niektorými funkcionalitami.</div>
                <div>Ak vám stačí pekný statický web, alebo systém nastavený na pridávanie článkov, tak Wordpress nie je tak zlá voľba.</div>
                <div>Ale možno ste si všimli,</div>
                <div>že niektoré webstránky sú si podobné, že ste už niekde také videli,</div>
                <div>že nie sú najrýchlejšie,</div>
                {/* <div>že texty sa trocha strácajú v peknom grafickom dizajne,</div> */}
                <div>Možno, že webstránky na Wordpresse máte a chceli ste ešte niečo tam mať, ale taký plugin nebol,</div>
                <div>a aj malá úprava niekedy vyžaduje určitú úroveň znalosti php,</div>
                <div>a teda, no čo už, alebo to treba u niekoho objednať...</div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2"
                >
                    <div>Ak už máte webstránky vo Wordpresse a chcete niečo zmeniť alebo pridať môžte sa obrátiť na nás.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default IntroTextWordpressProblemCCh;  
