import React, { useLayoutEffect, useState } from "react";
import { Box, useTheme } from "@mui/system";
import { getMaskStaticPathWithAspectRatio } from "../../utils/staticPathsForMaskImages"
import { useBreakpoint } from "./contextBreakpointMediaQ";

// const hiddenMask = "linear-gradient(black, transparent)" // `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;

const defaultOptions = {
    styleAll: {},
    masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
    // cornerMasks:{topLeft:"mask20TL", topRight: "mask20TR", bottomRight: "mask20BR", bottomLeft: "mask20BL"},
    // masks: { top: "mask11Kruh", right: "mask11Kruh", bottom: "mask10V", left: "mask10" },
    frames: [ "top", "right", "bottom", "left" ],
    topFr: {
        isCornerMask: false,
        frameThickness: 100,
        colors3ForLinGradient: [ "red", "primary.main", "red" ],
        xDiff: -10,
        yDiff: -10,
        style: {
            // WebkitMaskRepeat: 'repeat-x', maskRepeat: 'repeat-x'
        },
        classFr: "topFr"
    },
    rightFr: {
        isCornerMask: false,
        frameThickness: 100,
        // colors3ForLinGradient: [ "primary.dark", "primary.main", "primary.light", ],
        colors3ForLinGradient: [ "primary.dark", "primary.main", "transparent", ],
        xDiff: -10,
        yDiff: -10,
        classFr: "rightFr"
    },
    bottomFr: {
        isCornerMask: false,
        frameThickness: 100,
        colors3ForLinGradient: [ "primary.dark", "primary.main", "primary.light", ],
        xDiff: -10,
        yDiff: -10,
        classFr: "bottomFr"
    },
    leftFr: {
        isCornerMask: false,
        frameThickness: 100,
        // colors3ForLinGradient: [ "transparent", "primary2.main", "transparent" ],
        colors3ForLinGradient: [ "primary.dark", "primary.main", "transparent", ],
        xDiff: -10,
        yDiff: -10,
        classFr: "leftFr"
    },
}

const sxStyleWrap = (styleAll) => ({
    position: "relative",
    width: 'inherit',
    zIndex: '0',
    "& .divFrameM": {
        position: "absolute",
        borderStyle: 'solid',
        mixBlendMode: 'multiply',
        maxWidth: '100%',
        zIndex: "0",
        ...styleAll
    }
})




// ==================================================================
const FrameMaskForDiv = ({ children, styleWrap = {}, options = {
    ...defaultOptions, topFr: { isCornerMask: true }, rightFr: { isCornerMask: true },
    bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
}, defaults = { ...defaultOptions } }) => {

    const deviceWidth = useBreakpoint()

    const { masks: masksD, frames: framesD, leftFr: leftFrD, rightFr: rightFrD, topFr: topFrD, bottomFr: bottomFrD } = defaults
    let { frames, masks, leftFr, rightFr, topFr, bottomFr } = options
    const { styleAll = {} } = options

    frames = frames || framesD
    masks = masks || masksD

    const isTop = frames.includes("top")
    const isRight = frames.includes("right")
    const isBottom = frames.includes("bottom")
    const isLeft = frames.includes("left")

    leftFr = isLeft ? { ...leftFrD, ...leftFr } : undefined
    rightFr = isRight ? { ...rightFrD, ...rightFr } : undefined
    topFr = isTop ? { ...topFrD, ...topFr } : undefined
    bottomFr = isBottom ? { ...bottomFrD, ...bottomFr } : undefined

    const staticPathAndAspectRatio = {
        top: isTop ? getMaskStaticPathWithAspectRatio(masks.top) : undefined,
        right: isRight ? getMaskStaticPathWithAspectRatio(masks.right) : undefined,
        bottom: isBottom ? getMaskStaticPathWithAspectRatio(masks.bottom) : undefined,
        left: isLeft ? getMaskStaticPathWithAspectRatio(masks.left) : undefined
    }

    return (

        <Box
            sx={sxStyleWrap(styleAll)}
            style={styleWrap}
        >
            {isTop && <Frame deviceWidth={deviceWidth} fr={topFr} staticMaskImgPath={staticPathAndAspectRatio.top?.path} aspectRatio={staticPathAndAspectRatio.top?.aspectRatio} pos="top" />}
            {isRight && <Frame deviceWidth={deviceWidth} fr={rightFr} staticMaskImgPath={staticPathAndAspectRatio.right?.path} aspectRatio={staticPathAndAspectRatio.right?.aspectRatio} pos="right" />}
            {isBottom && <Frame deviceWidth={deviceWidth} fr={bottomFr} staticMaskImgPath={staticPathAndAspectRatio.bottom?.path} aspectRatio={staticPathAndAspectRatio.bottom?.aspectRatio} pos="bottom" />}
            {isLeft && <Frame deviceWidth={deviceWidth} fr={leftFr} staticMaskImgPath={staticPathAndAspectRatio.left?.path} aspectRatio={staticPathAndAspectRatio.left?.aspectRatio} pos="left" />}
            {children}
        </Box>
    )
}



const Frame = ({ deviceWidth, fr, staticMaskImgPath, aspectRatio, pos = "top" }) => {
    const theme = useTheme()

    const {
        isCornerMask,
        colors3ForLinGradient,
        style = {},
        classFr
    } = fr

    const { xDiff, yDiff, frameThickness = 100 } = {
        sm: { xDiff: fr.xDiff / 2, yDiff: fr.yDiff / 2, frameThickness: fr.frameThickness / 4 },
        xs: { xDiff: fr.xDiff / 4, yDiff: fr.yDiff / 4, frameThickness: fr.frameThickness / 4 },
        md: { xDiff: fr.xDiff, yDiff: fr.yDiff, frameThickness: fr.frameThickness },
        lg: { xDiff: fr.xDiff, yDiff: fr.yDiff, frameThickness: fr.frameThickness },
        xl: { xDiff: fr.xDiff, yDiff: fr.yDiff, frameThickness: fr.frameThickness },
    }[ deviceWidth ] || {};

    const colors3Themed = colors3ForLinGradient.map((col => {

        const arr = col.split(".")

        if (arr.length === 2) {
            const arr = col.split(".")

            const newCol = theme.palette[ arr[ 0 ] ][ arr[ 1 ] ]
            return newCol
        }
        return col
    }))



    const getStylePosAndGradientDirection = () => {
        switch (pos) {
            case "top":
                return {
                    stylePos: {
                        top: `${yDiff}px`,
                        left: `${xDiff}px`,
                        width: isCornerMask ? `${aspectRatio * frameThickness}px` : `calc(100% - ${2 * xDiff}px)`,
                        height: `${frameThickness}px`
                    },
                    gradientDirection: isCornerMask ? "to bottom right" : "to bottom"
                };
            case "right":
                return {
                    stylePos: {
                        top: `${yDiff}px`,
                        right: `${xDiff}px`,
                        width: isCornerMask ? `${aspectRatio * frameThickness}px` : `${frameThickness}px`,
                        height: isCornerMask ? `${frameThickness}px` : `calc(100% - ${2 * yDiff}px)`
                    },
                    gradientDirection: isCornerMask ? "to bottom left" : "to left"
                };
            case "bottom":
                return {
                    stylePos: {
                        bottom: `${yDiff}px`,
                        left: isCornerMask ? "unset" : `${xDiff}px`,
                        right: isCornerMask ? `${xDiff}px` : "unset",
                        width: isCornerMask ? `${aspectRatio * frameThickness}px` : `calc(100% - ${2 * xDiff}px)`,
                        height: `${frameThickness}px`
                    },
                    gradientDirection: isCornerMask ? "to top left" : "to top"
                };
            case "left":
                return {
                    stylePos: {
                        //     top: `${yDiff}px`,
                        //     left: `${xDiff}px`,
                        top: isCornerMask ? "unset" : `${yDiff}px`,
                        bottom: isCornerMask ? `${yDiff}px` : "unset",
                        left: `${xDiff}px`,
                        width: isCornerMask ? `${aspectRatio * frameThickness}px` : `${frameThickness}px`,
                        height: isCornerMask ? `${frameThickness}px` : `calc(100% - ${2 * yDiff}px)`
                    },
                    gradientDirection: isCornerMask ? "to top right" : "to right"
                };
            default:
                return {};
        }
    };

    const { stylePos, gradientDirection } = getStylePosAndGradientDirection();


    const gradient3 = `linear-gradient(${gradientDirection},${colors3Themed[ 0 ]}, ${colors3Themed[ 1 ]}, ${colors3Themed[ 2 ]})`

    const [ isBgImgReadyToLoad, setIsBgImgReadyToLoad ] = useState(false)

    useLayoutEffect(() => {

        const imgBg = new Image();
        imgBg.src = staticMaskImgPath;
        imgBg.onload = () => {
            setIsBgImgReadyToLoad(true)
            //2-nd alternative: imgBg.addEventListener('load', () => setIsBgImgReadyToLoad(true))
        }
    }, [ staticMaskImgPath ]);

    return (
        <Box
            className={`${classFr} divFrameM`}
            sx={{
                ...stylePos,
                ...style,
                WebkitMaskSize: isCornerMask ? "100% 100%" : "inherit",
                maskSize: isCornerMask ? "100% 100%" : "contain",
                WebkitMaskRepeat: isCornerMask ? "unset" : 'round',
                // background: (theme) => `linear-gradient(to right,${leftFr.grad3Colors[ 0 ]}, ${theme.palette[ leftFr.grad3Colors[ 1 ] ].main}, ${leftFr.grad3Colors[ 2 ]})`,
                // background: `linear-gradient(${gradientDirection}, ${colors3Themed.join(", ")})`,
                background: gradient3,
                WebkitMaskImage: isBgImgReadyToLoad ? `url(${staticMaskImgPath})` : "unset",
                maskImage: isBgImgReadyToLoad ? `url(${staticMaskImgPath})` : "unset",
                // but better would be to provide firstly prepared placeholder small image instead of nothing
            }}
            key={classFr}
        >
        </Box>
    )
}

export default FrameMaskForDiv


