import React, { useEffect, useRef, useMemo } from 'react'
import { motion, useAnimation, useInView } from "framer-motion"
import { getMaskStaticPath, maskPatternsHoriz } from "../../utils/staticPathsForMaskImages"
import { configWavePathsD, configWavePathDNames, getPathDNull, svgCirclePathDRelativeToCenter, svgPathsDRelativeToCenterOfCirclesAround, svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround, SvgWithPathsOfOuterCircleAndSmallCirclesAround } from "../../utils/calculateSvgPath"


const DivWithWavedClipPathAnimation = ({
    nameOfPaths = "sinusWaveFromLeftToBow",
    clipPathId = "sinusWaveFromLeftToBow",
    maskName = "mask5",
    diameter = 100,
    pointsNumber = 10,
    outerCirclePercentage = 0.5,
    distanceAroundCircleFromCenterPercentage = 0.8,
    radiusAroundCircle = 0.12,
    styleDiv = {},
    divMinHeight = "200px",
    isAnimation = true,
    // isMaskPatternHorizontal = false,
    // bgImageGradient = 'radial-gradient(circle at 50% 50%, #770303 0%, #d30101 25%, transparent 75%, transparent 100%)'
    bgImageFromGradientColor1 = "rgb(119, 3, 3)",
    bgImageFromGradientColor2 = "rgb(211, 1, 1)",
    bgImageToGradientColor1 = "rgb(85, 6, 6)",
    bgImageToGradientColor2 = "rgb(21, 229, 104)",
    isFitMaskHeightToImg = true,
    isFitMaskWidthToImg = true,
}) => {

    const ctrlPath = useAnimation()
    const ctrlDiv = useAnimation()
    // console.log("nameOfPaths", nameOfPaths)
    const ref = useRef(null)
    const isInView = useInView(ref, {
        once: false,
        // margin: "-100px 100px -100px 0px" 
        margin: "0px 100px 0px 0px"
    })

    const pathDEnd = configWavePathDNames[ nameOfPaths ]
    const pathDNull = getPathDNull(nameOfPaths)
    // const pathDs = useMemo(() => [ pathDNull, pathDEnd ], [ pathDNull, pathDEnd ])
    const pathDs = useMemo(() => {
        if (Array.isArray(pathDEnd)) {
            return [ pathDNull, ...pathDEnd ];
        } else {
            return [ pathDNull, pathDEnd ];
        }
    }, [ pathDNull, pathDEnd ]);
    // console.log('DivWithWavedClipPathAnimation pathDs', pathDs)
    const dsNumber = pathDs.length
    if (!dsNumber) {
        console.error("No pathDs found for nameOfPaths", nameOfPaths)
    }
    // const { dStart,...ds } = pathsD
    // const dOuterCircle = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage })
    // const dsCirclesAround = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage, radiusAroundCircle })
    const bgImageToGradient = `radial-gradient(circle at 50% 50%, ${bgImageToGradientColor1} 0%, ${bgImageToGradientColor2} 60%, transparent 100%)`

    const isMaskAnimation = (isFitMaskHeightToImg && isFitMaskWidthToImg) ? false : isAnimation
    const maskSizeWidthEnd = isFitMaskWidthToImg ? "auto" : "33%"
    const maskSizeHeightEnd = isFitMaskHeightToImg ? "auto" : "33%"

    useEffect(() => {
        let isMounted = true
        if (!isAnimation) return
        if (!isInView) {
            ctrlPath.stop();
            ctrlDiv.stop()
            // ctrlPath.start(() => ({
            //     d: pathDs[ 0 ],
            //     // fillOpacity: 0,

            //     transition: {
            //         // type: "spring", stiffness: 100,
            //         duration: 0.2,
            //         // ease: "easeInOut",
            //         // delay: 2
            //     }
            // }))
            return
        }
        const seq1 = async () => {

            for (const d of pathDs) {
                // console.log("d", d)
                isMounted && isInView && await ctrlPath.start(() => ({
                    d: d,
                    // fillOpacity: 0,
                    transition: {
                        // type: "spring", stiffness: 100,
                        duration: 1,
                        ease: "easeInOut",
                        // delay: 2
                    }
                })
                )
            }
            isMounted && isInView && ctrlDiv.start(() => ({
                // fillOpacity: 0,
                backgroundSize: "100%",
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    delay: dsNumber * 1 + 1

                }
            }))
            isMounted && isInView && ctrlDiv.start(() => ({

                backgroundImage: bgImageToGradient, // 'radial-gradient(circle at 50% 50%, #580303 0%, #530101 25%, transparent 75%, transparent 100%)',
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: dsNumber + 2

                }
            }))
            isMounted && isInView && isMaskAnimation && ctrlDiv.start(() => ({

                maskSize: `${maskSizeWidthEnd} ${maskSizeHeightEnd}`, //"20%",
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    delay: dsNumber + 2.5

                }
            }))
        }
        isMounted && isInView && seq1()
        return () => {
            isMounted = false
            ctrlPath.stop()
            ctrlDiv.stop()
        }
    }, [ isInView, isAnimation, bgImageToGradient, ctrlDiv, ctrlPath, dsNumber, pathDs, isMaskAnimation, maskSizeHeightEnd, maskSizeWidthEnd ])

    // const staticMaskImgPath = isMaskPatternHorizontal ? maskPatternsHoriz[ maskName ] : getMaskStaticPathWithAspectRatio(maskName).path
    const staticMaskImgPath = getMaskStaticPath(maskName)

    const bgImageFromGradient = `radial-gradient(circle at 50% 50%, ${bgImageFromGradientColor1} 0%, ${bgImageFromGradientColor2} 60%, transparent 100%)`
    // const { dOuterCircle, dsCirclesAround } = svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround()
    // const dOuterCircleStart = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage: 0 })
    // const dsCirclesAroundStart = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage: 0, radiusAroundCircle: 0 })
    // console.log("dOuterCircle", dOuterCircle)
    // console.log("dsCirclesAround", dsCirclesAround)
    const maskSizeHeightStart = isFitMaskHeightToImg ? "auto" : "100%"
    const maskSizeWidthStart = isFitMaskWidthToImg ? "auto" : "100%"
    return (
        <>
            <svg width="100" height="100"
                viewBox="0 0 100 100"
                style={{ position: 'absolute' }}
            >
                <defs>
                    <clipPath id={clipPathId}
                        clipPathUnits="objectBoundingBox"
                    >
                        <motion.path
                            // d={clipPathFrom}
                            d={isAnimation ? pathDs[ 0 ] : pathDs[ pathDs.length - 1 ]}
                            animate={ctrlPath}
                            // fill="#FFFFFF"
                            // fill="#000000"
                            stroke="#000000" strokeMiterlimit="10"
                            strokeWidth="50"
                        // style={{
                        //     fillOpacity: 0.5,
                        //     fill: "black"
                        // }}
                        />
                        {/* {dsCirclesAroundStart.map((d, i) => (
                            <motion.path
                                key={`around-${i}`}
                                d={d}
                                animate={ctrlAroundCircle}
                                custom={{ i }}
                                // fillOpacity={0.5}
                                // style={{
                                //     fill: "black",
                                //     fillOpacity: 0.5
                                // }}
                                stroke="#000000" strokeMiterlimit="10"
                            />
                        ))} */}
                    </clipPath>
                </defs>

            </svg>
            <motion.div
                ref={ref}
                animate={ctrlDiv}
                style={{
                    clipPath: `url(#${clipPathId})`,
                    // maskImage: mask,
                    // backgroundImage: 
                    // 'radial-gradient(circle at 50% 50%, #770303 0%, #d30101 25%, transparent 75%, transparent 100%)',
                    // radial-gradient(circle at 50% 50%, #770303 0%, ##d30101 25%, transparent 75%, transparent 100%)
                    // background-image: 
                    // radial-gradient(circle at 50% 50%, rgb(119, 3, 3) 0%, rgb(211, 1, 1) 25%, transparent 75%, transparent 100%);
                    // radial-gradient(circle at 50% 50%, #770303 0%, ##d30101 25%, transparent 75%, transparent 100%)

                    backgroundImage: isAnimation ? bgImageFromGradient : bgImageToGradient,
                    minHeight: divMinHeight,
                    WebkitMaskImage: `url(${staticMaskImgPath})`,
                    maskImage: `url(${staticMaskImgPath})`,
                    backgroundSize: isAnimation ? '1%' : '100%',
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "center",
                    // // maskSize: isAnimation ? '100% 100%' : '10% 10%',
                    // maskSize: isAnimation ? '100%' : '20%',
                    maskSize: isMaskAnimation ? `${maskSizeWidthStart} ${maskSizeHeightStart}` : `${maskSizeWidthEnd} ${maskSizeHeightEnd}`,
                    maskPosition: '50%',
                    // // backgroundAttachment: 'fixed',
                    // // backgroundColor: "green",
                    // // height: "200px",
                    // // maxHeight: { maxHeight },
                    // // width: '100%',
                    // // // WebkitMaskImage: 'radial-gradient(circle at 50% 50%, transparent 0%, transparent 25%, black 75%, black 100%)', // For Safari
                    ...styleDiv,
                }}
            >

            </motion.div>
        </>
    )
}
const MemoizedDivWithWavedClipPathAnimation = React.memo(DivWithWavedClipPathAnimation);

export default MemoizedDivWithWavedClipPathAnimation;
