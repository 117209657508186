import React, { memo, useRef, useEffect } from "react"
import { motion, useAnimation } from "framer-motion";
import Box from "@mui/system/Box";
import { useCircleMotionStyles2, usePresenceAnimation2, useDidMount } from "./hooksCM"
import TooltipCM from "./tooltipCM"
import { ITEM_SIZE, MARGIN_RIGHT, } from "./constantsCM"  // CONTAINER_SIZE, BUTTON_SIZE


const styleSxDivCircle = {
  borderRadius: "50%",
  position: "absolute",
  color: "secondary2.contrastText",
  bgcolor: "secondary2.main",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",
  pointerEvents: "all",
  willChange: "transform",
  transformOrigin: "unset",
  justifyItems: "center",
  boxShadow: (theme) => `inset -5px -11px 7px 0px ${theme.palette.secondary2.dark}`,
  zIndex: 1,
  "&:hover": {
    zIndex: 3,
  },
  "&.selected": {
    boxSizing: "content-box",
    borderColor: "primary.main",
    borderWidth: "10px",
    borderRadius: "10px",
    borderStyle: "ridge",
    // bgcolor: "background.default",
    zIndex: 3,

    "& p": {
      textShadow: (theme) => `0px 0px 0px ${theme.palette.background.contrastText}`,
      borderBottomStyle: "double",
    }
  },
}


const varDivMenuItem = ({ index, itemSelected }) => {
  const delaytime = index * 0.5
  const rotating = (index % 2) === 0 ? { rotateX: 0 } : { rotateY: 0 }
  return {
    initial: false,
    animate: {
      ...rotating,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        delay: delaytime,
      }
    },
    exit: {
      scale: 0.5,
      transition: {
        duration: 0.2
      },
    },
    animateSelected: {
      scale: 2.2,
      x: 0,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        repeat: 1,
        repeatType: "reverse",
      }
    },
  }
}

const timeInitialDelay = 2000


function MenuItemCM({ value, comp, label, index, nrOfItems, layerSide, itemSelected, handleClickItemInParent, closing, confirmItemEndToMenu, ...rest }) {
  // we only want framer-motion auto-layout animations after the component
  // has mounted (and finished to mount-animation)
  const isMounted = useRef(false);
  const refRenderCount = useRef(0)
  const refItemSelected = useRef()
  refItemSelected.current = itemSelected
  const refDiv = React.createRef()

  const controls = useAnimation()

  const handleClick = (e, value, label, index) => {
    e.stopPropagation();
    e.bubbles = false
    handleClickItemInParent({ value, label, index })
  }

  const timeTransitionDelay = 5000 + (index + 1) * timeInitialDelay
  const zStandard = 1
  const zSelected = 3
  const id = `${label}-${index}`

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (window) {
      if (closing) {
        controls.stop()
        if (itemSelected === true) {
          confirmItemEndToMenu(true)
        }
        return
      }
      let timerRet
      if (itemSelected === true) {
        controls.stop()
        controls.start("animateSelected")
      } else {
        async function runOnetimeAnimation() {
          if (refRenderCount.current > 0) return
          refRenderCount.current = refRenderCount.current + 1

          await controls.start("animate")
          const timer = window.setTimeout(() => {
            if (isMounted.current) {
              controls.start({
                rotateX: [ null, 90, 0, 0, 0 ],
                rotateY: [ null, 0, 0, 90, 0 ],
                zIndex: itemSelected === true ? zSelected : zStandard + 1,
                transitionEnd: { zIndex: null },
                transition: {
                  duration: 1,
                }
              })
            }
          }, timeTransitionDelay)
          return timer
        }
        runOnetimeAnimation().then((ret) => { })
      }
      return () => {
        controls.stop()
        clearTimeout(timerRet)
      }
    }
  }, [
    closing,
    confirmItemEndToMenu, controls, timeTransitionDelay, itemSelected ])


  const didMount = useDidMount();

  // custom-hook for creating the circle-animation -> when placement === "center"
  const circleStyle = useCircleMotionStyles2(index, nrOfItems);

  const stepSize = ITEM_SIZE + MARGIN_RIGHT;
  const maxIndex = nrOfItems - 1;
  const x = (index - maxIndex) * stepSize + stepSize * maxIndex;
  const barStyle = {
    left: x
  };


  // custom-hook for triggering animation when this component gets mounted or unmounted
  // Basically it sets the motion-value to 1 on mount, and 0 on unmount

  const isPresent = usePresenceAnimation2({
    type: "spring",
    damping: 20,
    stiffness: 200
  });


  // We're wrapping our menu-item in a `Tooltip` component we created in another example.
  // We're also applying a certain type of animation (circle / bar) depending on the
  // side the layer is currently on
  const finalCircleStyle = { ...circleStyle, width: 1.2 * ITEM_SIZE, height: 1.2 * ITEM_SIZE, }


  const styleItem = layerSide === "center" ? finalCircleStyle : barStyle

  return (
    <TooltipCM text={label} itemSelected={itemSelected}    >
      <Box component={motion.div}
        ref={refDiv}
        id={id}
        variants={varDivMenuItem({ index, itemSelected })}
        initial="initial"
        animate={controls}
        {...rest}
        className={`menu-item ${refItemSelected.current === true ? "selected" : ""}`}
        sx={styleSxDivCircle}
        style={{
          ...styleItem,
          scale: 1,
          rotateX: (index % 2) === 0 ? 100 : 0,
          rotateY: (index % 2) === 1 ? 100 : 0
        }}
        layout={isPresent && didMount}
        onClick={(e) => handleClick(e, value, label, index)}
        key={`menuItem-${label}`}
      >
        {comp}
      </Box>
    </TooltipCM>
  );
}

export default memo(MenuItemCM)

