import React, { useEffect } from "react";
import { motion, useAnimation, useMotionValue, transform, MotionValue } from "framer-motion";
import Card1 from "../../Card1";
import { Box } from "@mui/material";

type AroundItemProps = {
    iItem: number;
    cardOptions: {
        imgName: string;
        imgSrc: string;
        itemMaxWidthPx: number;
    };
    positionFirst: {
        angle: number;
        x: number;
        y: number;
    };
    classNameAround: string;
    aroundPositions: {
        angle: number;
        left: number;
        top: number;
    }[];
    // handleOnClick: ({ e, iItem }: { e: MouseEvent<Element, MouseEvent>; iItem: number; }) => void;
    selectedIndex: number;
    angleM: MotionValue<number>;
    deviceSize: string;
    isInView: boolean;
};

const varAround = {

    initial: false,
    enter: {
        opacity: 1,
        transition: {
            duration: 1,

        },
    },
    exit: {
        y: 100,
        skew: 360,
        opacity: 0.5,
        transition: {
            duration: 0.2,
        },
    },
};

const AroundItem = (props: AroundItemProps) => {
    const {
        iItem,
        cardOptions,
        positionFirst,
        classNameAround,
        aroundPositions,
        // handleOnClick,
        selectedIndex,
        angleM,
        deviceSize,
        isInView
    } = props
    const numberPositions = aroundPositions.length

    const { imgName, imgSrc, itemMaxWidthPx } = cardOptions
    const controlsA = useAnimation()

    const angleFirst = positionFirst.angle

    useEffect(() => {
        if (!isInView) return
        let ticks = 0
        const rotateOnMotionAngleA = (latest: number) => {
            ticks = ticks + 1
            const iNext = (iItem + ticks) % numberPositions
            const { top, left, } = aroundPositions[iNext] // angle

            controlsA.start({
                rotateZ: iNext > (numberPositions / 2) ? latest + angleFirst + 180 : latest + angleFirst,
                y: top,
                x: left,
                transition: {
                    duration: 1,
                }
            })
        }

        const unsubscribeAngleMA = angleM.onChange(rotateOnMotionAngleA)
        return () => {
            controlsA.stop()
            unsubscribeAngleMA()
        }

    }, [angleFirst, deviceSize, aroundPositions, angleM, controlsA, iItem, numberPositions, isInView]); // randomInterrupt,



    const findRightElFromEvent = (eTarget: EventTarget, idNextParentEl: string): HTMLElement | null => {

        let parent = eTarget as HTMLElement;
        while (parent) {
            if (parent.parentElement && parent.parentElement.id === idNextParentEl) {
                break;
            }
            parent = parent.parentElement;
        }
        return parent
    }

    function handleTap(event: any, info: any, { iItem }: { iItem: number }) {

        // const isDeselect = selectedIndex.current === iItem
        const isDeselect = selectedIndex === iItem

        const idNextParentEl = "wrapDivsAround"
        const divEl = findRightElFromEvent(event.target, idNextParentEl)
        divEl?.parentElement?.querySelector(".selectedAround")?.classList.remove("selectedAround");
        (!isDeselect) && divEl?.classList.add("selectedAround", "spec")
        document.querySelector("#ellipseRotating > div.selectedRotating")?.classList.remove("selectedRotating");
        (!isDeselect) && document.querySelector(`#ellipseRotating > div.rotatingItem:nth-of-type(${iItem + 1})`)?.classList.add("selectedRotating", "spec")
        event.stopPropagation();

        controlsA.start({
            rotateX: [90, 0],
            transition: {
                duration: 0.3
            }
        })
    }


    function onHoverA(event: React.MouseEvent<HTMLDivElement>, info: any) {
        controlsA.start({
            scale: 1.2,
            cursor: "pointer",
            transition: {
                duration: 0.2,
                repeat: 1,
                repeatType: "reverse",
            }
        })
    }

    return (
        <Box component={motion.div}
            variants={varAround} //({ iItem })}
            animate={controlsA}
            onTap={(e: React.MouseEvent<HTMLDivElement>, info: any) => handleTap(e, info, { iItem })}
            onHoverStart={onHoverA}
            className={classNameAround}
            style={{
                cursor: "pointer",
            }}
            // onClick={(e) => handleOnClick({ e, iItem })}
            key={`divs-around-${iItem}`}
        >
            <Card1
                options={{
                    imgName,
                    imgSrc,
                    itemMaxWidthPx,
                    classNameCard: "classNameCard", // Add classNameCard property here
                }}
                key={`card-${imgName}--${iItem}`} />
        </Box>
    )
}

export default AroundItem;