import React from "react";
import Typography from '@mui/material/Typography';
import VideoTag from "../../_shared/videoTag"

const Faktury = () => {
  return (
    <>
      <Typography variant="h4">
        Faktúry
      </Typography>
      <Typography sx={{ fontSize: [ "smaller", "small", ] }}>
        Aplikácia pre pohodlné vytvorenie, vytlačenie, uloženie a evidovanie
        faktúry. S výpočtami. S kontrolou vloženia dát pre vybrané časti.
        Riešiaca viacstránkové faktúry (veľa položiek).
      </Typography>
      <VideoTag src="/sf/vba-fa/faktury.mp4" title="App Faktúry" />
    </>
  );
};

export default Faktury;
