import React from "react"
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const styleCardDef = {
    position: "absolute",
    border: "1px solid #ccc",
    background: "transparent",
    boxShadow: "inset 0 0 20px rgba(0,0,0,.2)",
    px: 0,
    pt: 0,
    pb: [ 0, 0, 1, 2, 3 ]
}

const CarouselCard = ({ children, carouselItemOptions }) => {

    const { imgName, imgSrc, rotateYAngle, translateZPxDistance, styleCardWrapper, styleCard, switchAxes } = carouselItemOptions

    const tr = switchAxes ? `rotateX(${rotateYAngle}deg) translateZ(${translateZPxDistance}px)` : `rotateY(${rotateYAngle}deg) translateZ(${translateZPxDistance}px)`
    const cardWrapperStyle = { transform: tr }

    return (
        <Box key={`${imgName}-cardWrapper`}
            style={{ position: "relative", ...cardWrapperStyle, ...styleCardWrapper }} >
            <Card
                key={`${imgName}-card`}
                sx={styleCardDef}
                style={styleCard}
                raised={true}
                elevation={10}
                variant="elevation"
            >
                <img
                    src={imgSrc}
                    alt={imgName}
                    key={imgName}
                />
                {children}
                {/* <CardContent>
                    <Typography variant="body2" component="p">
                        {imgName}
                    </Typography>
                </CardContent> */}
            </Card>
        </Box>
    )

}

export default CarouselCard
