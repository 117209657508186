import React, { Children, useLayoutEffect, useEffect, useRef, useState, useCallback, useMemo, Fragment } from 'react'
import { motion, useAnimation } from 'framer-motion';
import { Box } from "@mui/system";
// https://hackernoon.com/react-hook-to-measure-an-elements-size-and-handle-responsive-components-ge3l3vj9
// https://github.com/wellyshen/react-cool-dimensions
import useDimensions from "react-cool-dimensions";

import { getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame } from '../../utils/getWave';
import { getSvgViewboxToFitPaths } from '../../utils/functions';
import { NAMED_WAVED_FRAMES_CONFIGS } from './wavedFrameAnimationForChildrenConfigs';



const buildPaddingStyleFromName = (name = "T20-R20-B20-L20") => {
  const arr = name.split("-")
  let num
  const obj = arr.reduce((acc, cur) => {
    acc[ cur.id ] = cur.name;
    switch (true) {
      case cur.includes("T"):
        num = cur.substring(1)
        acc.paddingTop = parseInt(num)
        break;
      case cur.includes("R"):
        num = cur.substring(1)
        acc.paddingRight = parseInt(num)
        break;
      case cur.includes("B"):
        num = cur.substring(1)
        acc.paddingBottom = parseInt(num)
        break;
      case cur.includes("L"):
        num = cur.substring(1)
        acc.paddingLeft = parseInt(num)
        break;
      default:
        break;
    }
    return acc;
  }, {});
  const objCleaned = Object.fromEntries(Object.entries(obj).filter(([ key, v ]) => key !== 'undefined'))
  return objCleaned
}



function getFramesPointPair({ paddingPart = 1, createPaddings, position, configPointPair, rootTop, rootLeft, rootRight, rootBottom, paddingTop, paddingRight, paddingBottom, paddingLeft }) {

  let pStart, pEnd
  let styleSvg = {}
  if (configPointPair === "startMaxEndMax") {
    switch (position) {
      case "top":
        pStart = createPaddings ? [ rootLeft, rootTop ] : [ rootLeft, rootTop - paddingTop ]
        pEnd = createPaddings ? [ rootRight, rootTop + paddingTop ] : [ rootRight, rootTop ]
        styleSvg = {
          transform: `translate3d(${rootLeft}px, ${rootTop}px, 0px)`,
        }
        break;
      case "right":
        pStart = createPaddings ? [ rootRight, rootTop ] : [ rootRight + paddingRight, rootTop ]
        pEnd = createPaddings ? [ rootRight - paddingRight, rootBottom ] : [ rootRight, rootBottom ]
        styleSvg = {
          transform: `translate3d(${rootRight - paddingRight}px, ${rootTop}px, 0px)`,
        }
        break;
      case "bottom":
        pStart = createPaddings ? [ rootRight, rootBottom ] : [ rootRight, rootBottom + paddingBottom ]
        pEnd = createPaddings ? [ rootLeft, rootBottom - paddingBottom ] : [ rootLeft, rootBottom ]
        styleSvg = {
          transform: `translate3d(${rootLeft}px, ${rootBottom - paddingBottom}px, 0px)`,
        }
        break;
      case "left":
        pStart = createPaddings ? [ rootLeft, rootBottom ] : [ rootLeft - paddingLeft, rootBottom ]
        pEnd = createPaddings ? [ rootLeft + paddingLeft, rootTop ] : [ rootLeft, rootTop ]

        styleSvg = createPaddings ? {} : {
          transform: `translate3d(${rootLeft}px, ${rootTop}px, 0px)`,
        }
        break; default:
        break;
    }
  }
  if (configPointPair === "startMaxEndMin") {
    switch (position) {
      case "top":
        pStart = [ rootLeft, rootTop ]
        pEnd = [ rootRight - paddingRight, rootTop + paddingTop ]
        break;
      case "right":
        pStart = [ rootRight, rootTop ]
        pEnd = [ rootRight - paddingRight, rootBottom - paddingBottom ]
        styleSvg = { left: rootRight - paddingRight - paddingLeft }
        break;
      case "bottom":
        pStart = [ rootRight, rootBottom ]
        pEnd = [ rootLeft + paddingLeft, rootBottom - paddingBottom ]
        styleSvg = { top: rootBottom - paddingBottom, left: rootLeft + paddingLeft }
        break;
      case "left":
        pStart = [ rootLeft, rootBottom ]
        pEnd = [ rootLeft + paddingLeft, rootTop + paddingTop ]
        styleSvg = { top: rootTop + paddingTop }
        break; default:
        break;
    }
  }
  if (configPointPair === "startMinEndMax") {
    switch (position) {
      case "top":
        pStart = [ rootLeft + paddingLeft, rootTop ]
        pEnd = [ rootRight, rootTop + paddingTop ]
        styleSvg = { left: rootLeft + paddingLeft }
        break;
      case "right":
        pStart = [ rootRight, rootTop + paddingTop ]
        pEnd = [ rootRight - paddingRight, rootBottom ]
        styleSvg = { left: rootRight - paddingRight, top: rootTop + paddingTop }
        break;
      case "bottom":
        pStart = [ rootRight - paddingRight, rootBottom ]
        pEnd = [ rootLeft, rootBottom - paddingBottom ]
        styleSvg = { top: rootBottom - paddingBottom }
        break;
      case "left":
        pStart = [ rootLeft, rootBottom - paddingBottom ]
        pEnd = [ rootLeft + paddingLeft, rootTop ]
        break; default:
        break;
    }
  }
  if (configPointPair === "startMidEndMax") {
    switch (position) {
      case "top":
        pStart = [ rootLeft + paddingLeft / 2, rootTop ]
        pEnd = [ rootRight, rootTop + paddingTop ]
        styleSvg = { left: rootLeft + paddingLeft / 2 }
        break;
      case "right":
        pStart = [ rootRight, rootTop + paddingTop / 2 ]
        pEnd = [ rootRight - paddingRight, rootBottom ]
        styleSvg = { left: rootRight - paddingRight, top: rootTop + paddingTop / 2 }
        break;
      case "bottom":
        pStart = [ rootRight - paddingRight / 2, rootBottom ]
        pEnd = [ rootLeft, rootBottom - paddingBottom ]
        styleSvg = { top: rootBottom - paddingBottom }
        break;
      case "left":
        pStart = [ rootLeft, rootBottom - paddingBottom / 2 ]
        pEnd = [ rootLeft + paddingLeft, rootTop ]
        break; default:
        break;
    }
  }
  if (configPointPair === "startMidEndMid") {
    switch (position) {
      case "top":
        pStart = [ rootLeft + paddingLeft / 2, rootTop ]
        pEnd = [ rootRight - paddingRight / 2, rootTop + paddingTop ]
        styleSvg = { left: rootLeft + paddingLeft / 2 }
        break;
      case "right":
        pStart = [ rootRight, rootTop + paddingTop / 2 ]
        pEnd = [ rootRight - paddingRight, rootBottom - paddingBottom / 2 ]
        styleSvg = { left: rootRight - paddingRight, top: rootTop + paddingTop / 2 }
        break;
      case "bottom":
        pStart = [ rootRight - paddingRight / 2, rootBottom ]
        pEnd = [ rootLeft + paddingLeft / 2, rootBottom - paddingBottom ]
        styleSvg = { top: rootBottom - paddingBottom, left: rootLeft + paddingLeft / 2 }
        break;
      case "left":
        pStart = [ rootLeft, rootBottom - paddingBottom / 2 ]
        pEnd = [ rootLeft + paddingLeft, rootTop + paddingTop / 2 ]
        styleSvg = { top: rootTop + paddingTop / 2 }
        break; default:
        break;
    }
  }
  if (configPointPair === "horizontalMid") {
    switch (position) {
      case "top":
        pStart = [ rootLeft + paddingLeft / 2, rootTop + paddingTop / 2 ]
        pEnd = [ rootRight - paddingRight / 2, rootTop + paddingTop / 2 ]
        styleSvg = { left: rootLeft + paddingLeft / 2, top: rootTop + paddingTop / 2 }
        break;
      case "right":
        pStart = [ rootRight, rootTop + paddingTop / 2 ]
        pEnd = [ rootRight - paddingRight, rootBottom - paddingBottom / 2 ]
        styleSvg = { left: rootRight - paddingRight, top: rootTop + paddingTop / 2 }
        break;
      case "bottom":
        pStart = [ rootRight - paddingRight / 2, rootBottom ]
        pEnd = [ rootLeft + paddingLeft / 2, rootBottom - paddingBottom ]
        styleSvg = { top: rootBottom - paddingBottom, left: rootLeft + paddingLeft / 2 }
        break;
      case "left":
        pStart = [ rootLeft, rootBottom - paddingBottom / 2 ]
        pEnd = [ rootLeft + paddingLeft, rootTop + paddingTop / 2 ]
        styleSvg = { top: rootTop + paddingTop / 2 }
        break; default:
        break;
    }
  }

  const pointStart = { x: pStart[ 0 ], y: pStart[ 1 ] }
  const pointEnd = { x: pEnd[ 0 ], y: pEnd[ 1 ] }
  return [ pointStart, pointEnd, styleSvg ]
}


const styleSxWrap = {
  scrollbarWidth: "none",
  "&.wrapRefs": {
    height: "auto",
    width: "100%",
    "& .rootRef": {
      position: "relative",
      width: "fit-content",
      marginLeft: "auto",
      marginRight: "auto",
      "& div": {
      },
      "& .svgFrame": {
        position: "absolute",
        top: 0,
        left: 0,
        borderStyle: 'unset',
        "& .pathFrame": {
          stroke: "aqua",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          fill: "none",
          "&.pathFrame-0": {
          },
        },
      },

      "& .boxAround": {
        backgroundColor: "yellow",
        width: "10px",
        height: "10px",
        position: "absolute",
        top: 0,
      },
      "& .itemWrap": {
        "& .itemText": {
        },
        "& .itemMark": {
        },
      },
      "& .waved": {
      }
    },
  }
}



// const framePaddingsDefault = { paddingLeft: 25, paddingRight: 25, paddingTop: 25, paddingBottom: 25 }



/** WavedFrameAnimationForChildren
 * animation of appearing wavy lines around children
 * multiple different wavy lines available for top , different for left, different for bottom and left
 * choose top,right, bottom, left lines independently
 * choose how much space lines occupy via padding setting
 * wavy lines are made as path element in svg
 * arrCoefsPadding: values belongs to waves lines according order
 * arrCoefsPadding: 1 = line spread over full padding value, 0.3 = over 1/3 of padding, ...
 * arrCoefsPadding: -1 = line spread over full padding value but with shape mirrored
 * arrCoefsPadding: mixing + with minus values can go over padding: 
 * [0.5,-0.5] = padding kept exactly full && 2 lines direction same && just one line convex taking half of padding the other concave taking half of padding ...
 * [1,-0.5] = taking 150% of padding kept && 2 lines direction same && just one line convex taking full padding the other concave ...
 * check baseFrames to find out how to customize it with myFrames prop
 * @param  {} {children
 * @param  {} myFramesPadding=framePaddingsDefault
 * @param  {} myFrames=baseFrames}
 */
const WavedFrameAnimationForChildren = ({
  name = "",
  children,
  nameWavedFrames = "defaultBaseT6R2B4L2",
  namePaddings = "T20-R20-B20-L20",
  // framePaddings = framePaddingsDefault, //{ paddingLeft: 129, paddingRight: 100, paddingTop: 100, paddingBottom: 29 },
  optionsFrames, // = WAVED_FRAMES_CONFIGS.baseT6R2B4L2, // baseFrames
  // baseFrames = WAVED_FRAMES_CONFIGS.baseT6R2B4L2, // baseFrames
  // styles=[{},{},]
  stylesBottom, stylesTop, stylesLeft, stylesRight,
  delayAnimation = 1,
}) => {

  // console.log(name, " ============     WavedFrameAnimationForChildren start ===================================")
  // const startTime = performance.now();
  const isMounted = useRef(false);
  // // console.log("waved NAMED_WAVED_FRAMES_CONFIGS", NAMED_WAVED_FRAMES_CONFIGS)
  // const configKeys = Object.keys(WAVED_FRAMES_CONFIGS)
  const baseFrames = NAMED_WAVED_FRAMES_CONFIGS[ nameWavedFrames ]
  //   NAMED_WAVED_FRAMES_CONFIGS[ nameWavedFrames ] : NAMED_WAVED_FRAMES_CONFIGS.baseT6R2B4L2

  const framePaddings = buildPaddingStyleFromName(namePaddings)
  // console.log("waved nameWavedFrames", name, nameWavedFrames)

  const usedFramesDirty = {
    top: !optionsFrames ? baseFrames.top : optionsFrames.top ? { ...baseFrames.top, ...optionsFrames.top } : undefined,
    right: !optionsFrames ? baseFrames.right : optionsFrames.right ? { ...baseFrames.right, ...optionsFrames.right } : undefined,
    bottom: !optionsFrames ? baseFrames.bottom : optionsFrames.bottom ? { ...baseFrames.bottom, ...optionsFrames.bottom } : undefined,
    left: !optionsFrames ? baseFrames.left : optionsFrames.left ? { ...baseFrames.left, ...optionsFrames.left } : undefined,
  }

  const usedFrames = Object.fromEntries(Object.entries(usedFramesDirty).filter(([ _, v ]) => v !== undefined))
  // console.log("WavedFrameAnimationForChildren usedFrames", name, usedFrames)
  // const usedFrames = optionsFrames ? { ...baseFrames, ...optionsFrames } : { ...baseFrames }

  const isTop = (usedFrames.top?.waves) ? true : false
  const isRight = (usedFrames.right?.waves) ? true : false
  const isBottom = (usedFrames.bottom?.waves) ? true : false
  const isLeft = (usedFrames.left?.waves) ? true : false


  // const frames = {
  //   top: isTop ? { ...baseFrames.top, ...usedFrames.top } : null,
  //   right: isRight ? { ...baseFrames.right, ...usedFrames.right } : null,
  //   bottom: isBottom ? { ...baseFrames.bottom, ...usedFrames.bottom } : null,
  //   left: isLeft ? { ...baseFrames.left, ...usedFrames.left } : null,
  // }

  const frames = { ...usedFrames }
  if (isTop) {
    if (stylesTop) frames.top.styles = stylesTop
    if (frames.top.waves.length > 0) frames.top.isMultiplePaths = true
  }
  if (isRight) {
    if (stylesRight) frames.right.styles = stylesRight
    if (frames.right.waves.length > 0) frames.right.isMultiplePaths = true
  }
  if (isBottom) {
    if (stylesBottom) frames.bottom.styles = stylesBottom
    if (frames.bottom.waves.length > 0) frames.bottom.isMultiplePaths = true
  }
  if (isLeft) {
    if (stylesLeft) frames.left.styles = stylesLeft
    if (frames.left.waves.length > 0) frames.left.isMultiplePaths = true
  }
  // if (isTop && stylesTop) frames.top.styles = stylesTop
  // if (isRight && stylesRight) frames.right.styles = stylesRight
  // if (isBottom && stylesBottom) frames.bottom.styles = stylesBottom
  // if (isLeft && stylesLeft) frames.left.styles = stylesLeft

  // console.log("Waved frames", name, frames)
  // const paddings = { ...framePaddingsDefault, ...framePaddings }
  const paddings = { ...framePaddings }
  const { paddingLeft, paddingRight, paddingTop, paddingBottom } = paddings

  const styleRootPaddings = {
    paddingTop: (isTop && frames.top.createPaddings) ? paddingTop : null,
    paddingRight: (isRight && frames.right.createPaddings) ? paddingRight : null,
    paddingBottom: (isBottom && frames.bottom.createPaddings) ? paddingBottom : null,
    paddingLeft: (isLeft && frames.left.createPaddings) ? paddingLeft : null,
  }


  const refConstForHooks = useRef({ frames })
  // rootRef is wrapper of children measuring rect dimensions of children via hook useDimensions
  const rootRef = useRef()
  const [ rootRectViewport, setRootRectViewport ] = useState(false)
  // const refRenderCount = useRef(0)
  const refAlreadyFirstViewed = useRef(false)
  const refIsUserAction = useRef(false)

  const {
    observe,
    // unobserve,
    // entry,
    // width, height,
  } = useDimensions({
    // breakpoints: { XS: 0, SM: 320, MD: 480, LG: 640 },
    // updateOnBreakpointChange: true,
    // useBorderBoxSize: true, // Tell the hook to measure based on the border-box size, default is false
    // polyfill: ResizeObserver, // Use polyfill to make this feature works on more browsers
    // onResize: ({ currentBreakpoint }) => {
    onResize: ({ observe,
      // unobserve, width, height, entry 
    }) => {
      // Triggered whenever the size of the target is changed...
      // unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
      // Now the event callback will be triggered when breakpoint is changed
      // we can also access the "currentBreakpoint" here
      const rootRectBoundingClient = rootRef.current?.getBoundingClientRect()
      setRootRectViewport(rootRectBoundingClient)
    },
  });

  const isRoot = !!rootRectViewport
  // console.log("VWaved isRoot", name, isRoot)
  const rootTop = isRoot ? Math.round(0 - paddingTop) : undefined
  const rootRight = isRoot ? Math.round(rootRectViewport.right - rootRectViewport.left + paddingRight) : undefined // 
  const rootBottom = isRoot ? Math.round(rootRectViewport.bottom - rootRectViewport.top + paddingBottom) : undefined
  const rootLeft = isRoot ? Math.round(0 - paddingLeft) : undefined
  const [ pointTopStart, pointTopEnd, styleSvgTop ] = (isRoot && isTop) ? getFramesPointPair({ arrCoefsPadding: frames.top.arrCoefsPadding, createPaddings: frames.top.createPaddings, position: "top", configPointPair: frames.top.configPointPair, rootTop, rootLeft, rootRight, rootBottom, paddingTop, paddingRight, paddingBottom, paddingLeft }) : [ undefined, undefined, undefined ]
  const [ pointRightStart, pointRightEnd, styleSvgRight ] = (isRoot && isRight) ? getFramesPointPair({ createPaddings: frames.right.createPaddings, position: "right", configPointPair: frames.right.configPointPair, rootTop, rootLeft, rootRight, rootBottom, paddingTop, paddingRight, paddingBottom, paddingLeft }) : [ undefined, undefined, undefined ]
  const [ pointBottomStart, pointBottomEnd, styleSvgBottom ]
    = (isRoot && isBottom) ? getFramesPointPair({
      createPaddings: frames.bottom.createPaddings,
      position: "bottom",
      configPointPair: frames.bottom.configPointPair,
      rootTop,
      rootLeft,
      rootRight,
      rootBottom,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft
    }) : [ undefined, undefined, undefined ]
  const [ pointLeftStart, pointLeftEnd, styleSvgLeft ] = (isRoot && isLeft) ? getFramesPointPair({ createPaddings: frames.left.createPaddings, position: "left", configPointPair: frames.left.configPointPair, rootTop, rootLeft, rootRight, rootBottom, paddingTop, paddingRight, paddingBottom, paddingLeft }) : [ undefined, undefined, undefined ]


  const dsTop = useMemo(() =>
    getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame({
      exists: isTop,
      arrCoefsPadding: refConstForHooks.current.frames.top?.arrCoefsPadding,
      waves: isTop ? refConstForHooks.current.frames.top.waves : undefined,
      startXY: pointTopStart,
      endXY: pointTopEnd,
    }), [ isTop, pointTopStart, pointTopEnd
  ])

  const dsRight = useMemo(() => getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame({
    exists: isRight,
    arrCoefsPadding: refConstForHooks.current.frames.right?.arrCoefsPadding,
    isHorizontal: false,
    waves: isRight ? refConstForHooks.current.frames.right.waves : undefined,
    startXY: pointRightStart,
    endXY: pointRightEnd,
  }), [ isRight, pointRightStart, pointRightEnd ])


  const dsBottom = useMemo(() => getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame({
    exists: isBottom,
    arrCoefsPadding: refConstForHooks.current.frames.bottom?.arrCoefsPadding,
    waves: isBottom ? refConstForHooks.current.frames.bottom.waves : undefined,
    startXY: pointBottomStart,
    endXY: pointBottomEnd,
  }), [ isBottom, pointBottomStart, pointBottomEnd ])

  const dsLeft = useMemo(() => getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame({
    exists: isLeft,
    arrCoefsPadding: refConstForHooks.current.frames.left?.arrCoefsPadding,
    isHorizontal: false,
    waves: isLeft ? refConstForHooks.current.frames.left.waves : undefined,
    startXY: pointLeftStart,
    endXY: pointLeftEnd
  }), [ isLeft, pointLeftStart, pointLeftEnd ])

  const refSvgTop = useRef()
  const refSvgRight = useRef()
  const refSvgBottom = useRef()
  const refSvgLeft = useRef()
  const refIsAnimationAppearing = useRef(true)

  const ctrlAnimT = useAnimation()
  const ctrlAnimL = useAnimation()
  const ctrlAnimR = useAnimation()
  const ctrlAnimB = useAnimation()

  const arrayChildren = Children.toArray(children);
  const numberOfChilds = arrayChildren.length
  const svgTopChildren = isTop && isRoot && refSvgTop.current?.children

  const [ svgTopViewboxPair, setSvgTopViewboxPair ] = useState([])
  const svgRightChildren = isRight && isRoot && refSvgRight.current?.children
  const [ svgRightViewboxPair, setSvgRightViewboxPair ] = useState([])
  const [ svgRightViewboxAdapted, svgRightViewboxString ] = svgRightViewboxPair
  const svgRightViewboxAdaptedString = svgRightViewboxString
  const svgRightWidth = !!svgRightViewboxAdapted?.width ? `${svgRightViewboxAdapted.width}px` : "700px"
  const svgRightHeight = !!svgRightViewboxAdapted?.height ? `${svgRightViewboxAdapted.height}px` : "700px"
  const svgBottomChildren = isBottom && isRoot && refSvgBottom.current?.children
  const [ svgBottomViewboxPair, setSvgBottomViewboxPair ] = useState([])
  const [ svgBottomViewboxAdapted, svgBottomViewboxString ] = svgBottomViewboxPair
  const svgBottomViewboxAdaptedString = svgBottomViewboxString
  const svgBottomWidth = !!svgBottomViewboxAdapted?.width ? `${svgBottomViewboxAdapted.width}px` : "700px"
  const svgBottomHeight = !!svgBottomViewboxAdapted?.height ? `${svgBottomViewboxAdapted.height}px` : "700px"


  const svgLeftChildren = isLeft && isRoot && refSvgLeft.current?.children
  const [ svgLeftViewboxPair, setSvgLeftViewboxPair ] = useState([])
  const [ svgLeftViewboxAdapted, svgLeftViewboxString ] = svgLeftViewboxPair
  const svgLeftViewboxAdaptedString = svgLeftViewboxString
  const svgLeftWidth = !!svgLeftViewboxAdapted?.width ? `${svgLeftViewboxAdapted.width}px` : "700px"
  const svgLeftHeight = !!svgLeftViewboxAdapted?.height ? `${svgLeftViewboxAdapted.height}px` : "700px"

  const seqAppear = useCallback(() => {
    refIsAnimationAppearing.current = !refIsAnimationAppearing.current
    const { frames } = refConstForHooks.current
    const topDuration = isTop ? (refAlreadyFirstViewed.current ? frames.top.inAnim.duration : 0.1) : 0
    const rightDuration = isRight ? (refAlreadyFirstViewed.current ? frames.right.inAnim.duration : 0.1) : 0
    const bottomDuration = isBottom ? (refAlreadyFirstViewed.current ? frames.bottom.inAnim.duration : 0.1) : 0
    const leftDuration = isLeft ? (refAlreadyFirstViewed.current ? frames.left.inAnim.duration : 0.1) : 0
    // const durationCycle = isTop ? frames.top.inAnim.duration : 0 + isRight ? frames.right.inAnim.duration : 0 + isBottom ? frames.bottom.inAnim.duration : 0 + isLeft ? frames.left.inAnim.duration : 0
    const durationCycle = topDuration + rightDuration + bottomDuration + leftDuration
    const delayAnimationApplied = refIsUserAction.current ? 0 : delayAnimation
    // console.log("waved seqAppear start", name)
    Array(numberOfChilds).fill().forEach((_, j) => {

      if (isTop) {
        const { animation, transition } = frames.top.inAnim
        ctrlAnimT.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              strokeWidth: i + ii,
              transition: {
                ...transition,
                delay: delayAnimationApplied + ii * topDuration / numberOfPaths + i * durationCycle,
                duration: topDuration / numberOfPaths,
              },
            })
        )
      }

      if (isRight) {
        const { animation, transition } = frames.right.inAnim
        ctrlAnimR.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              transition: {
                ...transition,
                delay: delayAnimationApplied + ii * rightDuration / numberOfPaths + i * durationCycle,
                duration: rightDuration / numberOfPaths,
              },
            })
        )
      }
      if (isBottom) {
        const { animation, transition } = frames.bottom.inAnim
        ctrlAnimB.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              transition: {
                ...transition,
                delay: delayAnimationApplied + ii * bottomDuration / numberOfPaths + i * durationCycle,
                duration: bottomDuration / numberOfPaths,
              },
            })
        )
      }
      if (isLeft) {
        const { animation, transition } = frames.left.inAnim
        ctrlAnimL.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              transition: {
                ...transition,
                delay: delayAnimationApplied + ii * leftDuration / numberOfPaths + i * durationCycle,
                duration: leftDuration / numberOfPaths,
              },
            })
        )
      }
    })
  }, [ ctrlAnimB, ctrlAnimL, ctrlAnimR, ctrlAnimT, isBottom, isLeft, isRight, isTop, numberOfChilds ])

  const seqDisappear = useCallback(async () => {
    const { frames } = refConstForHooks.current
    const topDuration = isTop ? (refAlreadyFirstViewed.current ? frames.top.outAnim.duration : 0.1) : 0
    console.log("waved seqDisappear start", name, topDuration)
    const rightDuration = isRight ? (refAlreadyFirstViewed.current ? frames.right.outAnim.duration : 0.1) : 0
    const bottomDuration = isBottom ? (refAlreadyFirstViewed.current ? frames.bottom.outAnim.duration : 0.1) : 0
    const leftDuration = isLeft ? (refAlreadyFirstViewed.current ? frames.left.outAnim.duration : 0.1) : 0
    // const durationCycleBack = isTop ? frames.top.outAnim.duration : 0 + isRight ? frames.right.outAnim.duration : 0 + isBottom ? frames.bottom.outAnim.duration : 0 + isLeft ? frames.left.outAnim.duration : 0
    const durationCycleBack = topDuration + rightDuration + bottomDuration + leftDuration;

    Array(numberOfChilds).fill().forEach((_, j) => {
      if (isTop) {
        const { animation, transition } = frames.top.outAnim
        isMounted.current && ctrlAnimT.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              // strokeWidth:i*ii,
              transition: {
                ...transition,
                delay: (numberOfPaths - 1 - ii) * topDuration / numberOfPaths + i * durationCycleBack,
                duration: topDuration / numberOfPaths,
              },
            })
        )
      }
      if (isRight) {
        const { animation, transition } = frames.right.outAnim
        isMounted.current && ctrlAnimR.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              transition: {
                ...transition,
                delay: (numberOfPaths - 1 - ii) * rightDuration / numberOfPaths + i * durationCycleBack,
                duration: rightDuration / numberOfPaths,
              },
            })
        )
      }
      if (isBottom) {
        const { animation, transition } = frames.bottom.outAnim
        isMounted.current && ctrlAnimB.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              transition: {
                ...transition,
                delay: (numberOfPaths - 1 - ii) * bottomDuration / numberOfPaths + i * durationCycleBack,
                duration: bottomDuration / numberOfPaths,
              },
            })
        )
      }
      if (isLeft) {
        const { animation, transition } = frames.left.outAnim
        isMounted.current && ctrlAnimL.start(
          ({ i, ii, numberOfPaths }) => (
            {
              ...animation,
              transition: {
                ...transition,
                delay: (numberOfPaths - 1 - ii) * leftDuration / numberOfPaths + i * durationCycleBack,
                duration: leftDuration / numberOfPaths,
              },
            })
        )
      }
    })
    refIsAnimationAppearing.current = !refIsAnimationAppearing.current
  }, [ ctrlAnimB, ctrlAnimL, ctrlAnimR, ctrlAnimT, isBottom, isLeft, isRight, isTop, numberOfChilds ])

  const runOnetimeAnimationCallb = async () => {
    await seqDisappear()
    seqAppear()
    // if (refIsAnimationAppearing.current) {
    //   // if (refRenderCount.current > 2) seqAppear()
    //   // if (refAlreadyFirstViewed.current) seqAppear()
    //   // !refIsAnimationAppearing.current && seqDisappear()
    //   seqAppear()
    // } else {
    //   // if (refRenderCount.current > 2) seqDisappear()
    //   // if (refAlreadyFirstViewed.current) seqDisappear()
    //   seqDisappear()
    //   seqAppear()

    // }
    // refIsAnimationAppearing.current = !refIsAnimationAppearing.current
  }



  const listSvgTopPathsDMem = useMemo((props) => {

    if (!dsTop) return undefined
    const [ dBig, dMulti ] = dsTop
    if (frames.top.isMultiplePaths) {
      const topStyles = refConstForHooks.current.frames.top.styles
      const numberOfPaths = dMulti.length
      const i = 0
      return dMulti.map((d, ii) => {
        return (
          <motion.path
            d={d.join(" ")}
            className={`pathFrame pathFrame-${ii}`}
            initial={{
              pathLength: 1,
              pathOffset: -1,
            }}
            style={
              topStyles ? topStyles[ ii ] : undefined}
            strokeDasharray="0 1"
            animate={ctrlAnimT}
            custom={{ i, ii, numberOfPaths }}
            key={`pathFrame-${i}--${ii}`}
          />
        )
      })
    }
    return (
      <motion.path
        d={dBig.join(" ")}
        className="pathFrame"
        initial={{
          pathLength: 1,
          pathOffset: -1,
        }}
        animate={ctrlAnimT}
        custom={{ i: 0, ii: 1, numberOfPaths: 1 }}
        strokeDasharray="0 1"
        key={`path-big`}
      />
    )
  }, [ dsTop, frames.top?.isMultiplePaths, ctrlAnimT ])


  const listSvgRightPathsDMem = useMemo(() => {
    if (!dsRight) return undefined
    const [ dBig, dMulti ] = dsRight
    if (frames.right.isMultiplePaths) {
      const rightStyles = refConstForHooks.current.frames.right.styles
      const numberOfPaths = dMulti.length
      const i = 0
      return dMulti.map((d, ii) => (
        < motion.path
          d={d.join(" ")}
          className={`pathFrame pathFrame-${ii}`}
          initial={{
            pathLength: 1,
            pathOffset: -1,
          }}
          style={rightStyles ? rightStyles[ ii ] : undefined}
          animate={ctrlAnimR}
          strokeDasharray="0 1"
          custom={{ i, ii, numberOfPaths }}
          key={`pathFrame-${i}--${ii}`}
        />
      ))
    }
    return (
      < motion.path
        d={dBig.join(" ")}
        className="pathFrame"
        initial={{
          pathLength: 1,
          pathOffset: -1,
        }}
        animate={ctrlAnimR}
        custom={{ i: 0, ii: 1, numberOfPaths: 1 }}
        strokeDasharray="0 1"
        key={`path-big`}
      />)

  }, [ dsRight, frames.right?.isMultiplePaths, ctrlAnimR ])



  const listSvgBottomPathsDMem = useMemo(() => {

    if (!dsBottom) return undefined

    const [ dBig, dMulti ] = dsBottom
    const bottomStyles = refConstForHooks.current.frames.bottom.styles
    if (frames.bottom.isMultiplePaths) {

      const numberOfPaths = dMulti.length
      const i = 0
      return dMulti.map((d, ii) => {
        const styleB = bottomStyles ? bottomStyles[ ii ] : {}
        return (
          < motion.path
            d={d.join(" ")}
            className={`pathFrame pathFrame-${ii}`}
            initial={{
              pathLength: 1,
              pathOffset: -1,
            }}
            // style={bottomStyles ? bottomStyles[ ii ] : undefined}
            style={{ ...styleB }}
            animate={ctrlAnimB}
            custom={{ i, ii, numberOfPaths }}
            strokeDasharray="0 1"
            key={`pathFrame-${i}--${ii}`}
          />
        )
      })
    }
    return (
      < motion.path
        d={dBig.join(" ")}
        className="pathFrame"
        style={bottomStyles && { ...bottomStyles[ 0 ] }}
        initial={{
          pathLength: 1,
          pathOffset: -1,
        }}
        animate={ctrlAnimB}
        custom={{ i: 0, ii: 1, numberOfPaths: 1 }}
        strokeDasharray="0 1"
        key={`path-big`}
      />)

  }, [ dsBottom, frames.bottom?.isMultiplePaths, ctrlAnimB ])


  const listSvgLeftPathsDMem = useMemo(() => {
    if (!dsLeft) return undefined

    const [ dBig, dMulti ] = dsLeft
    if (frames.left.isMultiplePaths) {
      const leftStyles = refConstForHooks.current.frames.left.styles
      const numberOfPaths = dMulti.length
      const i = 0
      return dMulti.map((d, ii) => (
        < motion.path
          d={d.join(" ")}
          className={`pathFrame pathFrame-${ii}`}
          initial={{
            pathLength: 1,
            pathOffset: -1,
          }}
          style={leftStyles ? leftStyles[ ii ] : undefined}

          animate={ctrlAnimL}
          custom={{ i, ii, numberOfPaths }}
          strokeDasharray="0 1"
          key={`pathFrame-${i}--${ii}`}
        />
      ))
    }
    return (
      < motion.path
        d={dBig.join(" ")}
        className="pathFrame"
        initial={{
          pathLength: 1,
          pathOffset: -1,
        }}
        animate={ctrlAnimL}
        custom={{ i: 0, ii: 1, numberOfPaths: 1 }}

        strokeDasharray="0 1"
        key={`path-big`}
      />)

  }, [ dsLeft, frames.left?.isMultiplePaths, ctrlAnimL ])

  useEffect(() => {
    isMounted.current = true;
    // const endTime = performance.now();

    // const timeToRender = endTime - startTime;
    // console.log("Waved useLayoutEffect timeToRender", name, timeToRender)
    return () => {
      isMounted.current = false;
    }
  }, [])

  // useEffect(() => {
  //   if (isMounted.current) {
  //     seqDisappear();
  //   }
  // }, [ seqDisappear ]);

  useLayoutEffect(() => {
    if (svgTopChildren) {
      // const endTime = performance.now();
      const svgViewBoxPair = getSvgViewboxToFitPaths(svgTopChildren)
      if (svgViewBoxPair[ 0 ]) {
        setSvgTopViewboxPair(svgViewBoxPair)
        // const timeToRender = endTime - startTime;
        // console.log("Waved useLayoutEffect timeToRender", name, timeToRender)
      }
    }
  }, [ rootTop, rootLeft, rootRight, rootBottom, svgTopChildren ])


  useLayoutEffect(() => {
    if (svgRightChildren) {
      const svgViewBoxPair = getSvgViewboxToFitPaths(svgRightChildren)
      if (svgViewBoxPair[ 0 ]) setSvgRightViewboxPair(svgViewBoxPair)
    }
  }, [ rootTop, rootLeft, rootRight, rootBottom, svgRightChildren ])

  useLayoutEffect(() => {
    if (svgBottomChildren) {
      const svgViewBoxPair = getSvgViewboxToFitPaths(svgBottomChildren)
      if (svgViewBoxPair[ 0 ]) setSvgBottomViewboxPair(svgViewBoxPair)
    }
  }, [ rootTop, rootLeft, rootRight, rootBottom, svgBottomChildren ])


  useLayoutEffect(() => {
    // console.log("Waved LayoutEff2 alreadyFirstViewed ", name, refAlreadyFirstViewed.current)

    if (svgLeftChildren) {
      // console.log("Waved svgLeftChildren exist setState viewbox LayoutEff2", name)
      const svgViewBoxPair = getSvgViewboxToFitPaths(svgLeftChildren)
      if (svgViewBoxPair[ 0 ]) setSvgLeftViewboxPair(svgViewBoxPair)
    }
  }, [ rootTop, rootLeft, rootRight, rootBottom, svgLeftChildren ])


  const seqAnimStop = () => {
    ctrlAnimT.stop()
    ctrlAnimL.stop()
    ctrlAnimR.stop()
    ctrlAnimB.stop()
  }

  // refRenderCount.current += 1
  // // console.log("Waved refCount", name, refRenderCount.current)

  // console.log(name, " -------- WavedFrameAnimationForChildren end -----------------------------------------")
  return (
    <Box
      className="wrapRefs"
      sx={styleSxWrap}

    >
      <motion.div
        className="rootRef"
        ref={(el) => {
          observe(el); // Set the target element for measuring
          rootRef.current = el; // Share the element for other purposes
        }}
        style={styleRootPaddings}
        onTapStart={(e, info) => {
          refIsUserAction.current = true
          refAlreadyFirstViewed.current = true
          runOnetimeAnimationCallb()
          // refIsUserAction.current = false
        }}
        onTapCancel={e => {
          if (isMounted.current) {
            ctrlAnimT.stop()
            ctrlAnimL.stop()
            ctrlAnimR.stop()
            ctrlAnimB.stop()
          }
        }}
        onHoverStart={(e, info) => {
          refIsUserAction.current = true
          refAlreadyFirstViewed.current = true
          runOnetimeAnimationCallb()
        }}
        onHoverEnd={e => {
          // refIsUserAction.current = false
          refAlreadyFirstViewed.current = true
          if (isMounted.current) {
            ctrlAnimT.stop()
            ctrlAnimL.stop()
            ctrlAnimR.stop()
            ctrlAnimB.stop()
          }
        }}
        viewport={{
          // margin: "50px 0px -0% 0px",
          amount: "some",
        }}
        onViewportEnter={entry => {
          //// console.log('%c waved viewportEnter alreadyViewed, refRenderCount ', 'color: red', name, refAlreadyFirstViewed.current, refRenderCount.current)
          // console.log('%c waved viewportEnter alreadyViewed ', 'color: red', name, refAlreadyFirstViewed.current)
          refIsUserAction.current = false

          if (refAlreadyFirstViewed.current) {
            seqAnimStop()
            // if (refRenderCount.current > 2) { 
            //   }
            seqAppear()
          }
        }}
        onViewportLeave={entry => {
          if (isMounted.current) {
            // // console.log("%c waved viewportLeave alreadyViewed refRenderCount ", 'color: red', name, refAlreadyFirstViewed.current, refRenderCount.current)
            // console.log("%c waved viewportLeave alreadyViewed ", 'color: red', name, refAlreadyFirstViewed.current)
            // seqAnimStop()
            // if (refRenderCount.current > 2) seqDisappear()
            if (refAlreadyFirstViewed.current) {
              seqAnimStop()
              // seqDisappear()
            } else {
              refAlreadyFirstViewed.current = true
            }
          }
        }}
        key="WFAFC-rootRef"
      >
        {children}

        {isTop &&
          <>
            <svg
              ref={refSvgTop}
              className="svgFrame"
              viewBox={svgTopViewboxPair[ 1 ]}
              width={!!svgTopViewboxPair[ 0 ] ? `${svgTopViewboxPair[ 0 ]?.width}px` : "0px"}
              height={!!svgTopViewboxPair[ 0 ] ? `${svgTopViewboxPair[ 0 ]?.height}px` : "0px"}
              style={{ ...styleSvgTop }}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              {listSvgTopPathsDMem}
            </svg>

          </>
        }


        {isRight && (
          <svg
            ref={refSvgRight}
            className="svgFrame"
            viewBox={svgRightViewboxAdaptedString}
            width={svgRightWidth}
            height={svgRightHeight}
            style={{ ...styleSvgRight }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            {listSvgRightPathsDMem}
          </svg>
        )}

        {isBottom && (
          <svg
            ref={refSvgBottom}
            className="svgFrame"
            viewBox={svgBottomViewboxAdaptedString}
            width={svgBottomWidth}
            height={svgBottomHeight}
            style={{ ...styleSvgBottom }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            {listSvgBottomPathsDMem}
          </svg>
        )}

        {isLeft && (<svg
          ref={refSvgLeft}
          className="svgFrame"
          viewBox={svgLeftViewboxAdaptedString}
          width={svgLeftWidth}
          height={svgLeftHeight}
          style={{ ...styleSvgLeft }}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          {listSvgLeftPathsDMem}
        </svg>
        )}

      </motion.div>
    </Box>
  )
}


// const MemoizedWavedFrameAnimationForChildren = React.memo(WavedFrameAnimationForChildren,
//   (prevProps, nextProps) => {
//     // Perform a deep comparison of the animationKey prop
//     const areOptionsFramesEqual = JSON.stringify(prevProps.optionsFrames) === JSON.stringify(nextProps.optionsFrames);
//     const areStylesBottomEqual = JSON.stringify(prevProps.stylesBottom) === JSON.stringify(nextProps.stylesBottom);
//     const areStylesTopEqual = JSON.stringify(prevProps.stylesTop) === JSON.stringify(nextProps.stylesTop);
//     const areStylesRightEqual = JSON.stringify(prevProps.stylesRight) === JSON.stringify(nextProps.stylesRight);
//     const areStylesLeftEqual = JSON.stringify(prevProps.stylesLeft) === JSON.stringify(nextProps.stylesLeft);
//     // console.log("MemoizedWavedFrameAnimationForChildren areOptionsFramesEqual", areOptionsFramesEqual)
//     // Perform a shallow comparison of the rest of the props
//     const areOtherPropsEqual = Object.keys(prevProps).every(key => {
//       // if (key !== 'stylesTop' || 'stylesRight' || 'stylesBottom' || 'stylesLeft' || "children") return true;
//       if (key !== "children") return true;
//       return prevProps[ key ] === nextProps[ key ];
//     });
//     // // console.log("MemoizedBowList areOtherPropsEqual", areOtherPropsEqual)

//     // Only update the component if any of the props have changed
//     return areOptionsFramesEqual && areStylesBottomEqual && areStylesTopEqual && areStylesRightEqual && areStylesLeftEqual && areOtherPropsEqual;
//   }
// );


// export default MemoizedWavedFrameAnimationForChildren
export default WavedFrameAnimationForChildren


