import React from "react";
import Grid from '@mui/material/Grid';
import useVideaFinstat from "./useVideaFinstat";

const styleSxWrap = {
  mb: "4rem",
  "& .video": {
    width: [ "400px", "300px", "200px" ],
    maxWidth: '100%',
  }
}

const VideaFinstat = () => {

  const videaFinstat = useVideaFinstat()

  const allVidea = videaFinstat.nodes.map(
    ({ base, name }) => {
      const src = `/sf/vba-finst/${base}`;
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
          <video
            controls
            autoPlay={false}
            title={name}
            className="video"
          >
            <source src={src} type="video/mp4" />
          </video>
        </Grid>
      );
    }
  );
  return (
    <Grid
      container
      spacing={0}
      alignItems="flex-start"
      justifyContent="space-between"
      sx={styleSxWrap}
      style={{ marginBottom: "4rem" }}
    >
      {allVidea}
    </Grid>
  );
};

export default VideaFinstat;
