
// import { isObject2 } from "./functions"

const BG_IMAGES_PATHS = {
    bgChalk1ChromolithoCornerTR: "/sf/im/bg/bgChalk1ChromolithoCornerTR.svg",
    bgChalk1ChromolithoCornerTL: "/sf/im/bg/bgChalk1ChromolithoCornerTL.svg",
    bgChalk1ChromolithoCornerBR: "/sf/im/bg/bgChalk1ChromolithoCornerBR.svg",
    bgChalk1ChromolithoCornerBL: "/sf/im/bg/bgChalk1ChromolithoCornerBL.svg",


    bodkyHorizMyGradW390H49: "/sf/im/bg/bodky-horiz-myGrad-w390-h49.svg",
    bodkyHorizMyGradW390H49V: "/sf/im/bg/bodky-horiz-myGrad-w390-h49-vert.svg",
    bodkyVertMyGradW97H779: "/sf/im/bg/bodky-vert-myGrad-w97-h779-gr.svg",
    bodkyVertMyGradW97H779H: "/sf/im/bg/bodky-vert-myGrad-w97-h779-gr-horiz.svg",

    bodkyQHorizW779H97: "/sf/im/bg/bodky-Q-horiz-w779-h97.svg",
    bodkyQHorizW779H97V: "/sf/im/bg/bodky-Q-horiz-w779-h97-vert.svg",

    bodkyQW97H779: "/sf/im/bg/bodky-Q-w97-h779-gr-horiz.svg",
    bodkyQW97H779V: "/sf/im/bg/bodky-Q-w97-h779-gr.svg",



    bodkyVertMyGradH779W97: "/sf/im/bg/bodky-vert-myGrad-h779-w97.svg",

    vodaVLeseW1200h900PhotoIllustration11Dusty: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration11-dusty-q10.jpg",
    vodaVLeseW1200h900PhotoIllustration11DustyVertL: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration11-dusty-vertL.jpg",
    vodaVLeseW1200h900PhotoIllustration11DustyVertR: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration11-dusty-vertR-q10.jpg",

    vodaVLeseW1200h900PhotoIllustration14paintStroke: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration14-paintStroke-q10.jpg",
    vodaVLeseW1200h900PhotoIllustration14paintStrokeVL: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration14-paintStroke-vertL-q10.jpg",
    vodaVLeseW1200h900PhotoIllustration14paintStrokeVR: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration14-paintStroke-vertR-q10.jpg",


    vodaVLeseW1200h900PhotoIllustration7SoftGlow: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration7-softGlow-q10.jpg",
    vodaVLeseW1200h900PhotoIllustration7SoftGlowVL: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration7-softGlow-q10-VL.jpg",
    vodaVLeseW1200h900PhotoIllustration7SoftGlowVR: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration7-softGlow-q10-VR.jpg",

    vodaVLeseW1200h900PhotoIllustration2: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration2-q10.jpg",
    vodaVLeseW1200h900PhotoIllustration2VL: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration2-q10-VL.jpg",
    vodaVLeseW1200h900PhotoIllustration2VR: "/sf/im/bg/voda-v-lese-w1200h900-photoIllustration2-q10-VR.jpg",

    vodaVLeseW1200h900highlightBloom2: "/sf/im/bg/voda-v-lese-w1200h900-highlightBloom2-q10.jpg",
    vodaVLeseW1200h900highlightBloom2VL: "/sf/im/bg/voda-v-lese-w1200h900-highlightBloom2-q10-VL.jpg",
    vodaVLeseW1200h900highlightBloom2VR: "/sf/im/bg/voda-v-lese-w1200h900-highlightBloom2-q10-VR.jpg",

    vodaVLeseW1200h900DreamSmoothing: "/sf/im/bg/voda-v-lese-w1200h900-dreamSmoothing-q10.jpg",
    vodaVLeseW1200h900DreamSmoothingVL: "/sf/im/bg/voda-v-lese-w1200h900-dreamSmoothing-q10-VL.jpg",
    vodaVLeseW1200h900DreamSmoothingVR: "/sf/im/bg/voda-v-lese-w1200h900-dreamSmoothing-q10-VR.jpg",

    portBuildings: "/sf/im/bg/olivia-bliss-HTnycx0h_kk-unsplash_c-posterize3-mainPart-relief-q1.png",
    portBuildingsL: "/sf/im/bg/olivia-bliss-HTnycx0h_kk-unsplash_c-posterize3-mainPart-relief-q1-520x1920-L.png",
    portBuildingsR: "/sf/im/bg/olivia-bliss-HTnycx0h_kk-unsplash_c-posterize3-mainPart-relief-q1-520x1920-R.png",


}
export const bgsMap = {
    bgc1TR: { name: "bgChalk1ChromolithoCornerTR", aspectRatio: 128.26162 / 50.44249 },
    bgc1TL: { name: "bgChalk1ChromolithoCornerTL", aspectRatio: 128.26162 / 50.44249 },
    bgc1BR: { name: "bgChalk1ChromolithoCornerBR", aspectRatio: 128.26162 / 50.44249 },
    bgc1BL: { name: "bgChalk1ChromolithoCornerBL", aspectRatio: 128.26162 / 50.44249 },
    bg1: { name: "bodkyHorizMyGradW390H49", aspectRatio: 779 / 97 },
    bg1V: { name: "bodkyHorizMyGradW390H49V", aspectRatio: 97.3349 / 779.0437 },
    bg2: { name: "bodkyVertMyGradW97H779H", aspectRatio: 779 / 97 },
    bg2V: { name: "bodkyVertMyGradW97H779", aspectRatio: 97 / 779 },

    bg3: { name: "bodkyQHorizW779H97", aspectRatio: 779 / 97 },
    bg3V: { name: "bodkyQHorizW779H97V", aspectRatio: 97 / 779 },

    bg4: { name: "bodkyQW97H779", aspectRatio: 779 / 97 },
    bg4V: { name: "bodkyQW97H779V", aspectRatio: 97 / 779 },
    bgI1: { name: "vodaVLeseW1200h900PhotoIllustration11Dusty", aspectRatio: 1200 / 900 },
    bgI1VL: { name: "vodaVLeseW1200h900PhotoIllustration11DustyVertL", aspectRatio: 900 / 1200 },
    bgI1VR: { name: "vodaVLeseW1200h900PhotoIllustration11DustyVertR", aspectRatio: 900 / 1200 },
    bgI2: { name: "vodaVLeseW1200h900PhotoIllustration14paintStroke", aspectRatio: 1200 / 900 },
    bgI2VL: { name: "vodaVLeseW1200h900PhotoIllustration14paintStrokeVL", aspectRatio: 900 / 1200 },
    bgI2VR: { name: "vodaVLeseW1200h900PhotoIllustration14paintStrokeVR", aspectRatio: 900 / 1200 },

    bgI3: { name: "vodaVLeseW1200h900PhotoIllustration7SoftGlow", aspectRatio: 1200 / 900 },
    bgI3VL: { name: "vodaVLeseW1200h900PhotoIllustration7SoftGlowVL", aspectRatio: 900 / 1200 },
    bgI3VR: { name: "vodaVLeseW1200h900PhotoIllustration7SoftGlowVR", aspectRatio: 900 / 1200 },

    bgI4: { name: "vodaVLeseW1200h900PhotoIllustration2", aspectRatio: 1200 / 900 },
    bgI4VL: { name: "vodaVLeseW1200h900PhotoIllustration2VL", aspectRatio: 900 / 1200 },
    bgI4VR: { name: "vodaVLeseW1200h900PhotoIllustration2VR", aspectRatio: 900 / 1200 },

    bgI5: { name: "vodaVLeseW1200h900highlightBloom2", aspectRatio: 1200 / 900 },
    bgI5VL: { name: "vvodaVLeseW1200h900highlightBloom2VL", aspectRatio: 900 / 1200 },
    bgI5VR: { name: "vodaVLeseW1200h900highlightBloom2VR", aspectRatio: 900 / 1200 },

    bgI6: { name: "vodaVLeseW1200h900DreamSmoothing", aspectRatio: 1200 / 900 },
    bgI6VL: { name: "vodaVLeseW1200h900DreamSmoothingVL", aspectRatio: 900 / 1200 },
    bgI6VR: { name: "vodaVLeseW1200h900DreamSmoothingVR", aspectRatio: 900 / 1200 },

    bgI7: { name: "portBuildings", aspectRatio: 1920 / 520 },
    bgI7VL: { name: "portBuildingsL", aspectRatio: 520 / 1920 },
    bgI7VR: { name: "portBuildingsR", aspectRatio: 520 / 1920 },


}


export const getBgStaticPathWithAspectRatio = (bgImage) => {
    if (!bgImage) return
    //  const isObject = isObject2(bgImage)

    const nameOrObj = bgsMap[ bgImage ]
    const { name, aspectRatio } = nameOrObj
    return { path: BG_IMAGES_PATHS[ name ], aspectRatio }

}

