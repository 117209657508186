import React from "react"

type PathD = string | string[];

export interface SepAbsSvgData {
    viewBoxHeightOrig: number;
    viewBoxWidthOrig: number;
    dPath: PathD;
    dPathStyle: React.CSSProperties | React.CSSProperties[];
    svgWidth: string;
    svgChildren: React.ReactNode;
}

export const sepAbsSvgData: Record<string, SepAbsSvgData> = {
    normal: {
        viewBoxHeightOrig: 110,
        viewBoxWidthOrig: 1200,
        dPath: [
            "M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z",
            "M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z",
            "M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z",
        ],
        dPathStyle: [{ fillOpacity: 0.25 }, { fillOpacity: 0.5 }, { fillOpacity: 1 }],
        svgWidth: "calc(100% - 1px)",
        svgChildren: (
            <>
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="sepPath" />
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="sepPath" />
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="sepPath" />
            </>
        ),
    },
    wavyCornerLB: {
        viewBoxHeightOrig: 59.374969,
        viewBoxWidthOrig: 110.77101,
        dPath:
            "m 290.21608,195.21981 c 2.3857,-13.34456 -1.67947,-47.91904 -2.78615,-55.02684 -7.38694,1.14574 -14.06269,1.6329 -21.564,1.18304 -0.23868,-0.0143 -0.47728,-0.0294 -0.71587,-0.0453 -6.81796,-0.45372 -12.97042,-1.47938 -19.75643,-2.44438 0,0 -1e-5,0 -1e-5,0 -0.75432,-0.10726 -1.50849,-0.2124 -2.26311,-0.31419 0,0 0,0 0,0 -7.951,-1.07228 -15.37523,-1.67482 -23.36487,-1.5126 0,0 -1e-5,0 -1e-5,0 -1.35377,0.0275 -2.70391,0.0766 -4.05715,0.1469 0,0 -1e-5,0 -1e-5,0 -12.15269,0.63047 -24.19004,3.18204 -36.26352,3.73939 3.27967,4.14815 8.57632,5.26863 14.63162,5.56099 6.03882,0.29156 12.26095,-0.19879 17.32194,1.60389 1.58784,0.56558 3.02096,1.20082 4.32325,1.8802 1.48014,0.77217 2.80079,1.60639 3.98818,2.46335 4.71978,3.39863 7.75763,7.51417 10.6686,10.37711 3.05667,3.00624 6.39944,5.20723 12.68023,5.73718 0.10494,0.0154 0.2092,0.0315 0.31278,0.0483 0.82054,0.13401 1.60166,0.31304 2.34541,0.5303 10.26275,3.00147 12.84348,13.24365 20.2522,17.56328 0.24579,0.1433 0.49464,0.28188 0.74629,0.41588 0.24794,0.13245 0.49871,0.26038 0.75207,0.38394 5.03632,2.44615 10.44145,2.94418 15.21712,3.4552 4.53402,0.48517 8.0001,0.96182 7.53144,4.25438 z",
        dPathStyle: {},
        svgWidth: "calc(100% - 1px)",
        svgChildren: (
            <path
                d="m 290.21608,195.21981 c 2.3857,-13.34456 -1.67947,-47.91904 -2.78615,-55.02684 -7.38694,1.14574 -14.06269,1.6329 -21.564,1.18304 -0.23868,-0.0143 -0.47728,-0.0294 -0.71587,-0.0453 -6.81796,-0.45372 -12.97042,-1.47938 -19.75643,-2.44438 0,0 -1e-5,0 -1e-5,0 -0.75432,-0.10726 -1.50849,-0.2124 -2.26311,-0.31419 0,0 0,0 0,0 -7.951,-1.07228 -15.37523,-1.67482 -23.36487,-1.5126 0,0 -1e-5,0 -1e-5,0 -1.35377,0.0275 -2.70391,0.0766 -4.05715,0.1469 0,0 -1e-5,0 -1e-5,0 -12.15269,0.63047 -24.19004,3.18204 -36.26352,3.73939 3.27967,4.14815 8.57632,5.26863 14.63162,5.56099 6.03882,0.29156 12.26095,-0.19879 17.32194,1.60389 1.58784,0.56558 3.02096,1.20082 4.32325,1.8802 1.48014,0.77217 2.80079,1.60639 3.98818,2.46335 4.71978,3.39863 7.75763,7.51417 10.6686,10.37711 3.05667,3.00624 6.39944,5.20723 12.68023,5.73718 0.10494,0.0154 0.2092,0.0315 0.31278,0.0483 0.82054,0.13401 1.60166,0.31304 2.34541,0.5303 10.26275,3.00147 12.84348,13.24365 20.2522,17.56328 0.24579,0.1433 0.49464,0.28188 0.74629,0.41588 0.24794,0.13245 0.49871,0.26038 0.75207,0.38394 5.03632,2.44615 10.44145,2.94418 15.21712,3.4552 4.53402,0.48517 8.0001,0.96182 7.53144,4.25438 z"
                transform="rotate(-179.0054,144.74014,98.865255)"
            />
        ),
    },
    wave5: {
        svgWidth: "calc(100% - 1px)",
        viewBoxHeightOrig: 120,
        viewBoxWidthOrig: 1200,
        dPath: "M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z",
        dPathStyle: { opacity: ".25" },
        svgChildren: (
            <>
                <path d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z" opacity=".25" />
            </>
        ),
    },
    halfCircles: {
        svgWidth: "calc(100% - 1px)",
        viewBoxHeightOrig: 320,
        viewBoxWidthOrig: 320,
        dPath: "M 10 315 L 110 215 A 30 50 0 0 1 162.55 162.45 L 172.55 152.45 A 30 50 -45 0 1 215.1 109.9 L 315 10",
        dPathStyle: {},
        svgChildren: (
            <>
                <path d="M 10 315 L 110 215 A 30 50 0 0 1 162.55 162.45 L 172.55 152.45 A 30 50 -45 0 1 215.1 109.9 L 315 10" />
            </>
        ),
    },
};





type FillColor = string;
type OverflowValue = "visible" | "hidden" | "scroll" | "auto" | "unset" | "initial" | "inherit";
type DPathOrderNotIn = [0] | [1] | [2] | [0, 1] | [1, 2] | [0, 2] | [];
type NameSvg = keyof typeof sepAbsSvgData;
type TransformValue = "none" | "unset" | string;

export type BaseOptionsSeparatorAbs = {
    gradientBgColor?: string,
    isRelative: boolean;
    isVertical?: boolean;
    overflow?: OverflowValue;
    leftsShift?: number | number[];
    nameSvg: NameSvg;
    countSvg?: number;
    direction: "" | "row" | "column" | "row-reverse" | "column-reverse" | undefined;
    isInEdges: boolean;
    dPathOrderNotIn?: DPathOrderNotIn;
    isQuatro: boolean;
    isDouble: boolean;
    mirrorX?: boolean,
    mirrorY?: boolean,
    isRotateXReverse?: boolean;
    isRotateYReverse?: boolean;
    ySymetrical?: "-" | "up" | "down",
    paddingYSep: number;
    paddingXSep?: number;
    heightsWanted: number | number[] | null;
    widthsWanted: number;
    svgXPadding: number;
    svgYPadding: number;
    strokeWidth?: number;

    fillColors?: FillColor[];
    exitFillColors?: FillColor[];
    strokeColor?: string;

    wrapFirstGradientLevelOpacity?: number,
    styleWrapSepLast?: React.CSSProperties | undefined;
    wrapClass?: string;


    countUnits?: number;
    transformWrapSep?: TransformValue,
    styleSvg?: React.CSSProperties;
    styleWrapDbl?: React.CSSProperties;
    styleWrapDown?: React.CSSProperties;
    styleWrapSep?: React.CSSProperties;
    style1Position?: React.CSSProperties;
    styleWrap1?: React.CSSProperties | undefined;

    rotateZ90Svg?: boolean;
};

const fillCol1 = "#057778"
const fillCol2 = "#458938"
const fillCol3 = "#012458"
const strokeCol = "#758"
const bgCol = "#789"

// type OptionsSeparatorAbsContainer = {
//     [key: string]: OptionsSeparatorAbs;
//   };

export type OptionsSeparatorAbsContainer = Record<string, BaseOptionsSeparatorAbs>
export const optionsSeparatorAbs: OptionsSeparatorAbsContainer = {

    sepNormalAbs2Up2Down: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        styleSvg: { borderRadius: "50%", transform: "skew(12deg,12deg) translateY(10px)", borderStyle: "ridge" },
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 300,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 5,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsFullUpDownHalfProfile: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 3,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 300,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 5,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsUpDownHalfProfilePlusInVerticalLines: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 3,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 300,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 5,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsCrossUpsideDown: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 300,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 5,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsTUpsideDown: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsFullUpFullDown: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsFullUpFullManyHumpsDown: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsFiveVerticalsFromBottomInMiddleHighest: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalRel2FullHorizontal: {
        isRelative: true,
        styleWrapSep: { backgroundColor: bgCol },
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalRel2FullHorizontalHills: {
        styleSvg: {
            transform: 'rotateX(180deg)', //= inner hills  // 'rotateY(180deg)'
            height: "100px", //hills height
        },
        isRelative: true,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: 100, // [ 8, 16, 32, 48, 64 ],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2, //40 = very flat, 0 = max hills
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: false,

        // dPathOrderNotIn: [ 0 ],
        // isRotateYReverse: false,
        // mirrorX: false,
        // mirrorY: false,
        // dPathOrderNotIn: [ 1 ],
        // isRotateXReverse: true,
        // isRotateYReverse: true,
        // ySymetrical: "down",// up, down
    },
    sepNormalRel6FullInColumn: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalRel2x6W100InRowSkewed: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "normal",
        styleSvg: {
            borderRadius: "50%",
            transform: "skew(12deg,12deg) translateY(10px)", borderStyle: "ridge"
        },
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalRel2x6W100InRowPaddedSkewed: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "normal",
        styleSvg: {
            borderRadius: "50%",
            transform: "skew(12deg,12deg) translateY(10px)", borderStyle: "ridge"
        },
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 20,
        svgXPadding: 5,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalRel2HorizontalW200FromLeft: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 200,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesAbsInCorners: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesAbsInCornersWithW100: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesAbsInCornersWithW100HorizPadding: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesAbsOneCenteredVerticale2Up2Down: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesRelOneCenteredVerticale4: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesRelOneVerticaleLeft4: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesRel2Left2Right: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCircles6W100InRowCentered: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCircles3x6W100InRowCentered: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 3,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCircles3x6W100Z90InRowCentered: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 3,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 100,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 2,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesRelative: {
        isRelative: true,
        overflow: "unset",
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 50,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 5,
        svgXPadding: 0,
        direction: "row",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesRelativeColumnCount5MaxH200H50W200: {
        isRelative: true,
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 5,
        styleWrapSep: { maxHeight: 200 },
        heightsWanted: 50, //[8, 16, 32, 48, 64],
        widthsWanted: 200,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepHalfCirclesRelativeColumnCount5W200BorderSkew: {
        isRelative: true,
        nameSvg: "halfCircles",
        countUnits: 1,
        countSvg: 5,
        styleSvg: { borderRadius: "50%", transform: "skew(33deg,73deg)", borderStyle: "ridge" },
        heightsWanted: 0, //[8, 16, 32, 48, 64],
        widthsWanted: 200,
        rotateZ90Svg: true,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "row",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    sepNormalAbsEdgesColumnHcontLeftcont: {
        isRelative: false,
        overflow: "unset",
        nameSvg: "normal",
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 5,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: true,
    },
    SepSvgBodkyQW779H97_5xAllOverOneRow: {
        styleWrapSep: { backgroundColor: bgCol },
        isRelative: true,
        overflow: "unset",
        nameSvg: "SvgBodkyQW779H97",
        countUnits: 1,
        countSvg: 5,
        heightsWanted: [8, 16, 32, 48, 64],
        leftsShift: [-4, -8, -16, -24, -24],
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: false,
        isDouble: false,
    },
    SepSvgBodkyQW97H779_1colLeft_1colRight: {
        styleWrapSep: { backgroundColor: bgCol },
        isRelative: false,
        isVertical: true,
        overflow: "unset",
        nameSvg: "SvgBodkyQW97H779",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: 0,
        leftsShift: 0,
        widthsWanted: 50,
        rotateZ90Svg: false,
        paddingYSep: 0,
        paddingXSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "row",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: false,
    },
    SepSvgBodky1_1colLeft_1colRight: {
        styleWrapSep: { backgroundColor: bgCol },
        isRelative: false,
        isVertical: true,
        overflow: "unset",
        nameSvg: "SvgBodky1",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: 0,
        leftsShift: 0,
        widthsWanted: 50,
        rotateZ90Svg: false,
        paddingYSep: 0,
        paddingXSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "row",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: true,
        isDouble: false,
    },
    sepSvgBodky1_1colRight: {
        styleWrapSep: { backgroundColor: bgCol },
        isRelative: true,
        isVertical: true,
        overflow: "unset",
        nameSvg: "SvgBodky1",
        countUnits: 1,
        countSvg: 2,
        heightsWanted: 0,
        leftsShift: 0,
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        paddingXSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        styleWrap1: { right: 0, transform: "rotateY(180deg)" },
        isQuatro: false,
        isDouble: false,
    },
    sepSvgBodky1_1colLeft: {
        styleWrapSep: { backgroundColor: bgCol },
        isRelative: true,
        isVertical: true,
        overflow: "unset",
        nameSvg: "SvgBodky1",
        countUnits: 1,
        countSvg: 2,
        heightsWanted: 0,
        leftsShift: 0,
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        paddingXSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        styleWrap1: { left: 0 },
        isQuatro: false,
        isDouble: false,
    },
    sepVerticalWavesWidth: {
        gradientBgColor: strokeCol,
        styleWrapSep: {
            display: "table-cell",
        },
        isRelative: true,
        dPathOrderNotIn: [],
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: 0,
        widthsWanted: 100,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: false,
        isDouble: true,
        isRotateYReverse: false,
    },

    sepVerticalWavesWidthAbs: {
        gradientBgColor: strokeCol,
        styleWrapSep: {
            display: "table-cell",
            padding: "110px 0px",
            borderRadius: "50%",
            boxShadow: '-1px 7px 1px 0px #454',
        },
        isRelative: true,
        isVertical: false,
        dPathOrderNotIn: [],
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: 0,
        widthsWanted: 200,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: true,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        isQuatro: false,
        isDouble: true,
        isRotateYReverse: false,
    },

    sepNormalRelDblFullHorizontal1: {
        // gradientBgColor: strokeCol,
        isRelative: true,
        overflow: "unset",
        nameSvg: "normal",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: null,
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        // fillColors: [ fillCol1, fillCol2, fillCol3, ],
        // strokeColor: strokeCol,
        strokeWidth: 1,
        dPathOrderNotIn: [],
        isQuatro: false,
        isDouble: true,
        isRotateYReverse: false,
    },
    sepWavyCornerLBRelDblFullHorizontal1: {
        gradientBgColor: strokeCol,
        isRelative: true,
        overflow: "unset",
        nameSvg: "wavyCornerLB",
        countUnits: 1,
        countSvg: 1,
        heightsWanted: null,
        widthsWanted: 0,
        rotateZ90Svg: false,
        paddingYSep: 0,
        svgYPadding: 0,
        svgXPadding: 0,
        direction: "column",
        isInEdges: false,
        fillColors: [fillCol1, fillCol2, fillCol3,],
        strokeColor: strokeCol,
        strokeWidth: 1,
        dPathOrderNotIn: [],
        isQuatro: false,
        isDouble: true,
        isRotateYReverse: false,
    }
}

export type NamedSeparator = keyof typeof optionsSeparatorAbs;


