import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
// import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
// import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const IntroTextSpravneWebStrankyCCh = ({ colors = {} }) => {
    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };

    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;
    const myAnimationOptionsCCh = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="Idealne-web-stranky" className="toc"><span>Ideálne webstránky</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="Idealne-web-stranky"
            >
                <div>Webstránky. Wanted</div>
                <div className="connected">
                    <h4 id="Prehladne" className="waved toc"><span>Prehľadné</span></h4>
                    <div className="connectedDescr" >Neutopte svoje informácie a návštevníka.</div>
                </div>
                <div className="connected">
                    <h4 id="Rychle" className="waved toc"><span>Rýchle</span></h4>
                    <div className="connectedDescr" >Rýchla doba, čosi dlhšie načítavanie webstránky, ľudia netrpezliví, klikajú alternatívy alebo Google.</div>
                </div>
                <div className="connected">
                    <h4 id="web-stranky-ktore-sa-daju-najst" className="waved toc"><span>Webstránky, ktoré sa dajú nájsť</span></h4>
                    <div className="connectedDescr" >Kto by nechcel byť vpredu na Google, Bing a pod.?</div>
                </div>
                <div className="connected">
                    <h4 id="Pekny-design" className="waved toc"><span>Design</span></h4>
                    <div className="connectedDescr" >Pútavý dizajn poteší oko návštevníka, web v pamäti a nabudúce príde alebo spomenie kamarátovi.</div>
                </div>
                <div className="connected">
                    <h4 id="Flexibilita" className="waved toc"><span>Flexibilita</span></h4>
                    <div>Neskoršie updaty takmer bez obmedzení.</div>
                    <div className="connectedDescr" >Pre ďalšie zmeny - stránky, stĺpcové štruktúry, dizajnové prvky, menu, texty ...</div>
                </div>
                <div className="connected">
                    <h4 id="Dynamicke-data" className="waved toc toc0"><span>Dynamické dáta</span></h4>
                    <div className="connectedDescr" >Nie vždy nevyhnutné, ale možnosť je.</div>
                    <div className="connectedDescr" >Napr. zobrazenie, spracovanie dát z niektorých pravidelne sa meniacich informácií na webe.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ" // "baseT2R2B2L2"


                    // myFrames={myFrames}
                    // myFramesPadding={myFramesPadding}
                    namePaddings="T10-R20-B10-L20"
                >
                    <div className="waved">Objednajte si také webstránky u nás.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default IntroTextSpravneWebStrankyCCh;