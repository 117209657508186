// https://stackoverflow.com/questions/46700368/angled-direction-sine-wave


// The wave length will be in pixels. For a sin wave to make a complete cycle you need to rotate 
// its input angle by Math.PI * 2 so you will need to convert it to value matching pixel wavelength.
// const waveLen = 400; // pixels

// The phase of the sin wave is at what part of the wave it starts at, as the wave length is in pixels, 
// the phase is also in pixels, and represents the distance along the wave that denotes the starting angle.
// const phase = 200; // mid way

// The amplitude of the wave is how far above and below the center line the wave max and min points are = in pixels
// const amplitude = 100;

// A wave also has an offset, though in this case not really important I will added it as well. In pixels as well
// const offset = 0;

// The line that marks the center of the wave has a start and end coordinate

const defaultWavedPathDSettings = {
    oneWave: 2 * Math.PI,
    frequencyPerSide: 4,
    amplitude: 10,
    numberOfSides: 4,
    paddingOffset: 110,
    whatSides: { isTop: true, isLeft: true, isBottom: true, isRight: true },
    sidesRatio100Perc: { partTop: 0.25, partLeft: 0.25, partBottom: 0.25, partRight: 0.25 }
}



// const getWavedPathD = (mySettings = {
const getWavedPathD = ({
    oneWave = 2 * Math.PI,
    frequencyPerSide = 4,
    amplitude = 10,
    // numberOfSides = 4,
    paddingOffset = 110,
    whatSides = { isTop: true, isLeft: true, isBottom: true, isRight: true },
    sidesRatio100Perc = { partTop: 0.25, partLeft: 0.25, partBottom: 0.25, partRight: 0.25 }
}) => {
    // console.log("getWavedPathD")
    // const { mySettings = {} } = props
    // const mySettings
    //     let settings
    // if(mySettings)

    // const settings = mySettings ? { ...defaultWavedPathDSettings, ...mySettings } : { ...defaultWavedPathDSettings }
    // const settings = { ...defaultSkewPathDSettings, ...mySettings }
    // const { oneWave, frequencyPerSide, amplitude, paddingOffset, whatSides, sidesRatio100Perc, numberOfSides } = settings

    const { isTop, isLeft, isBottom, isRight } = whatSides
    const { partTop, partLeft, partBottom, partRight } = sidesRatio100Perc

    const totalPointsTop = isTop ? oneWave * frequencyPerSide * amplitude : 0
    const totalPointsLeft = isLeft ? oneWave * frequencyPerSide * amplitude : 0
    const totalPointsBottom = isBottom ? oneWave * frequencyPerSide * amplitude : 0
    const totalPointsRight = isRight ? oneWave * frequencyPerSide * amplitude : 0

    const totalPoints = Math.floor(
        // oneWave * frequencyPerSide * amplitude * numberOfSides
        totalPointsTop + totalPointsLeft + totalPointsBottom + totalPointsRight
    );



    const pathFunction = (point) => {
        return Math.sin(point / 10) * amplitude;
        // Math.sin(x*frequency)*amplitude;
        //Complete wave in 2*Math.PI (i.e. 6.28318)
    }


    const calculateTop = (point) => {
        const xyPoint = {
            x: paddingOffset + point,
            y: paddingOffset - pathFunction(point)
        };
        return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    }

    const calculateRight = (point) => {
        const xyPoint = {
            x: paddingOffset + totalPoints * 0.25 + pathFunction(point),
            y: paddingOffset + (point - totalPoints * 0.25)
        };
        return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    }



    const calculateBottom = (point) => {
        const xyPoint = {
            x: paddingOffset + totalPoints * 0.25 - (point - totalPoints / 2),
            y: paddingOffset + totalPoints * 0.25 + pathFunction(point)
        };
        return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    }


    const calculateLeft = (point) => {
        const xyPoint = {
            x: -pathFunction(point) + paddingOffset,
            y: totalPoints * 0.25 - (point - totalPoints * 0.75) + paddingOffset
        };
        return [ "L", xyPoint.x, xyPoint.y ].join(" ");
    }


    const getWavedSvgPathD = () => {
        const svgPathD = [];
        svgPathD.push([ "M", paddingOffset, paddingOffset ].join(" ")); //line thickness of 2/1 + amplitude of 10
        for (let point = 1; point < totalPoints; point++) {
            // if (point < totalPoints * 0.25) {
            if (isTop && point < totalPoints * partTop) {
                svgPathD.push(calculateTop(point));
            } else if (isRight && totalPoints * partTop <= point && point < totalPoints * (partTop + partRight)) {
                svgPathD.push(calculateRight(point));
            } else if (isBottom && totalPoints * (partTop + partRight) <= point && point < totalPoints * (partTop + partRight + partBottom)) {
                svgPathD.push(calculateBottom(point));
            } else if (isLeft) {
                svgPathD.push(calculateLeft(point));
            }
            point += 1;
        }
        svgPathD.push([ "L", paddingOffset, paddingOffset ].join(" "));
        // dPath = `${svgPathD.join(" ")}`
        // return `${svgPathD.join(" ")}`
        return svgPathD.join(" ");

    }

    const dPath = getWavedSvgPathD()

    return dPath

}





const x1 = 20;
const y1 = 20;
const x2 = 400;
const y2 = 400;

const defaultSkewPathDSettingsOld = {
    waveLenZ: 400,
    phaseZ: 200,
    amplitudeZ: 100,
    offset: 0,
    startXY: [ 20, 20 ],
    endXY: [ 400, 400 ],
    numberOfWaves: 2,
    amplitudeFactor: 5,
    phaseCoeff: 0.5,
    // isReturning1Array: false,
    // isReturning3Arrays: false,
    pointPrefix: "L",
    pointStep: 20,
    isMiddleOtherWave: false,
    waveMiddle: {
        partMid: 0.1,
        numberOfWavesMid: 10,
        amplitudeFactorMid: 2,
        pointStepMid: 2,
    },
}




const wavesConfig = {
    waves: {
        waves1phase05step10af10: {
            // partCoef: 0.2,
            partCoef: 1,
            amplitudeZ: 100,
            offset: 1, //0.5,
            numberOfWaves: 1, //2,
            phaseCoef: 0.5, // 0.5,
            pointPrefix: "L",
            pointStep: 10,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 10,

        },
        waves5phase05step2af2: {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 5,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 2,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 2,
        },
        waves2phase05step20af5: {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 2,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 20,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 5,
        },
        waves5phase05step2af3: {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 5,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 2,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 3,
        },
        waves15phase05step10af10: {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 15,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 10,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 10,
        },
    },
    wavesActive: [ "wave1" ],
    // wavesActive: ["wave1", "wave2", "wave3", "wave4", "wave5"],
    startXY: [ 20, 20 ],
    endXY: [ 400, 400 ],
}






const defaultSkewPathDSettingsForFrame = {
    isHorizontal: true,
    waves: {
        defWave1: {
            config: {
                // partCoef: 0.2,
                partCoef: 0.2,
                amplitudeZ: 100,
                offset: 1, //0.5,
                numberOfWaves: 1, //2,
                phaseCoef: 0.5, // 0.5,
                pointPrefix: "L",
                pointStep: 10,
                waveLenZ: 400,
                isWaveLenFromNumberOfwaves: true,
                isAmplitudeFromSkewLine: true,
                amplitudeFactor: 10,

            }, coefX: 1, coefP: 1
        },
        defWave2: {
            config: {
                partCoef: 0.2,
                amplitudeZ: 100,
                offset: 0,
                numberOfWaves: 5,
                phaseCoef: 0.5,
                pointPrefix: "L",
                pointStep: 2,
                waveLenZ: 400,
                isWaveLenFromNumberOfwaves: true,
                isAmplitudeFromSkewLine: true,
                amplitudeFactor: 2,
            }, coefX: 1, coefP: 1
        },
        defWave3: {
            config: {
                partCoef: 0.2,
                amplitudeZ: 100,
                offset: 0,
                numberOfWaves: 2,
                phaseCoef: 0.5,
                pointPrefix: "L",
                pointStep: 20,
                waveLenZ: 400,
                isWaveLenFromNumberOfwaves: true,
                isAmplitudeFromSkewLine: true,
                amplitudeFactor: 5,
            }, coefX: 1, coefP: 1
        },
        defWave4: {
            config: {
                partCoef: 0.2,
                amplitudeZ: 100,
                offset: 0,
                numberOfWaves: 5,
                phaseCoef: 0.5,
                pointPrefix: "L",
                pointStep: 2,
                waveLenZ: 400,
                isWaveLenFromNumberOfwaves: true,
                isAmplitudeFromSkewLine: true,
                amplitudeFactor: 3,
            }, coefX: 1, coefP: 1
        },
        defWave5: {
            config: {
                partCoef: 0.2,
                amplitudeZ: 100,
                offset: 0,
                numberOfWaves: 15,
                phaseCoef: 0.5,
                pointPrefix: "L",
                pointStep: 10,
                waveLenZ: 400,
                isWaveLenFromNumberOfwaves: true,
                isAmplitudeFromSkewLine: true,
                amplitudeFactor: 10,
            }, coefX: 1, coefP: 1
        },
    },
    // wavesActive: ["wave1"],
    // wavesActive: ["wave1", "wave2", "wave3", "wave4", "wave5"],
    startXY: [ 20, 20 ],
    endXY: [ 400, 400 ],
}


const defaultSkewPathDSettings = {
    waves: [ //{
        // defWave1: {
        {
            // partCoef: 0.2,
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 1, //0.5,
            numberOfWaves: 1, //2,
            phaseCoef: 0.5, // 0.5,
            pointPrefix: "L",
            pointStep: 10,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 10,
        },
        // defWave2: {
        {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 5,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 2,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 2,
        },
        // defWave3: {
        {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 2,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 20,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 5,
        },
        // defWave4: {
        {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 5,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 2,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 3,
        },
        // defWave5: {
        {
            partCoef: 0.2,
            amplitudeZ: 100,
            offset: 0,
            numberOfWaves: 15,
            phaseCoef: 0.5,
            pointPrefix: "L",
            pointStep: 10,
            waveLenZ: 400,
            isWaveLenFromNumberOfwaves: true,
            isAmplitudeFromSkewLine: true,
            amplitudeFactor: 10,
        },
    ],
    // },
    // wavesActive: ["wave1"],
    // wavesActive: ["wave1", "wave2", "wave3", "wave4", "wave5"],
    startXY: [ 20, 20 ],
    endXY: [ 400, 400 ],
    styles: [ { fill: "red" }, {} ]

}

const waveDefaults = {
    waveLenZ: 400,
    amplitudeZ: 100,
    isWaveLenFromNumberOfwaves: true,
    isAmplitudeFromSkewLine: true,
    pointPrefix: "L",
    offset: 0,
}
const getWaveConfig = (name = "waves15phase05step10af10part05") => {

    const arr = name.split("waves")
    const arr2 = arr[ 1 ].split("phase")
    let numberOfWavesStr = arr2[ 0 ]
    if (numberOfWavesStr.charAt(0) === "0") numberOfWavesStr = "0.".concat(numberOfWavesStr.substring(1))
    const numberOfWaves = Number(numberOfWavesStr)
    const arr3 = arr2[ 1 ].split("step")
    let phaseCoefStr = arr3[ 0 ]
    if (phaseCoefStr.charAt(0) === "0") phaseCoefStr = "0.".concat(phaseCoefStr.substring(1))
    const phaseCoef = Number(phaseCoefStr)
    const arr4 = arr3[ 1 ].split("af")
    const pointStep = Number(arr4[ 0 ])
    const arr5 = arr4[ 1 ].split("part")
    const amplitudeFactor = Number(arr5[ 0 ])
    let partCoefStr = arr5[ 1 ]
    if (partCoefStr.charAt(0) === "0") partCoefStr = "0.".concat(partCoefStr.substring(1))
    const partCoef = Number(partCoefStr)

    const wave = {
        ...waveDefaults,
        numberOfWaves,
        phaseCoef,
        pointStep,
        amplitudeFactor,
        partCoef,
    }
    // console.log("wave", wave)
    return wave
}

const getWaveConfigNoPartCoef = (name = "waves15phase05step10af10") => {

    const arr = name.split("waves")
    const arr2 = arr[ 1 ].split("phase")
    let numberOfWavesStr = arr2[ 0 ]
    if (numberOfWavesStr.charAt(0) === "0") numberOfWavesStr = "0.".concat(numberOfWavesStr.substring(1))
    const numberOfWaves = Number(numberOfWavesStr)
    const arr3 = arr2[ 1 ].split("step")
    let phaseCoefStr = arr3[ 0 ]
    if (phaseCoefStr.charAt(0) === "0") phaseCoefStr = "0.".concat(phaseCoefStr.substring(1))
    const phaseCoef = Number(phaseCoefStr)
    const arr4 = arr3[ 1 ].split("af")
    const pointStep = Number(arr4[ 0 ])
    const arr5 = arr4[ 1 ].split("part")
    const amplitudeFactor = Number(arr5[ 0 ])

    // let partCoefStr = arr5[1]
    // if (partCoefStr.charAt(0) === "0") partCoefStr = "0.".concat(partCoefStr.substring(1))
    // const partCoef = Number(partCoefStr)

    const wave = {
        ...waveDefaults,
        numberOfWaves,
        phaseCoef,
        pointStep,
        amplitudeFactor,
        // partCoef,
    }
    // console.log("wave", wave)
    return wave
}

const getWaveConfigPart = (name = "waves15phase05step10af10", partCoef = 1) => {

    const arr = name.split("waves")
    const arr2 = arr[ 1 ].split("phase")
    let numberOfWavesStr = arr2[ 0 ]
    if (numberOfWavesStr.charAt(0) === "0") numberOfWavesStr = "0.".concat(numberOfWavesStr.substring(1))
    const numberOfWaves = Number(numberOfWavesStr)
    const arr3 = arr2[ 1 ].split("step")
    let phaseCoefStr = arr3[ 0 ]
    if (phaseCoefStr.charAt(0) === "0") phaseCoefStr = "0.".concat(phaseCoefStr.substring(1))
    const phaseCoef = Number(phaseCoefStr)
    const arr4 = arr3[ 1 ].split("af")
    const pointStep = Number(arr4[ 0 ])
    const arr5 = arr4[ 1 ].split("part")
    const amplitudeFactor = Number(arr5[ 0 ])

    // let partCoefStr = arr5[1]
    // if (partCoefStr.charAt(0) === "0") partCoefStr = "0.".concat(partCoefStr.substring(1))
    // const partCoef = Number(partCoefStr)

    const wave = {
        ...waveDefaults,
        numberOfWaves,
        phaseCoef,
        pointStep,
        amplitudeFactor,
        partCoef,
    }
    // console.log("wave", wave)
    return wave
}


const getWavedSvgPathDsBetween2Points = (wavesSettings = {}) => {
    const settings = { ...defaultSkewPathDSettings, ...wavesSettings };
    const { waves, startXY, endXY, directAmplitude = undefined } = settings;

    if (!startXY || !endXY) return undefined;

    const [ x1, y1 ] = startXY;
    const [ x2, y2 ] = endXY;

    // get the vector form of the line
    const vx = x2 - x1;
    const vy = y2 - y1;

    // Get the length of the line in pixels
    const distPxTotal = Math.round(Math.sqrt(vx * vx + vy * vy));

    // Make the vector one pixel long to move along the line
    const px = vx / distPxTotal;
    const py = vy / distPxTotal;

    // a vector to move out from the line (at 90 deg to the ine)
    // Get the angle of the line in radians
    const ax = -py; // a for amplitude vector
    const ay = px;

    const minDistXY = Math.min(Math.abs(vx), Math.abs(vy));

    let lastPxNumber = 0;
    let lastPoint = [ x1, y1 ];
    let lastPointPrefix;

    const pathsDs = waves.map((wave) => {
        const {
            partCoef,
            offset,
            numberOfWaves,
            phaseCoef,
            pointPrefix,
            pointStep,
            waveLenZ,
            amplitudeZ,
            isWaveLenFromNumberOfwaves,
            isAmplitudeFromSkewLine,
            amplitudeFactor,
        } = wave;

        const amplitude = directAmplitude
            ? directAmplitude
            : isAmplitudeFromSkewLine
                ? Math.round(minDistXY / amplitudeFactor)
                : amplitudeZ;

        const distPx = partCoef * distPxTotal;
        const waveLen = isWaveLenFromNumberOfwaves
            ? distPx / numberOfWaves
            : waveLenZ;
        const phase = phaseCoef * distPx;

        const pathDBase = [];
        let x;
        let y;
        pathDBase.push([ "M", lastPoint[ 0 ], lastPoint[ 1 ] ].join(" "));
        const endPx = lastPxNumber + distPx + 0.5;

        for (let i = lastPxNumber + 1; i <= endPx; i = i + pointStep) {
            if (i > endPx) {
                i = endPx;
            }

            // Use the distance to get the current angle of the wave
            // based on the wave length and phase
            const ang = ((i + phase) / waveLen) * Math.PI * 2;

            // and at this position get sin
            const val = Math.sin(ang);

            // Scale to match the amplitude and move to offset
            // as the distance from the center of the line
            const amp = val * amplitude + offset;

            // Get line center at distance i using the pixel vector
            x = x1 + px * i;
            y = y1 + py * i;

            // Use the amp vector to move away from the line at 90 degree
            x = Math.round(x + ax * amp);
            y = Math.round(y + ay * amp);

            pathDBase.push(
                !!pointPrefix ? [ pointPrefix, x, y ].join(" ") : [ x, y ].join(" ")
            );
        }

        lastPoint = [ x, y ];
        lastPxNumber = lastPxNumber + distPx;
        lastPointPrefix = pointPrefix;
        return pathDBase;
    });

    const pathDBig = pathsDs.reduce((accDArr, curDArrOrig, i) => {
        const curDArr = [ ...curDArrOrig ];
        curDArr[ 0 ] = curDArr[ 0 ].replace("M", lastPointPrefix);
        return [ ...accDArr, ...curDArr ];
    }, []).reverse();

    pathDBig[ 0 ] = pathDBig[ 0 ].replace(lastPointPrefix, "M");

    return [ pathDBig, pathsDs ];
}

const getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame = (wavesSettings = {}) => {

    const {
        exists = false,
        startXY, // = { x: 0, y: 0 },
        endXY, // = { x: 0, y: 0 },
        isHorizontal = 1,
        waves = [],
        arrCoefsPadding = Array(Object.keys(waves).length).fill(1),
    } = wavesSettings;

    if (!exists || !startXY || !endXY) return undefined;

    //   const settings = { ...defaultSkewPathDSettingsForFrame, ...wavesSettings };

    const distBefore = 0
    const lastPxNumber = 0

    const x1start = startXY.x
    const y1start = startXY.y
    // if (isNaN(x1start) || isNaN(y1start)) {
    //     debugger
    // }
    let lastPoint = [ x1start, y1start ]
    let lastPointPrefix
    let prev = isHorizontal ? x1start : y1start

    const x2End = endXY.x
    const y2End = endXY.y

    // const vx = x2End - x1;
    // const vy = y2End - y1;

    // // Get the length of the line in pixels
    // const distPxTotal = Math.round(Math.sqrt(vx * vx + vy * vy));
    const maxPadding = isHorizontal ? y2End - y1start : x2End - x1start

    let isForward = true
    let toggledPaddingOsLimit

    const pathsDs = waves.map((wave, i) => {
        const coefP = arrCoefsPadding[ i ]
        const {
            partCoef,
            offset,
            numberOfWaves,
            phaseCoef,
            pointPrefix,
            pointStep,
            waveLenZ,
            amplitudeZ,
            isWaveLenFromNumberOfwaves,
            isAmplitudeFromSkewLine,
            amplitudeFactor,
        } = wave

        const [ x1, y1 ] = lastPoint
        // console.log("x1,y1", Math.round(x1), Math.round(y1))
        // if (isNaN(x1) || isNaN(y1)) {
        //     debugger
        // }
        // const x2 = x1 + Math.round(coefX * x2End)
        // const y2 = Math.round(coefP * toggledY2)

        if (isHorizontal) {
            toggledPaddingOsLimit = isForward ? y1 + Math.round(coefP * maxPadding) : y1 - Math.round(coefP * maxPadding) // y2End - Math.round((coefP) * maxPadding) // y1start - Math.round((1 - coefP) * maxPadding)
        } else {
            toggledPaddingOsLimit = isForward ? x1 + Math.round(coefP * maxPadding) : x1 - Math.round(coefP * maxPadding) // x2End - Math.round((coefP) * maxPadding) //x1start - Math.round((1 - coefP) * maxPadding)
        }

        const x2 = isHorizontal ? prev + Math.round(partCoef * (x2End - x1start)) : toggledPaddingOsLimit // Math.round(coefP * toggledPaddingLimits) //x1
        const y2 = isHorizontal ? toggledPaddingOsLimit : prev + Math.round(partCoef * (y2End - y1start))  //Math.round(coefP * toggledPaddingLimits)
        // console.log("x2,y2", x2, y2)
        // if (isNaN(x2) || isNaN(y2)) {
        //     debugger
        // }
        // console.log("partCoef", partCoef)
        // console.log("coefP", coefP)

        // get the vector form of the line
        const vx = x2 - x1;
        const vy = y2 - y1;

        // Get the length of the line in pixels
        const distPxTotal = Math.round(Math.sqrt(vx * vx + vy * vy));

        // Make the vector one pixel long to move along the line
        const px = vx / distPxTotal;
        const py = vy / distPxTotal;

        // We also need a vector to move out from the line (at 90 deg to the ine)
        // So rotate the pixel vector 90deg CW
        const ax = -py; // a for amplitude vector
        const ay = px;

        const minDistXY = Math.min(Math.abs(vx), Math.abs(vy))

        const amplitude = isAmplitudeFromSkewLine ? Math.round(minDistXY / amplitudeFactor) : amplitudeZ
        const distPx = distPxTotal
        const waveLen = isWaveLenFromNumberOfwaves ? distPx / numberOfWaves : waveLenZ
        const phase = phaseCoef * distPx

        const pathDBase = [];
        const [ xC, yC ] = lastPoint
        pathDBase.push([ "M", xC, yC ].join(" "));
        const endPx = lastPxNumber + distPx + 0.5

        for (let i = lastPxNumber + 1; i <= endPx; i = i + pointStep) {
            // fix i if past end
            if (i > endPx) {
                i = endPx
            } // Carefull dont mess with this ot it will block the page

            // Use the distance to get the current angle of the wave
            // based on the wave length and phase
            const ang = ((i + phase) / waveLen) * Math.PI * 2;

            // and at this position get sin
            const val = Math.sin(ang);

            // Scale to match the amplitude and move to offset
            // as the distance from the center of the line
            const amp = val * amplitude + offset;

            // Get line center at distance i using the pixel vector
            var x = x1 + px * i  // x1 + px * i // x1 + px * i;
            var y = y1 + py * i //y1start + py * i // y1 + py * i;

            // Use the amp vector to move away from the line at 90 degree
            x += ax * amp;
            y += ay * amp;

            pathDBase.push(
                !!pointPrefix ? [ pointPrefix, Math.round(x), Math.round(y) ].join(" ") : [ Math.round(x), Math.round(y) ].join(" ")
            );
            // Now add the point 
            // ctx.lineTo(x, y);
        }
        lastPoint = [ x, y ]
        prev = isHorizontal ? x : y //+ xPrev
        // console.log("lastPoint x y", Math.round(lastPoint[ 0 ]), Math.round(lastPoint[ 1 ]))
        // lastPxNumber = lastPxNumber + distPx
        lastPointPrefix = pointPrefix
        isForward = !isForward
        return pathDBase
    })

    // console.log("pathsDs", pathsDs)
    const pathDBig = pathsDs.reduce((accDArr, curDArrOrig, i) => {
        const curDArr = [ ...curDArrOrig ]
        curDArr[ 0 ] = curDArr[ 0 ].replace("M", lastPointPrefix)
        return [ ...accDArr, ...curDArr ]
    }, []).reverse()
    pathDBig[ 0 ] = pathDBig[ 0 ].replace(lastPointPrefix, "M")
    return [ pathDBig, pathsDs ]
}



export { getWavedPathD, getWavedSvgPathDsBetween2UhloprieckaPointsKeptInRectangleForFrame, getWavedSvgPathDsBetween2Points, getWaveConfig, getWaveConfigNoPartCoef, getWaveConfigPart }




