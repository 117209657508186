import React from "react"
const VideoTag = ({ src, title, type = "video/mp4", width = "400", ...props }) => (
  <video
    controls
    muted
    autoPlay={false}
    width={width}
    title={title}
    style={{ maxWidth: '100%' }}
  >
    <source src={src} type={type} />
  </video>
);
export default VideoTag;

