
const POLYGON_STAR1 = "polygon(50% 2.45%, 61.8% 38.77%, 100% 38.77%, 69.1% 61.23%, 80.9% 97.55%, 50% 75.1%, 19.1% 97.55%, 30.9% 61.23%, 0% 38.77%, 38.2% 38.77%)"
const POLYGON_STAR = "polygon(50% 2.45%, 55% 52%,      100% 38.77%, 55% 60%,      80.9% 97.55%, 50% 65%,   19.1% 97.55%, 45% 60%,      0% 38.77%, 48% 52%)"

const POLYGON_RECTANGLE_10_POINTS_1 = "polygon(0% 0%, 50% 0%, 100% 0%,100% 0%,100% 50%,100% 100%,100% 100%,50% 100%, 0% 100%,0% 0% )"
const POLYGON_RECTANGLE_10_POINTS_2 = "polygon(0% 0%, 100% 0%,100% 50%,100% 100%,100% 100%,50% 100%,50% 100%, 0% 100%, 0% 100%,0% 0% )"
const POLYGON_RECTANGLE_10_POINTS_LEFT = "polygon(0% 0%, 50% 0%, 100% 0%, 100% 35%, 100% 70%, 100% 100%, 50% 100%, 25% 100%, 0% 100%, 0% 35%)"
// const POLYGON_RECTANGLE_10_POINTS_LEFT_Units ="M0,0 L50,0 L100,0 L100,35 L100,70 L100,100 L50,100 L25,100 L0,100 L0,35 Z"" "polygon(0 0, 50 0, 100 0, 100 35, 100 70, 100 100, 50 100, 25 100, 0 100, 0 35)"
const POLYGON_RECTANGLE_10_POINTS_LEFT_Units = "m0,0 l50,0 l100,0 l100,35 l100,70 l100,100 l50,100 l25,100 l0,100 l0,35 Z" // "polygon(0 0, 50 0, 100 0, 100 35, 100 70, 100 100, 50 100, 25 100, 0 100, 0 35)"
const POLYGON_RECTANGLE_10_POINTS_RIGHT = "polygon(100% 0%, 100% 50%, 100% 100%, 50% 100%, 0% 100%, 0% 75%, 0% 50%, 0% 25%, 0% 0%, 50% 0%)"
const POLYGON_RECTANGLE = "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
const POLYGON_RECTANGLE_TL_BR_NULL = "polygon(0% 0%, 0% 0%, 100% 100%, 100% 100%)"
const POLYGON_RECTANGLE_TR_LB_NULL = "polygon(100% 0%, 100% 0%, 0% 100%, 0% 100%)"
const POLYGON_RECTANGLE_RIGHT_NULL = "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
const POLYGON_RECTANGLE_LEFT_NULL = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
const POLYGON_RECTANGLE_CLOSED_UP = "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"

const POLYGON_RECTANGLE_LTRB = "polygon(0% 0%, 100% 90%, 100% 100%, 0% 10%)"

const POLYGON_10 = "polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)"
const POLYGON_10_RIGHT_FULL = "polygon(50% 0%, 100% 0, 100% 35%, 100% 70%, 100% 100%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)"
const POLYGON_10_LEFT_FULL = "polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 0 100%, 0% 70%, 0% 35%, 0 0)"


function mergePaths(path1, path2) {
    // Remove the initial "M" command from the second path
    const path2WithoutInitialM = path2.replace(/^M[^LQZC]*[LQZC]/, '');

    // Concatenate the two paths
    const mergedPath = path1 + ' ' + path2WithoutInitialM;

    return mergedPath;
}

// const CURVE_RIGHT_FULL = "M0,0 Q50,50 100,0 V100 H0 Z";
// const CURVE_LEFT_FULL = "M0,0 Q50,50 0,100 V100 H100 Z";
// const CURVE_RIGHT_FULL = "M0.5,0 Q0,0.5 0.5,1";
// const CURVE_RIGHT_FULL = "M0.5,0 0.5,0 Q0,0.5 0.5,1 1,1 1,0";
// const CURVE_LEFT_FULL = "M0,0 0.5,0 Q1,0.5 0.5,1 0.6,1 1,1";
// const CURVE_RIGHT_FULL = "M0.5,0 Q0,0.5 0.5,1";
const CURVE_RIGHT_FULL = "M1,0 L0.5,0 Q0,0.5 0.5,1 L1,1";
// const CURVE_RIGHT_FULL = "M0,0 Q0,0.5 0,1 L1,1";
// const CURVE_LEFT_FULL = "M0.5,0 Q1,0.5 0.5,1";
const CURVE_LEFT_FULL = "M0,0 L0.5,0 Q1,0.5 0.5,1 L0,1";

// const CURVE_LEFT_HALF_ELLIPSE = "M0.5,0 Q0,0.5 0.5,1";
const CURVE_LEFT_HALF_ELLIPSE = "M1,0 L0.5,0 Q0,0.5 0.5,1 L1,1";
const CURVE_LEFT_HALF_ELLIPSE_80 = "M1,0 L0.8,0 Q0,0.5 0.8,1 L1,1";
const CURVE_LEFT_HALF_ELLIPSE_20 = "M1,0 L0.2,0 Q0,0.5 0.2,1 L1,1";
const CURVE_RIGHT_HALF_ELLIPSE = "M0,0 L0.5,0 Q1,0.5 0.5,1 L0,1";
const CURVE_RIGHT_HALF_ELLIPSE_20 = "M0,0 L0.2,0 Q1,0.5 0.2,1 L0,1";
const CURVE_RIGHT_HALF_ELLIPSE_80 = "M0,0 L0.8,0 Q1,0.5 0.8,1 L0,1";
const CURVE_ELLIPSE = "M0.5,0 Q1,0.5 0.5,1 Q0,0.5 0.5,0";
const CURVE_FULL_ELLIPSE = "M1,0 L0.5,0 Q0,0.5 0.5,1 L1,1 L0.5,1 Q1,0.5 0.5,0 L0,0";
const CHARACTER_A = "M0.2,1 L0.5,0 L0.8,1 L0.7,1 L0.5,0.2 L0.3,1 Z"  //"M0.5,0 L0.8,0 Q1,0.5 0.8,1 L0.5,1 L0.2,1 Q0,0.5 0.2,0 L0.5,0"
const CHARACTER_B = "M0,0 L0.2,0 Q0,0.5 0.2,1 L0.5,1 L0.8,1 Q1,0.5 0.8,0 L0.5,0"
// const INSET_2_3 = "inset(0% 70% 0% 29% round 8px)"
const INSET_2_3 = "inset(0% 70% 0% 29%)"
const INSET_1_3 = "inset(0% 29% 0% 70%)"
const INSET_LEFT = "inset(0% 1% 0% 99%)"
const INSET_RIGHT = "inset(0% 99% 0% 1%)"
const INSET_LB_RT = "inset(0% 99% 0% 99%)"
const INSET_LT_RB = "inset(0% 89% 0% 89%)"
const INSET_FULL = "inset(0% 0% 0% 0%)"

const CURVE_ELLIPSE_80_20 = mergePaths(CURVE_LEFT_HALF_ELLIPSE_80, CURVE_RIGHT_HALF_ELLIPSE_20)
const CURVE_ELLIPSE_20_80_2 = mergePaths(CURVE_RIGHT_HALF_ELLIPSE_20, CURVE_LEFT_HALF_ELLIPSE_80)
const CURVE_ELLIPSE_20_80 = mergePaths(CURVE_LEFT_HALF_ELLIPSE_20, CURVE_RIGHT_HALF_ELLIPSE_80)
const CURVE_ELLIPSE_20_80_hard_2 = "M0.8,0 L0.2,0 Q0,0.5 0.2,1 L0.8,1 0.8,0 Q1,0.5 0.8,1 L0,1"

const CURVE_FULL_ELLIPSE_40 = "M1,0 L0.6,0 Q0,0.5 0.6,1 L1,1 L0.4,1 Q1,0.5 0.4,0 L0,0";

const CURVE_ELLIPSE_20_80_hard_asym = "M0.8,0 L0.2,0 Q0,0.5 0.1,0.9 L0.2,1 L0.8,1 Q1,0.5 0.9,0.1 L0.8,0"
const CURVE_ELLIPSE_20_80_hard = "M0.8,0 L0.2,0 Q0,0.5 0.2,1 L0.3,1 L0.8,1 Q1,0.5 0.8,0 L1,0"
const CURVE_ELLIPSE_20_80_hard_asym_b = "M0.8,0 L0.2,0.1 Q0,0.5 0.3,1 L0.5,1 L0.8,0.9 Q1,0.5 0.75,0 L0.65,0"


function convertPolygonToPath(polygonString) {
    // Remove the "polygon(" and ")" parts
    const pointsString = polygonString.replace('polygon(', '').replace(')', '');

    // Split the string by "," to get an array of points
    const points = pointsString.split(', ');

    // Map over the array of points
    const pathPoints = points.map((point, index) => {
        // Remove any "%" signs and split the point by space to get the x and y coordinates
        const [ x, y ] = point.replace('%', '').split(' ').map(Number);

        // Scale the coordinates by a factor of 10
        const scaledX = x * 10;
        const scaledY = y * 10;

        // If it's the first point, we need to use a "m" command, otherwise we use a "l" command
        const command = index === 0 ? 'm' : 'l';

        // Return the command and the scaled coordinates
        return `${command}${scaledX},${scaledY}`;
    });

    // Join the array of points back together with spaces, and prepend a "m" command and append a "Z"
    const pathString = `${pathPoints.join(' ')} Z`;

    return pathString;
}

export { POLYGON_RECTANGLE_TL_BR_NULL, POLYGON_RECTANGLE_TR_LB_NULL, POLYGON_RECTANGLE_LEFT_NULL, POLYGON_RECTANGLE_RIGHT_NULL, CHARACTER_A, CHARACTER_B, convertPolygonToPath, POLYGON_RECTANGLE_10_POINTS_LEFT_Units, mergePaths, CURVE_FULL_ELLIPSE_40, CURVE_ELLIPSE_20_80_hard, CURVE_ELLIPSE_20_80_hard_asym, CURVE_ELLIPSE_20_80_hard_asym_b, CURVE_ELLIPSE_80_20, CURVE_ELLIPSE_20_80, CURVE_RIGHT_HALF_ELLIPSE_20, CURVE_LEFT_HALF_ELLIPSE_80, CURVE_FULL_ELLIPSE, CURVE_ELLIPSE, CURVE_RIGHT_FULL, CURVE_LEFT_FULL, POLYGON_10, POLYGON_10_RIGHT_FULL, POLYGON_10_LEFT_FULL, POLYGON_RECTANGLE, POLYGON_RECTANGLE_CLOSED_UP, POLYGON_RECTANGLE_LTRB, POLYGON_STAR, POLYGON_STAR1, POLYGON_RECTANGLE_10_POINTS_1, POLYGON_RECTANGLE_10_POINTS_2, POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_RECTANGLE_10_POINTS_RIGHT, INSET_LEFT, INSET_RIGHT, INSET_2_3, INSET_1_3, INSET_LB_RT, INSET_LT_RB, INSET_FULL }