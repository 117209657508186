import React, { lazy, Suspense } from 'react';

const NeededFromOwnerForWebPages = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'NeededFromOwnerForWebPagesLazy' */ './NeededFromOwnerForWebPages'));

function NeededFromOwnerForWebPagesLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading NeededFromOwnerForWebPages ...</div>}>
                <NeededFromOwnerForWebPages {...props} />
            </Suspense>
        </div>
    );
}

export default NeededFromOwnerForWebPagesLazy;