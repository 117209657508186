import React, { useState } from "react";
import { useForm, useController } from "react-hook-form";
import { motion, } from 'framer-motion'
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import BowListLazy from "../../_shared/shaping/BowListLazy"
import AlertOkDialog from "../../_shared/alertOkDialog"

const bowListOptions = {
  itemHeightPx: 100,
  listHeightPx: "auto",
  primarySecondarySeparator: "|",

}
const bowListOptionsAddedListBothBowsL = {
  bothBows: true,
  bowDirection: "left",
  motionDirection: "left",
}

const styleBottomBorders20 = {
  borderBottomLeftRadius: "20%",
  borderBottomRightRadius: "20%",
}

const styleSxWrap = {
  padding: 2,
  // bgcolor: "primary.main",
  borderRadius: "inherit",
  boxShadow: 11,
  fontSize: [ "smaller !important", "small !important", "medium !important" ],
  "& .paperWrap": {
    p: { xl: 1 },
    textAlign: 'center',
    borderRadius: "20px",
    "& .MuiPaper-elevation1": {
      boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    "& h5": {
      fontSize: 'medium',
    },
    "& .formPaper": {
      py: 0,
      px: 1,
      my: 2,

    },
    "& input, textarea": {
      fontSize: 'medium',
    },
    "& .divWrapButton": {
      borderRadius: "20px",
      p: 1,
      // bgcolor: "primary.main",
      boxShadow: 11,
      "& .paperWrapButton": {
        p: 1,
        "& .btn": {
          // bgcolor: "secondary.dark",
          // color: "secondary.contrastText",
          fontSize: 'small',
        }
      },
    },
  },
}


const formSendTo =
  "https://5mti2xasmh.execute-api.eu-central-1.amazonaws.com/dev/static-site-mailer";



function FormInput({ control, name, rules = { required: false }, defaultValue = "", propsDirectly, required = true, className = "" }) {
  const {
    field: { ref, ...inputProps }, //: { onChange, onBlur, value, name, ref },
    fieldState: { error }, // invalid, isTouched, isDirty
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const label = required ? error ? `${name}* ${error.message}` : `${name}*` : name
  const isErr = !!error
  return (<TextField
    {...inputProps}
    inputRef={ref}
    label={label}
    error={isErr}
    className={className}
    {...propsDirectly}

  />);
}


const propsDirectlyMeno = {
  type: "text",
  id: "meno",
  variant: "outlined",
  margin: "normal",
  fullWidth: true,
  placeholder: "Vaše meno",
}

const propsDirectlyEmail = {
  type: "email",
  id: "email",
  variant: "outlined",
  margin: "normal",
  fullWidth: true,
  placeholder: "abc@yyy.com",
}


const propsDirectlyTel = {
  type: "tel",
  id: "tel",
  variant: "outlined",
  margin: "normal",
  fullWidth: true,
  placeholder: "0987 6543 210",
}

const propsDirectlyPredmet = {
  type: "text",
  id: "predmet",
  variant: "outlined",
  margin: "normal",
  fullWidth: true,
  placeholder: "Predmet správy",
}

const propsDirectlySprava = {
  type: "text",
  id: "sprava",
  variant: "outlined",
  margin: "normal",
  fullWidth: true,
  placeholder: "Vaša správa",
  multiline: true,
  minRows: 10,
}



const varFormVyzva = {
  initial: false,
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },

  exit: {
    y: -200,
    opacity: 0.5,
    transition: {
      duration: 0.1,
    },
  },
}


const KontaktForm = () => {

  const {
    handleSubmit,
    control } = useForm({
      mode: "onTouched",
    });

  const [ alertOpen, setAlertOpen ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState();

  const btnOKdescription = "Zatvoriť"

  const fnAfterSubmit = () => {
    var myform = document.querySelector("form[name='contact']");

    myform.reset();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setAlertOpen(false);
  };

  const onSubmit = (data, e) => {

    e.preventDefault();

    fetch(formSendTo, {
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

      .then((backFromPosting) => {

        setAlertMessage(
          `Ďakujeme za správu, ${data.Meno}. Ozveme sa čo najskôr.`
        );
        setAlertOpen(true);
      })
      .catch((error) => {
        alert(error)
        throw (error)
      });

  };

  return (
    <Box component="div"
      sx={styleSxWrap}
    >
      <div
        className="paperWrap MuiPaper-elevation1"
      >

        {alertOpen && (
          <AlertOkDialog
            // message={alertMessage}
            opened={alertOpen}
            handleClose={fnAfterSubmit}
            btnOKdescription={btnOKdescription}
          >
            {alertMessage}
          </AlertOkDialog>)}
        <motion.div
          variants={varFormVyzva}
          initial="initial"
          animate="animate"
          style={{ y: -200, }}
          key="formVyzva"
        >
          <BowListLazy
            name="BL-kontaktForm-Napiste"
            options={{
              // nameStyleList: "primary",
              // nameStyleItem: "secondary",
              ...bowListOptions, ...bowListOptionsAddedListBothBowsL,
              styleList: {
                ...styleBottomBorders20
              },
              disableAlignTextToBow: true,
            }}
            key="BL-kontaktForm-Napiste"
          >
            {[ <Typography variant="h5" key="writeUs">
              Napíšte, opýtajte sa ...
            </Typography> ]}
          </BowListLazy>
        </motion.div>
        <form
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >

          <input type="hidden" name="form-name" value="contact" />
          <p style={{ display: "none" }}>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <Paper
            className="formPaper"
          >

            <FormInput
              control={control}
              name="Meno"
              rules={{
                required: 'treba uviesť',
                minLength: 2,
                maxLength: 50,
                // pattern: /^[A-Za-z ]+$/i
              }}
              defaultValue=""
              propsDirectly={propsDirectlyMeno}
            />
          </Paper>

          <section>
            <Paper
              className="formPaper"
            >

              <FormInput
                control={control}
                name="Email"
                rules={{
                  required: 'treba uviesť',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Email* !nemá platný tvar (abc@yyy.com)'
                  }
                }}
                defaultValue=""
                propsDirectly={propsDirectlyEmail}
              />
            </Paper>
          </section>
          <Paper
            className="formPaper"
          >

            <FormInput
              control={control}
              name="Tel"
              rules={{
                required: false,
                minLength: 7,
                maxLength: 15,
                pattern: /^[0-9 ]+$/i
              }}
              defaultValue=""
              propsDirectly={propsDirectlyTel}
              required={false}
            />
          </Paper>
          <Paper
            className="formPaper"
          >

            <FormInput
              control={control}
              name="Predmet"
              rules={{
                required: 'musí byť',
                minLength: 5,
                maxLength: 50,
              }}
              defaultValue=""
              propsDirectly={propsDirectlyPredmet}
            />
          </Paper>
          <Paper
            className="formPaper"
          >

            <FormInput
              control={control}
              name="Správa"
              rules={{
                required: '- bez správy ??? - no way...',
                minLength: 2,
                maxLength: 500,
              }}
              defaultValue=""
              propsDirectly={propsDirectlySprava}
            />
          </Paper>
          <div
            className="divWrapButton"
          >
            <Paper
              className="paperWrapButton"
            >
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                className="btn MuiPaper-elevation3"
              >
                Odoslať
              </Button>
            </Paper>
          </div>
        </form>
      </div>
    </Box>
  );
};
export default KontaktForm;