import React, { useCallback } from 'react';
import useResizeObserver from "use-resize-observer";
import DivsAroundEllipseMotion from './shaping/divsAroundEllipseMotion/DivsAroundEllipseMotion';


const itemMaxWidthPx = 200

const CardsAroundEllipseMotion = ({ imagesNodes, rx = 200, ry = 200, offset = 0, widthAround = 250, heightAround = 250, cw = true, sizesIntoStyle = true, size }) => {

    const { ref, width = 1 } = useResizeObserver(); // height = 1 
    // const currentWidth = Math.round(width)

    const arrItemData = imagesNodes.map((imgNode, i) => {

        const image = imgNode.childImageSharp.gatsbyImageData
        const { name } = imgNode
        const cardOptions = { index: i, imgName: name, imgSrc: image.images.fallback.src, itemMaxWidthPx }

        return {
            rotating: name,
            cardOptions: cardOptions
        }
    })

    const getCorrectedDimensions = useCallback(() => {
        const widthEllipseWithAround = 2 * rx + 2 * widthAround;
        const coef = width ? width / widthEllipseWithAround : 1; // 1920; // or any other base width
        const corrected = {
            rx: Math.round(coef * rx),
            widthAround: Math.round(coef * widthAround),
            ry: Math.round(coef * ry),
            heightAround: Math.round(coef * heightAround),
        };
        return corrected
    }, [ rx, widthAround, ry, heightAround, width ])

    const corrSizes = getCorrectedDimensions()

    return (
        <div ref={ref}>
            <DivsAroundEllipseMotion arrItemData={arrItemData} rx={corrSizes.rx} ry={corrSizes.ry} offset={0} widthAround={corrSizes.widthAround} heightAround={corrSizes.heightAround} cw={true} sizesIntoStyle={false} />
        </div>
    )
}
export default CardsAroundEllipseMotion


